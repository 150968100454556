var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import TuneIcon from '@mui/icons-material/Tune';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import '../../../../components/filters/styles/FiltersHeader.css';
import SearchEntities from '../../../../components/filters/SearchEntities';
import LimitationEntities from '../../../../components/filters/LimitationEntities';
import EntitiesPagination from '../../../../components/filters/EntitiesPagination';
import { useNavigate } from 'react-router-dom';
import { usePermission } from '../../../../hooks/usePermission';
var CostosRepuestosFiltersHeader = function (_a) {
    var currentPage = _a.currentPage, setCurrentPage = _a.setCurrentPage, totalPages = _a.totalPages, selectMode = _a.selectMode, handleSelectModeToggle = _a.handleSelectModeToggle, handleSelectAllToggle = _a.handleSelectAllToggle, selectedCostosRepuestos = _a.selectedCostosRepuestos, costosRepuestos = _a.costosRepuestos, limitPerPage = _a.limitPerPage, setLimitPerPage = _a.setLimitPerPage, showSearchResults = _a.showSearchResults, searchResults = _a.searchResults, totalItems = _a.totalItems, setShowSearchResults = _a.setShowSearchResults, setSearchResults = _a.setSearchResults, handleSearch = _a.handleSearch, setSearchKeyword = _a.setSearchKeyword, setShowAdvancedFiltersCostosRepuestosModal = _a.setShowAdvancedFiltersCostosRepuestosModal, setShowDownloadCostosRepuestosModal = _a.setShowDownloadCostosRepuestosModal, CostosRepuestosPageType = _a.CostosRepuestosPageType;
    var navigate = useNavigate();
    var canCreateCostosRepuestos = usePermission('costos_repuestos', 'crear').canAccess;
    var navigateToRegister = function () {
        navigate('/costos-repuestos/register');
    };
    return (_jsxs("div", { className: "FiltersHeader-filters-container", children: [_jsx(EntitiesPagination, { currentPage: currentPage, setCurrentPage: setCurrentPage, totalPages: totalPages }), _jsxs("div", { className: "FiltersHeader-select-items-container", children: [_jsx("div", { className: "FiltersHeader-select-items", onClick: handleSelectModeToggle, children: selectMode ? 'Cancelar /' : 'Seleccionar' }), selectMode && (_jsxs(_Fragment, { children: [_jsx("div", { className: "FiltersHeader-select-all-items", onClick: handleSelectAllToggle, children: selectedCostosRepuestos.length ===
                                    (showSearchResults ? searchResults.length : costosRepuestos.length)
                                    ? 'Deseleccionar Todo'
                                    : 'Seleccionar Todo' }), _jsx("div", { className: "FiltersHeader-selected-count", children: "".concat(selectedCostosRepuestos.length, "/").concat(showSearchResults ? searchResults.length : costosRepuestos.length) })] }))] }), _jsx(LimitationEntities, { limitPerPage: limitPerPage, setLimitPerPage: setLimitPerPage }), _jsx("div", { className: "FiltersHeader-title ".concat(CostosRepuestosPageType === 'CostosRepuestos-Pages' ? 'costosRepuestosPagesTitle' : ''), children: CostosRepuestosPageType === 'CostosRepuestos-Detail'
                    ? 'COSTOS DE REPUESTOS DETALLE'
                    : 'COSTOS DE REPUESTOS' }), _jsx("div", { className: "FiltersHeader-document-count", children: showSearchResults ? (_jsxs("p", { className: "FiltersHeader-totalItems", children: [_jsx("span", { className: "FiltersHeader-highlight", children: searchResults.length }), " de", ' ', _jsx("span", { className: "FiltersHeader-highlight", children: totalItems })] })) : (_jsxs("p", { className: "FiltersHeader-totalItems", children: [_jsx("span", { className: "FiltersHeader-highlight", children: costosRepuestos.length }), " de", ' ', _jsx("span", { className: "FiltersHeader-highlight", children: totalItems })] })) }), _jsx(SearchEntities, { showSearchResults: showSearchResults, setShowSearchResults: function (show) {
                    setShowSearchResults(show);
                    if (!show)
                        setCurrentPage(1);
                }, setSearchResults: setSearchResults, handleSearch: function (keyword) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setSearchKeyword(keyword);
                                setCurrentPage(1);
                                return [4 /*yield*/, handleSearch(keyword, 1, limitPerPage)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }), _jsx(TuneIcon, { className: "FiltersHeader-filters-icon", onClick: function () { return setShowAdvancedFiltersCostosRepuestosModal(true); } }), _jsx(MoreVertIcon, { className: "FiltersHeader-actions-icon", onClick: function () { return setShowDownloadCostosRepuestosModal(true); } }), canCreateCostosRepuestos && (_jsx(AddIcon, { className: "FiltersHeader-add-icon", onClick: navigateToRegister }))] }));
};
export default CostosRepuestosFiltersHeader;
