import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import RegisterSolicitudServicioForm from '../components/solicitudes_servicios/forms/RegisterSolicitudServicioForm';
var RegisterEquipoModeloPage = function () {
    var _a = useState(true), showForm = _a[0], setShowForm = _a[1];
    var handleCancel = function () {
        setShowForm(false);
    };
    return (_jsxs("div", { children: [_jsx(DashboardMenuLateral, {}), showForm && _jsx(RegisterSolicitudServicioForm, { onCancel: handleCancel })] }));
};
export default RegisterEquipoModeloPage;
