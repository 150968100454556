import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { View, Text, StyleSheet } from '@react-pdf/renderer';
var styles = StyleSheet.create({
    resultadoSection: {
        marginBottom: 10,
        pageBreakInside: 'avoid',
    },
    resultTitle: {
        backgroundColor: '#00ddfa',
        height: 29,
        justifyContent: 'center',
        alignItems: 'center',
    },
    resultText: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    section: {
        width: '48%',
        marginTop: 10,
        pageBreakInside: 'avoid',
    },
    sectionTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    sectionContent: {
        fontSize: 9,
        fontWeight: 'normal',
        textAlign: 'center',
        marginTop: 5,
    },
    dadoBajaSection: {
        marginTop: 10,
        backgroundColor: '#facc00',
        height: 18,
        justifyContent: 'center',
        alignItems: 'center',
        pageBreakInside: 'avoid',
    },
    dadoBajaText: {
        color: '#ffffff',
        fontSize: 9,
        textAlign: 'center',
    },
});
var OrdenesPDFResultadoPreventivoSection = function (_a) {
    var orden = _a.orden;
    var resultado_orden = orden.resultado_orden;
    return (_jsxs(View, { style: styles.resultadoSection, children: [_jsx(View, { style: styles.resultTitle, children: _jsx(Text, { style: styles.resultText, children: "RESULTADO" }) }), _jsxs(View, { style: styles.gridContainer, children: [_jsxs(View, { style: styles.section, children: [_jsx(Text, { style: styles.sectionTitle, children: "Solicitud" }), _jsx(Text, { style: styles.sectionContent, children: orden.id_solicitud_servicio.observacion || 'NA' })] }), _jsxs(View, { style: styles.section, children: [_jsx(Text, { style: styles.sectionTitle, children: "Comentarios Finales" }), _jsx(Text, { style: styles.sectionContent, children: (resultado_orden === null || resultado_orden === void 0 ? void 0 : resultado_orden.comentarios_finales) || 'No especificado' })] })] }), (resultado_orden === null || resultado_orden === void 0 ? void 0 : resultado_orden.solicitud_dar_baja) && (_jsx(View, { style: styles.dadoBajaSection, children: _jsx(Text, { style: styles.dadoBajaText, children: "Se solicita dado de baja" }) }))] }));
};
export default OrdenesPDFResultadoPreventivoSection;
