import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import { usePermission } from "../../../../hooks/usePermission";
var DashboardMenuCentralProcesosProtocolos = function () {
    var navigate = useNavigate();
    var canViewCampos = usePermission('campos', 'verTodos').canAccess;
    var canViewPreventivos = usePermission('preventivos', 'verTodos').canAccess;
    return (_jsxs("ul", { className: "DashboardMenuCentral-nav-ul", children: [canViewPreventivos && (_jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/preventivos'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(FlakyOutlinedIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Preventivos" })] }) }) })), canViewCampos && (_jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/campos'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(FormatListBulletedOutlinedIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Campos" })] }) }) }))] }));
};
export default DashboardMenuCentralProcesosProtocolos;
