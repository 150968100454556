// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CotizacionesByIdOrden-container {
    height: 34.54vh; /* 373px */
    left: 30.63vw; /* 588px */
    top: 15.09vh; /* 163px */
    width: 20.36vw; /* 391px */
    position: relative;
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/ordenes_servicios/styles/CotizacionesByIdOrden.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,UAAU;IAC3B,aAAa,EAAE,UAAU;IACzB,YAAY,EAAE,UAAU;IACxB,cAAc,EAAE,UAAU;IAC1B,kBAAkB;EACpB","sourcesContent":[".CotizacionesByIdOrden-container {\n    height: 34.54vh; /* 373px */\n    left: 30.63vw; /* 588px */\n    top: 15.09vh; /* 163px */\n    width: 20.36vw; /* 391px */\n    position: relative;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
