import axios from '../../../utils/config/axios.config';
export var getAllEmpresas = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/empresas', options)
        .then(function (response) { return response.data.empresas; }) // Ajusta para obtener la propiedad "empresas" de la respuesta
        .catch(handleError);
};
export var getEmpresaById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/empresas/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createEmpresa = function (token, empresaData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/empresas', empresaData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createMultipleEmpresas = function (token, empresasData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/empresas/multiple', empresasData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateEmpresa = function (token, id, empresaData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/empresas?id=".concat(id), empresaData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteEmpresaById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/empresas/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // window.location.href = '/login';
            console.log(error);
        }
    }
    throw error;
};
