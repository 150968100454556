var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { validateOTP } from '../../services/authService';
import './styles/OTPValidatorForm.css';
var OTPValidationForm = function (_a) {
    var email = _a.email;
    var _b = useState(''), validationMessage = _b[0], setValidationMessage = _b[1];
    var navigate = useNavigate();
    useEffect(function () {
        var otpField = document.getElementById('otp');
        if (otpField.value) {
            otpField.classList.add('filled');
        }
        var handleInput = function (event) {
            var input = event.target;
            if (input.value) {
                input.classList.add('filled');
            }
            else {
                input.classList.remove('filled');
            }
        };
        otpField.addEventListener('input', handleInput);
        return function () {
            otpField.removeEventListener('input', handleInput);
        };
    }, []);
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, validateOTP(email, values.otp)];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        console.log('OTP is valid, redirecting to update-password');
                        navigate("/update-password", { state: { email: email } });
                    }
                    else {
                        setValidationMessage('El código OTP no es válido o está expirado. Inténtalo nuevamente.');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setValidationMessage('Error al validar el código OTP. Inténtalo nuevamente.');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { children: [_jsx(Formik, { initialValues: { otp: '' }, onSubmit: handleSubmit, children: _jsx("div", { className: 'OTPValidatorForm-box', children: _jsxs(Form, { className: 'OTPValidatorForm-form', children: [_jsx("h2", { children: "\u00A1Hola!" }), _jsxs("div", { className: 'OTPValidatorForm-inputBox', children: [_jsxs("p", { className: 'OTPValidatorForm-p', children: ["Por favor, ingrese el c\u00F3digo de 6 d\u00EDgitos que recibi\u00F3 en su bandeja de mensajes en su direcci\u00F3n de correo electr\u00F3nico: ", email] }), _jsx("br", {}), _jsx(Field, { className: 'OTPValidatorForm-Field', type: "text", id: "otp", name: "otp", autoComplete: 'off', required: true }), _jsx("span", { children: "Ej: xxx-xxx" }), " ", _jsx("i", {}), " "] }), _jsx("br", {}), _jsx("button", { className: 'OTPValidatorForm-button', type: "submit", children: "Validar" })] }) }) }), validationMessage && _jsx("p", { style: { color: 'red' }, children: validationMessage })] }));
};
export default OTPValidationForm;
