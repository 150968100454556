import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import logo from '../../Landing/images/hero/logoBioswfitHero.png';
import '../styles/LoadingScreen.css';
var LoadingScreen = function (_a) {
    var _b = _a.duration, duration = _b === void 0 ? 5000 : _b, _c = _a.checkDisplayDuration, checkDisplayDuration = _c === void 0 ? 2000 : _c;
    var _d = useState(0), loadingProgress = _d[0], setLoadingProgress = _d[1];
    var _e = useState(false), showCheck = _e[0], setShowCheck = _e[1];
    var _f = useState(true), isLoading = _f[0], setIsLoading = _f[1];
    useEffect(function () {
        var intervalDuration = 50; // Intervalo de actualización en milisegundos
        var interval = setInterval(function () {
            setLoadingProgress(function (prev) {
                var next = prev + (intervalDuration / duration) * 100;
                if (next >= 100) {
                    clearInterval(interval);
                    setLoadingProgress(100);
                    setShowCheck(true); // Mostrar el ícono de verificación
                    // Ocultar la pantalla de carga después de mostrar el ícono de verificación
                    setTimeout(function () {
                        setIsLoading(false);
                    }, checkDisplayDuration);
                    return 100;
                }
                return next;
            });
        }, intervalDuration);
        return function () { return clearInterval(interval); };
    }, [duration, checkDisplayDuration]);
    if (!isLoading)
        return null;
    return (_jsxs("div", { className: "loading-screen", children: [_jsx("img", { src: logo, alt: "Bioswift Logo", className: "loading-logo" }), _jsx("div", { className: "progress-bar-container", children: _jsx("div", { className: "progress-bar", style: { width: "".concat(loadingProgress, "%") } }) }), showCheck && _jsx(CheckCircleIcon, { className: "check-icon" })] }));
};
export default LoadingScreen;
