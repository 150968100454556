import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import useUserRoleVerifier from "../../hooks/useUserRoleVerifier";
import ConstructionIcon from '@mui/icons-material/Construction';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { usePermission } from '../../../../hooks/usePermission';
var DashboardCentralVisitas = function () {
    var navigate = useNavigate();
    var isAdmin = useUserRoleVerifier(['administrador']);
    var canViewVisitas = usePermission('visitas', 'verTodos').canAccess;
    return (_jsxs("ul", { className: "DashboardMenuCentral-nav-ul", children: [(canViewVisitas &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/visitas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(ConstructionIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Visitas" })] }) }) })), (canViewVisitas &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/visitas-pendientes'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(ConstructionIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Visitas Pendientes" })] }) }) })), (canViewVisitas &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/visitas-abiertas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(ConstructionIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Visitas Abiertas" })] }) }) })), (canViewVisitas &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/visitas-cerradas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsxs("i", { className: 'DashboardMenuCentral-nav-icon-i', children: [_jsx(ConstructionIcon, { className: 'DashboardMenuCentral-icon' }), _jsx(DoDisturbOnIcon, { className: 'DashboardMenuCentral-closed-icon' })] }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Visitas Cerradas" })] }) }) })), _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/home'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(CalendarMonthIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Calendario Visitas" })] }) }) })] }));
};
export default DashboardCentralVisitas;
