import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { usePermission } from '../../../../hooks/usePermission';
var DashboardMenuCentralGenerales = function () {
    var navigate = useNavigate();
    // Obtenemos el par { canAccess, isLoading }
    var _a = usePermission('usuarios', 'verTodos'), canViewUsuarios = _a.canAccess, isLoading = _a.isLoading;
    var canViewClientes = usePermission('clientes', 'verTodos').canAccess;
    var canViewSedes = usePermission('sedes', 'verTodos').canAccess;
    var canViewContratos = usePermission('contratos', 'verTodos').canAccess;
    // Mientras no hayas terminado de cargar permisos, podrías mostrar un <Spinner/> o algo mínimo
    if (isLoading) {
        return _jsx("div", { children: "Cargando men\u00FA..." });
    }
    return (_jsxs("ul", { className: "DashboardMenuCentral-nav-ul", children: [canViewUsuarios && (_jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/users'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: "DashboardMenuCentral-nav-icon-i", children: _jsx(PersonIcon, { className: "DashboardMenuCentral-icon" }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Usuarios" })] }) }) })), canViewClientes && (_jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/clientes'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: "DashboardMenuCentral-nav-icon-i", children: _jsx(AccountBalanceIcon, { className: "DashboardMenuCentral-icon" }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Clientes" })] }) }) })), canViewSedes && (_jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/sedes'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: "DashboardMenuCentral-nav-icon-i", children: _jsx(ApartmentIcon, { className: "DashboardMenuCentral-icon" }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Sedes" })] }) }) })), canViewContratos && (_jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/contratos'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: "DashboardMenuCentral-nav-icon-i", children: _jsx(ReceiptIcon, { className: "DashboardMenuCentral-icon" }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Contratos" })] }) }) }))] }));
};
export default DashboardMenuCentralGenerales;
