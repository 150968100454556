import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import './styles/RegisterCotizacionForm.css';
var RegisterCotizacionForm = function (_a) {
    var _b = useState(false), isFormVisible = _b[0], setIsFormVisible = _b[1]; // Estado para manejar visibilidad del formulario
    var handleCotizacionSuccess = function () {
        // Lógica cuando la cotización se registra con éxito
        alert('Cotización registrada exitosamente.');
    };
    var handleCancel = function () {
        // Lógica para cancelar el registro
    };
    return (_jsx("div", { className: "RegisterCotizacionForm-container", children: _jsx("div", { className: "RegisterCotizacionForm-form-container", children: _jsx("p", { children: "In Progress..." }) }) }));
};
export default RegisterCotizacionForm;
