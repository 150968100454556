import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/EquipoCard.css';
var EquipoCard = function (_a) {
    var equipo = _a.equipo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "EquipoCard-box ".concat(isSelected ? 'EquipoCard-selected' : '', "\n    }");
    return (_jsxs("li", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '102%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#00ddfa',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#00ddfa',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx(FaxOutlinedIcon, { className: "EquipoCard-div" }), _jsxs("p", { className: 'EquipoCard-oid', children: ["ID: ", equipo ? equipo._id : 'N/A'] }), _jsx("div", { className: "EquipoCard-text-wrapper", onClick: onClick, children: equipo.modelo_equipos ? equipo.modelo_equipos.id_clase.clase : 'N/A' }), _jsx("div", { className: "EquipoCard-equipospages-card-x", children: "Client: " }), _jsx("div", { className: "EquipoCard-equipospages-card-2", children: equipo.id_sede.id_client ? equipo.id_sede.id_client.client_name : 'N/A' }), _jsxs("div", { className: "EquipoCard-overlap-2", children: [_jsx("div", { className: "EquipoCard-equipospages-card-3", children: "Sede:" }), _jsx("div", { className: "EquipoCard-equipospages-card-4", children: equipo.id_sede ? equipo.id_sede.sede_nombre : 'Sede no especificada' })] }), _jsxs("div", { className: "EquipoCard-overlap-3", children: [_jsx("div", { className: "EquipoCard-equipospages-card-5", children: "SN:" }), _jsx("div", { className: "EquipoCard-equipospages-card-6", children: equipo ? equipo.serie : 'N/A' })] }), _jsx(CheckOutlinedIcon, { className: "EquipoCard-check" })] }, equipo._id));
};
export default EquipoCard;
