import axios from '../../../utils/config/axios.config';
export var getAllCausasFallas = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/fallas-causas', options)
        .then(function (response) { return response.data.fallasCausas; })
        .catch(handleError);
};
export var getCausaFallasById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/fallas-causas/".concat(id), options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createCausaFallas = function (token, causaFallasData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/fallas-causas', causaFallasData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateCausaFallas = function (token, id, causaFallasData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/fallas-causas?id=".concat(id), causaFallasData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteCausaFallasById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/fallas-causas/".concat(id), options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            console.log(error);
        }
    }
    throw error;
};
