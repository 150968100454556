// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Loading Screen base */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #071320;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    padding: 20px;
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  
  /* Clase para aplicar el fade-out */
  .loading-screen.fade-out {
    opacity: 0;
  }
  
  
  .loading-logo {
    max-width: 200px;
    margin-bottom: 30px;
    animation: pulse 2s infinite;
  }
  
  .progress-bar-container {
    width: 100%;
    max-width: 400px;
    height: 8px;
    background-color: #39393a;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: rgb(3, 229, 204);
    width: 0;
    transition: width 50ms linear;
  }

.check-icon {
  font-size: 2rem;
  width: 3vw !important;
  height: 5vh !important;
  color: rgb(1, 255, 170);
  animation: fadeInScale 0.6s ease-in-out;
  margin-top: 20px;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/loadingModule/styles/LoadingScreen.css"],"names":[],"mappings":";AACA,wBAAwB;AACxB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,UAAU;IACV,iCAAiC;EACnC;;EAEA,mCAAmC;EACnC;IACE,UAAU;EACZ;;;EAGA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,4BAA4B;EAC9B;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,kCAAkC;IAClC,QAAQ;IACR,6BAA6B;EAC/B;;AAEF;EACE,eAAe;EACf,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;AACF","sourcesContent":["\n/* Loading Screen base */\n.loading-screen {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: #071320;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    z-index: 2000;\n    padding: 20px;\n    opacity: 1;\n    transition: opacity 0.5s ease-out;\n  }\n  \n  /* Clase para aplicar el fade-out */\n  .loading-screen.fade-out {\n    opacity: 0;\n  }\n  \n  \n  .loading-logo {\n    max-width: 200px;\n    margin-bottom: 30px;\n    animation: pulse 2s infinite;\n  }\n  \n  .progress-bar-container {\n    width: 100%;\n    max-width: 400px;\n    height: 8px;\n    background-color: #39393a;\n    border-radius: 4px;\n    overflow: hidden;\n  }\n  \n  .progress-bar {\n    height: 100%;\n    background-color: rgb(3, 229, 204);\n    width: 0;\n    transition: width 50ms linear;\n  }\n\n.check-icon {\n  font-size: 2rem;\n  width: 3vw !important;\n  height: 5vh !important;\n  color: rgb(1, 255, 170);\n  animation: fadeInScale 0.6s ease-in-out;\n  margin-top: 20px;\n}\n\n@keyframes fadeInScale {\n  0% {\n    opacity: 0;\n    transform: scale(0.5);\n  }\n  100% {\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
