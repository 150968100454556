import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import memcoLogo from '../../../../utils/img/memco-logotype.png';
import ziriuzQR from '../../../../utils/img/ziriuzqrcode.png';
var CotizacionHeaderPDF = function (_a) {
    var cotizacion = _a.cotizacion;
    return (_jsx(View, { style: styles.headerContainer, children: _jsxs(View, { style: styles.headerGroup, children: [_jsx(Image, { style: styles.logo, src: memcoLogo }), _jsxs(View, { style: styles.overlapGroup, children: [_jsx(Text, { style: styles.ordenTitle, children: "COTIZACI\u00D3N" }), _jsx(Text, { style: styles.ordenNumber, children: cotizacion._id || 'N/A' })] }), _jsxs(View, { style: styles.memcoInfo, children: [_jsxs(View, { style: styles.memcoSection, children: [_jsx(Text, { style: styles.memcoTitle, children: "MEMCO S.A.S. / NIT:" }), _jsx(Text, { style: styles.memcoDetails, children: " 900454322-1 " })] }), _jsxs(View, { style: styles.memcoSection, children: [_jsx(Text, { style: styles.memcoDetails, children: "Bogot\u00E1: " }), _jsx(Text, { style: styles.memcoDetails, children: "Cra. 70 # 21A - 16 / " }), _jsx(Text, { style: styles.memcoDetails, children: "Medell\u00EDn: " }), _jsx(Text, { style: styles.memcoDetails, children: "Cra. 50FF # 6 Sur - 75 " })] }), _jsxs(View, { style: styles.memcoSection, children: [_jsx(Text, { style: styles.memcoDetails, children: "Cali: Av. 4N # 17N - 51 / " }), _jsx(Text, { style: styles.memcoDetails, children: "Barranquilla: Cl. 75B # 42F - 83, Local 7" })] }), _jsxs(View, { style: styles.memcoSection, children: [_jsx(Text, { style: styles.memcoDetails, children: "Celular: 321 343 9040 / " }), _jsx(Text, { style: styles.memcoDetails, children: "E-mail: Gerencia@memcosas.com " })] })] }), _jsxs(View, { style: styles.ordenInfo, children: [_jsxs(Text, { style: styles.ordenId, children: ["ID: ", cotizacion._id] }), _jsxs(Text, { style: styles.fechaTitle, children: ["Fecha Creaci\u00F3n: ", cotizacion.fecha_creation] }), _jsxs(Text, { style: styles.ordenEstado, children: ["Estado: ", cotizacion.id_estado.estado] }), _jsx(Text, { style: styles.software, children: "Software: Bioswift" }), _jsx(Image, { style: styles.qrCode, src: ziriuzQR })] })] }) }));
};
var styles = StyleSheet.create({
    headerContainer: {
        marginBottom: 10,
        pageBreakInside: 'avoid',
    },
    headerGroup: {
        position: 'relative',
    },
    logo: {
        position: 'absolute',
        top: '-11px',
        left: 0,
        width: '165px',
        height: '82px',
    },
    overlapGroup: {
        backgroundColor: '#0FBAC6',
        position: 'absolute',
        top: '8px',
        left: '303px',
        width: '245px',
        height: '55px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ordenTitle: {
        color: '#ffffff',
        fontFamily: 'Helvetica',
        fontSize: 20,
        fontWeight: 'bold',
        position: 'absolute',
        top: '6px'
    },
    ordenNumber: {
        color: '#ffffff',
        fontFamily: 'Helvetica',
        fontSize: 16,
        fontWeight: 'bold',
        position: 'absolute',
        top: '29px'
    },
    memcoInfo: {
        position: 'absolute',
        top: '90px',
        lineHeight: 1.2,
        textAlign: 'left',
    },
    ordenInfo: {
        position: 'absolute',
        left: '303px',
        width: '250px',
        height: '80px',
        fontFamily: 'Helvetica',
        lineHeight: 20,
        textAlign: 'left',
        top: '85px',
    },
    ordenId: {
        position: 'absolute',
        fontSize: 10,
        fontFamily: 'Helvetica',
        textAlign: 'left',
        fontWeight: 'bold'
    },
    fechaTitle: {
        position: 'absolute',
        fontSize: 10,
        fontFamily: 'Helvetica',
        textAlign: 'left',
        top: '13px',
        fontWeight: 'bold'
    },
    ordenEstado: {
        position: 'absolute',
        fontSize: 10,
        fontFamily: 'Helvetica',
        textAlign: 'left',
        top: '26px'
    },
    software: {
        position: 'absolute',
        fontSize: 10,
        fontFamily: 'Helvetica',
        textAlign: 'left',
        top: '39px'
    },
    qrCode: {
        position: 'absolute',
        width: '65px',
        height: '64px',
        top: '-10px',
        left: '180px'
    },
    memcoDetails: {
        fontSize: 9,
        fontFamily: 'Helvetica',
    },
    memcoSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    memcoTitle: {
        fontSize: 9,
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
    },
});
export default CotizacionHeaderPDF;
