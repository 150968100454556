import { useEffect, useState } from 'react';
var useUserRoleVerifier = function (requiredRoles) {
    var _a = useState(false), hasPermission = _a[0], setHasPermission = _a[1];
    useEffect(function () {
        // Obtiene los roles del usuario desde el almacenamiento local o de sesión
        var userRoles = JSON.parse(sessionStorage.getItem('userRoles') || '[]');
        // Verifica si el usuario tiene al menos uno de los roles requeridos
        var hasRequiredRole = userRoles.some(function (role) { return requiredRoles.includes(role); });
        setHasPermission(hasRequiredRole);
    }, []); // No es necesario incluir 'requiredRoles' como dependencia aquí, ya que no cambia una vez que el componente se ha montado
    return hasPermission;
};
export default useUserRoleVerifier;
