import axios from '../../../utils/config/axios.config';
export var getAllRoles = function (token) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .get('/roles', options)
        .then(function (response) { return response.data.roles; })
        .catch(function (error) {
        if (error.response) {
            var status_1 = error.response.status;
            if (status_1 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
// Obtener un rol por ID
export var getRoleById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get('/roles/', options)
        .catch(function (error) {
        if (error.response) {
            var status_2 = error.response.status;
            if (status_2 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
// Crear un nuevo rol
export var createRole = function (token, roleData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/roles/create-role', roleData, options)
        .catch(function (error) {
        if (error.response) {
            if (error.response.status === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
// Actualizar un rol por ID
export var editRoleById = function (token, id, roleData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/roles?id=".concat(id), roleData, options)
        .catch(function (error) {
        if (error.response) {
            window.location.href = '/login';
        }
        throw error;
    });
};
// Eliminar un rol por ID
export var deleteRoleById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete('/roles/', options)
        .catch(function (error) {
        if (error.response) {
            var status_3 = error.response.status;
            if (status_3 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
