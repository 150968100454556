var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import '../../../../components/filters/styles/AdvancedFilters.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { getAllSolicitudesDadoBaja } from '../../services/solicitudesDadoBajaService';
import FiltersInputsDadoBajaComponent from './FiltersInputsDadoBajaComponent';
import FiltersCamposToSelectDadoBaja from './FiltersCamposToSelectDadoBaja';
var AdvancedFiltersDadoBaja = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onApplyFilters = _a.onApplyFilters, currentPage = _a.currentPage, limitPerPage = _a.limitPerPage, DadoBajaPageType = _a.DadoBajaPageType;
    var _b = useState(function () {
        var savedFilters = sessionStorage.getItem('advancedFiltersDadoBaja');
        return savedFilters
            ? JSON.parse(savedFilters)
            : [
                {
                    _id: '',
                    'id_solicitud_baja_estado.estado': '',
                    'id_orden.id_solicitud_servicio.id_servicio._id': '', // SERVICIO
                    'id_orden.id_solicitud_servicio.id_equipo._id': '', // ID EQUIPO
                    'id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase': '', // EQUIPO
                    'id_orden.id_solicitud_servicio.id_equipo.id_sede._id': '', // ID SEDE
                    'id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre': '', // SEDE
                    'id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id': '', // ID CLIENTE
                    'id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name': '', // CLIENTE
                }
            ];
    }), filters = _b[0], setFilters = _b[1];
    var _c = useState(filters.map(function () { return false; })), loading = _c[0], setLoading = _c[1];
    var _d = useState(filters.map(function () { return null; })), results = _d[0], setResults = _d[1];
    var _e = useState(false), showViewButton = _e[0], setShowViewButton = _e[1];
    var _f = useState(true), isEditing = _f[0], setIsEditing = _f[1];
    var _g = useState([]), alerts = _g[0], setAlerts = _g[1];
    var _h = useState(false), showFieldSelector = _h[0], setShowFieldSelector = _h[1];
    var _j = useState(function () {
        var savedFields = sessionStorage.getItem('selectedFieldsDadoBaja');
        return savedFields
            ? JSON.parse(savedFields)
            : ['ID', 'ESTADO', 'SERVICIO', 'ID EQUIPO', 'EQUIPO', 'ID SEDE', 'SEDE', 'ID CLIENTE', 'CLIENTE'];
    }), selectedFields = _j[0], setSelectedFields = _j[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        var savedFilters = sessionStorage.getItem('advancedFiltersDadoBaja');
        if (savedFilters) {
            setFilters(JSON.parse(savedFilters));
        }
        var savedSelectedFields = sessionStorage.getItem('selectedFieldsDadoBaja');
        if (savedSelectedFields) {
            setSelectedFields(JSON.parse(savedSelectedFields));
        }
    }, []);
    var handleAddFilter = function () {
        setFilters(__spreadArray(__spreadArray([], filters, true), [{
                _id: '',
                'id_solicitud_baja_estado.estado': '',
                'id_orden.id_solicitud_servicio.id_servicio._id': '', // SERVICIO
                'id_orden.id_solicitud_servicio.id_equipo._id': '', // ID EQUIPO
                'id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase': '', // EQUIPO
                'id_orden.id_solicitud_servicio.id_equipo.id_sede._id': '', // ID SEDE
                'id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre': '', // SEDE
                'id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id': '', // ID CLIENTE
                'id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name': '' // CLIENTE
            }], false));
        setLoading(__spreadArray(__spreadArray([], loading, true), [false], false));
        setResults(__spreadArray(__spreadArray([], results, true), [null], false));
    };
    var handleFilterChange = function (index, field, value) {
        var newFilters = __spreadArray([], filters, true);
        var hexPattern = /^[0-9a-fA-F]*$/;
        if (selectedFields.includes('ID') && field === '_id') {
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('ESTADO') && field === 'id_solicitud_baja_estado.estado') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('ID EQUIPO') && field === 'id_orden.id_solicitud_servicio.id_equipo._id') {
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('ID SEDE') && field === 'id_orden.id_solicitud_servicio.id_equipo.id_sede._id') {
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('ID CLIENTE') && field === 'id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id') {
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('SERVICIO') && field === 'id_orden.id_solicitud_servicio.id_servicio._id') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('EQUIPO') && field === 'id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase') {
            newFilters[index][field] = value.toLowerCase(); // Para búsqueda parcial
        }
        else if (selectedFields.includes('SEDE') && field === 'id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre') {
            newFilters[index][field] = value.toLowerCase(); // Para búsqueda parcial
        }
        else if (selectedFields.includes('CLIENTE') && field === 'id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name') {
            newFilters[index][field] = value.toLowerCase(); // Para búsqueda parcial
        }
        setFilters(newFilters);
        sessionStorage.setItem('advancedFiltersDadoBaja', JSON.stringify(newFilters));
    };
    var handleRemoveFilter = function (index) {
        var newFilters = __spreadArray([], filters, true);
        var newLoading = __spreadArray([], loading, true);
        var newResults = __spreadArray([], results, true);
        newFilters.splice(index, 1);
        newLoading.splice(index, 1);
        newResults.splice(index, 1);
        setFilters(newFilters);
        setLoading(newLoading);
        setResults(newResults);
    };
    var handleSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newAlert, invalidFilters, newAlert, newLoading, newResults, allResults, result, solicitudesDadoBaja, _loop_1, i, paginatedResults, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (filters.length === 0) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Agregue al menos un filtro.',
                            severity: 'info',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    invalidFilters = filters.some(function (filter) {
                        return selectedFields.some(function (field) {
                            if (field === 'ID' && filter._id !== '' && filter._id.length !== 24)
                                return true;
                            if (field === 'ID EQUIPO' && filter['id_orden.id_solicitud_servicio.id_equipo._id'] !== '' && filter['id_orden.id_solicitud_servicio.id_equipo._id'].length !== 24)
                                return true;
                            if (field === 'ID SEDE' && filter['id_orden.id_solicitud_servicio.id_equipo.id_sede._id'] !== '' && filter['id_orden.id_solicitud_servicio.id_equipo.id_sede._id'].length !== 24)
                                return true;
                            if (field === 'ID CLIENTE' && filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id'] !== '' && filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id'].length !== 24)
                                return true;
                            return false;
                        });
                    });
                    if (invalidFilters) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Por favor ingrese un formato de ID válido.',
                            severity: 'error',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    // Aplicar filtro específico para tipo de página de DadoBaja
                    if (DadoBajaPageType === 'SolicitudesDadoBaja-Pendientes') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_baja_estado.estado'] = 'Pendiente';
                        });
                    }
                    if (DadoBajaPageType === 'SolicitudesDadoBaja-Aprobadas') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_baja_estado.estado'] = 'Aprobada';
                        });
                    }
                    if (DadoBajaPageType === 'SolicitudesDadoBaja-Rechazadas') {
                        filters.forEach(function (filter) {
                            filter['id_solicitud_baja_estado.estado'] = 'Rechazada';
                        });
                    }
                    sessionStorage.setItem('advancedFiltersDadoBaja', JSON.stringify(filters));
                    newLoading = filters.map(function () { return true; });
                    newResults = filters.map(function () { return null; });
                    setLoading(newLoading);
                    setResults(newResults);
                    allResults = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getAllSolicitudesDadoBaja(token)];
                case 2:
                    result = _a.sent();
                    solicitudesDadoBaja = result.solicitudesDadoBaja;
                    _loop_1 = function (i) {
                        var filter = filters[i];
                        var filteredDadoBaja = solicitudesDadoBaja.filter(function (solicitud) {
                            return selectedFields.every(function (field) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
                                if (field === 'ID') {
                                    return !filter._id || solicitud._id === filter._id;
                                }
                                if (field === 'EQUIPO') {
                                    return !filter['id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'] ||
                                        ((_e = (_d = (_c = (_b = (_a = solicitud.id_orden) === null || _a === void 0 ? void 0 : _a.id_solicitud_servicio) === null || _b === void 0 ? void 0 : _b.id_equipo) === null || _c === void 0 ? void 0 : _c.modelo_equipos) === null || _d === void 0 ? void 0 : _d.id_clase) === null || _e === void 0 ? void 0 : _e.clase.toLowerCase().includes(filter['id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'].toLowerCase()));
                                }
                                if (field === 'ID EQUIPO') {
                                    return !filter['id_orden.id_solicitud_servicio.id_equipo._id'] || ((_h = (_g = (_f = solicitud.id_orden) === null || _f === void 0 ? void 0 : _f.id_solicitud_servicio) === null || _g === void 0 ? void 0 : _g.id_equipo) === null || _h === void 0 ? void 0 : _h._id) === filter['id_orden.id_solicitud_servicio.id_equipo._id'];
                                }
                                if (field === 'ID SEDE') {
                                    return !filter['id_orden.id_solicitud_servicio.id_equipo.id_sede._id'] || ((_m = (_l = (_k = (_j = solicitud.id_orden) === null || _j === void 0 ? void 0 : _j.id_solicitud_servicio) === null || _k === void 0 ? void 0 : _k.id_equipo) === null || _l === void 0 ? void 0 : _l.id_sede) === null || _m === void 0 ? void 0 : _m._id) === filter['id_orden.id_solicitud_servicio.id_equipo.id_sede._id'];
                                }
                                if (field === 'ID CLIENTE') {
                                    return !filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id'] || ((_s = (_r = (_q = (_p = (_o = solicitud.id_orden) === null || _o === void 0 ? void 0 : _o.id_solicitud_servicio) === null || _p === void 0 ? void 0 : _p.id_equipo) === null || _q === void 0 ? void 0 : _q.id_sede) === null || _r === void 0 ? void 0 : _r.id_client) === null || _s === void 0 ? void 0 : _s._id) === filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id'];
                                }
                                if (field === 'EQUIPO') {
                                    return !filter['id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'] || ((_x = (_w = (_v = (_u = (_t = solicitud.id_orden) === null || _t === void 0 ? void 0 : _t.id_solicitud_servicio) === null || _u === void 0 ? void 0 : _u.id_equipo) === null || _v === void 0 ? void 0 : _v.modelo_equipos) === null || _w === void 0 ? void 0 : _w.id_clase) === null || _x === void 0 ? void 0 : _x.clase) === filter['id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'];
                                }
                                if (field === 'SEDE') {
                                    return (!filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre'] ||
                                        ((_1 = (_0 = (_z = (_y = solicitud.id_orden) === null || _y === void 0 ? void 0 : _y.id_solicitud_servicio) === null || _z === void 0 ? void 0 : _z.id_equipo) === null || _0 === void 0 ? void 0 : _0.id_sede) === null || _1 === void 0 ? void 0 : _1.sede_nombre.toLowerCase().includes(filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre'].toLowerCase())));
                                }
                                if (field === 'CLIENTE') {
                                    return (!filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name'] ||
                                        ((_6 = (_5 = (_4 = (_3 = (_2 = solicitud.id_orden) === null || _2 === void 0 ? void 0 : _2.id_solicitud_servicio) === null || _3 === void 0 ? void 0 : _3.id_equipo) === null || _4 === void 0 ? void 0 : _4.id_sede) === null || _5 === void 0 ? void 0 : _5.id_client) === null || _6 === void 0 ? void 0 : _6.client_name.toLowerCase().includes(filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name'].toLowerCase())));
                                }
                                return true;
                            });
                        });
                        if (filteredDadoBaja.length > 0) {
                            newResults[i] = true;
                            allResults.push.apply(allResults, filteredDadoBaja);
                        }
                        else {
                            newResults[i] = false;
                        }
                    };
                    for (i = 0; i < filters.length; i++) {
                        _loop_1(i);
                    }
                    paginatedResults = allResults.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage);
                    onApplyFilters(paginatedResults, allResults.length);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al obtener las solicitudes dado de baja:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(newLoading.map(function () { return false; }));
                    setResults(newResults);
                    setShowViewButton(true);
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleView = function () {
        onClose();
    };
    var handleEdit = function () {
        setIsEditing(true);
        setShowViewButton(false);
        setResults(filters.map(function () { return null; }));
    };
    var handleCloseAlert = function (id) {
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
    };
    var handleFieldSelection = function (fields) {
        setSelectedFields(fields);
        var newFilters = filters.map(function (filter) {
            var newFilter = { _id: filter._id };
            if (fields.includes('ESTADO')) {
                newFilter['id_solicitud_baja_estado.estado'] = filter['id_solicitud_baja_estado.estado'] || '';
            }
            if (fields.includes('ID EQUIPO')) {
                newFilter['id_orden.id_solicitud_servicio.id_equipo._id'] = filter['id_orden.id_solicitud_servicio.id_equipo._id'] || '';
            }
            if (fields.includes('ID SEDE')) {
                newFilter['id_orden.id_solicitud_servicio.id_equipo.id_sede._id'] = filter['id_orden.id_solicitud_servicio.id_equipo.id_sede._id'] || '';
            }
            if (fields.includes('ID CLIENTE')) {
                newFilter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id'] = filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id'] || '';
            }
            if (fields.includes('EQUIPO')) {
                newFilter['id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'] = filter['id_orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'] || '';
            }
            if (fields.includes('SEDE')) {
                newFilter['id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre'] = filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre'] || '';
            }
            if (fields.includes('CLIENTE')) {
                newFilter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name'] = filter['id_orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name'] || '';
            }
            return newFilter;
        });
        setFilters(newFilters);
        setShowFieldSelector(false);
        sessionStorage.setItem('selectedFieldsDadoBaja', JSON.stringify(fields));
        sessionStorage.setItem('advancedFiltersDadoBaja', JSON.stringify(newFilters));
    };
    return (isOpen ? (_jsxs("div", { className: "AdvancedFilters-modal-overlay", children: [_jsx("div", { className: "AdvancedFilters-modal-content", children: _jsx("div", { className: "AdvancedFilters-advanced-filters", children: _jsxs("div", { className: "AdvancedFilters-div", children: [_jsxs("div", { className: "AdvancedFilters-overlap", children: [_jsx("header", { className: "AdvancedFilters-header", children: _jsx("div", { className: "AdvancedFilters-overlap-group", children: _jsx("div", { className: "AdvancedFilters-filter-title", children: "FILTRAR SOLICITUDES DADO DE BAJA" }) }) }), isEditing && _jsx(AddCircleIcon, { className: "AdvancedFilters-filter-add-icon", onClick: handleAddFilter }), _jsx(FilterListIcon, { className: "AdvancedFilters-options-icon", onClick: function () { return setShowFieldSelector(true); } })] }), filters.length > 0 && (_jsx(FiltersInputsDadoBajaComponent, { filters: filters, selectedFields: selectedFields, handleFilterChange: handleFilterChange, handleRemoveFilter: handleRemoveFilter, loading: loading, results: results, isEditing: isEditing, token: token, DadoBajaPageType: DadoBajaPageType })), isEditing ? (_jsx("button", { className: "AdvancedFilters-cancel-button", onClick: onClose, children: "Cancelar" })) : (_jsx("button", { className: "AdvancedFilters-edit-button", onClick: handleEdit, children: "Editar" })), showViewButton ? (_jsx("button", { className: "AdvancedFilters-view-button", onClick: handleView, children: "Ver" })) : (_jsx("button", { className: "AdvancedFilters-send-button", onClick: handleSearch, children: "Enviar" }))] }) }) }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), showFieldSelector && (_jsx(FiltersCamposToSelectDadoBaja, { onSubmit: handleFieldSelection, onClose: function () { return setShowFieldSelector(false); }, initialSelectedFields: selectedFields }))] })) : null);
};
export default AdvancedFiltersDadoBaja;
