var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getAllOrdenes } from '../../services/ordenesService';
import '../../../../components/filters/styles/AdvancedFilters.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import FiltersCamposToSelectOrdenes from './FiltersCamposToSelectOrdenes';
import FiltersInputsComponent from './FiltersInputsOrdenesComponent';
var AdvancedFiltersOrdenes = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onApplyFilters = _a.onApplyFilters, currentPage = _a.currentPage, limitPerPage = _a.limitPerPage, OrdenPageType = _a.OrdenPageType;
    var _b = useState(function () {
        var savedFilters = sessionStorage.getItem('advancedFilters');
        return savedFilters ? JSON.parse(savedFilters) : [
            {
                _id: '',
                'id_solicitud_servicio._id': '',
                'id_solicitud_servicio.id_servicio._id': '',
                'id_solicitud_servicio.creacion': '',
                'id_solicitud_servicio.id_equipo._id': '',
                'id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase': '',
                'id_orden_estado.estado': '',
                'id_solicitud_servicio.id_equipo.id_sede.sede_nombre': '',
                'id_solicitud_servicio.id_equipo.serie': '',
                'id_solicitud_servicio.id_equipo.modelo_equipos.modelo': '', // MODELO
                'id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca': '', // MARCA
                'id_solicitud_servicio.id_equipo.id_area.area': '', // AREA
                'id_solicitud_servicio.id_equipo.id_tipo.tipo': '', // TIPO
                'id_solicitud_servicio.id_equipo.id_contrato.numero': '', // CONTRATO EQUIPO
                'id_solicitud_servicio.id_equipo.activo_fijo': '', // ACTIVO FIJO
                'id_solicitud_servicio.id_equipo.mtto': '', // MTTO
                'id_solicitud_servicio.id_equipo.sap': '', // SAP
                'id_solicitud_servicio.id_equipo.id_sede._id': '', // ID SEDE
                'id_solicitud_servicio.id_sede.id_client._id': '', // ID CLIENTE
                'id_solicitud_servicio.id_sede.id_client.client_name': '' // CLIENTE
            }
        ];
    }), filters = _b[0], setFilters = _b[1];
    var _c = useState(filters.map(function () { return false; })), loading = _c[0], setLoading = _c[1];
    var _d = useState(filters.map(function () { return null; })), results = _d[0], setResults = _d[1];
    var _e = useState(false), showViewButton = _e[0], setShowViewButton = _e[1];
    var _f = useState(true), isEditing = _f[0], setIsEditing = _f[1];
    var _g = useState([]), alerts = _g[0], setAlerts = _g[1];
    var _h = useState(false), showFieldSelector = _h[0], setShowFieldSelector = _h[1];
    var _j = useState(function () {
        var savedFields = sessionStorage.getItem('selectedFields');
        return savedFields ? JSON.parse(savedFields) : [
            'ID', 'ID SOLICITUD', 'SERVICIO', 'SOLICITUD FECHA', 'ID EQUIPO', 'EQUIPO', 'MODELO',
            'MARCA', 'AREA', 'TIPO', 'CONTRATO EQUIPO', 'SERIE', 'ACTIVO FIJO', 'MTTO', 'SAP', 'ID SEDE',
            'ID CLIENTE', 'CLIENTE'
        ];
    }), selectedFields = _j[0], setSelectedFields = _j[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        var savedFilters = sessionStorage.getItem('advancedFilters');
        if (savedFilters) {
            setFilters(JSON.parse(savedFilters));
        }
        var savedSelectedFields = sessionStorage.getItem('selectedFields');
        if (savedSelectedFields) {
            setSelectedFields(JSON.parse(savedSelectedFields));
        }
    }, []);
    var handleAddFilter = function () {
        setFilters(__spreadArray(__spreadArray([], filters, true), [{
                _id: '',
                'id_solicitud_servicio._id': '',
                'id_solicitud_servicio.id_servicio._id': '',
                'id_solicitud_servicio.creacion': '',
                'id_solicitud_servicio.id_equipo._id': '',
                'id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase': '',
                'id_orden_estado.estado': '',
                'id_solicitud_servicio.id_equipo.id_sede.sede_nombre': '',
                'id_solicitud_servicio.id_equipo.serie': '',
                'id_solicitud_servicio.id_equipo.modelo_equipos.modelo': '', // MODELO
                'id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca': '', // MARCA
                'id_solicitud_servicio.id_equipo.id_area.area': '', // AREA
                'id_solicitud_servicio.id_equipo.id_tipo.tipo': '', // TIPO
                'id_solicitud_servicio.id_equipo.id_contrato.numero': '', // CONTRATO EQUIPO
                'id_solicitud_servicio.id_equipo.activo_fijo': '', // ACTIVO FIJO
                'id_solicitud_servicio.id_equipo.mtto': '', // MTTO
                'id_solicitud_servicio.id_equipo.sap': '', // SAP
                'id_solicitud_servicio.id_equipo.id_sede._id': '', // ID SEDE
                'id_solicitud_servicio.id_sede.id_client._id': '', // ID CLIENTE
                'id_solicitud_servicio.id_sede.id_client.client_name': '' // CLIENTE
            }], false));
        setLoading(__spreadArray(__spreadArray([], loading, true), [false], false));
        setResults(__spreadArray(__spreadArray([], results, true), [null], false));
    };
    var handleFilterChange = function (index, field, value) {
        var newFilters = __spreadArray([], filters, true);
        if (selectedFields.includes('ID') && field === '_id') {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('ID SOLICITUD') && field.includes('id_solicitud_servicio._id')) {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('SERVICIO') && field.includes('id_solicitud_servicio.id_servicio._id')) {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('ID EQUIPO') && field.includes('id_solicitud_servicio.id_equipo._id')) {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('SOLICITUD FECHA') && field === 'id_solicitud_servicio.creacion') {
            newFilters[index][field] = value.replace(/[^0-9\-: ]/g, '');
        }
        else if (selectedFields.includes('EQUIPO') && field === 'id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('ESTADO') && field === 'id_orden_estado.estado') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('SEDE') && field === 'id_solicitud_servicio.id_equipo.id_sede.sede_nombre') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('SERIE') && field === 'id_solicitud_servicio.id_equipo.serie') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('MODELO') && field === 'id_solicitud_servicio.id_equipo.modelo_equipos.modelo') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('MARCA') && field === 'id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('AREA') && field === 'id_solicitud_servicio.id_equipo.id_area.area') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('TIPO') && field === 'id_solicitud_servicio.id_equipo.id_tipo.tipo') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('CONTRATO EQUIPO') && field === 'id_solicitud_servicio.id_equipo.id_contrato.numero') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('ACTIVO FIJO') && field === 'id_solicitud_servicio.id_equipo.activo_fijo') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('MTTO') && field === 'id_solicitud_servicio.id_equipo.mtto') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('SAP') && field === 'id_solicitud_servicio.id_equipo.sap') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('ID SEDE') && field === 'id_solicitud_servicio.id_equipo.id_sede._id') {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('ID CLIENTE') && field === 'id_solicitud_servicio.id_sede.id_client._id') {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('CLIENTE') && field === 'id_solicitud_servicio.id_sede.id_client.client_name') {
            newFilters[index][field] = value;
        }
        setFilters(newFilters);
        sessionStorage.setItem('userFilters', JSON.stringify(newFilters));
    };
    var handleRemoveFilter = function (index) {
        var newFilters = __spreadArray([], filters, true);
        var newLoading = __spreadArray([], loading, true);
        var newResults = __spreadArray([], results, true);
        newFilters.splice(index, 1);
        newLoading.splice(index, 1);
        newResults.splice(index, 1);
        setFilters(newFilters);
        setLoading(newLoading);
        setResults(newResults);
    };
    var handleSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newAlert, invalidFilters, newAlert, newLoading, newResults, allResults, result, ordenes, _loop_1, i, paginatedResults, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (filters.length === 0) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Agregue al menos un filtro.',
                            severity: 'info',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    invalidFilters = filters.some(function (filter) {
                        return selectedFields.some(function (field) {
                            if (field === 'ID' && filter._id && filter._id.length !== 24 && filter._id !== '')
                                return true;
                            if (field === 'ID SOLICITUD' && filter['id_solicitud_servicio._id'] && filter['id_solicitud_servicio._id'].length !== 24 && filter['id_solicitud_servicio._id'] !== '')
                                return true;
                            if (field === 'SERVICIO' && filter['id_solicitud_servicio.id_servicio._id'] && filter['id_solicitud_servicio.id_servicio._id'].length !== 24 && filter['id_solicitud_servicio.id_servicio._id'] !== '')
                                return true;
                            if (field === 'ID EQUIPO' && filter['id_solicitud_servicio.id_equipo._id'] && filter['id_solicitud_servicio.id_equipo._id'].length !== 24 && filter['id_solicitud_servicio.id_equipo._id'] !== '')
                                return true;
                            if (field === 'ID SEDE' && filter['id_solicitud_servicio.id_equipo.id_sede._id'] && filter['id_solicitud_servicio.id_equipo.id_sede._id'].length !== 24 && filter['id_solicitud_servicio.id_equipo.id_sede._id'] !== '')
                                return true;
                            if (field === 'ID CLIENTE' && filter['id_solicitud_servicio.id_sede.id_client._id'] && filter['id_solicitud_servicio.id_sede.id_client._id'].length !== 24 && filter['id_solicitud_servicio.id_sede.id_client._id'] !== '')
                                return true;
                            return false;
                        });
                    });
                    if (invalidFilters) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Por favor ingrese un formato de ID válido.',
                            severity: 'error',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    // Validación para forzar el estado a "Abierta" y servicio a "Preventivo" si OrdenPageType es "OrdenesAbiertasPR"
                    if (OrdenPageType === 'OrdenesAbiertasPR') {
                        filters.forEach(function (filter) {
                            filter['id_orden_estado.estado'] = 'Abierta';
                            filter['id_solicitud_servicio.id_servicio._id'] = '65a92d6b956f2f16bab82a9c';
                        });
                    }
                    // Nueva validación para "OrdenesAbiertasCIG"
                    if (OrdenPageType === 'OrdenesAbiertasCIG') {
                        filters.forEach(function (filter) {
                            filter['id_orden_estado.estado'] = 'Abierta';
                            // Si el servicio es "Preventivo" (65a92d6b956f2f16bab82a9c), lo eliminamos para asegurarnos de que no se use.
                            if (filter['id_solicitud_servicio.id_servicio._id'] === '65a92d6b956f2f16bab82a9c') {
                                delete filter['id_solicitud_servicio.id_servicio._id'];
                            }
                        });
                    }
                    // Validación para forzar el estado a "Abierta" y servicio a "Preventivo" si OrdenPageType es "OrdenesAbiertasPR"
                    if (OrdenPageType === 'OrdenesCerradas') {
                        filters.forEach(function (filter) {
                            filter['id_orden_estado.estado'] = 'Cerrada';
                        });
                    }
                    sessionStorage.setItem('advancedFilters', JSON.stringify(filters));
                    newLoading = filters.map(function () { return true; });
                    newResults = filters.map(function () { return null; });
                    setLoading(newLoading);
                    setResults(newResults);
                    allResults = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getAllOrdenes(token)];
                case 2:
                    result = _a.sent();
                    ordenes = result.ordenes;
                    _loop_1 = function (i) {
                        var filter = filters[i];
                        var filteredOrdenes = ordenes.filter(function (orden) {
                            return selectedFields.every(function (field) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39;
                                // Excluir órdenes con servicio "Preventivo" si OrdenPageType es "OrdenesAbiertasCIG"
                                if (OrdenPageType === 'OrdenesAbiertasCIG' && ((_b = (_a = orden.id_solicitud_servicio) === null || _a === void 0 ? void 0 : _a.id_servicio) === null || _b === void 0 ? void 0 : _b._id) === '65a92d6b956f2f16bab82a9c') {
                                    return false; // Excluir esta orden
                                }
                                if (field === 'ID') {
                                    return !filter._id || orden._id === filter._id;
                                }
                                if (field === 'ID SOLICITUD') {
                                    return (!filter['id_solicitud_servicio._id'] ||
                                        ((_c = orden.id_solicitud_servicio) === null || _c === void 0 ? void 0 : _c._id) === filter['id_solicitud_servicio._id']);
                                }
                                if (field === 'SERVICIO') {
                                    return (!filter['id_solicitud_servicio.id_servicio._id'] ||
                                        ((_e = (_d = orden.id_solicitud_servicio) === null || _d === void 0 ? void 0 : _d.id_servicio) === null || _e === void 0 ? void 0 : _e._id) === filter['id_solicitud_servicio.id_servicio._id']);
                                }
                                if (field === 'ID EQUIPO') {
                                    return (!filter['id_solicitud_servicio.id_equipo._id'] ||
                                        ((_g = (_f = orden.id_solicitud_servicio) === null || _f === void 0 ? void 0 : _f.id_equipo) === null || _g === void 0 ? void 0 : _g._id) === filter['id_solicitud_servicio.id_equipo._id']);
                                }
                                if (field === 'EQUIPO') {
                                    var equipoFilter = ((_h = filter['id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase']) === null || _h === void 0 ? void 0 : _h.toLowerCase()) || '';
                                    var equipoValue = ((_o = (_m = (_l = (_k = (_j = orden.id_solicitud_servicio) === null || _j === void 0 ? void 0 : _j.id_equipo) === null || _k === void 0 ? void 0 : _k.modelo_equipos) === null || _l === void 0 ? void 0 : _l.id_clase) === null || _m === void 0 ? void 0 : _m.clase) === null || _o === void 0 ? void 0 : _o.toLowerCase()) || '';
                                    return !equipoFilter || equipoValue.includes(equipoFilter);
                                }
                                if (field === 'SOLICITUD FECHA') {
                                    return !filter['id_solicitud_servicio.creacion'] || ((_p = orden.id_solicitud_servicio) === null || _p === void 0 ? void 0 : _p.creacion.includes(filter['id_solicitud_servicio.creacion']));
                                }
                                if (field === 'ESTADO') {
                                    return (!filter['id_orden_estado.estado'] ||
                                        ((_q = orden.id_orden_estado) === null || _q === void 0 ? void 0 : _q.estado) === filter['id_orden_estado.estado']);
                                }
                                if (field === 'SEDE') {
                                    var sedeFilter = ((_r = filter['id_solicitud_servicio.id_equipo.id_sede.sede_nombre']) === null || _r === void 0 ? void 0 : _r.toLowerCase()) || '';
                                    var sedeValue = ((_v = (_u = (_t = (_s = orden.id_solicitud_servicio) === null || _s === void 0 ? void 0 : _s.id_equipo) === null || _t === void 0 ? void 0 : _t.id_sede) === null || _u === void 0 ? void 0 : _u.sede_nombre) === null || _v === void 0 ? void 0 : _v.toLowerCase()) || '';
                                    return !sedeFilter || sedeValue.includes(sedeFilter);
                                }
                                if (field === 'SERIE') {
                                    var serieFilter = ((_w = filter['id_solicitud_servicio.id_equipo.serie']) === null || _w === void 0 ? void 0 : _w.toLowerCase().trim()) || '';
                                    var serieValue = ((_z = (_y = (_x = orden.id_solicitud_servicio) === null || _x === void 0 ? void 0 : _x.id_equipo) === null || _y === void 0 ? void 0 : _y.serie) === null || _z === void 0 ? void 0 : _z.toLowerCase().trim()) || '';
                                    return !serieFilter || serieValue.includes(serieFilter);
                                }
                                if (field === 'MODELO') {
                                    return (!filter['id_solicitud_servicio.id_equipo.modelo_equipos.modelo'] ||
                                        ((_3 = (_2 = (_1 = (_0 = orden.id_solicitud_servicio) === null || _0 === void 0 ? void 0 : _0.id_equipo) === null || _1 === void 0 ? void 0 : _1.modelo_equipos) === null || _2 === void 0 ? void 0 : _2.modelo) === null || _3 === void 0 ? void 0 : _3.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.modelo_equipos.modelo'].toLowerCase())));
                                }
                                if (field === 'MARCA') {
                                    return (!filter['id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca'] ||
                                        ((_8 = (_7 = (_6 = (_5 = (_4 = orden.id_solicitud_servicio) === null || _4 === void 0 ? void 0 : _4.id_equipo) === null || _5 === void 0 ? void 0 : _5.modelo_equipos) === null || _6 === void 0 ? void 0 : _6.id_marca) === null || _7 === void 0 ? void 0 : _7.marca) === null || _8 === void 0 ? void 0 : _8.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca'].toLowerCase())));
                                }
                                if (field === 'AREA') {
                                    return (!filter['id_solicitud_servicio.id_equipo.id_area.area'] ||
                                        ((_12 = (_11 = (_10 = (_9 = orden.id_solicitud_servicio) === null || _9 === void 0 ? void 0 : _9.id_equipo) === null || _10 === void 0 ? void 0 : _10.id_area) === null || _11 === void 0 ? void 0 : _11.area) === null || _12 === void 0 ? void 0 : _12.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.id_area.area'].toLowerCase())));
                                }
                                if (field === 'TIPO') {
                                    return (!filter['id_solicitud_servicio.id_equipo.id_tipo.tipo'] ||
                                        ((_16 = (_15 = (_14 = (_13 = orden.id_solicitud_servicio) === null || _13 === void 0 ? void 0 : _13.id_equipo) === null || _14 === void 0 ? void 0 : _14.id_tipo) === null || _15 === void 0 ? void 0 : _15.tipo) === null || _16 === void 0 ? void 0 : _16.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.id_tipo.tipo'].toLowerCase())));
                                }
                                if (field === 'CONTRATO EQUIPO') {
                                    return (!filter['id_solicitud_servicio.id_equipo.id_contrato.numero'] ||
                                        ((_20 = (_19 = (_18 = (_17 = orden.id_solicitud_servicio) === null || _17 === void 0 ? void 0 : _17.id_equipo) === null || _18 === void 0 ? void 0 : _18.id_contrato) === null || _19 === void 0 ? void 0 : _19.numero) === null || _20 === void 0 ? void 0 : _20.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.id_contrato.numero'].toLowerCase())));
                                }
                                if (field === 'ACTIVO FIJO') {
                                    return (!filter['id_solicitud_servicio.id_equipo.activo_fijo'] ||
                                        ((_23 = (_22 = (_21 = orden.id_solicitud_servicio) === null || _21 === void 0 ? void 0 : _21.id_equipo) === null || _22 === void 0 ? void 0 : _22.activo_fijo) === null || _23 === void 0 ? void 0 : _23.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.activo_fijo'].toLowerCase())));
                                }
                                if (field === 'MTTO') {
                                    return (!filter['id_solicitud_servicio.id_equipo.mtto'] ||
                                        ((_26 = (_25 = (_24 = orden.id_solicitud_servicio) === null || _24 === void 0 ? void 0 : _24.id_equipo) === null || _25 === void 0 ? void 0 : _25.mtto) === null || _26 === void 0 ? void 0 : _26.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.mtto'].toLowerCase())));
                                }
                                if (field === 'SAP') {
                                    return (!filter['id_solicitud_servicio.id_equipo.sap'] ||
                                        ((_29 = (_28 = (_27 = orden.id_solicitud_servicio) === null || _27 === void 0 ? void 0 : _27.id_equipo) === null || _28 === void 0 ? void 0 : _28.sap) === null || _29 === void 0 ? void 0 : _29.toLowerCase().includes(filter['id_solicitud_servicio.id_equipo.sap'].toLowerCase())));
                                }
                                if (field === 'ID SEDE') {
                                    return (!filter['id_solicitud_servicio.id_equipo.id_sede._id'] ||
                                        ((_32 = (_31 = (_30 = orden.id_solicitud_servicio) === null || _30 === void 0 ? void 0 : _30.id_equipo) === null || _31 === void 0 ? void 0 : _31.id_sede) === null || _32 === void 0 ? void 0 : _32._id) === filter['id_solicitud_servicio.id_equipo.id_sede._id']);
                                }
                                if (field === 'ID CLIENTE') {
                                    return (!filter['id_solicitud_servicio.id_sede.id_client._id'] ||
                                        ((_35 = (_34 = (_33 = orden.id_solicitud_servicio) === null || _33 === void 0 ? void 0 : _33.id_sede) === null || _34 === void 0 ? void 0 : _34.id_client) === null || _35 === void 0 ? void 0 : _35._id) === filter['id_solicitud_servicio.id_sede.id_client._id']);
                                }
                                if (field === 'CLIENTE') {
                                    return (!filter['id_solicitud_servicio.id_sede.id_client.client_name'] ||
                                        ((_39 = (_38 = (_37 = (_36 = orden.id_solicitud_servicio) === null || _36 === void 0 ? void 0 : _36.id_sede) === null || _37 === void 0 ? void 0 : _37.id_client) === null || _38 === void 0 ? void 0 : _38.client_name) === null || _39 === void 0 ? void 0 : _39.toLowerCase().includes(filter['id_solicitud_servicio.id_sede.id_client.client_name'].toLowerCase())));
                                }
                                return true;
                            });
                        });
                        if (filteredOrdenes.length > 0) {
                            newResults[i] = true;
                            allResults.push.apply(allResults, filteredOrdenes);
                        }
                        else {
                            newResults[i] = false;
                        }
                    };
                    for (i = 0; i < filters.length; i++) {
                        _loop_1(i);
                    }
                    paginatedResults = allResults.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage);
                    onApplyFilters(paginatedResults, allResults.length);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al obtener las órdenes:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(newLoading.map(function () { return false; }));
                    setResults(newResults);
                    setShowViewButton(true);
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleView = function () {
        onClose();
    };
    var handleEdit = function () {
        setIsEditing(true);
        setShowViewButton(false);
        setResults(filters.map(function () { return null; }));
    };
    var handleCloseAlert = function (id) {
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
    };
    var handleFieldSelection = function (fields) {
        setSelectedFields(fields);
        var newFilters = filters.map(function (filter) {
            var newFilter = { _id: filter._id };
            if (fields.includes('ID SOLICITUD')) {
                newFilter['id_solicitud_servicio._id'] = filter['id_solicitud_servicio._id'] || '';
            }
            if (fields.includes('SERVICIO')) {
                newFilter['id_solicitud_servicio.id_servicio._id'] = filter['id_solicitud_servicio.id_servicio._id'] || '';
            }
            if (fields.includes('ID EQUIPO')) {
                newFilter['id_solicitud_servicio.id_equipo._id'] = filter['id_solicitud_servicio.id_equipo._id'] || '';
            }
            if (fields.includes('EQUIPO')) {
                newFilter['id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'] = filter['id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase'] || '';
            }
            if (fields.includes('SOLICITUD FECHA')) {
                newFilter['id_solicitud_servicio.creacion'] = filter['id_solicitud_servicio.creacion'] || '';
            }
            if (fields.includes('ESTADO')) {
                newFilter['id_orden_estado.estado'] = filter['id_orden_estado.estado'] || '';
            }
            if (fields.includes('SEDE')) { // Añadir SEDE al filtro
                newFilter['id_solicitud_servicio.id_equipo.id_sede.sede_nombre'] = filter['id_solicitud_servicio.id_equipo.id_sede.sede_nombre'] || '';
            }
            if (fields.includes('SERIE')) {
                newFilter['id_solicitud_servicio.id_equipo.serie'] = filter['id_solicitud_servicio.id_equipo.serie'] || '';
            }
            if (fields.includes('MODELO')) {
                newFilter['id_solicitud_servicio.id_equipo.modelo_equipos.modelo'] = filter['id_solicitud_servicio.id_equipo.modelo_equipos.modelo'] || '';
            }
            if (fields.includes('MARCA')) {
                newFilter['id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca'] = filter['id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca'] || '';
            }
            if (fields.includes('AREA')) {
                newFilter['id_solicitud_servicio.id_equipo.id_area.area'] = filter['id_solicitud_servicio.id_equipo.id_area.area'] || '';
            }
            if (fields.includes('TIPO')) {
                newFilter['id_solicitud_servicio.id_equipo.id_tipo.tipo'] = filter['id_solicitud_servicio.id_equipo.id_tipo.tipo'] || '';
            }
            if (fields.includes('CONTRATO EQUIPO')) {
                newFilter['id_solicitud_servicio.id_equipo.id_contrato.numero'] = filter['id_solicitud_servicio.id_equipo.id_contrato.numero'] || '';
            }
            if (fields.includes('ACTIVO FIJO')) {
                newFilter['id_solicitud_servicio.id_equipo.activo_fijo'] = filter['id_solicitud_servicio.id_equipo.activo_fijo'] || '';
            }
            if (fields.includes('MTTO')) {
                newFilter['id_solicitud_servicio.id_equipo.mtto'] = filter['id_solicitud_servicio.id_equipo.mtto'] || '';
            }
            if (fields.includes('SAP')) {
                newFilter['id_solicitud_servicio.id_equipo.sap'] = filter['id_solicitud_servicio.id_equipo.sap'] || '';
            }
            if (fields.includes('ID SEDE')) {
                newFilter['id_solicitud_servicio.id_equipo.id_sede._id'] = filter['id_solicitud_servicio.id_equipo.id_sede._id'] || '';
            }
            if (fields.includes('ID CLIENTE')) {
                newFilter['id_solicitud_servicio.id_sede.id_client._id'] = filter['id_solicitud_servicio.id_sede.id_client._id'] || '';
            }
            if (fields.includes('CLIENTE')) {
                newFilter['id_solicitud_servicio.id_sede.id_client.client_name'] = filter['id_solicitud_servicio.id_sede.id_client.client_name'] || '';
            }
            return newFilter;
        });
        setFilters(newFilters);
        setShowFieldSelector(false);
        // Guardar los campos seleccionados y filtros en session storage
        sessionStorage.setItem('selectedFields', JSON.stringify(fields));
        sessionStorage.setItem('userFilters', JSON.stringify(newFilters));
    };
    return (isOpen ? (_jsxs("div", { className: "AdvancedFilters-modal-overlay", children: [_jsx("div", { className: "AdvancedFilters-modal-content", children: _jsx("div", { className: "AdvancedFilters-advanced-filters", children: _jsxs("div", { className: "AdvancedFilters-div", children: [_jsxs("div", { className: "AdvancedFilters-overlap", children: [_jsx("header", { className: "AdvancedFilters-header", children: _jsx("div", { className: "AdvancedFilters-overlap-group", children: _jsx("div", { className: "AdvancedFilters-filter-title", children: "FILTRAR ORDENES" }) }) }), isEditing && _jsx(AddCircleIcon, { className: "AdvancedFilters-filter-add-icon", onClick: handleAddFilter }), _jsx(FilterListIcon, { className: "AdvancedFilters-options-icon", onClick: function () { return setShowFieldSelector(true); } })] }), filters.length > 0 && (_jsx(FiltersInputsComponent, { filters: filters, selectedFields: selectedFields, handleFilterChange: handleFilterChange, handleRemoveFilter: handleRemoveFilter, loading: loading, results: results, isEditing: isEditing, token: token, OrdenPageType: OrdenPageType })), isEditing ? (_jsx("button", { className: "AdvancedFilters-cancel-button", onClick: onClose, children: "Cancelar" })) : (_jsx("button", { className: "AdvancedFilters-edit-button", onClick: handleEdit, children: "Editar" })), showViewButton ? (_jsx("button", { className: "AdvancedFilters-view-button", onClick: handleView, children: "Ver" })) : (_jsx("button", { className: "AdvancedFilters-send-button", onClick: handleSearch, children: "Enviar" }))] }) }) }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), showFieldSelector && (_jsx(FiltersCamposToSelectOrdenes, { onSubmit: handleFieldSelection, onClose: function () { return setShowFieldSelector(false); }, initialSelectedFields: selectedFields }))] })) : null);
};
export default AdvancedFiltersOrdenes;
