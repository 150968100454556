import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import RegisterCotizacionForm from '../components/cotizaciones/forms/RegisterCotiazcionForm';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
var RegisterCotizacionPage = function (_a) {
    var _b = useState(false), isFormVisible = _b[0], setIsFormVisible = _b[1]; // Estado para manejar visibilidad del formulario
    var handleCotizacionSuccess = function () {
        // Lógica cuando la cotización se registra con éxito
        alert('Cotización registrada exitosamente.');
    };
    var handleCancel = function () {
        // Lógica para cancelar el registro
    };
    return (_jsxs("div", { className: "RegisterCotizacionPage-container", children: [_jsx(DashboardMenuLateral, {}), _jsx(RegisterCotizacionForm, {})] }));
};
export default RegisterCotizacionPage;
