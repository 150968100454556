import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './styles/OrdenLimitation.css';
var predefinedLimits = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
var OrdenLimitation = function (_a) {
    var limitPerPage = _a.limitPerPage, setLimitPerPage = _a.setLimitPerPage;
    var handleLimitChange = function (event) {
        setLimitPerPage(Number(event.target.value));
    };
    return (_jsxs("div", { className: "orden-limitation-container", children: [_jsx("select", { id: "limit-select", value: limitPerPage, onChange: handleLimitChange, className: "orden-limitation-select", children: predefinedLimits.map(function (limit) { return (_jsx("option", { value: limit, children: limit }, limit)); }) }), _jsx("input", { type: "number", value: limitPerPage, onChange: handleLimitChange, className: "orden-limitation-input" })] }));
};
export default OrdenLimitation;
