import axios from '../../../utils/config/axios.config';
export var getAllRepuestosEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos/repuestos', options)
        .then(function (response) { return response.data; }) // Ajusta para obtener la propiedad "repuestoEquipos" de la respuesta
        .catch(function (error) {
        handleRequestError(error);
    });
};
export var getRepuestoEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/repuestos", options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        handleRequestError(error);
    });
};
export var createRepuestoEquipo = function (token, repuestoEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/repuestos', repuestoEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        handleRequestError(error);
    });
};
export var updateRepuestoEquipo = function (token, id, repuestoEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos/repuestos?id=".concat(id), repuestoEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        handleRequestError(error);
    });
};
export var deleteRepuestoEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/repuestos", options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        handleRequestError(error);
    });
};
var handleRequestError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            window.location.href = '/login';
        }
    }
    else {
        // Maneja otros errores aquí
        throw error;
    }
};
