import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
var SolicitudServicioCambiosOrden = function (_a) {
    var _b, _c;
    var orden = _a.orden;
    var solicitud = orden.id_solicitud_servicio;
    if (!solicitud) {
        return _jsx("p", { children: "No hay detalles de la solicitud de servicio." });
    }
    return (_jsxs(_Fragment, { children: [_jsx("li", { className: "CambiosOrden-cambio-card", children: _jsxs("div", { className: "CambiosOrden-overlap-group-3", children: [_jsx("p", { className: "CambiosOrden-subestado-t", children: "Solicitud Creada" }), _jsxs("div", { className: "CambiosOrden-subestado-comments", children: ["Aviso: ", solicitud.aviso || 'N/A', " - Observaci\u00F3n: ", solicitud.observacion || 'N/A'] }), _jsx("div", { className: "CambiosOrden-separator" }), _jsx("div", { className: "CambiosOrden-registered-by", children: "REGISTRADO POR:" }), _jsx("div", { className: "CambiosOrden-username", children: ((_b = solicitud.id_creador) === null || _b === void 0 ? void 0 : _b.name) || 'N/A' }), _jsx("div", { className: "CambiosOrden-date-t", children: "EL D\u00CDA:" }), _jsx("div", { className: "CambiosOrden-date-value", children: solicitud.creacion || 'N/A' })] }) }), _jsx("li", { className: "CambiosOrden-cambio-card", children: solicitud.id_cambiador && (_jsxs("div", { className: "CambiosOrden-overlap-group-3", children: [_jsx("p", { className: "CambiosOrden-subestado-t", children: "Solicitud Aprobada" }), _jsx("div", { className: "CambiosOrden-subestado-comments", children: solicitud.observacion_estado || 'N/A' }), _jsx("div", { className: "CambiosOrden-separator" }), _jsx("div", { className: "CambiosOrden-registered-by", children: "APROBADO POR:" }), _jsx("div", { className: "CambiosOrden-username", children: ((_c = solicitud.id_cambiador) === null || _c === void 0 ? void 0 : _c.name) || 'N/A' }), _jsx("div", { className: "CambiosOrden-date-t", children: "EL D\u00CDA:" }), _jsx("div", { className: "CambiosOrden-date-value", children: solicitud.cambio_estado || 'N/A' })] })) })] }));
};
export default SolicitudServicioCambiosOrden;
