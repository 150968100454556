import axios from '../../../utils/config/axios.config';
export var getAllVisitas = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/visitas', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var getVisitaById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/visitas/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createVisita = function (token, visitaData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/visitas', visitaData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateVisita = function (token, id, visitaData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/visitas?id=".concat(id), visitaData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteVisitaById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/visitas/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // window.location.href = '/login';
            console.log(error);
        }
    }
    throw error;
};
export var getPresignedUrl = function (token, fileName) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            fileName: fileName,
        },
    };
    return axios
        .get('/visitas/generate-presigned-url', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var getPresignedUrlForGet = function (token, objectKey) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            key: objectKey,
        },
    };
    return axios
        .get('/visitas/generate-presigned-url-get', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
