import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import showingProduct from "../images/nuestros_productos/showing_product.png";
import product2 from "../images/nuestros_productos/product2.png";
import product3 from "../images/nuestros_productos/product3.png";
import "../styles/nuestros_productos.css";
var products = [
    {
        image: showingProduct,
        title: "Quattro Tandem",
        description: "La solución perfecta para clínicas avanzadas. Ofrece aire comprimido seco y constante, con un diseño modular que asegura expansión futura. Compacto, potente y fiable, optimiza tu espacio sin comprometer el rendimiento. ",
        brand: "Durr Dental",
        dtitle: "Compresor Dental de Aire Comprimido"
    },
    {
        image: product2,
        title: "Rayos X de Pared AXR-70 Pantográfico Saevo",
        description: "Precisión, seguridad y tecnología avanzada en cada toma. Con 95% menos de plomo, ofrece radiografías intraorales claras, mientras su movimiento suave y estable garantiza comodidad y resultados precisos.",
        brand: "SAEVO",
        dtitle: "Pared Pantográfico"
    },
    {
        image: product3,
        title: "Autoclave Clase B FOMOS 22 Lts",
        description: "Esterilización de última generación para clínicas exigentes. Su doble propósito permite esterilizar instrumentos y algodón. Con funciones automáticas, ajuste de tiempo y pruebas avanzadas, garantiza seguridad máxima. Compacto y eficiente, incluye sistema de alarma y bloqueo doble para total tranquilidad.",
        brand: "FOMOS",
        dtitle: "Equipo de Esterilización"
    },
];
export var NuestrosProductos = function () {
    var _a = useState(0), currentIndex = _a[0], setCurrentIndex = _a[1];
    var _b = useState(false), isAnimating = _b[0], setIsAnimating = _b[1];
    var handleNext = function () {
        if (isAnimating)
            return; // Evitar múltiples clics durante la animación
        setIsAnimating(true);
        setTimeout(function () {
            setCurrentIndex(function (prevIndex) { return (prevIndex + 1) % products.length; });
            setIsAnimating(false);
        }, 500); // Duración de la animación
    };
    var handlePrev = function () {
        if (isAnimating)
            return; // Evitar múltiples clics durante la animación
        setIsAnimating(true);
        setTimeout(function () {
            setCurrentIndex(function (prevIndex) { return (prevIndex - 1 + products.length) % products.length; });
            setIsAnimating(false);
        }, 500); // Duración de la animación
    };
    var handleMasProducts = function () {
        window.open("https://memcosas.com/tienda/", "_blank");
    };
    var handleProductClick = function (index) {
        if (index === (currentIndex - 1 + products.length) % products.length) {
            handlePrev(); // Si se hace clic en el producto de la izquierda
        }
        else if (index === (currentIndex + 1) % products.length) {
            handleNext(); // Si se hace clic en el producto de la derecha
        }
    };
    return (_jsx("div", { className: "nuestros-productos", children: _jsxs("div", { className: "overlap", children: [_jsx("div", { className: "bg", children: _jsxs("div", { className: "overlap-group", children: [_jsx("div", { className: "rectangle" }), _jsx("div", { className: "div" })] }) }), _jsxs("div", { className: "ver-mas", onClick: handleMasProducts, children: ["VER ", _jsx("span", { className: "ver-mas-gradient", children: "M\u00C1S" })] }), _jsxs("div", { className: "product-info ".concat(isAnimating ? "fade-out" : ""), children: [_jsx("div", { className: "overlap-2", children: _jsx("p", { className: "descritpion ".concat(isAnimating ? "fade-out" : ""), children: products[currentIndex].description }) }), _jsx("p", { className: "title ".concat(isAnimating ? "fade-out" : ""), children: products[currentIndex].dtitle })] }), _jsx("div", { className: "product-carousel", children: products.map(function (product, index) {
                        var positionClass = "side";
                        if (index === currentIndex) {
                            positionClass = "center";
                        }
                        else if (index === (currentIndex - 1 + products.length) % products.length) {
                            positionClass = "left";
                        }
                        else if (index === (currentIndex + 1) % products.length) {
                            positionClass = "right";
                        }
                        return (_jsx("img", { className: "product-image ".concat(positionClass), alt: "Product", src: product.image, onClick: function () { return handleProductClick(index); } }, index));
                    }) }), _jsxs("div", { className: "product-name", children: [_jsx("div", { className: "marca ".concat(isAnimating ? "fade-out" : ""), children: products[currentIndex].brand }), _jsxs("div", { className: "overlap-3 ".concat(isAnimating ? "fade-out" : ""), children: [_jsx("div", { className: "shadow-text ".concat(isAnimating ? "fade-out" : ""), children: products[currentIndex].title }), _jsx("div", { className: "text ".concat(isAnimating ? "fade-out" : ""), children: products[currentIndex].title })] })] }), _jsx("div", { className: "overlap-wrapper", children: _jsxs("div", { className: "overlap-4", children: [_jsx("div", { className: "text-wrapper", children: "NUESTROS PRODUCTOS" }), _jsx("div", { className: "shadow-text-2", children: "NUESTROS PRODUCTOS" }), _jsx("div", { className: "text-2", children: "NUESTROS PRODUCTOS" })] }) }), _jsx("div", { className: "carousel-indicators", children: products.map(function (_, index) { return (_jsx("span", { className: "indicator ".concat(index === currentIndex ? "active" : ""), onClick: function () { return setCurrentIndex(index); } }, index)); }) })] }) }));
};
export default NuestrosProductos;
