// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solicitud-servicio-limitation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.42vw;
    color: #00ddfa;
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0.53vh 2.26vw 0.53vh 1.59vw;
  }
  
  .solicitud-servicio-limitation-select {
    margin-right: 0.5vw;
    font-size: 0.7vw;
    padding: 0.2vw;
    color: #00ddfa;
    background-color: transparent;
    border: 0.1vw solid #000000;
    border-radius: 0.3vw;
  }
  
  .solicitud-servicio-limitation-input {
    width: 3vw;
    padding: 0.3vw;
    color: #00ddfa;
    font-size: 0.7vw;
    background-color: transparent;
    border: 0.1vw solid #000000;
    border-radius: 0.3vw;
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/solicitudes/components/filters/styles/SolicitudServicioLimitation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,MAAM;IACN,OAAO;IACP,oCAAoC;EACtC;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,cAAc;IACd,6BAA6B;IAC7B,2BAA2B;IAC3B,oBAAoB;EACtB;;EAEA;IACE,UAAU;IACV,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,6BAA6B;IAC7B,2BAA2B;IAC3B,oBAAoB;EACtB","sourcesContent":[".solicitud-servicio-limitation-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 0.42vw;\n    color: #00ddfa;\n    position: relative;\n    height: 100%;\n    top: 0;\n    left: 0;\n    padding: 0.53vh 2.26vw 0.53vh 1.59vw;\n  }\n  \n  .solicitud-servicio-limitation-select {\n    margin-right: 0.5vw;\n    font-size: 0.7vw;\n    padding: 0.2vw;\n    color: #00ddfa;\n    background-color: transparent;\n    border: 0.1vw solid #000000;\n    border-radius: 0.3vw;\n  }\n  \n  .solicitud-servicio-limitation-input {\n    width: 3vw;\n    padding: 0.3vw;\n    color: #00ddfa;\n    font-size: 0.7vw;\n    background-color: transparent;\n    border: 0.1vw solid #000000;\n    border-radius: 0.3vw;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
