var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { updateOrden } from '../../services/ordenesService';
import { getAllOrdenesSubEstado } from '../../services/ordenSubEstadoService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/RegisterCambiosOrden.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
var RegisterCambioOrden = function (_a) {
    var idOrden = _a.idOrden, onCambioSuccess = _a.onCambioSuccess, cambiosExistentes = _a.cambiosExistentes, onCancel = _a.onCancel;
    var _b = useState(''), comentario = _b[0], setComentario = _b[1];
    var _c = useState(''), subEstadoId = _c[0], setSubEstadoId = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(''), error = _e[0], setError = _e[1];
    var _f = useState([]), subEstados = _f[0], setSubEstados = _f[1];
    var loggedIn = useSessionStorage('sessionJWTToken');
    var userId = useSessionStorage('userId');
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    var _g = useState([]), alerts = _g[0], setAlerts = _g[1]; // Estado de alertas
    useEffect(function () {
        var fetchSubEstados = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, getAllOrdenesSubEstado(token)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            setSubEstados(response); // Aquí está la corrección
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error al obtener los subestados:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchSubEstados();
    }, [loggedIn]);
    // Función para agregar una alerta
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now() + Math.random(), message: message, severity: severity }], false); });
    };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var nuevoCambio, cambiosActualizados, cambioData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    setLoading(true);
                    setError('');
                    if (!loggedIn || !userId) {
                        setError('No estás autenticado');
                        addAlert('No estás autenticado', 'error');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    nuevoCambio = {
                        ids_orden_sub_estado: subEstadoId,
                        id_creador: userId,
                        date_created: formattedDateCreated,
                        comentario: comentario,
                    };
                    cambiosActualizados = cambiosExistentes
                        ? __spreadArray(__spreadArray([], cambiosExistentes, true), [nuevoCambio], false) : [nuevoCambio];
                    cambioData = { orden_cambios: cambiosActualizados };
                    return [4 /*yield*/, updateOrden(loggedIn, idOrden, cambioData)];
                case 2:
                    _a.sent();
                    // Añadir alerta de éxito
                    addAlert('Cambio registrado exitosamente', 'success');
                    // Cerrar modal después de la actualización
                    setTimeout(function () {
                        onCambioSuccess(); // Notificar al componente padre sobre el éxito
                        setLoading(false);
                    }, 1000);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error al registrar el cambio en la orden:', error_2);
                    setError('Ocurrió un error al registrar el cambio');
                    // Añadir alerta de error
                    addAlert('Ocurrió un error al registrar el cambio', 'error');
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("form", { onSubmit: handleSubmit, className: "RegisterCambioOrden-box", children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: function (id) { return setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); }); } }), _jsx("div", { className: "RegisterCambioOrden-register-cambio", children: _jsxs("div", { className: "RegisterCambioOrden-overlap-group", children: [_jsx("div", { className: "RegisterCambioOrden-overlap", children: _jsx("div", { className: "RegisterCambioOrden-title-t", children: "AGREGAR CAMBIO" }) }), _jsx("p", { className: "RegisterCambioOrden-select-input", children: "Seleccione el subestado a registrar: *" }), _jsxs("select", { className: "RegisterCambioOrden-subestado-li", value: subEstadoId, onChange: function (e) { return setSubEstadoId(e.target.value); }, required: true, children: [_jsx("option", { value: "", disabled: true, children: "Selecciona un Sub Estado" }), subEstados.map(function (subEstado) { return (_jsx("option", { value: subEstado._id, children: subEstado.sub_estado }, subEstado._id)); })] }), _jsx("div", { className: "RegisterCambioOrden-comments-i", children: "Ingrese las observaciones: *" }), _jsx("textarea", { className: "RegisterCambioOrden-comments-t", value: comentario, onChange: function (e) { return setComentario(e.target.value); }, placeholder: "Comentario", required: true }), _jsx("button", { className: "RegisterCambioOrden-register-button", disabled: loading, type: 'submit', children: loading ? 'Cargando...' : 'AGREGAR CAMBIO' }), _jsx("button", { className: "RegisterCambioOrden-cancel-button", type: "button", onClick: onCancel, disabled: loading, children: "CANCELAR" }), error && _jsx("p", { className: "RegisterCambioOrden-error-message", children: error })] }) })] }));
};
export default RegisterCambioOrden;
