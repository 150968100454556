// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendCotizacionEmail-email-form{
    height: 100%;
    width: 100%;
}

.SendCotizacionEmail-email-text{
    text-align: center;
    font-size: 1.2vw;
    font-weight: bold;
    color: #00ddfa;
}

.SendCotizacionEmail-email-input{
    text-align: center;
    font-size: 0.62vw;
    height: 3.7vh;
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 0.52vw;
    border: none;
    position: relative;
    left: 0;
    top: 4vh;
}

.SendCotizacionEmail-send-icon{
    height: 2.78vh !important; /* 30px -> 2.78vh */
    width: 1.56vw !important; /* 30px -> 1.56vw */
    color: #00ddfa;
    cursor: pointer;
    position: relative;
}

.SendCotizacionEmail-send-button{
    height: 2.78vh;
    width: 1.56vw;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 54.72vh;
    left: 54vw;
}

.SendCotizacionEmail-cancel-icon{
    height: 2.78vh !important;
    width: 1.56vw !important;
    color: #d9d9d9;
    cursor: pointer;
    position: absolute;
    top: 54.72vh;
    left: 47.71vw;
}
`, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/cotizaciones/styles/SendCotizacionEmail.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,QAAQ;AACZ;;AAEA;IACI,yBAAyB,EAAE,mBAAmB;IAC9C,wBAAwB,EAAE,mBAAmB;IAC7C,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".SendCotizacionEmail-email-form{\n    height: 100%;\n    width: 100%;\n}\n\n.SendCotizacionEmail-email-text{\n    text-align: center;\n    font-size: 1.2vw;\n    font-weight: bold;\n    color: #00ddfa;\n}\n\n.SendCotizacionEmail-email-input{\n    text-align: center;\n    font-size: 0.62vw;\n    height: 3.7vh;\n    width: 100%;\n    background-color: #d9d9d9;\n    border-radius: 0.52vw;\n    border: none;\n    position: relative;\n    left: 0;\n    top: 4vh;\n}\n\n.SendCotizacionEmail-send-icon{\n    height: 2.78vh !important; /* 30px -> 2.78vh */\n    width: 1.56vw !important; /* 30px -> 1.56vw */\n    color: #00ddfa;\n    cursor: pointer;\n    position: relative;\n}\n\n.SendCotizacionEmail-send-button{\n    height: 2.78vh;\n    width: 1.56vw;\n    background-color: transparent;\n    border: none;\n    position: absolute;\n    top: 54.72vh;\n    left: 54vw;\n}\n\n.SendCotizacionEmail-cancel-icon{\n    height: 2.78vh !important;\n    width: 1.56vw !important;\n    color: #d9d9d9;\n    cursor: pointer;\n    position: absolute;\n    top: 54.72vh;\n    left: 47.71vw;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
