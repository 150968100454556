import axios from '../../../utils/config/axios.config';
// Función para obtener todos los modelos de equipos con parámetros opcionales de límite y página
export var getAllModeloEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos/modelo', options)
        .then(function (response) { return response.data; }) // Devolver los datos directamente como en getAllEquipos
        .catch(function (error) { return handleError(error); }); // Utiliza el mismo manejo de errores que en getAllEquipos
};
// Get a ModeloEquipo by ID
export var getModeloEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/modelo/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Create a new ModeloEquipo
export var createModeloEquipo = function (token, modeloEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/modelo', modeloEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Nueva función para crear modelos de equipos en masivo
export var createMultipleModeloEquipos = function (token, modelosData) {
    var options = {
        headers: { 'x-access-token': token },
    };
    return axios
        .post('/equipos/modelo/crear-masivo', modelosData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Update a ModeloEquipo by ID
export var updateModeloEquipo = function (token, id, modeloEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos/modelo?id=".concat(id), modeloEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Delete a ModeloEquipo by ID
export var deleteModeloEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/modelo/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Handle errors
var handleError = function (error) {
    if (error.response) {
        var _a = error.response, status_1 = _a.status, data = _a.data;
        if (status_1 === 500) {
            console.error("Error 500: ".concat(data));
            window.location.href = '/login'; // Redirige al usuario a la página de inicio de sesión
        }
    }
    else {
        console.error('Unknown error:', error);
        window.location.href = '/login'; // Redirige al usuario a la página de inicio de sesión
    }
    throw error;
};
