import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import RegisterUserForm from '../components/forms/RegisterUserForm';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../hooks/useSessionStorage';
import DashboardMenuLateral from '../components/dashboard/DashboardMenulateral';
import './styles/RegisterUserPage.css';
import useUserRoleVerifier from '../hooks/useUserRoleVerifier';
import { logoutService } from '../services/authService';
export var RegisterPage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var isAdmin = useUserRoleVerifier(['administrador']);
    var navigate = useNavigate();
    useEffect(function () {
        if (!loggedIn) {
            logoutService();
        }
    }, [loggedIn]);
    return (_jsxs("div", { children: [_jsx("div", { className: 'RegisterPage-DashboardMenuLateral', children: _jsx(DashboardMenuLateral, {}) }), _jsx("div", { className: 'RegisterPage-container', children: _jsx(RegisterUserForm, {}) })] }));
};
