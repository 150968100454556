import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import CotizacionHeaderPDF from './CotizacionHeaderPDF';
import CotizacionesBodyPDF from './CotizacionesBodyPDF';
import CotizacionesCostosPDF from './CotizacionesCostosPDF';
import CotizacionSignSectionPDF from './CotizacionSignSection';
var CotizacionesPDF = function (_a) {
    var cotizacion = _a.cotizacion;
    var repuestos = cotizacion.ids_repuestos || [];
    var itemsAdicionales = cotizacion.items_adicionales || [];
    var _b = useState(0), totalCotizacion = _b[0], setTotalCotizacion = _b[1];
    var formatCurrency = function (cantidad, valorUnitario) {
        var total = cantidad * valorUnitario;
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(total);
    };
    var formatCurrencyValorU = function (value) {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(value);
    };
    useEffect(function () {
        var calcularTotalCotizacion = function () {
            var totalRepuestos = repuestos.reduce(function (acc, repuesto) { return acc + (repuesto.cantidad || 0) * (repuesto.valor_unitario || 0); }, 0);
            var totalItemsAdicionales = itemsAdicionales.reduce(function (acc, item) { return acc + (item.cantidad || 0) * (item.valor_unitario || 0); }, 0);
            return totalRepuestos + totalItemsAdicionales;
        };
        setTotalCotizacion(calcularTotalCotizacion());
    }, [repuestos, itemsAdicionales]);
    return (_jsx(Document, { children: _jsx(Page, { size: "A4", style: styles.page, children: _jsxs(View, { style: styles.container, children: [_jsx(CotizacionHeaderPDF, { cotizacion: cotizacion }), _jsx(CotizacionesBodyPDF, { cotizacion: cotizacion }), _jsx(CotizacionesCostosPDF, { cotizacion: cotizacion, formatCurrency: formatCurrency, formatCurrencyValorU: formatCurrencyValorU, totalCotizacion: totalCotizacion }), _jsx(CotizacionSignSectionPDF, { cotizacion: cotizacion })] }) }) }));
};
var styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        padding: 10,
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap', // Permite que los elementos se ajusten en varias páginas.
    },
});
export default CotizacionesPDF;
