import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import useUserRoleVerifier from "../../hooks/useUserRoleVerifier";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { usePermission } from '../../../../hooks/usePermission';
var DashboardCentralSolicitudBodega = function () {
    var canViewSolicitudesBodega = usePermission('solicitudes_bodega', 'verTodos').canAccess;
    var navigate = useNavigate();
    var isAdmin = useUserRoleVerifier(['administrador']);
    return (_jsxs("ul", { className: "DashboardMenuCentral-nav-ul", children: [(canViewSolicitudesBodega &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/solicitudes-bodega'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(LocalShippingIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Solicitudes Bodega" })] }) }) })), (canViewSolicitudesBodega &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/solicitudes-bodega-pendientes'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsxs("i", { className: 'DashboardMenuCentral-nav-icon-i', children: [_jsx(LocalShippingIcon, { className: 'DashboardMenuCentral-icon' }), _jsx(HelpOutlinedIcon, { className: 'DashboardMenuCentral-add-icon' })] }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Solicitudes Bodega Pendientes" })] }) }) })), (canViewSolicitudesBodega &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/solicitudes-bodega-aprobadas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(LocalShippingIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Solicitudes Bodega Aprobadas" })] }) }) })), (canViewSolicitudesBodega &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/solicitudes-bodega-despachadas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(LocalShippingIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Solicitudes Bodega Despachadas" })] }) }) })), (canViewSolicitudesBodega &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/solicitudes-bodega-terminadas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(LocalShippingIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Solicitudes Bodega Terminadas" })] }) }) })), (canViewSolicitudesBodega &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/solicitudes-bodega-rechazadas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(LocalShippingIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Solicitudes Bodega Rechazadas" })] }) }) }))] }));
};
export default DashboardCentralSolicitudBodega;
