var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { createCampos, getAllCampos } from '../../../services/camposService';
import { useNavigate } from 'react-router-dom';
import './styles/RegisterCampoForm.css';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlertComponent from '../../../../../components/MuiAlertsComponent'; // Ajusta la ruta si es necesario
var RegisterCampoForm = function () {
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    var _a = useState({
        id_tipo: '',
        title: '',
        date_created: formattedDateCreated,
    }), campoData = _a[0], setCampoData = _a[1];
    var _b = useState(null), titleAvailable = _b[0], setTitleAvailable = _b[1];
    var _c = useState(false), titleCheckLoading = _c[0], setTitleCheckLoading = _c[1];
    var _d = useState([]), alerts = _d[0], setAlerts = _d[1];
    var loggedIn = useSessionStorage('sessionJWTToken');
    var navigate = useNavigate();
    var checkTitleAvailability = function (title) { return __awaiter(void 0, void 0, void 0, function () {
        var token, response, camposList, titleExists, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setTitleCheckLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    token = loggedIn;
                    return [4 /*yield*/, getAllCampos(token)];
                case 2:
                    response = _a.sent();
                    camposList = response.campos.map(function (campo) { return campo.title.toLowerCase(); });
                    titleExists = camposList.includes(title.toLowerCase());
                    setTitleAvailable(!titleExists);
                    if (titleExists) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'El título ya existe', severity: 'warning' }], false); });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al verificar el título del campo', error_1);
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Error al verificar el título', severity: 'error' }], false); });
                    return [3 /*break*/, 5];
                case 4:
                    setTitleCheckLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setCampoData(__assign(__assign({}, campoData), (_a = {}, _a[name] = value, _a)));
        if (name === 'title' && value.trim()) {
            checkTitleAvailability(value);
        }
        else {
            setTitleAvailable(null);
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var id_tipo, title, token, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    id_tipo = campoData.id_tipo, title = campoData.title;
                    // Validar campos obligatorios
                    if (!id_tipo || !title) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Los campos con * son obligatorios', severity: 'warning' }], false); });
                        return [2 /*return*/];
                    }
                    // Validar si el título está disponible
                    if (titleAvailable === false) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'El título ya existe. Por favor elige otro título.', severity: 'warning' }], false); });
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    token = loggedIn;
                    // Mostrar la alerta de loading
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: "Actualizando...  ", severity: 'info' }], false); });
                    return [4 /*yield*/, createCampos(token, campoData)];
                case 2:
                    _a.sent();
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Campo registrado exitosamente', severity: 'success' }], false); });
                    setTimeout(function () {
                        navigate('/campos');
                    }, 1000);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error al registrar el campo:', error_2);
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Error al registrar el campo', severity: 'error' }], false); });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAlertClose = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    return (_jsxs("div", { children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleAlertClose }), " ", _jsx("form", { onSubmit: handleSubmit, children: _jsx("div", { className: "RegisterCampoForm-box", children: _jsx("div", { className: "RegisterCampoForm-register-campo", children: _jsxs("div", { className: "RegisterCampoForm-overlap-group", children: [_jsx("div", { className: "RegisterCampoForm-overlap", children: _jsx("div", { className: "RegisterCampoForm-campo-title", children: "REGISTRAR NUEVO CAMPO" }) }), _jsx("p", { className: "RegisterCampoForm-tipo-p", children: "Seleccione el tipo de campo: *" }), _jsxs("select", { className: "RegisterCampoForm-tipo-input", name: "id_tipo", value: campoData.id_tipo, onChange: handleChange, children: [_jsx("option", { value: "", children: "Seleccione el tipo" }), _jsx("option", { value: "Pas\u00F3 \u00F3 Fall\u00F3", children: "Pas\u00F3 \u00F3 Fall\u00F3" }), _jsx("option", { value: "Cuantitativo", children: "Cuantitativo" })] }), _jsx("p", { className: "RegisterCampoForm-title-p", children: "Ingrese el t\u00EDtulo del campo: *" }), _jsx("input", { className: "RegisterCampoForm-title-input", type: "text", name: "title", value: campoData.title, onChange: handleChange, placeholder: "T\u00EDtulo del Campo" }), titleCheckLoading ? (_jsx(CircularProgress, { style: { color: '#00ddfa', position: 'absolute', top: '29vh', left: '25.7vw' }, size: 20 })) : (titleAvailable !== null && (_jsx("p", { className: titleAvailable ? 'title-available' : 'title-unavailable', children: titleAvailable ? 'Título disponible' : 'Título no disponible' }))), _jsx("button", { className: "RegisterCampoForm-cancelar", onClick: function () { return navigate('/campos'); }, children: "Cancelar" }), _jsx("button", { className: "RegisterCampoForm-registrar", type: 'submit', children: "Registrar" })] }) }) }) })] }));
};
export default RegisterCampoForm;
