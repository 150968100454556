var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getSolicitudBodegaById } from '../../../solicitudes_bodega/services/solicitudesBodegaService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import CircularProgress from '@mui/material/CircularProgress';
import SolicitudBodegaByIdPendiente from './SolicitudBodegaByIdPendiente';
import SolicitudBodegaByIdAprobada from './SolicitudBodegaByIdAprobada';
import SolicitudBodegaByIdDespachada from './SolicitudBodegaByIdDespachada';
import SolicitudBodegaByIdTerminada from './SolicitudBodegaByIdTerminada';
import SolicitudBodegaByIdRechazada from './SolicitudBodegaByIdRechazada';
import './styles/SolicitudBodegaByIdOrden.css';
var SolicitudBodegaByIdOrden = function (_a) {
    var idSolicitudBodega = _a.idSolicitudBodega, onBack = _a.onBack, onCambioSuccess = _a.onCambioSuccess;
    var _b = useState(null), solicitudBodega = _b[0], setSolicitudBodega = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        var fetchSolicitudBodega = function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchedSolicitud, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!idSolicitudBodega || !token) {
                            setLoading(false);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, getSolicitudBodegaById(token, idSolicitudBodega)];
                    case 2:
                        fetchedSolicitud = _a.sent();
                        setSolicitudBodega(fetchedSolicitud);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Failed to fetch solicitud bodega:', error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchSolicitudBodega();
    }, [idSolicitudBodega, token]);
    var refreshSolicitudBodega = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fetchedSolicitud, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!idSolicitudBodega || !token) {
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getSolicitudBodegaById(token, idSolicitudBodega)];
                case 2:
                    fetchedSolicitud = _a.sent();
                    setSolicitudBodega(fetchedSolicitud);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error('Failed to fetch solicitud bodega:', error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (loading) {
        return (_jsx("div", { style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: '#00ddfa', position: 'relative', top: '270px', left: '290px' }, children: _jsx(CircularProgress, { color: "inherit" }) }));
    }
    if (!solicitudBodega) {
        return _jsx("div", { children: "No se encontr\u00F3 la solicitud de bodega." });
    }
    return (_jsxs("div", { className: 'SolicitudBodegaByIdOrden-container', children: [solicitudBodega.id_estado.estado === "Pendiente" && (_jsx(SolicitudBodegaByIdPendiente, { solicitudBodega: solicitudBodega, onBack: onBack, onCambioSuccess: onCambioSuccess })), solicitudBodega.id_estado.estado === "Aprobada" && (_jsx(SolicitudBodegaByIdAprobada, { solicitudBodega: solicitudBodega, onBack: onBack, onCambioSuccess: onCambioSuccess, onNewNovedad: refreshSolicitudBodega })), solicitudBodega.id_estado.estado === "Rechazada" && (_jsx(SolicitudBodegaByIdRechazada, { solicitudBodega: solicitudBodega, onBack: onBack, onCambioSuccess: onCambioSuccess, onNewNovedad: refreshSolicitudBodega })), solicitudBodega.id_estado.estado === "Despachada" && (_jsx(SolicitudBodegaByIdDespachada, { solicitudBodega: solicitudBodega, onBack: onBack, onCambioSuccess: onCambioSuccess, onNewNovedad: refreshSolicitudBodega })), solicitudBodega.id_estado.estado === "Terminada" && (_jsx(SolicitudBodegaByIdTerminada, { solicitudBodega: solicitudBodega, onBack: onBack, onCambioSuccess: onCambioSuccess, onNewNovedad: refreshSolicitudBodega }))] }));
};
export default SolicitudBodegaByIdOrden;
