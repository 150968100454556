import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import "../styles/faq.css"; // Archivo de estilos para toda la sección
var faqs = [
    {
        question: "¿Qué es Bioswift y para qué esta diseñado?",
        answer: "Bioswift es una plataforma para gestionar equipos biomédicos y sus repuestos, permitiendo la trazabilidad de órdenes, visitas y solicitudes de servicio.",
    },
    {
        question: "¿Quiénes son los clientes que pueden utilizar Bioswift",
        answer: "Pueden usarlo tanto instituciones de salud como doctores independientes con consultorios propios.",
    },
    {
        question: "¿Cómo ayuda Bioswift en la gestión de equipos?",
        answer: "Facilita el monitoreo, mantenimiento y gestión de equipos biomédicos con alertas y reportes detallados.",
    },
    {
        question: "¿Bioswift cumple con normativas del sector biomédico?",
        answer: "Sí, está diseñado para cumplir con regulaciones y asegurar la trazabilidad de mantenimiento y documentación.",
    },
    {
        question: "¿Se pueden generar reportes en Bioswift?",
        answer: "Sí, Bioswift genera reportes automáticos sobre el estado de los equipos y su historial de mantenimiento.",
    },
    {
        question: "¿Cómo se gestionan los usuarios y permisos en Bioswift?",
        answer: "La plataforma permite asignar diferentes roles y permisos a los usuarios según su función.",
    },
    {
        question: "¿Bioswift ofrece integración con otros sistemas?",
        answer: "Sí, puede integrarse con software hospitalario (HIS) y sistemas de mantenimiento (CMMS).",
    },
    {
        question: "¿Es posible acceder a Bioswift desde dispositivos móviles?",
        answer: "Sí, es una plataforma web responsive accesible desde computadoras, tablets y smartphones.",
    },
    {
        question: "¿Cómo se puede llegar a adquirir Bioswift?",
        answer: "Puedes contactarnos a través de nuestra página web para una demostración y asesoría personalizada.",
    },
];
// Componente interno FAQBox
var FAQBox = function (_a) {
    var question = _a.question, answer = _a.answer;
    var _b = useState(false), isHovered = _b[0], setIsHovered = _b[1];
    return (_jsxs("div", { className: "faq-wrapper", onMouseEnter: function () { return setIsHovered(true); }, onMouseLeave: function () { return setIsHovered(false); }, children: [_jsxs("div", { className: "faq-item ".concat(isHovered ? "active" : ""), children: [_jsx("div", { className: "faq-question", children: question }), " "] }), _jsx("div", { className: "faq-answer ".concat(isHovered ? "active" : ""), children: answer }), " "] }));
};
var FAQ = function () {
    return (_jsxs("div", { className: "faq-container", children: [_jsx("h2", { className: "faq-title", children: "PREGUNTAS FRECUENTES" }), _jsx("div", { className: "faq-grid", children: faqs.map(function (faq, index) { return (_jsx(FAQBox, { question: faq.question, answer: faq.answer }, index)); }) })] }));
};
export default FAQ;
