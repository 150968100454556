import axios from '../../../utils/config/axios.config';
// Obtener todas las áreas de equipos
export var getAllAreasEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos/areas', options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Obtener un área de equipo por ID
export var getAreaEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/areas/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Crear una nueva área de equipo
export var createAreaEquipo = function (token, areaEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/areas', areaEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// **Nueva función para crear múltiples áreas de equipos en masivo**
export var createMultipleAreasEquipos = function (token, areasEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/areas/crear-masivo', areasEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Actualizar un área de equipo por ID
export var updateAreaEquipo = function (token, id, areaEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos/areas?id=".concat(id), areaEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Eliminar un área de equipo por ID
export var deleteAreaEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/areas/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Manejar errores
var handleError = function (error) {
    if (error.response) {
        var _a = error.response, status_1 = _a.status, data = _a.data;
        if (status_1 === 500) {
            console.error("Error 500: ".concat(data));
            window.alert('ERROR: No puedes hacer esto');
        }
    }
    else {
        console.error('Error desconocido:', error);
        window.alert('ERROR: No puedes hacer esto');
    }
    throw error;
};
