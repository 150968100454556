import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import useUserRoleVerifier from "../../hooks/useUserRoleVerifier";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { usePermission } from '../../../../hooks/usePermission';
var DashboardOrdenesDeServicios = function () {
    var navigate = useNavigate();
    var isAdmin = useUserRoleVerifier(['administrador']);
    var canVerTodos = usePermission("ordenes_servicio", "verTodos").canAccess;
    return (_jsxs("ul", { className: "DashboardMenuCentral-nav-ul", children: [(canVerTodos &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/ordenes'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(AssignmentIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Ordenes de Servicio" })] }) }) })), (canVerTodos &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/ordenes-abiertas-preventivo'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(AssignmentTurnedInIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Ordenes Abiertas Preventivo" })] }) }) })), (canVerTodos &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/ordenes-abiertas-cig'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(AssignmentTurnedInIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Ordenes Abiertas CIG" })] }) }) })), (canVerTodos &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/ordenes-cerradas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsxs("i", { className: 'DashboardMenuCentral-nav-icon-i', children: [_jsx(AssignmentIcon, { className: 'DashboardMenuCentral-icon' }), _jsx(DoDisturbOnIcon, { className: 'DashboardMenuCentral-closed-icon' })] }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Ordenes Cerradas" })] }) }) })), _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/home'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(AssignmentLateIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Cambios Ordenes" })] }) }) })] }));
};
export default DashboardOrdenesDeServicios;
