import axios from '../../../utils/config/axios.config';
// Obtener todas las marcas de equipos
export var getAllMarcasEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos/marcas', options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Obtener una marca de equipo por ID
export var getMarcaEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/marcas/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Crear una nueva marca de equipo
export var createMarcaEquipo = function (token, marcaEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/marcas', marcaEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Nueva función para crear múltiples marcas de equipos en masivo
export var createMultipleMarcasEquipos = function (token, marcasEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/marcas/crear-masivo', marcasEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Actualizar una marca de equipo por ID
export var updateMarcaEquipo = function (token, id, marcaEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos/marcas?id=".concat(id), marcaEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Eliminar una marca de equipo por ID
export var deleteMarcaEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/marcas/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Manejar errores
var handleError = function (error) {
    if (error.response) {
        var _a = error.response, status_1 = _a.status, data = _a.data;
        if (status_1 === 500) {
            console.error("Error 500: ".concat(data));
            window.alert('ERROR: No puedes hacer esto');
        }
    }
    else {
        console.error('Error desconocido:', error);
        // Maneja otros errores aquí
    }
    throw error;
};
