var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import OrdenAbiertaCorrectivoPDF from './OrdenAbiertaCorrectivoPDF/OrdenAbiertaCorrectivoPDF';
import { generateGetPresignedUrlsForOrdenImages, generateGetPresignedUrlForFirmaOrden } from '../../services/ordenesService';
import CancelIcon from '@mui/icons-material/Cancel';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './styles/OrdenesPDFComponent.css';
import { getAllCotizaciones } from '../../../cotizaciones/services/cotizacionesService';
import OrdenAbiertaPreventivoPDF from './OrdenAbiertaPreventivoPDF/OrdenAbiertaPreventivoPDF';
import OrdenAnuladaCorrectivoPDF from './OrdenAnuladaCorrectivoPDF/OrdenAnuladaCorrectivoPDF';
import OrdenAnuladaPreventivoPDF from './OrdenAnuladaPreventivoPDF/OrdenAnuladaPreventivoPDF';
import OrdenCerradaCorrectivoPDF from './OrdenCerradaCorrectivoPDF/OrdenCerradaCorrectivoPDF'; // Importamos el nuevo componente
import OrdenCerradaPreventivoPDF from './OrdenCerradaPreventivoPDF/OrdenCerradaPreventivoPDF'; // Importamos el nuevo componente
var OrdenesPDFComponent = function (_a) {
    var orden = _a.orden, onClose = _a.onClose;
    var _b = useState([]), cotizaciones = _b[0], setCotizaciones = _b[1];
    var _c = useState([]), imageUrls = _c[0], setImageUrls = _c[1];
    var _d = useState({ entrega: '', recibe: '' }), firmaUrls = _d[0], setFirmaUrls = _d[1];
    useEffect(function () {
        var fetchCotizaciones = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, cotizaciones_1, cotizacionesRelacionadas, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = sessionStorage.getItem('sessionJWTToken') || '';
                        return [4 /*yield*/, getAllCotizaciones(token)];
                    case 1:
                        cotizaciones_1 = _a.sent();
                        cotizacionesRelacionadas = cotizaciones_1.filter(function (cot) {
                            return cot.id_orden && cot.id_orden._id === orden._id && cot.id_estado.estado === 'Aprobada';
                        });
                        setCotizaciones(cotizacionesRelacionadas);
                        console.log(cotizacionesRelacionadas);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching cotizaciones:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchCotizaciones();
    }, [orden._id]);
    useEffect(function () {
        var fetchImageUrls = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, keys, response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = sessionStorage.getItem('sessionJWTToken') || '';
                        if (!(token && orden.imagenes_imprimir)) return [3 /*break*/, 4];
                        keys = orden.imagenes_imprimir.map(function (img) {
                            var urlPath = new URL(img.image).pathname;
                            return urlPath.startsWith('/') ? urlPath.substring(1) : urlPath;
                        });
                        console.log('Keys for generating presigned URLs from imagenes_imprimir:', keys);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, generateGetPresignedUrlsForOrdenImages(token, keys)];
                    case 2:
                        response = _a.sent();
                        console.log('Received presigned URLs:', response.urls); // Log the received URLs
                        setImageUrls(response.urls);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.error('Error fetching presigned URLs:', error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var fetchFirmaUrls = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, firmaUrls, url, err_1, url, err_2;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        token = sessionStorage.getItem('sessionJWTToken') || '';
                        firmaUrls = { entrega: '', recibe: '' };
                        if (!((_a = orden.entrega) === null || _a === void 0 ? void 0 : _a.firma_entrega)) return [3 /*break*/, 4];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, generateGetPresignedUrlForFirmaOrden(token, orden.entrega.firma_entrega)];
                    case 2:
                        url = _c.sent();
                        firmaUrls.entrega = url;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _c.sent();
                        console.error("Error fetching firma entrega URL:", err_1);
                        return [3 /*break*/, 4];
                    case 4:
                        if (!((_b = orden.recibe) === null || _b === void 0 ? void 0 : _b.firma_recibe)) return [3 /*break*/, 8];
                        _c.label = 5;
                    case 5:
                        _c.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, generateGetPresignedUrlForFirmaOrden(token, orden.recibe.firma_recibe)];
                    case 6:
                        url = _c.sent();
                        firmaUrls.recibe = url;
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _c.sent();
                        console.error("Error fetching firma recibe URL:", err_2);
                        return [3 /*break*/, 8];
                    case 8:
                        setFirmaUrls(firmaUrls);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchImageUrls();
        fetchFirmaUrls();
    }, [orden]);
    var handleGeneratePDF = function () { return __awaiter(void 0, void 0, void 0, function () {
        var pdfDoc, blob, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (orden.id_orden_estado.estado === "Abierta") {
                        if (orden.id_solicitud_servicio.id_servicio.servicio === "Correctivo") {
                            pdfDoc = pdf(_jsx(OrdenAbiertaCorrectivoPDF, { orden: orden, cotizaciones: cotizaciones, includeHeader: true, includeDetails: true, imageUrls: imageUrls, firmaUrls: firmaUrls }));
                        }
                        else {
                            pdfDoc = pdf(_jsx(OrdenAbiertaPreventivoPDF, { orden: orden, cotizaciones: cotizaciones, includeHeader: true, includeDetails: true, imageUrls: imageUrls, firmaUrls: firmaUrls }));
                        }
                    }
                    else if (orden.id_orden_estado.estado === "Anulada") {
                        if (orden.id_solicitud_servicio.id_servicio.servicio === "Correctivo") {
                            pdfDoc = pdf(_jsx(OrdenAnuladaCorrectivoPDF, { orden: orden, cotizaciones: cotizaciones, includeHeader: true, includeDetails: true, imageUrls: imageUrls, firmaUrls: firmaUrls }));
                        }
                        else {
                            pdfDoc = pdf(_jsx(OrdenAnuladaPreventivoPDF, { orden: orden, cotizaciones: cotizaciones, includeHeader: true, includeDetails: true, imageUrls: imageUrls, firmaUrls: firmaUrls }));
                        }
                    }
                    else if (orden.id_orden_estado.estado === "Cerrada") {
                        if (orden.id_solicitud_servicio.id_servicio.servicio === "Correctivo") {
                            pdfDoc = pdf(_jsx(OrdenCerradaCorrectivoPDF, { orden: orden, cotizaciones: cotizaciones, includeHeader: true, includeDetails: true, imageUrls: imageUrls, firmaUrls: firmaUrls }));
                        }
                        else {
                            pdfDoc = pdf(_jsx(OrdenCerradaPreventivoPDF, { orden: orden, cotizaciones: cotizaciones, includeHeader: true, includeDetails: true, imageUrls: imageUrls, firmaUrls: firmaUrls }));
                        }
                    }
                    else {
                        alert('La orden no está en un estado válido para generar el PDF.');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, pdfDoc.toBlob()];
                case 1:
                    blob = _a.sent();
                    url = URL.createObjectURL(blob);
                    window.open(url, '_blank');
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "OrdenesPDFComponent-modal-overlay", children: _jsxs("div", { className: "OrdenesPDFComponent-modal-content", children: [_jsx(CancelIcon, { className: "OrdenesPDFComponent-modal-close-icon", onClick: onClose }), _jsx(PictureAsPdfIcon, { className: "OrdenesPDFComponent-modal-pdf-icon", onClick: handleGeneratePDF })] }) }));
};
export default OrdenesPDFComponent;
