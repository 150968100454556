import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import "./styles/MainPage.css";
import Head from "../components/head";
import HeroII from "../components/hero_ll";
import Caracteristicas from "../components/caracteristicas";
import CaracteristicasLl from "../components/caracteristicas_ll";
import CaracteristicasIII from "../components/caracteristicas_lll";
import Tecnologias from "../components/tecnologias";
import NuestrosClientes from "../components/nuestros_clientes";
import NuestrosProductos from "../components/nuestros_productos";
import Footer from "../components/footer";
import ContactClientEmail from "../components/ContactClientEmail";
import HeroTest from "../components/hero_test";
var MainPage = function () {
    var caracteristicasllRef = useRef(null);
    var _a = useState(true), showHeader = _a[0], setShowHeader = _a[1];
    var _b = useState(""), selectedSection = _b[0], setSelectedSection = _b[1]; // Estado para la sección seleccionada
    var _c = useState(false), showMore = _c[0], setShowMore = _c[1];
    var _d = useState(false), showContactModal = _d[0], setShowContactModal = _d[1];
    useEffect(function () {
        var heroElement = document.getElementById("hero");
        if (!heroElement)
            return;
        var observer = new IntersectionObserver(function (_a) {
            var entry = _a[0];
            setShowHeader(entry.isIntersecting); // Actualiza si el Hero está visible
        }, { threshold: 0.5 } // Se considera visible si el 50% de Hero está en el viewport
        );
        observer.observe(heroElement);
        return function () { return observer.disconnect(); };
    }, []);
    // Efecto para desplazarse automáticamente a la sección seleccionada
    useEffect(function () {
        if (selectedSection) {
            var sectionElement = document.getElementById(selectedSection);
            if (sectionElement) {
                sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }, [selectedSection]);
    // Función para manejar la sección seleccionada desde cualquier parte (Head, Footer, HeroII)
    var handleSectionChange = function (section) {
        setSelectedSection(section);
        if (section !== "caracteristicas") {
            setShowMore(false);
        }
    };
    var handleShowMore = function () {
        setShowMore(function (prev) { return !prev; });
        setTimeout(function () {
            var _a;
            (_a = caracteristicasllRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth" });
        }, 400);
    };
    // Callback para abrir el modal de contacto (pasado a Hero)
    var handleDemoClick = function () {
        setShowContactModal(true);
    };
    // Callback para cerrar el modal (se pasa al componente ContactClientEmail)
    var handleCloseModal = function () {
        setShowContactModal(false);
    };
    return (_jsxs("div", { className: "MainPage-Container", children: [showHeader && _jsx(Head, { onSectionChange: handleSectionChange, selectedSection: selectedSection }), _jsxs("div", { className: "snap-container", children: [_jsx("div", { id: "hero", children: _jsx(HeroTest, { onDemoClick: handleDemoClick }) }), _jsx("div", { id: "heroll", children: _jsx(HeroII, { onSectionChange: handleSectionChange, selectedSection: selectedSection, onDemoClick: handleDemoClick }) }), selectedSection === "caracteristicas" && (_jsx("div", { id: "caracteristicas", children: _jsx(Caracteristicas, { onShowMore: handleShowMore, showMore: showMore }) })), selectedSection === "caracteristicas" && showMore && (_jsxs(_Fragment, { children: [_jsx("div", { id: "caracteristicasll", ref: caracteristicasllRef, children: _jsx(CaracteristicasLl, {}) }), _jsx("div", { id: "caracteristicaslll", children: _jsx(CaracteristicasIII, {}) })] })), selectedSection === "tecnologias" && (_jsx("div", { id: "tecnologias", children: _jsx(Tecnologias, {}) })), selectedSection === "nuestrosClientes" && (_jsx("div", { id: "nuestrosClientes", children: _jsx(NuestrosClientes, {}) })), selectedSection === "nuestrosProductos" && (_jsx("div", { id: "nuestrosProductos", children: _jsx(NuestrosProductos, {}) })), _jsx("div", { id: "Footer", className: "Footer-Section", children: _jsx(Footer, { onSectionChange: handleSectionChange }) })] }), showContactModal && _jsx(ContactClientEmail, { onClose: handleCloseModal })] }));
};
export default MainPage;
