import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import DashboardMenuLateral from '../components/dashboard/DashboardMenulateral';
var RegisterSuccessPage = function () {
    var navigate = useNavigate();
    var handleOnClick = function () {
        navigate("/users");
    };
    return (_jsxs("div", { className: "PassUpdateSuccessfullPage-box", children: [_jsx(DashboardMenuLateral, {}), _jsx("div", { children: _jsxs("form", { className: "PassUpdateSuccessfullPage-form", children: [_jsx("h2", { children: "Usuario Registrado Correctamente" }), _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), _jsx("button", { type: 'submit', className: "PassUpdateSuccessfullPage-button", onClick: handleOnClick, children: "Volver" })] }) })] }));
};
export default RegisterSuccessPage;
