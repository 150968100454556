var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import '../../../../../components/filters/styles/AdvancedFilters.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import FiltersInputsClientsComponent from './FiltersInputsClientsComponent';
import FiltersCamposToSelectClients from './FiltersCamposToSelectClients';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { getAllClients } from '../../../services/clientsService';
import MuiAlertComponent from '../../../../../components/MuiAlertsComponent';
import { getAllClientsTipos } from '../../../services/clientsTiposService';
import { getAllEmpresas } from '../../../../empresas/services/empresasService';
var AdvancedFiltersClients = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onApplyFilters = _a.onApplyFilters, currentPage = _a.currentPage, limitPerPage = _a.limitPerPage;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState([]), clientTipos = _b[0], setClientTipos = _b[1];
    var _c = useState([]), empresas = _c[0], setEmpresas = _c[1];
    // Obtener los tipos de cliente y empresas al cargar el componente
    useEffect(function () {
        var fetchClientTipos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAllClientsTipos(token)];
                    case 1:
                        result = _a.sent();
                        setClientTipos(result.clientsTipos);
                        return [2 /*return*/];
                }
            });
        }); };
        var fetchEmpresas = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAllEmpresas(token)];
                    case 1:
                        result = _a.sent();
                        setEmpresas(result);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchClientTipos();
        fetchEmpresas();
    }, [token]);
    var _d = useState(function () {
        var savedFilters = sessionStorage.getItem('advancedFiltersClients');
        return savedFilters
            ? JSON.parse(savedFilters)
            : [
                {
                    _id: '', client_name: '', tipo_cliente: '', contrato: '', empresa: '',
                    client_nit: '', client_address: '', client_telefono: '', client_email: '', razon_social: ''
                }
            ];
    }), filters = _d[0], setFilters = _d[1];
    var _e = useState(filters.map(function () { return false; })), loading = _e[0], setLoading = _e[1];
    var _f = useState(filters.map(function () { return null; })), results = _f[0], setResults = _f[1];
    var _g = useState(false), showViewButton = _g[0], setShowViewButton = _g[1];
    var _h = useState(true), isEditing = _h[0], setIsEditing = _h[1];
    var _j = useState([]), alerts = _j[0], setAlerts = _j[1];
    var _k = useState(false), showFieldSelector = _k[0], setShowFieldSelector = _k[1];
    // Actualizar el estado inicial para incluir Tipo Cliente
    var _l = useState(function () {
        var savedFields = sessionStorage.getItem('selectedFieldsClients');
        return savedFields ? JSON.parse(savedFields) : ['ID', 'CLIENTE', 'TIPO CLIENTE', 'CONTRATO', 'EMPRESA', 'NIT', 'DIRECCIÓN', 'TELÉFONO', 'EMAIL', 'RAZÓN SOCIAL'];
    }), selectedFields = _l[0], setSelectedFields = _l[1];
    useEffect(function () {
        var savedFilters = sessionStorage.getItem('advancedFiltersClients');
        if (savedFilters) {
            setFilters(JSON.parse(savedFilters));
        }
        var savedSelectedFields = sessionStorage.getItem('selectedFieldsClients');
        if (savedSelectedFields) {
            setSelectedFields(JSON.parse(savedSelectedFields));
        }
    }, []);
    var handleAddFilter = function () {
        setFilters(__spreadArray(__spreadArray([], filters, true), [{
                _id: '', client_name: '', tipo_cliente: '', contrato: '', empresa: '',
                client_nit: '', client_address: '', client_telefono: '', client_email: '', razon_social: ''
            }], false));
        setLoading(__spreadArray(__spreadArray([], loading, true), [false], false));
        setResults(__spreadArray(__spreadArray([], results, true), [null], false));
    };
    var handleFilterChange = function (index, field, value) {
        var newFilters = __spreadArray([], filters, true);
        if (selectedFields.includes('ID') && field === '_id') {
            var hexPattern = /^[0-9a-fA-F]*$/;
            if (hexPattern.test(value) && value.length <= 24) {
                newFilters[index][field] = value;
            }
        }
        else if (selectedFields.includes('CLIENTE') && field === 'client_name') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('TIPO CLIENTE') && field === 'tipo_cliente') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('CONTRATO') && field === 'contrato') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('EMPRESA') && field === 'empresa') { // Nuevo filtro para empresa
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('NIT') && field === 'client_nit') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('DIRECCIÓN') && field === 'client_address') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('TELÉFONO') && field === 'client_telefono') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('EMAIL') && field === 'client_email') {
            newFilters[index][field] = value;
        }
        else if (selectedFields.includes('RAZÓN SOCIAL') && field === 'razon_social') {
            newFilters[index][field] = value;
        }
        setFilters(newFilters);
        sessionStorage.setItem('advancedFiltersClients', JSON.stringify(newFilters));
    };
    var handleRemoveFilter = function (index) {
        var newFilters = __spreadArray([], filters, true);
        var newLoading = __spreadArray([], loading, true);
        var newResults = __spreadArray([], results, true);
        newFilters.splice(index, 1);
        newLoading.splice(index, 1);
        newResults.splice(index, 1);
        setFilters(newFilters);
        setLoading(newLoading);
        setResults(newResults);
    };
    var handleSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newAlert, invalidFilters, newAlert, newLoading, newResults, allResults, result, clients, _loop_1, i, paginatedResults, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (filters.length === 0) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Agregue al menos un filtro.',
                            severity: 'info',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    invalidFilters = filters.some(function (filter) {
                        return selectedFields.some(function (field) {
                            if (field === 'ID' && filter._id.length !== 24 && filter._id !== '')
                                return true;
                            return false;
                        });
                    });
                    if (invalidFilters) {
                        newAlert = {
                            id: new Date().getTime(),
                            message: 'Por favor ingrese un formato de ID válido.',
                            severity: 'error',
                        };
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [newAlert], false));
                        return [2 /*return*/];
                    }
                    sessionStorage.setItem('advancedFiltersClients', JSON.stringify(filters));
                    newLoading = filters.map(function () { return true; });
                    newResults = filters.map(function () { return null; });
                    setLoading(newLoading);
                    setResults(newResults);
                    allResults = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getAllClients(token)];
                case 2:
                    result = _a.sent();
                    clients = result.clients;
                    _loop_1 = function (i) {
                        var filter = filters[i];
                        var filteredClients = clients.filter(function (client) {
                            return selectedFields.every(function (field) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                                if (field === 'ID') {
                                    return !filter._id || client._id === filter._id;
                                }
                                if (field === 'CLIENTE') {
                                    return !filter.client_name || ((_a = client.client_name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.client_name.toLowerCase()));
                                }
                                if (field === 'TIPO CLIENTE') {
                                    return !filter.tipo_cliente || ((_c = (_b = client.id_client_tipo) === null || _b === void 0 ? void 0 : _b.tipo) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === filter.tipo_cliente.toLowerCase();
                                }
                                if (field === 'CONTRATO') {
                                    return !filter.contrato || ((_d = client.ids_contratos) === null || _d === void 0 ? void 0 : _d.some(function (contrato) {
                                        return contrato.numero.toLowerCase().includes(filter.contrato.toLowerCase());
                                    }));
                                }
                                if (field === 'EMPRESA') {
                                    return !filter.empresa || ((_f = (_e = client.id_empresa) === null || _e === void 0 ? void 0 : _e.nombre) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(filter.empresa.toLowerCase()));
                                }
                                if (field === 'NIT') {
                                    return !filter.client_nit || ((_g = client.client_nit) === null || _g === void 0 ? void 0 : _g.toLowerCase().includes(filter.client_nit.toLowerCase()));
                                }
                                if (field === 'DIRECCIÓN') {
                                    return !filter.client_address || ((_h = client.client_address) === null || _h === void 0 ? void 0 : _h.toLowerCase().includes(filter.client_address.toLowerCase()));
                                }
                                if (field === 'TELÉFONO') {
                                    return !filter.client_telefono || ((_j = client.client_telefono) === null || _j === void 0 ? void 0 : _j.includes(filter.client_telefono));
                                }
                                if (field === 'EMAIL') {
                                    return !filter.client_email || ((_k = client.client_email) === null || _k === void 0 ? void 0 : _k.toLowerCase().includes(filter.client_email.toLowerCase()));
                                }
                                if (field === 'RAZÓN SOCIAL') {
                                    return !filter.razon_social || ((_l = client.razon_social) === null || _l === void 0 ? void 0 : _l.toLowerCase().includes(filter.razon_social.toLowerCase()));
                                }
                                return true;
                            });
                        });
                        if (filteredClients.length > 0) {
                            newResults[i] = true;
                            allResults.push.apply(allResults, filteredClients);
                        }
                        else {
                            newResults[i] = false;
                        }
                    };
                    for (i = 0; i < filters.length; i++) {
                        _loop_1(i);
                    }
                    paginatedResults = allResults.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage);
                    onApplyFilters(paginatedResults, allResults.length);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al obtener los clientes:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(newLoading.map(function () { return false; }));
                    setResults(newResults);
                    setShowViewButton(true);
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleView = function () {
        onClose();
    };
    var handleEdit = function () {
        setIsEditing(true);
        setShowViewButton(false);
        setResults(filters.map(function () { return null; }));
    };
    var handleCloseAlert = function (id) {
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
    };
    var handleFieldSelection = function (fields) {
        setSelectedFields(fields);
        var newFilters = filters.map(function (filter) {
            var newFilter = { _id: filter._id || '' };
            // Añadimos cada campo si está seleccionado
            if (fields.includes('CLIENTE')) {
                newFilter['client_name'] = filter['client_name'] || '';
            }
            if (fields.includes('TIPO CLIENTE')) {
                newFilter['tipo_cliente'] = filter['tipo_cliente'] || '';
            }
            if (fields.includes('CONTRATO')) {
                newFilter['contrato'] = filter['contrato'] || '';
            }
            if (fields.includes('EMPRESA')) {
                newFilter['empresa'] = filter['empresa'] || '';
            }
            if (fields.includes('NIT')) {
                newFilter['client_nit'] = filter['client_nit'] || '';
            }
            if (fields.includes('DIRECCIÓN')) {
                newFilter['client_address'] = filter['client_address'] || '';
            }
            if (fields.includes('TELÉFONO')) {
                newFilter['client_telefono'] = filter['client_telefono'] || '';
            }
            if (fields.includes('EMAIL')) {
                newFilter['client_email'] = filter['client_email'] || '';
            }
            if (fields.includes('RAZÓN SOCIAL')) {
                newFilter['razon_social'] = filter['razon_social'] || '';
            }
            return newFilter;
        });
        setFilters(newFilters);
        setShowFieldSelector(false);
        // Guardar los campos seleccionados y filtros en session storage
        sessionStorage.setItem('selectedFieldsClients', JSON.stringify(fields));
        sessionStorage.setItem('advancedFiltersClients', JSON.stringify(newFilters));
    };
    return (isOpen ? (_jsxs("div", { className: "AdvancedFilters-modal-overlay", children: [_jsx("div", { className: "AdvancedFilters-modal-content", children: _jsx("div", { className: "AdvancedFilters-advanced-filters", children: _jsxs("div", { className: "AdvancedFilters-div", children: [_jsxs("div", { className: "AdvancedFilters-overlap", children: [_jsx("header", { className: "AdvancedFilters-header", children: _jsx("div", { className: "AdvancedFilters-overlap-group", children: _jsx("div", { className: "AdvancedFilters-filter-title", children: "FILTRAR CLIENTES" }) }) }), isEditing && _jsx(AddCircleIcon, { className: "AdvancedFilters-filter-add-icon", onClick: handleAddFilter }), _jsx(FilterListIcon, { className: "AdvancedFilters-options-icon", onClick: function () { return setShowFieldSelector(true); } })] }), filters.length > 0 && (_jsx(FiltersInputsClientsComponent, { filters: filters, selectedFields: selectedFields, handleFilterChange: handleFilterChange, handleRemoveFilter: handleRemoveFilter, loading: loading, results: results, isEditing: isEditing, token: token, clientTipos: clientTipos, empresas: empresas })), isEditing ? (_jsx("button", { className: "AdvancedFilters-cancel-button", onClick: onClose, children: "Cancelar" })) : (_jsx("button", { className: "AdvancedFilters-edit-button", onClick: handleEdit, children: "Editar" })), showViewButton ? (_jsx("button", { className: "AdvancedFilters-view-button", onClick: handleView, children: "Ver" })) : (_jsx("button", { className: "AdvancedFilters-send-button", onClick: handleSearch, children: "Enviar" }))] }) }) }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), showFieldSelector && (_jsx(FiltersCamposToSelectClients, { onSubmit: handleFieldSelection, onClose: function () { return setShowFieldSelector(false); }, initialSelectedFields: selectedFields }))] })) : null);
};
export default AdvancedFiltersClients;
