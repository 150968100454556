import axios from '../../../utils/config/axios.config';
export var getAllSolicitudesServicios = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios.get('/solicitudes-servicios', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var getSolicitudServicioById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/solicitudes-servicios/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
export var createSolicitudServicio = function (token, solicitudServicioData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios.post('/solicitudes-servicios', solicitudServicioData, options).catch(handleError);
};
export var updateSolicitudServicio = function (token, id, solicitudServicioData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios.put("/solicitudes-servicios?id=".concat(id), solicitudServicioData, options).catch(handleError);
};
export var createMultipleSolicitudesServicio = function (token, solicitudesData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/solicitudes-servicios/crear-masivo', solicitudesData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateMultipleSolicitudesServicio = function (token, solicitudesData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put('/solicitudes-servicios/actualizar-masivo', solicitudesData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteSolicitudServicio = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios.delete('/solicitudes-servicios', options).catch(handleError);
};
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Token inválido o expirado
            // Redirigir al usuario a la página de inicio de sesión (/login)
            console.log(error.response);
        }
    }
    throw error;
};
