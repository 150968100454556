import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import VisitaByIdPendiente from './VisitaByIdPendiente';
import { getVisitaById } from '../../../visitas/services/visitasService';
import VisitaByIdAbierta from './VisitaByIdAbierta';
import VisitaByIdCerrada from './VisitaByIdCerrada';
import VisitaByIdRechazada from './VisitaByIdRechazada';
var VisitaByIdOrden = function (_a) {
    var idVisita = _a.idVisita, onVisitaSelect = _a.onVisitaSelect, onReloadNeeded = _a.onReloadNeeded;
    var _b = useState(null), visita = _b[0], setVisita = _b[1];
    var _c = useState(true), showVisitaByIdPendiente = _c[0], setShowVisitaByIdPendiente = _c[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        if (token && idVisita) {
            getVisitaById(token, idVisita)
                .then(function (data) {
                setVisita(data);
            })
                .catch(function (error) { return console.error('Error al obtener la visita por ID:', error); });
        }
    }, [token, idVisita]);
    var handleCloseVisitaByIdPendiente = function () {
        onVisitaSelect(null); // Llama a onVisitaSelect cuando se cierre VisitaByIdPendiente
    };
    return (_jsxs("div", { children: [visita && visita.id_visita_estado.estado === 'Pendiente' && (_jsx("div", { className: "VisitaByIdPendiente-modal-overlay", children: _jsx("div", { className: "VisitaByIdPendiente-modal-content", children: _jsx(VisitaByIdPendiente, { idVisita: visita._id, onClose: function () { return onVisitaSelect(null); }, onReloadNeeded: onReloadNeeded }) }) })), visita && visita.id_visita_estado.estado === 'Abierta' && (_jsx("div", { className: "VisitaByIdAbierta-modal-overlay", children: _jsx("div", { className: "VisitaByIdAbierta-modal-content", children: _jsx(VisitaByIdAbierta, { idVisita: visita._id, onClose: function () { return onVisitaSelect(null); }, onReloadNeeded: onReloadNeeded }) }) })), visita && visita.id_visita_estado.estado === 'Cerrada' && (_jsx("div", { className: "VisitaByIdAbierta-modal-overlay", children: _jsx("div", { className: "VisitaByIdAbierta-modal-content", children: _jsx(VisitaByIdCerrada, { idVisita: visita._id, onClose: function () { return onVisitaSelect(null); } }) }) })), visita && visita.id_visita_estado.estado === 'Rechazada' && (_jsx("div", { className: "VisitaByIdRechazada-modal-overlay", children: _jsx("div", { className: "VisitaByIdRechazada-modal-content", children: _jsx(VisitaByIdRechazada, { idVisita: visita._id, onClose: function () { return onVisitaSelect(null); } }) }) }))] }));
};
export default VisitaByIdOrden;
