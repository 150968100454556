// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActividadesEjecutar-div{
    height: auto;
    width: 100%;
    position: absolute;
    top: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.ActividadesEjecutar-container{
    height: auto;
    width: 100%;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ActividadesEjecutar-title{
    position: relative;
    top: 0;
    left: 0;
    height: 2.4vh;
    width: 15vw;
    font-size: 0.9vw;
    text-align: center;
    border-radius: 10px;
    color: #00ddfa;
    background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/modules/visitas/components/visitas/styles/ActividadesEjecutar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;;;AAIA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;;AAGA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".ActividadesEjecutar-div{\n    height: auto;\n    width: 100%;\n    position: absolute;\n    top: 97%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n\n\n.ActividadesEjecutar-container{\n    height: auto;\n    width: 100%;\n    position: absolute;\n    top: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n\n.ActividadesEjecutar-title{\n    position: relative;\n    top: 0;\n    left: 0;\n    height: 2.4vh;\n    width: 15vw;\n    font-size: 0.9vw;\n    text-align: center;\n    border-radius: 10px;\n    color: #00ddfa;\n    background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
