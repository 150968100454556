// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* //////////////////////////////// */




.VisitasPages-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .VisitasPages-loading-overlay {
    color: #00ddfa;
  }
  
  .VisitasPages-Container-Card {
    position: fixed;
    left: 3%;
    top: 13%;
    width: 95%;
    height: 100%;
  }
  .SolicitudServicioPages-cards-list{
      list-style: none
    }
    
  .VisitasPages-ul-cards {
    padding: 1%;
    width: 100%;
    height: 86%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));
    gap: 2%;
    row-gap: 5%;
    list-style: none;
    position: relative;
    overflow-y: scroll; /* Habilita el scroll vertical */
    overflow-x: hidden;
    scrollbar-width: thin; /* Ajusta el ancho del scrollbar para Firefox */
    scrollbar-color:  #636b6f61 transparent; /* Firefox */
  }
  
  /* Para Chrome, Safari y Edge */
  .VisitasPages-ul-cards::-webkit-scrollbar {
    width: 3px; /* Ajusta el ancho del scrollbar */
  }
  
  .VisitasPages-ul-cards::-webkit-scrollbar-track {
    background-color: transparent;
  
  }
  
  .VisitasPages-ul-cards::-webkit-scrollbar-thumb {
    background-color: transparent;
      border-radius: 3px;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/modules/visitas/pages/styles/VisitasPages.css"],"names":[],"mappings":";AACA,qCAAqC;;;;;AAKrC;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,UAAU;IACV,YAAY;EACd;EACA;MACI;IACF;;EAEF;IACE,WAAW;IACX,WAAW;IACX,WAAW;IACX,aAAa;IACb,2DAA2D;IAC3D,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB,EAAE,gCAAgC;IACpD,kBAAkB;IAClB,qBAAqB,EAAE,+CAA+C;IACtE,uCAAuC,EAAE,YAAY;EACvD;;EAEA,+BAA+B;EAC/B;IACE,UAAU,EAAE,kCAAkC;EAChD;;EAEA;IACE,6BAA6B;;EAE/B;;EAEA;IACE,6BAA6B;MAC3B,kBAAkB;EACtB","sourcesContent":["\n/* //////////////////////////////// */\n\n\n\n\n.VisitasPages-container {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n  }\n  \n  .VisitasPages-loading-overlay {\n    color: #00ddfa;\n  }\n  \n  .VisitasPages-Container-Card {\n    position: fixed;\n    left: 3%;\n    top: 13%;\n    width: 95%;\n    height: 100%;\n  }\n  .SolicitudServicioPages-cards-list{\n      list-style: none\n    }\n    \n  .VisitasPages-ul-cards {\n    padding: 1%;\n    width: 100%;\n    height: 86%;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));\n    gap: 2%;\n    row-gap: 5%;\n    list-style: none;\n    position: relative;\n    overflow-y: scroll; /* Habilita el scroll vertical */\n    overflow-x: hidden;\n    scrollbar-width: thin; /* Ajusta el ancho del scrollbar para Firefox */\n    scrollbar-color:  #636b6f61 transparent; /* Firefox */\n  }\n  \n  /* Para Chrome, Safari y Edge */\n  .VisitasPages-ul-cards::-webkit-scrollbar {\n    width: 3px; /* Ajusta el ancho del scrollbar */\n  }\n  \n  .VisitasPages-ul-cards::-webkit-scrollbar-track {\n    background-color: transparent;\n  \n  }\n  \n  .VisitasPages-ul-cards::-webkit-scrollbar-thumb {\n    background-color: transparent;\n      border-radius: 3px;\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
