var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { updateOrden } from '../../services/ordenesService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/AnularOrden.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
var AnularOrden = function (_a) {
    var idOrden = _a.idOrden, onClose = _a.onClose, onUpdate = _a.onUpdate;
    var _b = useState(''), observacionesAnulacion = _b[0], setObservacionesAnulacion = _b[1];
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var userId = useSessionStorage('userId');
    var loggedIn = useSessionStorage('sessionJWTToken');
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: message, severity: severity }], false); });
    };
    var handleSubmitAnulacion = function () { return __awaiter(void 0, void 0, void 0, function () {
        var now, anulacion_date, ordenData, token, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!observacionesAnulacion.trim()) {
                        addAlert('Las observaciones de anulación son obligatorias.', 'error');
                        return [2 /*return*/];
                    }
                    now = new Date();
                    anulacion_date = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
                    ordenData = {
                        id_orden_estado: "65c5962f66a5a36f3df9063b",
                        id_anulador: userId,
                        anulacion_date: anulacion_date,
                        observaciones_anulacion: observacionesAnulacion
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    token = loggedIn;
                    return [4 /*yield*/, updateOrden(token, idOrden, ordenData)];
                case 2:
                    _a.sent();
                    addAlert("Orden con ID ".concat(idOrden, " anulada correctamente."), 'success');
                    setTimeout(function () {
                        onClose();
                        onUpdate();
                    }, 2000);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    addAlert('Error al anular la orden.', 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    return (_jsxs("div", { className: "AnularOrden-modal-overlay", children: [_jsxs("div", { className: "AnularOrden-modal-content", children: [_jsx("h3", { className: 'AnularOrden-modal-content-title', children: "Anular Orden" }), _jsx("textarea", { className: "AnularOrden-textarea", value: observacionesAnulacion, onChange: function (e) { return setObservacionesAnulacion(e.target.value); }, placeholder: 'Observaciones...', required: true }), _jsxs("div", { className: "AnularOrden-modal-actions", children: [_jsx("button", { className: "AnularOrden-modal-cancel-button", onClick: onClose, children: "Cancelar" }), _jsx("button", { className: "AnularOrden-close-observacion-button", onClick: handleSubmitAnulacion, children: "Confirmar Anulaci\u00F3n" })] })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }));
};
export default AnularOrden;
