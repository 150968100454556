var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getAllAreasEquipos, updateAreaEquipo } from '../../services/areasEquiposService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/EditAreaEquipoButton.css';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent'; // Importamos el componente de alertas
import { logoutService } from '../../../users/services/authService';
var EditAreaEquipoButton = function (_a) {
    var areaEquipoId = _a.areaEquipoId, onEditSuccess = _a.onEditSuccess, onCancel = _a.onCancel, initialData = _a.initialData;
    var _b = useState(initialData), areaEquipoData = _b[0], setAreaEquipoData = _b[1];
    var loggedIn = useSessionStorage('sessionJWTToken');
    var _c = useState(null), areaAvailable = _c[0], setAreaAvailable = _c[1];
    var _d = useState(false), areaCheckLoading = _d[0], setAreaCheckLoading = _d[1];
    var _e = useState(null), existingAreaName = _e[0], setExistingAreaName = _e[1];
    var _f = useState([]), alerts = _f[0], setAlerts = _f[1]; // Estado para las alertas
    var _g = useState(false), isButtonDisabled = _g[0], setIsButtonDisabled = _g[1]; // Estado para deshabilitar el botón mientras se actualiza
    var handleEdit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!areaEquipoData.area.trim()) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Complete los campos', severity: 'warning' }], false); });
                        return [2 /*return*/];
                    }
                    if (areaAvailable === false) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'El área ya existe', severity: 'warning' }], false); });
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    if (!loggedIn) {
                        logoutService();
                        return [2 /*return*/];
                    }
                    setIsButtonDisabled(true);
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Actualizando...', severity: 'info' }], false); });
                    token = loggedIn;
                    return [4 /*yield*/, updateAreaEquipo(token, areaEquipoId, areaEquipoData)];
                case 2:
                    _a.sent();
                    setTimeout(function () {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            { id: Date.now(), message: 'Área actualizada correctamente', severity: 'success' }
                        ], false); });
                        setTimeout(function () {
                            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                                { id: Date.now(), message: 'Redirigiendo...', severity: 'info' }
                            ], false); });
                            setTimeout(function () {
                                onEditSuccess();
                                window.location.reload();
                            }, 1000);
                        }, 1000);
                    }, 1000);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al editar el área de equipo:', error_1);
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        { id: Date.now(), message: 'Error al actualizar el área', severity: 'error' }
                    ], false); });
                    setIsButtonDisabled(false); // Habilitar el botón nuevamente en caso de error
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var checkAreaAvailability = function (areaName) { return __awaiter(void 0, void 0, void 0, function () {
        var response, areasList, areaExists, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, getAllAreasEquipos(loggedIn)];
                case 1:
                    response = _a.sent();
                    areasList = response.areasEquipos.map(function (area) { return area.area.toLowerCase(); });
                    // Si es el mismo valor actual, lo permitimos como nombre existente
                    if (areaName.toLowerCase() === initialData.area.toLowerCase()) {
                        setExistingAreaName(areaName); // Nombre existente
                        setAreaAvailable(true); // Disponible porque es el mismo
                    }
                    else {
                        areaExists = areasList.includes(areaName.toLowerCase());
                        setAreaAvailable(!areaExists);
                        setExistingAreaName(null); // No es el nombre existente, limpiamos el estado
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error al verificar el área', error_2);
                    return [3 /*break*/, 4];
                case 3:
                    setAreaCheckLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (e) {
        var value = e.target.value;
        setAreaEquipoData(__assign(__assign({}, areaEquipoData), { area: value }));
        if (value.trim()) {
            setAreaCheckLoading(true);
            checkAreaAvailability(value); // Verificamos si es el nombre existente o está disponible
        }
        else {
            setAreaAvailable(null);
            setExistingAreaName(null); // Limpiamos el nombre existente si el valor está vacío
        }
    };
    var handleAlertClose = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); }); // Cerrar alerta específica
    };
    return (_jsxs("div", { children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleAlertClose }), " ", _jsx("form", { className: "EditAreaEquipoButton-box", children: _jsx("div", { className: "EditAreaEquipoButton-register-marca", children: _jsxs("div", { className: "EditAreaEquipoButton-overlap-group", children: [_jsx("div", { className: "EditAreaEquipoButton-overlap", children: _jsx("p", { className: "EditAreaEquipoButton-register-marca-title", children: "ACTUALIZAR \u00C1REA DE EQUIPO" }) }), _jsx("label", { className: "EditAreaEquipoButton-register-marca-label", children: "Ingrese el nombre del \u00E1rea de equipo que desea actualizar:" }), _jsx("input", { className: "EditAreaEquipoButton-register-marca-input", type: "text", value: areaEquipoData.area || '', onChange: handleChange }), areaCheckLoading ? (_jsx(CircularProgress, { style: { color: '#00ddfa', position: 'absolute', top: '14.9vh', left: '70vh' }, size: 20 })) : (_jsxs(_Fragment, { children: [existingAreaName && (_jsx("p", { className: 'existing-area-name', children: "Nombre actual del \u00E1rea" })), areaAvailable !== null && !existingAreaName && (_jsx("p", { className: areaAvailable ? 'area-available' : 'area-unavailable', children: areaAvailable ? 'Área disponible' : 'Área no disponible' }))] })), _jsx("button", { className: "EditAreaEquipoButton-img", type: 'button', onClick: handleEdit, disabled: isButtonDisabled, children: isButtonDisabled ? 'Actualizando...' : 'Actualizar' }), _jsx("button", { className: "EditAreaEquipoButton-cancelar-button", onClick: onCancel, disabled: isButtonDisabled, children: "Cancelar" })] }) }) })] }));
};
export default EditAreaEquipoButton;
