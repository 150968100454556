var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { updateVisita } from '../../services/visitasService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/PruebaElectricaRegister.css'; // Ajusta la ruta a los estilos de este componente
var PruebaElectricaRegister = function (_a) {
    var idVisita = _a.idVisita, onActividadesUpdated = _a.onActividadesUpdated, visita = _a.visita;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(''), title = _b[0], setTitle = _b[1];
    var _c = useState(''), medida = _c[0], setMedida = _c[1];
    var _d = useState(''), minimo = _d[0], setMinimo = _d[1];
    var _e = useState(''), maximo = _e[0], setMaximo = _e[1];
    var _f = useState(''), unidad = _f[0], setUnidad = _f[1];
    var _g = useState(false), isLoading = _g[0], setIsLoading = _g[1];
    var visitaActual = visita;
    var _h = useState([
        {
            title: 'Voltaje de linea',
            medida: '',
            minimo: 110,
            maximo: 120,
            unidad: 'V',
            resultado: '',
        },
        {
            title: 'Resistencia de alistamiento L1-L2 Case',
            medida: '',
            minimo: 0,
            maximo: 2,
            unidad: 'MΩ',
            resultado: '',
        },
        {
            title: 'Fuga corriente a tierra (polo normal)',
            medida: '',
            minimo: 0,
            maximo: 500,
            unidad: 'μA',
            resultado: '',
        },
        {
            title: 'Fuga de corriente Tierra (L2 Open)',
            medida: '',
            minimo: 0,
            maximo: 1000,
            unidad: 'μA',
            resultado: '',
        },
        {
            title: 'Fuga de corriente Tierra (Pol Inv)',
            medida: '',
            minimo: 0,
            maximo: 500,
            unidad: 'μA',
            resultado: '',
        },
        {
            title: 'Fuga de corriente chasis (Pol Inv - L2 open)',
            medida: '',
            minimo: 0,
            maximo: 1000,
            unidad: 'μA',
            resultado: '',
        },
        {
            title: 'Fuga de corriente chasis (Pol Normal)',
            medida: '',
            minimo: 0,
            maximo: 100,
            unidad: 'μA',
            resultado: '',
        },
        {
            title: 'Fuga de corriente chasis (L2 open)',
            medida: '',
            minimo: 0,
            maximo: 500,
            unidad: 'μA',
            resultado: '',
        },
    ]), pruebasElectricas = _h[0], setPruebasElectricas = _h[1];
    var handleMedidaChange = function (index, medida) {
        var newPruebasElectricas = __spreadArray([], pruebasElectricas, true);
        newPruebasElectricas[index].medida = medida;
        newPruebasElectricas[index].resultado = medida >= newPruebasElectricas[index].minimo && medida <= newPruebasElectricas[index].maximo ? 'Pasó' : 'Falló';
        setPruebasElectricas(newPruebasElectricas);
    };
    var calculateResultado = function () {
        if (medida === '' || minimo === '' || maximo === '') {
            return '';
        }
        var medidaNum = Number(medida);
        var minimoNum = Number(minimo);
        var maximoNum = Number(maximo);
        return medidaNum >= minimoNum && medidaNum <= maximoNum ? 'Pasó' : 'Falló';
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var todasLasMedidasCompletadas, now, formattedDateCreated, nuevaActividad, datosActualizados, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    todasLasMedidasCompletadas = pruebasElectricas.every(function (prueba) { return prueba.medida !== ''; });
                    if (!todasLasMedidasCompletadas) {
                        alert('Por favor, complete todos los campos requeridos.');
                        return [2 /*return*/];
                    }
                    now = new Date();
                    formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
                    nuevaActividad = {
                        id_protocolo: '65a93e0689a02ef211e75ed7', // Protocolo de prueba eléctrica
                        prueba_electrica: pruebasElectricas.map(function (prueba) { return ({
                            title: prueba.title,
                            medida: Number(prueba.medida), // Convertir a número por si acaso
                            minimo: prueba.minimo,
                            maximo: prueba.maximo,
                            unidad: prueba.unidad,
                            resultado: prueba.resultado,
                        }); }),
                        date_created: formattedDateCreated,
                    };
                    datosActualizados = __assign(__assign({}, visitaActual), { actividades: __spreadArray(__spreadArray([], visitaActual.actividades, true), [nuevaActividad], false) });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    if (!(token && idVisita)) return [3 /*break*/, 3];
                    setIsLoading(true);
                    return [4 /*yield*/, updateVisita(token, idVisita, datosActualizados)];
                case 2:
                    _a.sent();
                    alert('Prueba eléctrica registrada con éxito.');
                    onActividadesUpdated();
                    return [3 /*break*/, 4];
                case 3:
                    alert('No se encontró token de sesión.');
                    _a.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    console.error('Error al registrar la prueba eléctrica:', error_1);
                    alert('Error al registrar la prueba eléctrica.');
                    return [3 /*break*/, 7];
                case 6:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "PruebaElectricaRegister-box", children: _jsx("form", { onSubmit: handleSubmit, children: _jsx("div", { className: "PruebaElectricaRegister-actividad-prueba", children: _jsxs("div", { className: "PruebaElectricaRegister-div", children: [_jsx("div", { className: "PruebaElectricaRegister-prueba-electrica-t", children: "PRUEBA DE SEGURIDAD ELECTRICA" }), _jsxs("div", { className: "PruebaElectricaRegister-form-container", children: [_jsx("div", { className: "PruebaElectricaRegister-title-section", children: _jsx("div", { className: "PruebaElectricaRegister-form-t-wrapper", children: _jsx("div", { className: "PruebaElectricaRegister-form-t", children: "PRUEBAS CUALITATIVAS" }) }) }), _jsx("div", { className: 'PruebaElectricaRegister-container-list', children: pruebasElectricas.map(function (prueba, index) { return (_jsx("ul", { className: 'PruebaElectricaRegister-ul', children: _jsxs("li", { className: "PruebaElectricaRegister-section", children: [_jsxs("div", { className: "PruebaElectricaRegister-campo-title", children: [prueba.title, " *"] }), _jsx("input", { type: "number", className: "PruebaElectricaRegister-input", value: prueba.medida, onChange: function (e) { return handleMedidaChange(index, Number(e.target.value)); } }), _jsxs("p", { className: "PruebaElectricaRegister-range", children: ["En ", prueba.unidad, " Rango ( ", prueba.minimo, " a ", prueba.maximo, " ) "] }), _jsx("button", { className: "PruebaElectricaRegister-paso", style: { backgroundColor: prueba.resultado === 'Pasó' ? '#00ff47' : '#ffffff' }, disabled: true, children: "Pas\u00F3" }), _jsx("button", { className: "PruebaElectricaRegister-fallo", style: { backgroundColor: prueba.resultado === 'Falló' ? '#ff0000' : '#ffffff' }, disabled: true, children: "Fall\u00F3" })] }) }, index)); }) })] }), _jsx("div", { className: "PruebaElectricaRegister-line" }), _jsx("button", { className: "PruebaElectricaRegister-button", children: "CREAR ACTIVIDAD" })] }) }) }) }));
};
export default PruebaElectricaRegister;
