var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateOrden } from '../../services/ordenesService';
import { createSolicitudDadoBaja } from '../../services/solicitudesDadoBajaService';
import './styles/ResultadoOrden.css';
import { getAllRepuestosEquipos } from '../../../equipos/services/repuestosEquiposService';
import { usePermission } from '../../../../hooks/usePermission';
import AddCircleIcon from '@mui/icons-material/AddCircle';
var ResultadoOrdenOtros = function (_a) {
    var _b;
    var idOrden = _a.idOrden, idClient = _a.idClient, resultadoOrden = _a.resultadoOrden, onUpdate = _a.onUpdate;
    var token = useSessionStorage('sessionJWTToken');
    var userId = useSessionStorage('userId');
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(''), comentariosFinales = _d[0], setComentariosFinales = _d[1];
    var _e = useState(false), requierePc = _e[0], setRequierePc = _e[1];
    var _f = useState(false), solicitudDarBaja = _f[0], setSolicitudDarBaja = _f[1];
    var _g = useState([]), repuestos = _g[0], setRepuestos = _g[1];
    var _h = useState([]), repuestosFiltrados = _h[0], setRepuestosFiltrados = _h[1];
    var _j = useState([]), repuestosSeleccionados = _j[0], setRepuestosSeleccionados = _j[1];
    var _k = useState([]), itemsAdicionales = _k[0], setItemsAdicionales = _k[1];
    var _l = useState([]), alerts = _l[0], setAlerts = _l[1];
    var readOnly = resultadoOrden != null;
    useEffect(function () {
        var _a;
        if (resultadoOrden) {
            setComentariosFinales(resultadoOrden.comentarios_finales || '');
            setSolicitudDarBaja(resultadoOrden.solicitud_dar_baja || false);
            setRequierePc((_a = resultadoOrden.requiere_pc) !== null && _a !== void 0 ? _a : false);
            setItemsAdicionales(resultadoOrden.items_adicionales || []);
        }
    }, [resultadoOrden]);
    useEffect(function () {
        if (!token)
            return;
        var fetchRepuestos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, allRepuestos, filtered, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getAllRepuestosEquipos(token)];
                    case 1:
                        result = _a.sent();
                        allRepuestos = (result === null || result === void 0 ? void 0 : result.repuestoEquipos) || [];
                        setRepuestos(allRepuestos);
                        if (idClient) {
                            filtered = allRepuestos.filter(function (repuesto) { return repuesto.id_cliente._id === idClient; });
                            setRepuestosFiltrados(filtered);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error al cargar los repuestos:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchRepuestos();
    }, [token, idClient]);
    var handleRepuestoSelect = function (idRepuesto) {
        var repuestoSeleccionado = repuestosFiltrados.find(function (repuesto) { return repuesto._id === idRepuesto; });
        if (repuestoSeleccionado && !repuestosSeleccionados.some(function (r) { return r._id === idRepuesto; })) {
            setRepuestosSeleccionados(function (prev) { return __spreadArray(__spreadArray([], prev, true), [repuestoSeleccionado], false); });
        }
    };
    var removeRepuesto = function (idRepuesto) {
        setRepuestosSeleccionados(function (prev) { return prev.filter(function (repuesto) { return repuesto._id !== idRepuesto; }); });
    };
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: message, severity: severity }], false); });
    };
    var createSolicitudDadoBajaIfNeeded = function () { return __awaiter(void 0, void 0, void 0, function () {
        var solicitudData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!solicitudDarBaja) return [3 /*break*/, 4];
                    solicitudData = {
                        id_solicitud_baja_estado: '6642654cc858b4a7a115053c',
                        id_cliente: idClient,
                        id_orden: idOrden,
                        id_creador: userId,
                        date_created: new Date().toISOString(),
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createSolicitudDadoBaja(token, solicitudData)];
                case 2:
                    _a.sent();
                    addAlert('Solicitud de dado de baja creada exitosamente.', 'success');
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    addAlert('Error al crear la solicitud de dado de baja.', 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resultadoOrden, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!idOrden || comentariosFinales.trim() === '') {
                        addAlert('Todos los campos son requeridos.', 'error');
                        return [2 /*return*/];
                    }
                    if (requierePc && repuestosSeleccionados.length === 0) {
                        addAlert('Debe seleccionar al menos un repuesto si requiere PC.', 'error');
                        return [2 /*return*/];
                    }
                    resultadoOrden = {
                        comentarios_finales: comentariosFinales.trim(),
                        requiere_pc: requierePc,
                        repuestos_requeridos: repuestosSeleccionados.map(function (repuesto) { return repuesto._id; }),
                        items_adicionales: itemsAdicionales,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, updateOrden(token, idOrden, { resultado_orden: resultadoOrden })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, createSolicitudDadoBajaIfNeeded()];
                case 3:
                    _a.sent();
                    addAlert('Orden actualizada correctamente.', 'success');
                    onUpdate && onUpdate();
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _a.sent();
                    console.error('Error al actualizar la orden:', error_3);
                    addAlert('Error al actualizar la orden.', 'error');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var formatCurrency = function (value) {
        return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).replace(/\D00(?=\D*$)/, '');
    };
    var canAgregarResultadoOrden = usePermission("ordenes_servicio", "agregar_resultado_orden").canAccess;
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    if (isLoading) {
        return (_jsx("div", { style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: '#7EC6BF', position: 'relative', top: '400px' }, children: _jsx(CircularProgress, { color: "inherit" }) }));
    }
    return (_jsxs("div", { className: "ResultadoOrden-orden", children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), _jsxs("div", { className: "ResultadoOrden-div", children: [_jsx("header", { className: "ResultadoOrden-header", children: _jsx("div", { className: "ResultadoOrden-overlap-group", children: _jsx("div", { className: "ResultadoOrden-cambios-t", children: "RESULTADO DE LA ORDEN" }) }) }), _jsx("div", { className: "resultOrden-box", children: _jsxs("div", { className: "resultOrden-box-overlap-group", children: [_jsxs("div", { className: "resultOrdenOtros-box-final-comments2", children: [_jsx("div", { className: "resultOrden-box-text-wrapper", children: "Ingrese los comentarios finales: *" }), _jsx("textarea", { className: "resultOrden-box-final-comments-input", placeholder: "Comentarios...", value: comentariosFinales, onChange: function (e) { return setComentariosFinales(e.target.value); }, disabled: readOnly })] }), _jsxs("div", { className: "resultOrdenOtros-box-dado-baja-section2", children: [_jsx("div", { className: "resultOrden-box-dado-baja-t", children: "\u00BFRequiere PC?: *" }), _jsxs("label", { className: "resultOrden-switch", children: [_jsx("input", { className: "resultOrden-darbaja-input", type: "checkbox", name: "requiere_pc", checked: requierePc, onChange: function (e) { return setRequierePc(e.target.checked); }, disabled: readOnly }), _jsx("span", { className: "resultOrden-slider round" })] })] }), _jsx("div", { className: "repuesto-separator" }), _jsxs("div", { className: "repuestos-required-div", children: [_jsx("div", { className: "repuestos-required-p", children: requierePc
                                                ? 'Seleccione los Repuestos Requeridos: *'
                                                : 'No es necesario seleccionar repuestos, ya que no requiere PC.' }), _jsxs("select", { className: "repuestos-select", onChange: function (e) { return handleRepuestoSelect(e.target.value); }, disabled: !requierePc || readOnly, children: [_jsx("option", { value: "", children: "Seleccione un repuesto" }), repuestosFiltrados.map(function (repuesto) { return (_jsxs("option", { value: repuesto._id, children: [repuesto.repuesto_name, " - ", repuesto._id] }, repuesto._id)); })] })] }), _jsxs("ul", { className: "repuestos-seleccionados-list", children: [(_b = resultadoOrden === null || resultadoOrden === void 0 ? void 0 : resultadoOrden.repuestos_requeridos) === null || _b === void 0 ? void 0 : _b.map(function (repuesto) { return (_jsxs("li", { className: "repuestos-seleccionados-item", children: [repuesto.repuesto_name, " - ", repuesto._id] }, repuesto._id)); }), repuestosSeleccionados.map(function (repuesto) { return (_jsxs("li", { className: "repuestos-seleccionados-item", children: [repuesto.repuesto_name, " - ", repuesto._id, !readOnly && (_jsx(CancelIcon, { className: "repuestos-remove-icon", onClick: function () { return removeRepuesto(repuesto._id); } }))] }, repuesto._id)); })] }), _jsxs("div", { className: "items-adicionales-container", children: [_jsxs("div", { className: "items-adicionales-header", children: [_jsx("label", { className: "items-adicionales-label", children: "Items Adicionales: *" }), !readOnly && (_jsx(AddCircleIcon, { className: "items-adicionales-add-icon", onClick: function () {
                                                        setItemsAdicionales(function (prev) { return __spreadArray(__spreadArray([], prev, true), [{ descripcion: '', cantidad: 0, valor_unitario: 0 }], false); });
                                                    } }))] }), _jsx("ul", { className: "items-adicionales-list", children: itemsAdicionales.map(function (item, index) { return (_jsxs("li", { className: "items-adicionales-item", children: [_jsxs("div", { className: "items-adicionales-titles", children: [_jsx("p", { className: "item-adicional-p", children: "Descrip" }), _jsx("p", { className: "item-adicional-p", children: "Cant" }), _jsx("p", { className: "item-adicional-p", children: "Precio" })] }), _jsxs("div", { className: "items-adicionales-inputs", children: [_jsx("input", { type: "text", className: "items-adicionales-descripcion", placeholder: "Descripci\u00F3n", value: item.descripcion, onChange: function (e) {
                                                                    var newItems = __spreadArray([], itemsAdicionales, true);
                                                                    newItems[index].descripcion = e.target.value;
                                                                    setItemsAdicionales(newItems);
                                                                }, disabled: readOnly, required: true }), _jsx("input", { type: "text", className: "items-adicionales-cantidad", placeholder: "Cantidad", value: item.cantidad.toString(), onChange: function (e) {
                                                                    if (readOnly)
                                                                        return; // Si está en modo lectura, no actualiza
                                                                    var raw = e.target.value.replace(/[^\d]/g, '');
                                                                    var num = raw ? parseInt(raw) : 0;
                                                                    var newItems = __spreadArray([], itemsAdicionales, true);
                                                                    newItems[index].cantidad = num;
                                                                    setItemsAdicionales(newItems);
                                                                }, disabled: readOnly, required: true }), _jsx("input", { type: "text", className: "items-adicionales-valor-unitario", placeholder: "Valor Unitario", value: formatCurrency(item.valor_unitario), onChange: function (e) {
                                                                    if (readOnly)
                                                                        return;
                                                                    var raw = e.target.value.replace(/[^\d]/g, '');
                                                                    var num = raw ? parseInt(raw) : 0;
                                                                    var newItems = __spreadArray([], itemsAdicionales, true);
                                                                    newItems[index].valor_unitario = num;
                                                                    setItemsAdicionales(newItems);
                                                                }, disabled: readOnly, required: true }), !readOnly && (_jsx(CancelIcon, { className: "items-adicionales-remove-icon", onClick: function () { return setItemsAdicionales(function (prev) { return prev.filter(function (_, i) { return i !== index; }); }); } }))] })] }, index)); }) })] }), !readOnly && canAgregarResultadoOrden && (_jsx("button", { className: "resultOrdenOtros-update-button2", onClick: handleSubmit, children: "Generar Resultado" }))] }) })] })] }));
};
export default ResultadoOrdenOtros;
