var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
import { differenceInDays, parseISO } from 'date-fns';
// Función para calcular "Días Fuera" basado en el estado de la orden
var calculateDiasFuera = function (orden) {
    if (!orden.creacion)
        return 'N/A'; // Si no hay fecha de creación, retornar 'N/A'
    var creationDate = parseISO(orden.creacion);
    if (orden.id_orden_estado.estado === 'Abierta') {
        // Si la orden está "Abierta", calcular diferencia desde la fecha de creación hasta hoy
        var currentDate = new Date();
        return differenceInDays(currentDate, creationDate);
    }
    else if (orden.id_orden_estado.estado === 'Cerrada' && orden.cierre) {
        // Si la orden está "Cerrada", calcular diferencia entre fecha de creación y fecha de cierre
        var cierreDate = parseISO(orden.cierre);
        return differenceInDays(cierreDate, creationDate);
    }
    else if (orden.id_orden_estado.estado === 'Anulada' && orden.anulacion_date) {
        // Si la orden está "Anulada", calcular diferencia entre fecha de creación y fecha de anulación
        var anulacionDate = parseISO(orden.anulacion_date);
        return differenceInDays(anulacionDate, creationDate);
    }
    return 'N/A'; // Si no se puede calcular, retornar 'N/A'
};
export var exportOrdenesToExcel = function (ordenes) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob, url, a;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('Ordenes');
                // Define las columnas
                worksheet.columns = [
                    { header: 'ID_Orden', key: 'id_orden', width: 30 },
                    { header: 'Estado', key: 'estado', width: 30 },
                    { header: 'Servicio', key: 'servicio', width: 30 },
                    { header: 'Aviso', key: 'aviso', width: 30 },
                    { header: 'ID_Solicitud_Servicio', key: 'id_solicitud', width: 30 },
                    { header: 'Solicitud_Creacion', key: 'solicitud_creacion', width: 30 },
                    { header: 'ID_Equipo', key: 'id_equipo', width: 30 },
                    { header: 'Equipo', key: 'equipo', width: 30 },
                    { header: 'ID_Modelo', key: 'id_modelo', width: 30 },
                    { header: 'Modelo', key: 'modelo', width: 30 },
                    { header: 'ID_Marca', key: 'id_marca', width: 30 },
                    { header: 'Marca', key: 'marca', width: 30 },
                    { header: 'ID_Area', key: 'id_area', width: 30 },
                    { header: 'Area', key: 'area', width: 30 },
                    { header: 'ID_Tipo', key: 'id_tipo', width: 30 },
                    { header: 'Tipo', key: 'tipo', width: 30 },
                    { header: 'Serie', key: 'serie', width: 30 },
                    { header: 'Activo_Fijo', key: 'activo_fijo', width: 30 },
                    { header: 'Ubicacion', key: 'ubicacion', width: 30 },
                    { header: 'Frecuencia_mtto', key: 'frecuencia_mtto', width: 30 },
                    { header: 'Mtto', key: 'mtto', width: 30 },
                    { header: 'SAP', key: 'sap', width: 30 },
                    { header: 'ID_Sede', key: 'id_sede', width: 30 },
                    { header: 'Sede', key: 'sede_nombre', width: 30 },
                    { header: 'ID_Cliente', key: 'id_client', width: 30 },
                    { header: 'Cliente', key: 'client_name', width: 30 },
                    { header: 'Dias_Fuera', key: 'dias_fuera', width: 10 },
                    { header: 'Cierre', key: 'cierre', width: 20 },
                    { header: 'Observaciones_Cierre', key: 'observaciones_cierre', width: 40 },
                    { header: 'Anulacion', key: 'anulacion', width: 20 },
                    { header: 'Observaciones_Anulacion', key: 'observaciones_anulacion', width: 40 },
                    // Puedes añadir más columnas según tus necesidades
                ];
                // Estilo para el encabezado
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // Blanco
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' }, // Azul oscuro
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
                // Añadir las filas y aplicar estilos condicionales
                ordenes.forEach(function (orden) {
                    var row = worksheet.addRow({
                        id_orden: orden._id || 'N/A',
                        estado: orden.id_orden_estado.estado || 'N/A',
                        servicio: orden.id_solicitud_servicio.id_servicio.servicio || 'N/A',
                        aviso: orden.id_solicitud_servicio.aviso || 'N/A',
                        id_solicitud: orden.id_solicitud_servicio._id || 'N/A',
                        solicitud_creacion: orden.id_solicitud_servicio.creacion || 'N/A',
                        id_equipo: orden.id_solicitud_servicio.id_equipo._id || 'N/A',
                        equipo: orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase || 'N/A',
                        id_modelo: orden.id_solicitud_servicio.id_equipo.modelo_equipos._id || 'N/A',
                        modelo: orden.id_solicitud_servicio.id_equipo.modelo_equipos.modelo || 'N/A',
                        id_marca: orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_marca._id || 'N/A',
                        marca: orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_marca.marca || 'N/A',
                        id_area: orden.id_solicitud_servicio.id_equipo.id_area._id || 'N/A',
                        area: orden.id_solicitud_servicio.id_equipo.id_area.area || 'N/A',
                        id_tipo: orden.id_solicitud_servicio.id_equipo.id_tipo._id || 'N/A',
                        tipo: orden.id_solicitud_servicio.id_equipo.id_tipo.tipo || 'N/A',
                        serie: orden.id_solicitud_servicio.id_equipo.serie || 'N/A',
                        activo_fijo: orden.id_solicitud_servicio.id_equipo.activo_fijo || 'N/A',
                        ubicacion: orden.id_solicitud_servicio.id_equipo.ubicacion || 'N/A',
                        frecuencia_mtto: orden.id_solicitud_servicio.id_equipo.frecuencia || 'N/A',
                        mtto: orden.id_solicitud_servicio.id_equipo.mtto || 'N/A',
                        sap: orden.id_solicitud_servicio.id_equipo.sap || 'N/A',
                        id_sede: orden.id_solicitud_servicio.id_equipo.id_sede._id || 'N/A',
                        sede_nombre: orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre || 'N/A',
                        id_client: orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id || 'N/A',
                        client_name: orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name || 'N/A',
                        cierre: orden.cierre || 'N/A',
                        observaciones_cierre: orden.observaciones_cierre || 'N/A',
                        anulacion: orden.anulacion_date || 'N/A',
                        observaciones_anulacion: orden.observaciones_anulacion || 'N/A',
                        dias_fuera: calculateDiasFuera(orden),
                    });
                    // Aplicar bordes a todas las celdas de la fila
                    row.eachCell(function (cell) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    });
                    // Aplicar color a toda la fila según el estado de la orden
                    var estado = orden.id_orden_estado.estado;
                    var fillColor = '';
                    if (estado === 'Abierta') {
                        fillColor = 'FFCCFFCC'; // Verde más suave (similar al estilo "Bueno" en Excel)
                    }
                    else if (estado === 'Cerrada') {
                        fillColor = 'FFD9D9D9'; // Gris claro (Neutral)
                    }
                    else if (estado === 'Anulada') {
                        fillColor = 'FFFFC7CE'; // Rojo claro (Incorrecto)
                    }
                    // Si se ha definido un color, aplicar a todas las celdas de la fila
                    if (fillColor) {
                        row.eachCell(function (cell) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: fillColor },
                            };
                        });
                    }
                    // Aplicar color condicional para la columna "Días Fuera"
                    var diasFueraCell = row.getCell('dias_fuera');
                    if (typeof diasFueraCell.value === 'number' && diasFueraCell.value >= 5) {
                        diasFueraCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFF0000' }, // Rojo
                        };
                    }
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = window.URL.createObjectURL(blob);
                a = document.createElement('a');
                a.href = url;
                a.download = 'OrdenesDeBioswift.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
                return [2 /*return*/];
        }
    });
}); };
