var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
export var exportRepuestosInstaladosToExcel = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob, url, a;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('Repuestos Instalados');
                // Definición de columnas
                worksheet.columns = [
                    { header: 'ID Actividad', key: 'id_actividad', width: 30 },
                    { header: 'ID Repuesto', key: 'id_repuesto', width: 30 },
                    { header: 'Cantidad', key: 'cantidad', width: 15 },
                    { header: 'Valor Unitario', key: 'valor_unitario', width: 20 },
                    { header: 'Total', key: 'total', width: 20 },
                    { header: 'ID Orden', key: 'id_orden', width: 30 },
                    { header: 'Equipo', key: 'equipo', width: 30 },
                    { header: 'Serie', key: 'serie', width: 20 },
                    { header: 'Cerrador Visita', key: 'cerrador_visita', width: 30 },
                    { header: 'Fecha Cierre', key: 'cierre', width: 20 },
                    { header: 'Sede', key: 'sede', width: 30 },
                    { header: 'Cliente', key: 'cliente', width: 30 },
                ];
                // Estilo para el encabezado
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' },
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });
                // Agregar filas con datos
                data.forEach(function (_a) {
                    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                    var actividad = _a.actividad, ordenData = _a.ordenData, visitaData = _a.visitaData;
                    var row = worksheet.addRow({
                        id_actividad: actividad._id || 'N/A',
                        id_repuesto: actividad.id_repuesto || 'N/A',
                        cantidad: actividad.cantidad || 'N/A',
                        valor_unitario: actividad.valor_unitario || 'N/A',
                        total: actividad.total || 'N/A',
                        id_orden: ordenData._id || 'N/A',
                        equipo: ((_c = (_b = ordenData.id_solicitud_servicio) === null || _b === void 0 ? void 0 : _b.id_equipo) === null || _c === void 0 ? void 0 : _c._id) || 'N/A',
                        serie: ((_e = (_d = ordenData.id_solicitud_servicio) === null || _d === void 0 ? void 0 : _d.id_equipo) === null || _e === void 0 ? void 0 : _e.serie) || 'N/A',
                        cerrador_visita: ((_f = visitaData === null || visitaData === void 0 ? void 0 : visitaData.id_cerrador) === null || _f === void 0 ? void 0 : _f.name) || 'N/A',
                        cierre: ordenData.cierre || 'N/A',
                        sede: ((_j = (_h = (_g = ordenData.id_solicitud_servicio) === null || _g === void 0 ? void 0 : _g.id_equipo) === null || _h === void 0 ? void 0 : _h.id_sede) === null || _j === void 0 ? void 0 : _j.sede_nombre) || 'N/A',
                        cliente: ((_o = (_m = (_l = (_k = ordenData.id_solicitud_servicio) === null || _k === void 0 ? void 0 : _k.id_equipo) === null || _l === void 0 ? void 0 : _l.id_sede) === null || _m === void 0 ? void 0 : _m.id_client) === null || _o === void 0 ? void 0 : _o.client_name) || 'N/A',
                    });
                    // Aplicar bordes a cada celda
                    row.eachCell(function (cell) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                url = window.URL.createObjectURL(blob);
                a = document.createElement('a');
                a.href = url;
                a.download = 'RepuestosInstalados.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
                return [2 /*return*/];
        }
    });
}); };
