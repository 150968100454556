import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DefaultUserImg from '../../pages/img/defaultUserImg.png';
import Checkbox from '@mui/material/Checkbox';
import './styles/UserCard.css';
var UserCard = function (_a) {
    var _b, _c, _d;
    var user = _a.user, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "UsersPages-card-section ".concat(isSelected ? 'UsersPages-selected' : '', "\n}");
    return (_jsx("li", { className: liClassName, children: _jsxs("a", { type: '#', className: 'UsersPages-card', children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                        e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                        onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                    }, className: "SolicitudServicioCard-select-checkbox", sx: {
                        position: 'absolute',
                        top: '10px',
                        left: '87%',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                        zIndex: 5, // Asegura que esté sobre los demás elementos
                        transform: 'scale(1.5)',
                        '&.Mui-checked': {
                            color: '#00ddfa',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0, 221, 250, 0.1)',
                            borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                            fill: '#00ddfa',
                        },
                        '& input': {
                            zIndex: 3, // Ajuste del z-index del input en el DOM
                        },
                    } })), _jsxs("div", { className: 'UsersPages-card-overlay', children: [_jsxs("div", { className: 'UsersPages-card-header', children: [_jsx("img", { src: DefaultUserImg, className: 'UsersPages-card-UserImage', alt: '', height: '70px', width: '70px' }), _jsxs("div", { className: 'UsersPages-card-header-text', children: [_jsx("h3", { className: 'UsersPages-card-title', onClick: onClick, children: user.name }), user.roles.map(function (role) { return (_jsxs("span", { className: 'UsersPages-card-status', children: [role.name, ":"] }, role._id)); }), _jsxs("span", { className: 'UsersPages-card-municipio', children: [" ", ((_b = user.id_municipio) === null || _b === void 0 ? void 0 : _b.nombre) || '', " - ", ((_d = (_c = user.id_municipio) === null || _c === void 0 ? void 0 : _c.id_departamento) === null || _d === void 0 ? void 0 : _d.nombre) || ''] })] })] }), _jsxs("p", { className: 'UsersPages-card-description', children: ["CC: ", user.cedula] }), _jsx("p", { className: 'UsersPages-card-description', children: user.email })] })] }) }, user._id));
};
export default UserCard;
