var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { getMarcaEquipoById } from '../services/marcasEquipoService';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import { useNavigate, useParams } from 'react-router-dom';
import EditMarcaEquipoButton from '../components/marcasEquipos/EditMarcaEquipoButton';
import './styles/MarcaEquipoDetailPage.css';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
var MarcaEquipoDetailPage = function () {
    var id = useParams().id;
    if (!id) {
        return _jsx("p", { children: "Marca de equipo no encontrada." });
    }
    var loggedIn = useSessionStorage('sessionJWTToken');
    var _a = useState(null), marcaEquipo = _a[0], setMarcaEquipo = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), isEditing = _c[0], setIsEditing = _c[1];
    var navigate = useNavigate();
    useEffect(function () {
        if (!loggedIn) {
            return;
        }
        var fetchMarcaEquipo = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, getMarcaEquipoById(token, id)];
                    case 1:
                        result = _a.sent();
                        setMarcaEquipo(result);
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error al obtener detalles de la marca de equipo:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchMarcaEquipo();
    }, [loggedIn, id]);
    var handleEditSuccess = function () {
        setIsEditing(false);
    };
    var handleDeleteSuccess = function () {
        navigate('/equipos/marcas');
    };
    return (_jsxs("div", { children: [_jsx(DashboardMenuLateral, {}), isEditing ? (_jsx(EditMarcaEquipoButton, { marcaEquipoId: id || '', onEditSuccess: handleEditSuccess, onCancel: function () { return setIsEditing(false); }, initialData: marcaEquipo })) : (_jsx("div", { className: "MarcaEquipoDetailPage-box", children: _jsx("div", { className: "MarcaEquipoDetailPage-overlap-group-wrapper", children: _jsxs("div", { className: "MarcaEquipoDetailPage-overlap-group", children: [_jsx("div", { className: "MarcaEquipoDetailPage-overlap", children: _jsx(LocalOfferOutlinedIcon, { className: "MarcaEquipoDetailPage-marcaequipo-icon" }) }), _jsx("div", { className: "MarcaEquipoDetailPage-marcaequipo-title", children: marcaEquipo ? marcaEquipo.marca : '' }), _jsxs("div", { className: "MarcaEquipoDetailPage-marcaequipo-id", children: ["MARCA ID: ", marcaEquipo ? marcaEquipo._id : ''] }), _jsx(EditOutlinedIcon, { onClick: function () { return setIsEditing(true); }, className: "MarcaEquipoDetailPage-marcaequipo-edit" })] }) }) }))] }));
};
export default MarcaEquipoDetailPage;
