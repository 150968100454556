import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
var CotizacionSignSectionPDF = function (_a) {
    var cotizacion = _a.cotizacion;
    var mostrarConceptoTecnico = Boolean(cotizacion.concepto_tecnico && cotizacion.concepto_tecnico.trim() !== '');
    return (_jsxs(View, { style: styles.signSection, children: [mostrarConceptoTecnico && (_jsxs(View, { style: styles.conceptoContainer, children: [_jsx(Text, { style: styles.label, children: "Concepto T\u00E9cnico:" }), _jsx(Text, { style: styles.concepto, children: cotizacion.concepto_tecnico })] })), _jsx(Text, { style: styles.timePeriod, children: "La vigencia de esta cotizaci\u00F3n es de 30 d\u00EDas una vez aprobada la propuesta." }), _jsx(Text, { style: styles.cordially, children: "Cordialmente," }), cotizacion.firma && (_jsx(Image, { style: styles.firmaImg, src: cotizacion.firma })), _jsx(Text, { style: styles.firmaName, children: cotizacion.firma_username })] }));
};
var styles = StyleSheet.create({
    signSection: {
        marginBottom: '1',
        pageBreakBefore: 'always'
    },
    conceptoContainer: {
        marginBottom: 10,
    },
    label: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'bold',
        marginBottom: 2,
    },
    concepto: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        lineHeight: 1.2,
    },
    timePeriod: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        lineHeight: 1.2,
        marginTop: 10,
    },
    cordially: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        lineHeight: 1.2,
        marginBottom: '10px'
    },
    firmaImg: {
        borderBottom: '1.5px',
        borderColor: '#0FBAC6',
        height: 65,
        width: '30%',
        borderRadius: 5,
        marginBottom: '5px'
    },
    firmaName: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        width: '100%',
        lineHeight: 1.2,
    },
});
export default CotizacionSignSectionPDF;
