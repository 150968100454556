var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { createSolicitudDadoBaja, getAllSolicitudesDadoBaja } from '../../services/solicitudesDadoBajaService'; // Import the correct service
import { usePermission } from '../../../../hooks/usePermission';
import './styles/SolicitudDadoBajaComponent.css';
import { CircularProgress } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SolicitudDadoBajaByIdOrden from '../solicitudes_dado_baja/SolicitudDadoBajaByIdOrden';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
var SolicitudDadoBajaComponent = function (_a) {
    var idSolicitudDadoBaja = _a.idSolicitudDadoBaja, idCliente = _a.idCliente, nombreCliente = _a.nombreCliente, idOrden = _a.idOrden, resultadoOrden = _a.resultadoOrden;
    var _b = useState([]), solicitudesDadoBaja = _b[0], setSolicitudesDadoBaja = _b[1];
    var _c = useState(false), mostrarRegistro = _c[0], setMostrarRegistro = _c[1];
    var _d = useState('none'), activeView = _d[0], setActiveView = _d[1];
    var _e = useState(null), idSolicitudDadoBajaSeleccionada = _e[0], setIdSolicitudDadoBajaSeleccionada = _e[1];
    var userId = useSessionStorage('userId');
    var token = useSessionStorage('sessionJWTToken');
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useState(false), alertOpen = _g[0], setAlertOpen = _g[1];
    var _h = useState(''), alertMessage = _h[0], setAlertMessage = _h[1];
    var _j = useState('info'), alertSeverity = _j[0], setAlertSeverity = _j[1];
    var _k = useState([]), alerts = _k[0], setAlerts = _k[1];
    useEffect(function () {
        if (!idOrden || !token)
            return;
        setIsLoading(true);
        setTimeout(function () {
            getAllSolicitudesDadoBaja(token).then(function (data) {
                var solicitudesDadoBajaRelacionadas = data.filter(function (solicitudDadoBaja) {
                    return solicitudDadoBaja.id_orden && solicitudDadoBaja.id_orden._id === idOrden;
                });
                setSolicitudesDadoBaja(solicitudesDadoBajaRelacionadas);
                setIsLoading(false);
            }).catch(function (error) {
                console.error("Error al cargar solicitudes de dado de baja:", error);
                setIsLoading(false);
            });
        }, 1000);
    }, [idOrden, token]); // Incluye idOrden en las dependencias
    var reloadSolicitudesDadoBaja = function () {
        if (!idOrden || !token) {
            console.error("Falta token o ID de orden para recargar las solicitudes de dado de baja.");
            return;
        }
        setIsLoading(true);
        setTimeout(function () {
            getAllSolicitudesDadoBaja(token).then(function (data) {
                var solicitudesDadoBajaRelacionadas = data.filter(function (solicitudDadoBaja) {
                    return solicitudDadoBaja.id_orden && solicitudDadoBaja.id_orden._id === idOrden;
                });
                setSolicitudesDadoBaja(solicitudesDadoBajaRelacionadas);
                setIsLoading(false);
            }).catch(function (error) {
                console.error("Error al cargar solicitudes de dado de baja:", error);
                setIsLoading(false);
            });
        }, 1000);
    };
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now() + Math.random(), message: message, severity: severity }], false); });
    };
    var handleAgregarClick = function () {
        var hasErrors = false; // Variable para rastrear si hay errores
        if (!idCliente || !idOrden || !userId || !token) {
            addAlert("Faltan datos para crear una nueva solicitud de dado de baja.", 'error');
            hasErrors = true;
        }
        if (!resultadoOrden) {
            addAlert("Falta definir el resultado de la orden.", 'warning');
            hasErrors = true;
        }
        var solicitudPendiente = solicitudesDadoBaja.find(function (solicitud) { return solicitud.id_solicitud_baja_estado.estado === "Pendiente"; });
        if (solicitudPendiente) {
            addAlert("Actualmente hay una Solicitud de Baja en proceso.", 'warning');
            hasErrors = true;
        }
        var solicitudAprobada = solicitudesDadoBaja.find(function (solicitud) { return solicitud.id_solicitud_baja_estado.estado === "Aprobada"; });
        if (solicitudAprobada) {
            addAlert("Actualmente ya hay una Solicitud de Baja APROBADA para el Equipo.", 'warning');
            hasErrors = true;
        }
        // Si hay errores, no se continúa con la creación de la solicitud
        if (hasErrors) {
            return;
        }
        var now = new Date();
        var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
        var solicitudData = {
            id_solicitud_baja_estado: '6642654cc858b4a7a115053c',
            id_cliente: idCliente,
            id_orden: idOrden,
            id_creador: userId,
            date_created: formattedDateCreated
        };
        createSolicitudDadoBaja(token, solicitudData)
            .then(function (response) {
            addAlert("Solicitud de dado de baja creada exitosamente.", 'success');
            reloadSolicitudesDadoBaja();
            setActiveView('none');
        })
            .catch(function (error) {
            addAlert("Error al crear la solicitud de dado de baja.", 'error');
        });
    };
    var handleCancelarClick = function () {
        setMostrarRegistro(false);
    };
    var renderEstadoIcono = function (estado) {
        switch (estado) {
            case 'Pendiente':
                return _jsx(HelpIcon, { className: "SolicitudDadoBaja-estado-icono-pendiente" });
            case 'Aprobada':
                return _jsx(CheckCircleIcon, { className: "SolicitudDadoBaja-estado-icono-aprobada" });
            case 'Rechazada':
                return _jsx(DoDisturbIcon, { className: "SolicitudDadoBaja-estado-icono-rechazada" });
            default:
                return null; // O puedes devolver un icono por defecto si es necesario
        }
    };
    var handleVerDetalle = function (idSolicitudDadoBaja) {
        setIdSolicitudDadoBajaSeleccionada(idSolicitudDadoBaja);
        setActiveView('details');
        setMostrarRegistro(false);
    };
    var handleEditClick = function (idSolicitudDadoBaja) {
        setIdSolicitudDadoBajaSeleccionada(idSolicitudDadoBaja);
        setActiveView('edit');
    };
    var getMensajePorEstado = function (solicitudDadoBaja) {
        switch (solicitudDadoBaja.id_solicitud_baja_estado.estado) {
            case 'Pendiente':
                return 'Solicitud de Dado de Baja en estado pendiente';
            case 'Aprobada':
                return solicitudDadoBaja.observacion_estado;
            case 'Rechazada':
                return solicitudDadoBaja.observacion_estado;
            case 'Despachada':
                return solicitudDadoBaja.observacion_estado;
            case 'Terminada':
                return solicitudDadoBaja.observacion_estado;
            default:
                return ''; // Manejar estados desconocidos o no especificados
        }
    };
    var canCreateDadoBaja = usePermission('solicitudes_dado_baja', 'crear').canAccess;
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    return (_jsxs("div", { className: "SolicitudDadoBaja-solicitudes-orden", children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), _jsxs("div", { className: "SolicitudDadoBaja-div", children: [_jsx("header", { className: "SolicitudDadoBaja-header", children: _jsx("div", { className: "SolicitudDadoBaja-overlap-group", children: _jsx("div", { className: "SolicitudDadoBaja-cambios-t", children: "SOLICITUDES DADO DE BAJA" }) }) }), _jsxs("div", { className: "SolicitudDadoBaja-overlap", children: [_jsx("div", { className: "SolicitudDadoBaja-resumen-section", children: _jsx("div", { className: "SolicitudDadoBaja-resumen-header", children: _jsxs("div", { className: "SolicitudDadoBaja-overlap-group-2", children: [_jsx("div", { className: "SolicitudDadoBaja-resumen-t", children: "RESUMEN" }), (canCreateDadoBaja &&
                                                _jsx(AddCircleIcon, { className: "SolicitudDadoBaja-add-icon", onClick: handleAgregarClick }))] }) }) }), isLoading ? (_jsx("div", { style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: '#4F7A76', position: 'relative', top: '170px' }, children: _jsx(CircularProgress, { color: "inherit" }) })) : (_jsx("div", { className: "SolicitudDadoBaja-resume-list", children: _jsx("ul", { children: solicitudesDadoBaja.map(function (solicitudDadoBaja, index) { return (_jsxs("li", { className: "SolicitudDadoBaja-cambio-card", children: [_jsxs("div", { className: "SolicitudDadoBaja-overlap-group-3", children: [renderEstadoIcono(solicitudDadoBaja.id_solicitud_baja_estado.estado), _jsx("div", { className: 'SolicitudDadoBaja-estado-separator' }), _jsxs("div", { className: "SolicitudDadoBaja-oid", children: ["ID: ", solicitudDadoBaja._id] }), _jsx("div", { className: 'SolicitudDadoBaja-mensaje-title', children: "COMENTARIOS" }), _jsx("div", { className: "SolicitudDadoBaja-subestado-comments", children: getMensajePorEstado(solicitudDadoBaja) }), _jsx("div", { className: "SolicitudDadoBaja-separator" }), _jsx("div", { className: "SolicitudDadoBaja-registered-by", children: "CREADA POR:" }), _jsx("div", { className: "SolicitudDadoBaja-username", children: solicitudDadoBaja.id_creador.name }), _jsx("div", { className: "SolicitudDadoBaja-date-t", children: "FECHA:" }), _jsx("div", { className: "SolicitudDadoBaja-date-value", children: solicitudDadoBaja.date_created })] }), _jsx(OpenInNewIcon, { className: "SolicitudDadoBaja-open-icon", onClick: function () { return handleVerDetalle(solicitudDadoBaja._id); } })] }, index)); }) }) }))] }), activeView === 'details' && idSolicitudDadoBajaSeleccionada && (_jsx(SolicitudDadoBajaByIdOrden, { idSolicitudDadoBaja: idSolicitudDadoBajaSeleccionada, onBack: function () { return setActiveView('none'); }, onCambioSuccess: function () {
                            setActiveView('none');
                            reloadSolicitudesDadoBaja();
                        } }))] })] }));
};
export default SolicitudDadoBajaComponent;
