import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, View, StyleSheet } from '@react-pdf/renderer';
var CotizacionesBodyPDF = function (_a) {
    var cotizacion = _a.cotizacion;
    return (_jsxs(View, { children: [_jsx(Text, { style: styles.greeting, children: "Se\u00F1ores," }), _jsx(Text, { style: styles.clientName, children: cotizacion.id_cliente.client_name }), _jsxs(Text, { style: styles.clientNit, children: ["NIT: ", cotizacion.id_cliente.client_nit] }), _jsx(Text, { style: styles.bodyText, children: "De acuerdo a la solicitud se realiza la siguiente cotizaci\u00F3n:" })] }));
};
var styles = StyleSheet.create({
    greeting: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 268,
        left: 21,
        width: 199,
        lineHeight: 1.2,
    },
    clientName: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 289,
        left: 21,
        width: '100%',
        lineHeight: 1.2,
    },
    clientNit: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 310,
        left: 21,
        width: '100%',
        lineHeight: 1.2,
    },
    bodyText: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 342,
        left: 21,
        width: '100%',
        lineHeight: 1.2,
    },
});
export default CotizacionesBodyPDF;
