import axios from '../../../utils/config/axios.config';
// Obtener todas las clases de equipos
export var getAllClasesEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos/clases', options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Obtener una clase de equipo por ID
export var getClaseEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/clases/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Crear una nueva clase de equipo
export var createClaseEquipo = function (token, claseEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/clases', claseEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Nueva función para crear múltiples clases de equipos en masivo
export var createMultipleClasesEquipos = function (token, clasesEquipoData) {
    var options = {
        headers: { 'x-access-token': token },
    };
    return axios
        .post('/equipos/clases/crear-masivo', clasesEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Actualizar una clase de equipo por ID
export var updateClaseEquipo = function (token, id, claseEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos/clases?id=".concat(id), claseEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Eliminar una clase de equipo por ID
export var deleteClaseEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/clases/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Manejar errores
var handleError = function (error) {
    if (error.response) {
        var _a = error.response, status_1 = _a.status, data = _a.data;
        if (status_1 === 500) {
            console.error("Error 500: ".concat(data));
            window.alert('ERROR: No puedes hacer esto');
        }
    }
    else {
        console.error('Error desconocido:', error);
        window.alert('ERROR: No puedes hacer esto');
    }
    throw error;
};
