import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import './styles/UnauthorizedPage.css';
import { logoutService } from '../modules/users/services/authService';
var UnauthorizedPage = function () {
    useEffect(function () {
        var contPrincipal = document.querySelector('.cont_principal');
        if (contPrincipal) {
            contPrincipal.classList.add('cont_error_active');
        }
    }, []);
    var handleUnauthorizedRedirect = function () {
        logoutService();
    };
    return (_jsx("div", { className: "Unauthorized-Page", children: _jsx("div", { className: "UnauthorizedPage-body", children: _jsx("section", { className: "UnauthorizedPage-section", children: _jsxs("div", { className: "cont_principal", children: [_jsxs("div", { className: "cont_error", children: [_jsx("h1", { children: "Oops" }), _jsx("p", { children: "The Page you're looking for isn't here." }), _jsx("span", { className: "buttonReturn", onClick: handleUnauthorizedRedirect, children: "Return" })] }), _jsx("div", { className: "cont_aura_1" }), _jsx("div", { className: "cont_aura_2" })] }) }) }) }));
};
export default UnauthorizedPage;
