// usePermission.ts
import { useEffect, useState } from 'react';
import { userHasPermission } from '../helpers/checkPermissions';
export function usePermission(moduleName, actionName) {
    var _a = useState(false), canAccess = _a[0], setCanAccess = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    useEffect(function () {
        // 1) Obtenemos los roles del usuario
        var userRoles = JSON.parse(sessionStorage.getItem('userRoles') || '[]');
        // 2) Verificamos con userHasPermission
        var hasAccess = userHasPermission(moduleName, actionName, userRoles);
        setCanAccess(hasAccess);
        // 3) Marcamos como cargado
        setIsLoading(false);
        // Opcional: console.log para depurar
        // console.log("Roles del usuario:", userRoles);
        // console.log(`¿Permiso ${moduleName}.${actionName}?`, hasAccess);
    }, [moduleName, actionName]);
    return { canAccess: canAccess, isLoading: isLoading };
}
