var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import './styles/IndicadoresMainPage.css';
import RepuestosInstaladosTotal from '../components/repuestos_instalados/RepuestosInstaladosTotal';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { useNavigate } from 'react-router-dom';
import { getAllOrdenes } from '../../ordenes/services/ordenesService';
var IndicadoresMainPage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var _a = useState([]), ordenes = _a[0], setOrdenes = _a[1];
    var _b = useState([]), filteredOrdenes = _b[0], setFilteredOrdenes = _b[1]; // Estado para resultados filtrados
    var _c = useState([]), selectedOrdenes = _c[0], setSelectedOrdenes = _c[1];
    var _d = useState(false), ordenesSelected = _d[0], setOrdenesSelected = _d[1];
    var _e = useState(false), selectMode = _e[0], setSelectMode = _e[1];
    var _f = useState(false), showSearchResults = _f[0], setShowSearchResults = _f[1];
    var _g = useState([]), searchResults = _g[0], setSearchResults = _g[1];
    var _h = useState(true), loading = _h[0], setLoading = _h[1];
    var _j = useState(1), currentPage = _j[0], setCurrentPage = _j[1];
    var _k = useState(0), totalPages = _k[0], setTotalPages = _k[1];
    var _l = useState(1000), limitPerPage = _l[0], setLimitPerPage = _l[1]; // Cambiado de 50 a 1000
    var _m = useState(0), totalItems = _m[0], setTotalItems = _m[1];
    var _o = useState(''), searchKeyword = _o[0], setSearchKeyword = _o[1];
    var _p = useState(false), showAdvancedFiltersOrdenesModal = _p[0], setShowAdvancedFiltersOrdenesModal = _p[1];
    var _q = useState(false), showDownloadOrdenesModal = _q[0], setShowDownloadOrdenesModal = _q[1];
    var _r = useState([]), filters = _r[0], setFilters = _r[1];
    var _s = useState(false), filtered = _s[0], setFiltered = _s[1];
    var _t = useState(null), lastSearchType = _t[0], setLastSearchType = _t[1];
    var navigate = useNavigate();
    var fetchOrdenes = function (page, limit) { return __awaiter(void 0, void 0, void 0, function () {
        var token, result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    token = loggedIn;
                    return [4 /*yield*/, getAllOrdenes(token, limit, page)];
                case 1:
                    result = _a.sent();
                    setOrdenes(result.ordenes);
                    setTotalPages(result.totalPages || Math.ceil(result.totalItems / limit));
                    setTotalItems(result.totalItems);
                    setLoading(false);
                    setSelectedOrdenes([]); // Resetear selección cuando se cargan todas las órdenes
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error al obtener órdenes:', error_1);
                    window.location.href = '/login';
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "IndicadoresMainPage-container", children: [_jsx(DashboardMenuLateral, {}), _jsxs("div", { className: "IndicadoresMainPage-section", children: [_jsx(Typography, { variant: "h4", align: "center", style: { marginBottom: '2rem', color: '#7EC6BF' }, children: "Indicadores" }), _jsx(Grid, { container: true, spacing: 3, children: _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(RepuestosInstaladosTotal, { valor: 230000 }) }) })] })] }));
};
export default IndicadoresMainPage;
