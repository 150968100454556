var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { updateVisita } from '../../services/visitasService'; // Asegúrate de que la ruta sea correcta
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/EquipoDisponibilidadRegistrar.css'; // Asegúrate de crear y referenciar los estilos específicos para este componente
var PruebaDiagnosticaRegistrar = function (_a) {
    var idVisita = _a.idVisita, onActividadesUpdated = _a.onActividadesUpdated, visita = _a.visita;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(''), observacion = _b[0], setObservacion = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var now, formattedDateCreated, visitaActual, actividadesActualizadas, datosActualizados, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!observacion) {
                        alert('Por favor, complete el campo de observación.');
                        return [2 /*return*/];
                    }
                    now = new Date();
                    formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
                    visitaActual = visita;
                    actividadesActualizadas = __spreadArray(__spreadArray([], visitaActual.actividades, true), [
                        {
                            id_protocolo: '65a93de089a02ef211e75ecf', // ID fijo para "Inicio prueba diagnostica"
                            observacion: observacion,
                            date_created: formattedDateCreated,
                        }
                    ], false);
                    datosActualizados = __assign(__assign({}, visitaActual), { actividades: actividadesActualizadas });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    if (!(token && idVisita)) return [3 /*break*/, 3];
                    return [4 /*yield*/, updateVisita(token, idVisita, datosActualizados)];
                case 2:
                    _a.sent();
                    alert('Actividad "Inicio Prueba Diagnóstica" agregada a la visita con éxito.');
                    onActividadesUpdated();
                    return [3 /*break*/, 4];
                case 3:
                    alert('No se encontró token de sesión.');
                    _a.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    console.error('Error al actualizar la visita:', error_1);
                    alert('Error al actualizar la visita.');
                    return [3 /*break*/, 7];
                case 6:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "EquipoDisponibleRegistrar-actividad", children: _jsx("form", { onSubmit: handleSubmit, children: _jsxs("div", { className: "EquipoDisponibleRegistrar-div", children: [_jsx("div", { className: "EquipoDisponibleRegistrar-overlap-group", children: _jsx("div", { className: "EquipoDisponibleRegistrar-disponible-t", children: "Inicio Prueba Diagn\u00F3stica" }) }), _jsx("label", { className: "EquipoDisponibleRegistrar-disponible", htmlFor: "observacion", children: "Observaci\u00F3n: *" }), _jsx("textarea", { className: "EquipoDisponibleRegistrar-disponible-2", id: "observacion", value: observacion, onChange: function (e) { return setObservacion(e.target.value); }, placeholder: "Escribe tu observaci\u00F3n aqu\u00ED..." }), _jsx("button", { type: 'submit', className: "EquipoDisponibleRegistrar-overlap", children: "CREAR ACTIVIDAD" })] }) }) }));
};
export default PruebaDiagnosticaRegistrar;
