// permissions.ts
export var permissions = {
    usuarios: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    clientes: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    sedes: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    contratos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_solicitudes_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    modelos_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    areas_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    clases_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    marcas_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    tipos_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    repuestos_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    costos_equipos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    costos_repuestos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    campos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    preventivos: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    solicitudes_servicio: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo_en_equipos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    ordenes_servicio: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        agregar_imagenes_imprimir: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        ver_imagenes_imprimir: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        registrar_cambios: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        ver_cambios: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        agregar_resultado_orden: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        ver_resultado_orden: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        agregar_firmas: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        ver_firmas: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        cerrar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        anular: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        imprimir_pdf: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        cerrar_anular_reabrir_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportar_zip: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    visitas: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        registrar_actividades: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        ver_actividades: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        cerrar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    solicitudes_dado_baja: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    cotizaciones: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        enviar_email: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        imprimir_pdf: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
    solicitudes_bodega: {
        verTodos: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        verPorId: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        crear: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        editar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        eliminar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        aprobar_rechazar_masivo: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
        exportarExcel: ['invitado', 'tecnico', 'coordinador', 'analista', 'comercial', 'contabilidad', 'almacen', 'administrador', 'cliente', 'aux-almacen'],
    },
};
