var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import '../../../../components/filters/styles/ActionsComponent.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { exportSolicitudesToExcel } from '../../services/exportSolicitudesExcelService';
import { CircularProgress } from '@mui/material';
import UpdateSolicitudesServicioEstadoMassive from './UpdateSolicitudesServicioEstadoMassive'; // Importar el nuevo componente
var SolicitudesActionsComponent = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, solicitudes = _a.solicitudes, selectMode = _a.selectMode, solicitudesSelected = _a.solicitudesSelected, SolicitudPageType = _a.SolicitudPageType;
    var _b = useState(''), selectedAction = _b[0], setSelectedAction = _b[1]; // Acción seleccionada
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1]; // Estado de carga
    var _e = useState(false), showUpdateMassiveForm = _e[0], setShowUpdateMassiveForm = _e[1]; // Mostrar/ocultar formulario de actualización masiva
    // Función para manejar la acción de continuar
    var handleContinue = function () {
        if (selectedAction === '') {
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Seleccione una opción antes de continuar',
                    severity: 'warning'
                }
            ], false); });
            return;
        }
        if (selectedAction === 'descargarExcel') {
            exportSolicitudesToExcel(solicitudes);
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Solicitudes exportadas a Excel exitosamente',
                    severity: 'success'
                }
            ], false); });
            onClose();
        }
        if (selectedAction === 'actualizarSolicitudes') {
            if (!solicitudesSelected) {
                setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                    {
                        id: new Date().getTime(),
                        message: 'No hay Solicitudes de Servicio Seleccionadas',
                        severity: 'warning'
                    }
                ], false); });
                return;
            }
            // Si hay solicitudes seleccionadas, mostrar el formulario de actualización masiva
            setShowUpdateMassiveForm(true);
        }
    };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    // Si se selecciona la opción de aprobar/rechazar solicitudes masivas, renderizar el componente
    if (showUpdateMassiveForm) {
        return (_jsx(UpdateSolicitudesServicioEstadoMassive, { solicitudes: solicitudes, onCancel: function () { return setShowUpdateMassiveForm(false); } }));
    }
    return (isOpen ? (_jsx("div", { className: "ActionsComponent-modal-overlay", children: _jsxs("div", { className: "ActionsComponent-modal-content", children: [_jsx("h2", { className: "ActionsComponent-title", children: "ACCIONES - SOLICITUDES DE SERVICIO" }), _jsxs("select", { className: "ActionsComponent-select", value: selectedAction, onChange: function (e) { return setSelectedAction(e.target.value); }, disabled: isLoading, children: [_jsx("option", { value: "", disabled: true, children: "Seleccionar..." }), _jsx("option", { value: "descargarExcel", children: "Descargar Excel" }), (SolicitudPageType === 'SolicitudesServicios-pendientes-preventivo' || SolicitudPageType === 'SolicitudesServicios-pendientes-CIG') && (_jsx("option", { value: "actualizarSolicitudes", children: "Aprobar / Rechazar Solicitudes de Servicio" }))] }), _jsxs("div", { className: 'ActionsComponent-buttons-div', children: [_jsx("button", { className: "ActionsComponent-continue-button", onClick: handleContinue, disabled: isLoading, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : 'Continuar' }), _jsx("button", { className: "ActionsComponent-cancel-button", onClick: onClose, disabled: isLoading, children: "Cancelar" })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }) })) : null);
};
export default SolicitudesActionsComponent;
