var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { getAllMunicipios } from '../../../../departamentos/services/municipiosService';
import SedesSectionContratos from '../sedes/SedesSectionContratos';
import { getAllSedes } from '../../../../users/services/sedesService';
var MunicipiosSectionContratos = function (_a) {
    var contratoId = _a.contratoId;
    var _b = useState([]), municipiosDetails = _b[0], setMunicipiosDetails = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var loggedIn = useSessionStorage('sessionJWTToken');
    var fetchMunicipiosYSedes = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, municipios_1, sedes, sedesFiltradas, municipioSedesMap_1, sortedMunicipios, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!loggedIn)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    token = loggedIn;
                    return [4 /*yield*/, getAllMunicipios(token)];
                case 2:
                    municipios_1 = _a.sent();
                    return [4 /*yield*/, getAllSedes(token)];
                case 3:
                    sedes = _a.sent();
                    sedesFiltradas = sedes.sedes.filter(function (sede) { var _a, _b; return (_b = (_a = sede.id_client) === null || _a === void 0 ? void 0 : _a.ids_contratos) === null || _b === void 0 ? void 0 : _b.some(function (contrato) { return contrato._id === contratoId; }); });
                    municipioSedesMap_1 = {};
                    sedesFiltradas.forEach(function (sede) {
                        var _a;
                        var municipioId = (_a = sede.id_municipio) === null || _a === void 0 ? void 0 : _a._id; // Verificar que id_municipio existe
                        if (municipioId) {
                            if (!municipioSedesMap_1[municipioId]) {
                                municipioSedesMap_1[municipioId] = {
                                    municipio: municipios_1.find(function (muni) { return muni._id === municipioId; }), // Acceder directamente al array de municipios
                                    sedes: [],
                                };
                            }
                            municipioSedesMap_1[municipioId].sedes.push(sede);
                        }
                    });
                    sortedMunicipios = Object.values(municipioSedesMap_1).sort(function (a, b) { return b.sedes.length - a.sedes.length; });
                    setMunicipiosDetails(sortedMunicipios);
                    setLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error al obtener los detalles de los municipios y sedes:', error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchMunicipiosYSedes();
    }, [contratoId]);
    if (loading) {
        return _jsx("div", { children: "Cargando detalles de los municipios..." });
    }
    if (!municipiosDetails.length) {
        return _jsx("div", { children: "No se encontraron municipios asociados a las sedes de este contrato." });
    }
    return (_jsxs("div", { className: "ContratoDetailPage-complete-details", children: [_jsx("div", { className: "ContratoDetailPage-header", children: _jsx("div", { className: "ContratoDetailPage-section-title", children: "Resumen Regionales" }) }), _jsx("div", { className: "ContratoDetailPage-main-details-municipios", children: municipiosDetails.map(function (municipioDetail, index) {
                    var _a, _b, _c;
                    return (_jsxs("div", { className: "ContratoDetailPage-section", children: [_jsxs("div", { className: "ContratoDetailPage-section-title", children: [((_b = (_a = municipioDetail.municipio) === null || _a === void 0 ? void 0 : _a.id_departamento) === null || _b === void 0 ? void 0 : _b.nombre) || 'N/A', " - ", ((_c = municipioDetail.municipio) === null || _c === void 0 ? void 0 : _c.nombre) || 'N/A'] }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["Total de sedes: ", municipioDetail.sedes.length || 'N/A'] }), _jsx(SedesSectionContratos, { sedes: municipioDetail.sedes, contratoId: contratoId })] }, index));
                }) })] }));
};
export default MunicipiosSectionContratos;
