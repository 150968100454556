var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../../users/hooks/useSessionStorage';
import { useNavigate } from 'react-router-dom';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import './styles/RepuestosInstaladosPage.css';
import { CircularProgress } from '@mui/material';
import { getAllOrdenes } from '../../ordenes/services/ordenesService';
import OrdenesServicioFiltersHeader from '../../ordenes/components/ordenes_servicios/OrdenesServicioFiltersHeader';
import RepuestoInstaladoCard from '../components/repuestos_instalados/RepuestoInstaladoCard';
import AdvancedFiltersOrdenes from '../../ordenes/components/filters/AdvancedFiltersOrdenes';
import RepuestosInstaladosActionsComponent from '../components/repuestos_instalados/filters/RepuestosInstaladosActionsComponent';
var RepuestosInstaladosPage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    // ─────────────────────────────────────────────
    // ESTADOS ENFOCADOS A REPUESTOS INSTALADOS
    // ─────────────────────────────────────────────
    var _a = useState([]), ordenes = _a[0], setOrdenes = _a[1]; // Ahora almacena repuestos, no órdenes
    var _b = useState([]), filteredOrdenes = _b[0], setFilteredOrdenes = _b[1]; // También repuestos filtrados
    var _c = useState([]), selectedOrdenes = _c[0], setSelectedOrdenes = _c[1];
    var _d = useState(false), ordenesSelected = _d[0], setOrdenesSelected = _d[1];
    var _e = useState(false), selectMode = _e[0], setSelectMode = _e[1];
    var _f = useState(false), showSearchResults = _f[0], setShowSearchResults = _f[1];
    var _g = useState([]), searchResults = _g[0], setSearchResults = _g[1]; // Repuestos que resultan de la búsqueda
    var _h = useState(true), loading = _h[0], setLoading = _h[1];
    var _j = useState(1), currentPage = _j[0], setCurrentPage = _j[1];
    var _k = useState(0), totalPages = _k[0], setTotalPages = _k[1];
    var _l = useState(1000), limitPerPage = _l[0], setLimitPerPage = _l[1];
    var _m = useState(0), totalItems = _m[0], setTotalItems = _m[1];
    var _o = useState(''), searchKeyword = _o[0], setSearchKeyword = _o[1];
    var _p = useState(false), showAdvancedFiltersOrdenesModal = _p[0], setShowAdvancedFiltersOrdenesModal = _p[1];
    var _q = useState(false), showDownloadOrdenesModal = _q[0], setShowDownloadOrdenesModal = _q[1];
    var _r = useState([]), filters = _r[0], setFilters = _r[1];
    var _s = useState(false), filtered = _s[0], setFiltered = _s[1];
    var _t = useState(null), lastSearchType = _t[0], setLastSearchType = _t[1];
    var navigate = useNavigate();
    // ─────────────────────────────────────────────
    // FUNCIONES AUXILIARES
    // ─────────────────────────────────────────────
    /**
     * Extrae del array de órdenes únicamente las actividades con:
     *   1. orden.id_orden_estado.estado === 'Cerrada'
     *   2. actividad.id_protocolo.title === 'Cambiar Repuesto'
     */
    var extractRepuestosInstalados = function (ordenesData) {
        var repuestos = [];
        ordenesData.forEach(function (orden) {
            var _a;
            if (((_a = orden.id_orden_estado) === null || _a === void 0 ? void 0 : _a.estado) === 'Cerrada' && Array.isArray(orden.ids_visitas)) {
                orden.ids_visitas.forEach(function (visita) {
                    if (Array.isArray(visita.actividades)) {
                        visita.actividades.forEach(function (actividad) {
                            var _a;
                            if (((_a = actividad.id_protocolo) === null || _a === void 0 ? void 0 : _a.title) === 'Cambiar Repuesto') {
                                // Incluimos la actividad, la orden completa y la visita de origen
                                repuestos.push({ actividad: actividad, ordenData: orden, visitaData: visita });
                            }
                        });
                    }
                });
            }
        });
        return repuestos;
    };
    /**
     * Aplica paginación a un array de repuestos.
     */
    var paginate = function (items, page, limit) {
        var start = (page - 1) * limit;
        var end = page * limit;
        return items.slice(start, end);
    };
    // ─────────────────────────────────────────────
    // FETCH GENERAL
    // ─────────────────────────────────────────────
    var fetchOrdenes = function (page, limit) { return __awaiter(void 0, void 0, void 0, function () {
        var token, result, rawOrdenes, repuestos, paginated, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    token = loggedIn;
                    return [4 /*yield*/, getAllOrdenes(token, limit, page)];
                case 1:
                    result = _a.sent();
                    rawOrdenes = result.ordenes || [];
                    repuestos = extractRepuestosInstalados(rawOrdenes);
                    // Total de repuestos
                    setTotalItems(repuestos.length);
                    setTotalPages(Math.ceil(repuestos.length / limit));
                    paginated = paginate(repuestos, page, limit);
                    setSearchResults(paginated);
                    // Guardamos en "ordenes" el array completo de repuestos (sin paginar)
                    setOrdenes(repuestos);
                    setLoading(false);
                    setSelectedOrdenes([]);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error al obtener órdenes:', error_1);
                    window.location.href = '/login';
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // ─────────────────────────────────────────────
    // BÚSQUEDA POR PALABRA CLAVE
    // ─────────────────────────────────────────────
    var handleSearch = function (keyword, page, limit) { return __awaiter(void 0, void 0, void 0, function () {
        var token, result, rawOrdenes, keywordLower_1, searchInObject_1, filteredOrdenesByKeyword, repuestosFiltered, paginated, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    setSearchKeyword(keyword);
                    setShowSearchResults(true);
                    token = loggedIn;
                    return [4 /*yield*/, getAllOrdenes(token)];
                case 1:
                    result = _a.sent();
                    rawOrdenes = result.ordenes || [];
                    keywordLower_1 = keyword.toLowerCase();
                    searchInObject_1 = function (obj) {
                        return Object.values(obj).some(function (value) {
                            if (typeof value === 'object' && value !== null) {
                                return searchInObject_1(value);
                            }
                            return String(value).toLowerCase().includes(keywordLower_1);
                        });
                    };
                    filteredOrdenesByKeyword = rawOrdenes.filter(function (orden) { return searchInObject_1(orden); });
                    repuestosFiltered = extractRepuestosInstalados(filteredOrdenesByKeyword);
                    // Paginación
                    setTotalItems(repuestosFiltered.length);
                    setTotalPages(Math.ceil(repuestosFiltered.length / limit));
                    paginated = paginate(repuestosFiltered, page, limit);
                    // Guardamos en searchResults
                    setSearchResults(paginated);
                    // Como no estamos en "modo normal" sino en "búsqueda", 
                    // dejamos "ordenes" tal cual se cargó antes (si fuera necesario).
                    // No obstante, podríamos actualizarlo si queremos.
                    // setOrdenes(repuestosFiltered);
                    setLastSearchType('keyword');
                    setSelectedOrdenes([]);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error al buscar órdenes:', error_2);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // ─────────────────────────────────────────────
    // FILTROS AVANZADOS
    // ─────────────────────────────────────────────
    var handleAdvancedSearch = function (filteredData, totalItemsFromModal) {
        // filteredData son las órdenes que cumplen ciertos criterios avanzados
        // 1) Extraemos repuestos
        var repuestos = extractRepuestosInstalados(filteredData);
        // 2) Paginamos
        setCurrentPage(1);
        setFiltered(true);
        setLastSearchType('advancedFilters');
        setSelectedOrdenes([]);
        // Guardamos en "filteredOrdenes" la lista completa de repuestos (antes de paginar)
        setFilteredOrdenes(repuestos);
        // totalItems y totalPages se basan en la cantidad de repuestos
        setTotalItems(repuestos.length);
        setTotalPages(Math.ceil(repuestos.length / limitPerPage));
        // Aplica paginación
        var paginated = paginate(repuestos, 1, limitPerPage);
        setSearchResults(paginated);
        // Ocultamos la búsqueda por palabra clave
        setShowSearchResults(false);
    };
    // ─────────────────────────────────────────────
    // EFECTOS
    // ─────────────────────────────────────────────
    useEffect(function () {
        if (showAdvancedFiltersOrdenesModal)
            return;
        // Si estamos en modo "búsqueda por keyword"
        if (showSearchResults && lastSearchType === 'keyword') {
            // Ya se hizo la lógica en handleSearch
            return;
        }
        // Si estamos en modo "filtros avanzados"
        if (filtered && lastSearchType === 'advancedFilters') {
            // Volvemos a paginar la lista de repuestos guardada en "filteredOrdenes"
            var paginated = paginate(filteredOrdenes, currentPage, limitPerPage);
            setSearchResults(paginated);
            setTotalPages(Math.ceil(filteredOrdenes.length / limitPerPage));
            setTotalItems(filteredOrdenes.length);
            return;
        }
        // Caso general: cargar datos
        fetchOrdenes(currentPage, limitPerPage);
    }, [
        currentPage,
        limitPerPage,
        showAdvancedFiltersOrdenesModal,
        showSearchResults,
        filtered,
        lastSearchType
    ]);
    // Para mostrar si hay órdenes seleccionadas
    useEffect(function () {
        setOrdenesSelected(selectedOrdenes.length > 0);
    }, [selectedOrdenes]);
    // Manejo de cambios entre búsquedas y filtros
    useEffect(function () {
        if (lastSearchType === 'advancedFilters' && showSearchResults) {
            setShowSearchResults(false);
            setFiltered(true);
        }
        if (lastSearchType === 'keyword' && filtered) {
            setFiltered(false);
            setShowSearchResults(true);
        }
    }, [lastSearchType, showSearchResults, filtered]);
    // ─────────────────────────────────────────────
    // SELECCIÓN
    // ─────────────────────────────────────────────
    var handleSelectToggle = function (id) {
        setSelectedOrdenes(function (prev) {
            return prev.includes(id) ? prev.filter(function (oid) { return oid !== id; }) : __spreadArray(__spreadArray([], prev, true), [id], false);
        });
    };
    var handleSelectAllToggle = function () {
        var displayed = showSearchResults
            ? searchResults
            : filtered
                ? filteredOrdenes
                : ordenes;
        if (selectedOrdenes.length === displayed.length) {
            setSelectedOrdenes([]);
        }
        else {
            setSelectedOrdenes(displayed.map(function (item) { return item._id; }));
        }
    };
    var handleSelectModeToggle = function () {
        setSelectMode(!selectMode);
        setSelectedOrdenes([]);
    };
    var handleAdvancedFiltersClose = function () {
        setShowAdvancedFiltersOrdenesModal(false);
        if (lastSearchType === 'keyword') {
            setShowSearchResults(true);
            setFiltered(false);
        }
        else if (lastSearchType === 'advancedFilters') {
            setShowSearchResults(false);
            setFiltered(true);
        }
    };
    // ─────────────────────────────────────────────
    // NAVEGACIÓN
    // ─────────────────────────────────────────────
    var navigateToOrdenDetail = function (id) {
        if (!selectMode) {
            navigate("/ordenes/".concat(id));
        }
        else {
            handleSelectToggle(id);
        }
    };
    // ─────────────────────────────────────────────
    // CAMBIO DE PÁGINA Y LÍMITE
    // ─────────────────────────────────────────────
    var handlePageChange = function (newPage) {
        var pageNumber = typeof newPage === 'function' ? newPage(currentPage) : newPage;
        setCurrentPage(pageNumber);
        if (showSearchResults && lastSearchType === 'keyword') {
            handleSearch(searchKeyword, pageNumber, limitPerPage);
            return;
        }
        if (filtered && lastSearchType === 'advancedFilters') {
            var paginated = paginate(filteredOrdenes, pageNumber, limitPerPage);
            setSearchResults(paginated);
            return;
        }
        fetchOrdenes(pageNumber, limitPerPage);
    };
    var handleLimitChange = function (newLimit) {
        var limitNumber = typeof newLimit === 'function' ? newLimit(limitPerPage) : newLimit;
        setLimitPerPage(limitNumber);
        setCurrentPage(1);
        if (showSearchResults && lastSearchType === 'keyword') {
            handleSearch(searchKeyword, 1, limitNumber);
            return;
        }
        if (filtered && lastSearchType === 'advancedFilters') {
            var paginated = paginate(filteredOrdenes, 1, limitNumber);
            setSearchResults(paginated);
            setTotalPages(Math.ceil(filteredOrdenes.length / limitNumber));
            setTotalItems(filteredOrdenes.length);
            return;
        }
        fetchOrdenes(1, limitNumber);
    };
    // ─────────────────────────────────────────────
    // SIN CAMBIOS EN EL RETURN
    // ─────────────────────────────────────────────
    // Ojo: Este extractRepuestosInstalados en el return se queda tal cual;
    // ya no te afectará el conteo porque tus estados (ordenes, filteredOrdenes, searchResults)
    // ahora almacenan directamente repuestos. El “doble filtrado” no dañará el totalItems.
    var repuestosInstalados = showSearchResults ? searchResults : filtered ? filteredOrdenes : ordenes;
    return (_jsxs("div", { className: 'OrdenesPages-container', children: [_jsx(DashboardMenuLateral, {}), _jsx(OrdenesServicioFiltersHeader, { currentPage: currentPage, setCurrentPage: handlePageChange, totalPages: totalPages, selectMode: selectMode, handleSelectModeToggle: handleSelectModeToggle, handleSelectAllToggle: handleSelectAllToggle, selectedOrdenes: selectedOrdenes, ordenes: showSearchResults
                    ? searchResults
                    : filtered
                        ? filteredOrdenes
                        : ordenes, limitPerPage: limitPerPage, setLimitPerPage: handleLimitChange, showSearchResults: showSearchResults, searchResults: searchResults, totalItems: totalItems, setShowSearchResults: setShowSearchResults, setSearchResults: setSearchResults, handleSearch: handleSearch, setSearchKeyword: setSearchKeyword, setShowAdvancedFiltersOrdenesModal: setShowAdvancedFiltersOrdenesModal, setShowDownloadOrdenesModal: setShowDownloadOrdenesModal, OrdenPageType: "RepuestosInstalados" }), _jsx("div", { className: 'OrdenesPages-Container-Card', children: loading ? (_jsx("div", { className: "OrdenesPages-loading", children: _jsx(CircularProgress, { style: { color: '#4F7A76', position: 'absolute', top: '40%', left: '50%' } }) })) : (_jsx("ul", { className: 'OrdenesPages-ul-cards', children: repuestosInstalados.map(function (item) { return (_jsx(RepuestoInstaladoCard, { data: item, onClick: function () { return console.log('Detalle repuesto', item.actividad._id); }, selectMode: selectMode, isSelected: selectedOrdenes.includes(item.actividad._id), onSelectToggle: function () { return handleSelectToggle(item.actividad._id); } }, item.actividad._id)); }) })) }), showAdvancedFiltersOrdenesModal && (_jsx(AdvancedFiltersOrdenes, { isOpen: showAdvancedFiltersOrdenesModal, onClose: handleAdvancedFiltersClose, onApplyFilters: handleAdvancedSearch, currentPage: currentPage, limitPerPage: limitPerPage, OrdenPageType: 'RepuestosInstalados' })), showDownloadOrdenesModal && (_jsx(RepuestosInstaladosActionsComponent, { isOpen: showDownloadOrdenesModal, onClose: function () { return setShowDownloadOrdenesModal(false); }, repuestos: repuestosInstalados, selectMode: selectMode, repuestosSelected: ordenesSelected }))] }));
};
export default RepuestosInstaladosPage;
