var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getCotizacionById, getPresignedUrlForGetFirma } from '../../../cotizaciones/services/cotizacionesService';
import CotizacionByIdPendiente from './CotizacionByIdPendiente';
import CotizacionByIdAprobada from './CotizacionByIdAprobada';
import CotizacionByIdRechazada from './CotizacionByIdRechazada';
import './styles/CotizacionesByIdOrden.css';
var CotizacionesByIdOrden = function (_a) {
    var idCotizacion = _a.idCotizacion, onBack = _a.onBack, onCambioSuccess = _a.onCambioSuccess, imageUrls = _a.imageUrls;
    var _b = useState(null), cotizacion = _b[0], setCotizacion = _b[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        var fetchCotizacionData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var cotizacionData, urlPath, s3ObjectKey, presignedUrlResponse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!idCotizacion || !token)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, getCotizacionById(token, idCotizacion)];
                    case 2:
                        cotizacionData = _a.sent();
                        if (!cotizacionData.firma) return [3 /*break*/, 4];
                        urlPath = new URL(cotizacionData.firma).pathname;
                        s3ObjectKey = urlPath.substring(1);
                        return [4 /*yield*/, getPresignedUrlForGetFirma(token, s3ObjectKey)];
                    case 3:
                        presignedUrlResponse = _a.sent();
                        if (presignedUrlResponse.presignedUrl) {
                            // Actualizamos el estado con la cotización y la URL firmada
                            cotizacionData.firma = presignedUrlResponse.presignedUrl;
                        }
                        _a.label = 4;
                    case 4:
                        // Actualizamos el estado con los datos de la cotización
                        setCotizacion(cotizacionData);
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.error("Error al obtener la cotización por ID:", error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        fetchCotizacionData();
    }, [idCotizacion, token]);
    if (!cotizacion)
        return _jsx("div", { children: "Cargando cotizaci\u00F3n..." });
    return (_jsxs("div", { className: 'CotizacionesByIdOrden-container', children: [cotizacion.id_estado.estado === 'Pendiente' && (_jsx(CotizacionByIdPendiente, { cotizacion: cotizacion, onBack: onBack, onCambioSuccess: onCambioSuccess, imageUrls: imageUrls })), cotizacion.id_estado.estado === 'Aprobada' && (_jsx(CotizacionByIdAprobada, { cotizacion: cotizacion, onBack: onBack, imageUrls: imageUrls })), cotizacion.id_estado.estado === 'Rechazada' && (_jsx(CotizacionByIdRechazada, { cotizacion: cotizacion, onBack: onBack, imageUrls: imageUrls }))] }));
};
export default CotizacionesByIdOrden;
