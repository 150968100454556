var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSessionStorage } from '../../../../equipos/hooks/useSessionStorage';
import { useNavigate } from 'react-router-dom';
import MuiAlertsComponent from '../../../../../components/MuiAlertsComponent';
import '../forms/styles/RegisterCostoRepuestoForm.css';
import { createCostoRepuesto, getAllCostosRepuestos } from '../../../services/costosRepuestosService';
var RegisterCostoRepuestoForm = function () {
    var token = useSessionStorage('sessionJWTToken');
    var navigate = useNavigate();
    var formatCurrency = function (value) {
        return (value || value === 0)
            ? value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 })
            : '0';
    };
    var _a = useState({
        nombre_tecnico: '',
        costo: 0,
        codigo: ''
    }), form = _a[0], setForm = _a[1];
    var _b = useState(null), codigoAvailable = _b[0], setCodigoAvailable = _b[1];
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var handleChange = function (e) {
        setForm(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[e.target.name] = e.target.value, _a)));
        });
    };
    var handleCostoChange = function (e) {
        var rawValue = e.target.value.replace(/[^\d]/g, '');
        var numericValue = rawValue ? parseInt(rawValue) : 0;
        setForm(function (prev) { return (__assign(__assign({}, prev), { costo: numericValue })); });
    };
    var handleCodigoChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var value, resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    value = e.target.value.trim();
                    setForm(function (prev) { return (__assign(__assign({}, prev), { codigo: value })); });
                    if (!value) {
                        setCodigoAvailable(null);
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getAllCostosRepuestos(token)];
                case 2:
                    resp = _b.sent();
                    setCodigoAvailable(!resp.costosRepuestos.some(function (c) { return c.codigo === value; }));
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    setCodigoAvailable(null);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var nombre_tecnico, costo, codigo, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    nombre_tecnico = form.nombre_tecnico, costo = form.costo, codigo = form.codigo;
                    if (!nombre_tecnico || !costo || !codigo) {
                        setAlerts([{ id: Date.now(), message: 'Todos los campos son obligatorios', severity: 'warning' }]);
                        return [2 /*return*/];
                    }
                    if (codigoAvailable === false) {
                        setAlerts([{ id: Date.now(), message: 'El código no está disponible', severity: 'warning' }]);
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createCostoRepuesto(token, form)];
                case 2:
                    _b.sent();
                    setAlerts([{ id: Date.now(), message: 'Costo registrado correctamente', severity: 'success' }]);
                    setTimeout(function () { return navigate('/costos-repuestos'); }, 1000);
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    setAlerts([{ id: Date.now(), message: 'Error al registrar costo', severity: 'error' }]);
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseAlert = function (id) {
        return setAlerts(function (prev) { return prev.filter(function (a) { return a.id !== id; }); });
    };
    return (_jsxs("div", { className: "RegisterCostoEquipoForm-container", children: [_jsx("div", { className: 'RegisterCostoEquipoForm-header', children: "REGISTRAR COSTO REPUESTO" }), _jsx("div", { className: "RegisterCostoEquipoForm-box", children: _jsxs("form", { onSubmit: handleSubmit, className: "RegisterCostoEquipoForm-form", children: [_jsx("div", { className: 'RegisterCostoEquipoForm-separator' }), _jsx(MuiAlertsComponent, { alerts: alerts, onClose: handleCloseAlert }), _jsxs("div", { className: 'RegisterCostoEquipoForm-campos', children: [_jsxs("div", { className: 'RegisterCostoEquipoForm-labels', children: [_jsx("label", { children: "1. Nombre T\u00E9cnico*" }), _jsx("input", { name: "nombre_tecnico", value: form.nombre_tecnico, onChange: handleChange, required: true })] }), _jsxs("div", { className: 'RegisterCostoEquipoForm-labels', children: [_jsx("label", { children: "2. Costo (COP)*" }), _jsx("input", { name: "costo", type: "text", value: formatCurrency(form.costo), onChange: handleCostoChange, required: true })] }), _jsxs("div", { className: 'RegisterCostoEquipoForm-labels', children: [_jsx("label", { children: "3. C\u00F3digo*" }), _jsx("input", { name: "codigo", value: form.codigo, onChange: handleCodigoChange, required: true }), codigoAvailable === false && (_jsx("p", { className: "RegisterCostoEquipoForm-error", children: "El c\u00F3digo no est\u00E1 disponible" })), codigoAvailable === true && (_jsx("p", { className: "RegisterCostoEquipoForm-success", children: "C\u00F3digo disponible" }))] }), _jsxs("div", { className: "RegisterCostoEquipoForm-buttons", children: [_jsx("button", { type: "button", onClick: function () { return navigate('/costos-repuestos'); }, disabled: loading, children: "Cancelar" }), _jsx("button", { className: 'RegisterCostoEquipoForm-register', type: "submit", disabled: loading || codigoAvailable === false, children: loading ? 'Guardando…' : 'Registrar' })] })] })] }) })] }));
};
export default RegisterCostoRepuestoForm;
