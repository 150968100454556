var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
var ParticlesBackground = function () {
    var _a = useState(false), init = _a[0], setInit = _a[1];
    useEffect(function () {
        initParticlesEngine(function (engine) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadSlim(engine)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }).then(function () {
            setInit(true);
        });
        return function () {
            console.log("🔄 Desmontando partículas del Hero");
            setInit(false);
        };
    }, []);
    if (!init)
        return null;
    return (_jsx(Particles, { id: "tsparticles-hero", options: {
            fullScreen: { enable: false },
            background: { color: { value: "transparent" } },
            fpsLimit: 60,
            particles: {
                color: {
                    value: ["#ffffff", "#0066ff"], // 🔥 SOLO blanco y azul oscuro
                    animation: { enable: true }, // 🔄 Evita que cambien de color
                },
                move: {
                    enable: true,
                    speed: 0.8,
                    direction: "none",
                    outModes: { default: "out" },
                    random: true,
                    straight: false,
                    trail: { enable: false }, // ❌ Desactivamos la estela para evitar otros colores
                },
                number: { value: 90, density: { enable: true, width: 1200, height: 600 } },
                opacity: { value: 0.9 },
                shape: { type: "circle" },
                size: {
                    value: { min: 1.5, max: 3.5 },
                    animation: { enable: false }, // ❌ Evita cambios de tamaño aleatorios
                },
                links: {
                    enable: true,
                    distance: 120,
                    color: ["#ffffff", "#0066ff"], // 🔗 SOLO conexiones en blanco o azul oscuro
                    opacity: 0.2,
                    width: 0.8,
                },
            },
            interactivity: {
                events: {
                    onHover: { enable: true, mode: "grab" },
                    onClick: { enable: true, mode: "repulse" },
                },
                modes: {
                    grab: { distance: 140, lineLinked: { opacity: 0.2 } },
                    repulse: { distance: 120, duration: 0.5 },
                },
            },
            detectRetina: true,
            emitters: [
                {
                    direction: "none",
                    position: { x: 10, y: 50 },
                    rate: { quantity: 2, delay: 0.2 },
                    size: { width: 0, height: "100%" },
                },
                {
                    direction: "none",
                    position: { x: 90, y: 50 },
                    rate: { quantity: 2, delay: 0.2 },
                    size: { width: 0, height: "100%" },
                },
            ],
        } }));
};
export default ParticlesBackground;
