var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { createMarcaEquipo, getAllMarcasEquipos } from '../../../services/marcasEquipoService';
import './styles/RegisterMarcaEquipoForm.css';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlertComponent from '../../../../../components/MuiAlertsComponent'; // Importar el componente de alertas
var RegisterMarcaEquipoForm = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    var _a = useState({
        marca: '',
        date_created: formattedDateCreated,
    }), marcaEquipoData = _a[0], setMarcaEquipoData = _a[1];
    var _b = useState(null), marcaAvailable = _b[0], setMarcaAvailable = _b[1];
    var _c = useState(false), marcaCheckLoading = _c[0], setMarcaCheckLoading = _c[1];
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var _e = useState([]), alerts = _e[0], setAlerts = _e[1];
    var navigate = useNavigate();
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setMarcaEquipoData(__assign(__assign({}, marcaEquipoData), (_a = {}, _a[name] = value, _a)));
        if (value.trim()) {
            setMarcaCheckLoading(true);
            checkMarcaAvailability(value); // Verificar si la marca está disponible
        }
        else {
            setMarcaAvailable(null);
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var token, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!marcaEquipoData.marca.trim()) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Complete los campos', severity: 'warning' }], false); });
                        return [2 /*return*/];
                    }
                    if (marcaAvailable === false) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'La marca ya existe', severity: 'warning' }], false); });
                        return [2 /*return*/];
                    }
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Registrando...', severity: 'info' }], false); });
                    setIsSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    token = loggedIn;
                    return [4 /*yield*/, createMarcaEquipo(token, marcaEquipoData)];
                case 2:
                    _a.sent();
                    setTimeout(function () {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            { id: Date.now(), message: 'Marca registrada correctamente', severity: 'success' }
                        ], false); });
                        setTimeout(function () {
                            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                                { id: Date.now(), message: 'Redirigiendo...', severity: 'info' }
                            ], false); });
                            setTimeout(function () {
                                navigate('/equipos/marcas');
                            }, 1000);
                        }, 1000);
                    }, 1000);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al registrar la marca de equipo:', error_1);
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        { id: Date.now(), message: 'Error al registrar la marca', severity: 'error' }
                    ], false); });
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var hangleCancel = function () {
        navigate('/equipos/marcas');
    };
    useEffect(function () {
        if (!loggedIn) {
            navigate('/login');
        }
    }, [loggedIn, navigate]);
    var checkMarcaAvailability = function (marcaName) { return __awaiter(void 0, void 0, void 0, function () {
        var response, marcasList, marcaExists, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, getAllMarcasEquipos(loggedIn)];
                case 1:
                    response = _a.sent();
                    marcasList = response.marcasEquipos.map(function (marca) { return marca.marca.toLowerCase(); });
                    marcaExists = marcasList.includes(marcaName.toLowerCase());
                    setMarcaAvailable(!marcaExists);
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error al verificar la marca', error_2);
                    return [3 /*break*/, 4];
                case 3:
                    setMarcaCheckLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAlertClose = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    return (_jsxs("div", { children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleAlertClose }), " ", _jsx("form", { onSubmit: handleSubmit, className: "RegisterMarcaEquipoForm-box", children: _jsx("div", { className: "RegisterMarcaEquipoForm-register-marca", children: _jsxs("div", { className: "RegisterMarcaEquipoForm-overlap-group", children: [_jsx("div", { className: "RegisterMarcaEquipoForm-overlap", children: _jsx("p", { className: "RegisterMarcaEquipoForm-register-marca-title", children: "REGISTRAR NUEVA MARCA DE EQUIPO" }) }), _jsx("label", { className: "RegisterMarcaEquipoForm-register-marca-label", children: "Ingrese el nombre de la marca de equipo que desea registrar:" }), _jsx("input", { className: "RegisterMarcaEquipoForm-register-marca-input", type: "text", id: "marca", name: "marca", value: marcaEquipoData.marca, onChange: handleChange }), marcaCheckLoading ? (_jsx(CircularProgress, { style: { color: '#00ddfa', position: 'absolute', top: '13.9vh', left: '70vh' }, size: 20 })) : (marcaAvailable !== null && (_jsx("p", { className: marcaAvailable ? 'marca-available' : 'marca-unavailable', children: marcaAvailable ? 'Marca disponible' : 'Marca no disponible' }))), _jsx("button", { className: "RegisterMarcaEquipoForm-registrar-button", type: "submit", disabled: isSubmitting || marcaCheckLoading, children: isSubmitting ? 'Registrando...' : 'Registrar' }), _jsx("button", { className: "RegisterMarcaEquipoForm-cancelar-button", onClick: hangleCancel, disabled: isSubmitting, children: "Cancelar" })] }) }) })] }));
};
export default RegisterMarcaEquipoForm;
