var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// Función para exportar las solicitudes de servicio a Excel
export var exportSolicitudesToExcel = function (solicitudesServicios) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('SolicitudesServicios');
                // Define las columnas
                worksheet.columns = [
                    { header: 'ID Solicitud', key: 'id_solicitud', width: 30 },
                    { header: 'Estado', key: 'estado', width: 30 },
                    { header: 'Servicio', key: 'servicio', width: 30 },
                    { header: 'Aviso', key: 'aviso', width: 30 },
                    { header: 'Fecha de Creación', key: 'creacion', width: 30 },
                    { header: 'ID Equipo', key: 'id_equipo', width: 30 },
                    { header: 'Equipo', key: 'equipo', width: 30 },
                    { header: 'ID Modelo', key: 'id_modelo', width: 30 },
                    { header: 'Modelo', key: 'modelo', width: 30 },
                    { header: 'ID Marca', key: 'id_marca', width: 30 },
                    { header: 'Marca', key: 'marca', width: 30 },
                    { header: 'ID Área', key: 'id_area', width: 30 },
                    { header: 'Área', key: 'area', width: 30 },
                    { header: 'ID Tipo', key: 'id_tipo', width: 30 },
                    { header: 'Tipo', key: 'tipo', width: 30 },
                    { header: 'Serie', key: 'serie', width: 30 },
                    { header: 'Activo Fijo', key: 'activo_fijo', width: 30 },
                    { header: 'Ubicación', key: 'ubicacion', width: 30 },
                    { header: 'Frecuencia Mtto', key: 'frecuencia_mtto', width: 30 },
                    { header: 'Mantenimiento', key: 'mtto', width: 30 },
                    { header: 'SAP', key: 'sap', width: 30 },
                    { header: 'ID Sede', key: 'id_sede', width: 30 },
                    { header: 'Sede', key: 'sede_nombre', width: 30 },
                    { header: 'ID Cliente', key: 'id_cliente', width: 30 },
                    { header: 'Cliente', key: 'nombre_cliente', width: 30 },
                    { header: 'Observación', key: 'observacion', width: 40 },
                    { header: 'Observación Estado', key: 'observacion_estado', width: 40 },
                ];
                // Estilo para el encabezado
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // Blanco
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' }, // Azul oscuro
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
                // Añadir las filas y aplicar estilos condicionales
                solicitudesServicios.forEach(function (solicitud) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
                    var row = worksheet.addRow({
                        id_solicitud: solicitud._id || 'N/A',
                        estado: ((_a = solicitud.id_solicitud_estado) === null || _a === void 0 ? void 0 : _a.estado) || 'N/A',
                        servicio: ((_b = solicitud.id_servicio) === null || _b === void 0 ? void 0 : _b.servicio) || 'N/A',
                        aviso: solicitud.aviso || 'N/A',
                        creacion: solicitud.creacion || 'N/A',
                        id_equipo: ((_c = solicitud.id_equipo) === null || _c === void 0 ? void 0 : _c._id) || 'N/A',
                        equipo: ((_f = (_e = (_d = solicitud.id_equipo) === null || _d === void 0 ? void 0 : _d.modelo_equipos) === null || _e === void 0 ? void 0 : _e.id_clase) === null || _f === void 0 ? void 0 : _f.clase) || 'N/A',
                        id_modelo: ((_h = (_g = solicitud.id_equipo) === null || _g === void 0 ? void 0 : _g.modelo_equipos) === null || _h === void 0 ? void 0 : _h._id) || 'N/A',
                        modelo: ((_k = (_j = solicitud.id_equipo) === null || _j === void 0 ? void 0 : _j.modelo_equipos) === null || _k === void 0 ? void 0 : _k.modelo) || 'N/A',
                        id_marca: ((_o = (_m = (_l = solicitud.id_equipo) === null || _l === void 0 ? void 0 : _l.modelo_equipos) === null || _m === void 0 ? void 0 : _m.id_marca) === null || _o === void 0 ? void 0 : _o._id) || 'N/A',
                        marca: ((_r = (_q = (_p = solicitud.id_equipo) === null || _p === void 0 ? void 0 : _p.modelo_equipos) === null || _q === void 0 ? void 0 : _q.id_marca) === null || _r === void 0 ? void 0 : _r.marca) || 'N/A',
                        id_area: ((_t = (_s = solicitud.id_equipo) === null || _s === void 0 ? void 0 : _s.id_area) === null || _t === void 0 ? void 0 : _t._id) || 'N/A',
                        area: ((_v = (_u = solicitud.id_equipo) === null || _u === void 0 ? void 0 : _u.id_area) === null || _v === void 0 ? void 0 : _v.area) || 'N/A',
                        id_tipo: ((_x = (_w = solicitud.id_equipo) === null || _w === void 0 ? void 0 : _w.id_tipo) === null || _x === void 0 ? void 0 : _x._id) || 'N/A',
                        tipo: ((_z = (_y = solicitud.id_equipo) === null || _y === void 0 ? void 0 : _y.id_tipo) === null || _z === void 0 ? void 0 : _z.tipo) || 'N/A',
                        serie: ((_0 = solicitud.id_equipo) === null || _0 === void 0 ? void 0 : _0.serie) || 'N/A',
                        activo_fijo: ((_1 = solicitud.id_equipo) === null || _1 === void 0 ? void 0 : _1.activo_fijo) || 'N/A',
                        ubicacion: ((_2 = solicitud.id_equipo) === null || _2 === void 0 ? void 0 : _2.ubicacion) || 'N/A',
                        frecuencia_mtto: ((_3 = solicitud.id_equipo) === null || _3 === void 0 ? void 0 : _3.frecuencia) || 'N/A',
                        mtto: ((_4 = solicitud.id_equipo) === null || _4 === void 0 ? void 0 : _4.mtto) || 'N/A',
                        sap: ((_5 = solicitud.id_equipo) === null || _5 === void 0 ? void 0 : _5.sap) || 'N/A',
                        id_sede: ((_7 = (_6 = solicitud.id_equipo) === null || _6 === void 0 ? void 0 : _6.id_sede) === null || _7 === void 0 ? void 0 : _7._id) || 'N/A',
                        sede_nombre: ((_9 = (_8 = solicitud.id_equipo) === null || _8 === void 0 ? void 0 : _8.id_sede) === null || _9 === void 0 ? void 0 : _9.sede_nombre) || 'N/A',
                        id_cliente: ((_12 = (_11 = (_10 = solicitud.id_equipo) === null || _10 === void 0 ? void 0 : _10.id_sede) === null || _11 === void 0 ? void 0 : _11.id_client) === null || _12 === void 0 ? void 0 : _12._id) || 'N/A',
                        nombre_cliente: ((_15 = (_14 = (_13 = solicitud.id_equipo) === null || _13 === void 0 ? void 0 : _13.id_sede) === null || _14 === void 0 ? void 0 : _14.id_client) === null || _15 === void 0 ? void 0 : _15.client_name) || 'N/A',
                        observacion: solicitud.observacion || 'N/A',
                        observacion_estado: solicitud.observacion_estado || 'N/A',
                    });
                    // Estilos condicionales según el estado
                    if (((_16 = solicitud.id_solicitud_estado) === null || _16 === void 0 ? void 0 : _16.estado) === 'Pendiente') {
                        row.getCell('estado').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFADD8E6' }, // Azul claro
                        };
                    }
                    else if (((_17 = solicitud.id_solicitud_estado) === null || _17 === void 0 ? void 0 : _17.estado) === 'Aprobada') {
                        row.getCell('estado').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF90EE90' }, // Verde claro
                        };
                    }
                    else if (((_18 = solicitud.id_solicitud_estado) === null || _18 === void 0 ? void 0 : _18.estado) === 'Rechazada') {
                        row.getCell('estado').fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA07A' }, // Rojo claro
                        };
                    }
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'solicitudes_servicios.xlsx');
                return [2 /*return*/];
        }
    });
}); };
