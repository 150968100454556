import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import EquipoSectionContratos from '../equipos/EquipoSectionContratos';
var SedesSectionContratos = function (_a) {
    var sedes = _a.sedes, contratoId = _a.contratoId;
    if (!sedes.length) {
        return _jsx("div", { children: "No se encontraron sedes para este contrato." });
    }
    return (_jsxs("div", { className: "ContratoDetailPage-complete-details", children: [_jsx("div", { className: "ContratoDetailPage-header", children: _jsx("div", { className: "ContratoDetailPage-section-title", children: "Sedes Asociadas" }) }), sedes.map(function (sede, index) { return (_jsxs("div", { className: "ContratoDetailPage-main-details", children: [_jsxs("div", { className: "ContratoDetailPage-section", children: [_jsx("div", { className: "ContratoDetailPage-section-title", children: sede.sede_nombre || 'N/A' }), _jsx(ApartmentOutlinedIcon, { className: "ContratoDetailPage-section-icon" }), _jsx("div", { className: "ContratoDetailPage-section-text", children: sede.sede_address || 'N/A' }), _jsx(LocationOnOutlinedIcon, { className: "ContratoDetailPage-section-icon" }), _jsx("div", { className: "ContratoDetailPage-section-text", children: sede.sede_telefono || 'N/A' }), _jsx(PhoneOutlinedIcon, { className: "ContratoDetailPage-section-icon" }), _jsx("div", { className: "ContratoDetailPage-section-text", children: sede.sede_email || 'N/A' }), _jsx(EmailOutlinedIcon, { className: "ContratoDetailPage-section-icon" })] }, index), _jsx(EquipoSectionContratos, { sedeId: sede._id, contratoId: contratoId })] })); })] }));
};
export default SedesSectionContratos;
