import axios from '../../../utils/config/axios.config';
// Get all contracts
export var getAllContratos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/contratos', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Get a contract by ID
export var getContratoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/contratos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Create a new contract
export var createContrato = function (token, contratoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/contratos', contratoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Update a contract by ID
export var updateContrato = function (token, id, contratoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/contratos?id=".concat(id), contratoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Delete a contract by ID
export var deleteContratoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/contratos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Handle errors in axios responses
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Token inválido o expirado
            // window.location.href = '/login';
            console.error(error);
        }
    }
    // throw error;
};
