import axios from '../../../utils/config/axios.config';
// Get all client types
export var getAllClientsTipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/clients-tipos', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Get a client type by ID
export var getClientTipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/clients-tipos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Create a new client type
export var createClientTipo = function (token, clientTipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/clients-tipos', clientTipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Update a client type by ID
export var updateClientTipo = function (token, id, clientTipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/clients-tipos?id=".concat(id), clientTipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Delete a client type by ID
export var deleteClientTipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/clients-tipos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Handle errors in axios responses
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Token inválido o expirado
            console.error(error);
        }
    }
    // throw error;
};
