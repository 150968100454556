import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import LogInForm from '../components/forms/LoginForm';
import LoadingScreen from '../../loadingModule/components/LoadingScreen';
var LoginPage = function () {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    useEffect(function () {
        // Simula una carga o espera de datos
        var timer = setTimeout(function () {
            setIsLoading(false);
        }, 7000); // Duración total de la carga en milisegundos (5s de progreso + 2s de check)
        return function () { return clearTimeout(timer); };
    }, []);
    return (_jsx("div", { children: isLoading ? _jsx(LoadingScreen, { duration: 5000, checkDisplayDuration: 2000 }) : _jsx(LogInForm, {}) }));
};
export default LoginPage;
