import axios from '../../../utils/config/axios.config';
export var getAllServiciosTipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/servicios', options)
        .then(function (response) { return response.data.servicios; }) // Ajusta para obtener la propiedad "serviciosTipos" de la respuesta
        .catch(function (error) {
        if (error.response) {
            var _a = error.response, status_1 = _a.status, data = _a.data;
            if (status_1 === 500) {
                console.error('Error 500:', data);
                window.location.href = '/login';
            }
        }
        else {
            console.error('Error desconocido:', error);
            // Maneja otros errores aquí
        }
        throw error;
    });
};
export var getServicioTipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/servicios/".concat(id), options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_2 = error.response.status;
            if (status_2 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
export var createServicioTipo = function (token, servicioTipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/servicios', servicioTipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_3 = error.response.status;
            if (status_3 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
export var updateServicioTipo = function (token, id, servicioTipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/servicios/".concat(id), servicioTipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_4 = error.response.status;
            if (status_4 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
export var deleteServicioTipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/servicios/".concat(id), options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_5 = error.response.status;
            if (status_5 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
