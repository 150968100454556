// checkPermissions.ts
import { permissions } from "../services/permissions";
export function userHasPermission(moduleName, // por ejemplo, 'usuarios' o 'ordenes'
actionName, // por ejemplo, 'crear', 'editar'
userRoles // roles del usuario actual
) {
    // Se obtiene el objeto de permisos del módulo y se castea para indicar que se puede indexar con string
    var modulePermissions = permissions[moduleName];
    if (!modulePermissions)
        return false;
    var allowedRoles = modulePermissions[actionName];
    if (!allowedRoles)
        return false;
    return userRoles.some(function (role) { return allowedRoles.includes(role); });
}
