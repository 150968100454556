import axios from '../../../utils/config/axios.config';
// Función para manejar errores comunes
var handleCommonErrors = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Token inválido o expirado
            // Redirigir al usuario a la página de inicio de sesión (/login)
            //   window.location.href = '/login';
            //   window.alert('Sesión expirada, vuelva a iniciar sesión')
            console.log(error);
        }
    }
    throw error;
};
export var getAllSedes = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/sedes', options)
        .then(function (response) { return response.data; }) // Devolver los datos directamente como en getAllUsers
        .catch(handleCommonErrors); // Utilizar el mismo manejo de errores
};
export var getSedeById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        }
    };
    return axios
        .get("/sedes/", options)
        .catch(function (error) { return handleCommonErrors(error); });
};
export var createSede = function (token, sedeData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/sedes', sedeData, options)
        .then(function (response) {
        // Retornar la respuesta en lugar de manejarla aquí
        return response.data;
    })
        .catch(function (error) {
        handleCommonErrors(error);
        throw error;
    });
};
export var updateSedeById = function (token, id, sedeData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/sedes?id=".concat(id), sedeData, options)
        .catch(function (error) { return handleCommonErrors(error); });
};
export var deleteSedeById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .delete("/sedes?id=".concat(id), options)
        .catch(function (error) { return handleCommonErrors(error); });
};
export var searchSedesByKeyword = function (token, keyword) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    var requestBody = {
        keyword: keyword,
    };
    return axios
        .post('/search/sedes', requestBody, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_2 = error.response.status;
            if (status_2 === 500) {
                // Token inválido o expirado
                // Redirigir al usuario a la página de inicio de sesión (/login)
                // window.location.href = '/login';
                console.log(error);
            }
        }
        throw error;
    });
};
