import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
var CotizacionSignSectionPDF = function (_a) {
    var cotizacion = _a.cotizacion;
    return (_jsxs(View, { children: [_jsx(Text, { style: styles.timePeriod, children: "La vigencia de esta cotizaci\u00F3n es de 30 d\u00EDas una vez aprobada la propuesta." }), _jsx(Text, { style: styles.cordially, children: "Cordialmente," }), cotizacion.firma && (_jsx(Image, { style: styles.firmaImg, src: cotizacion.firma || 'N/A' })), _jsx(Text, { style: styles.firmaName, children: cotizacion.firma_username })] }));
};
var styles = StyleSheet.create({
    timePeriod: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 500,
        left: 16,
        width: 429,
        lineHeight: 1.2,
    },
    cordially: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 550,
        left: 16,
        width: 429,
        lineHeight: 1.2,
    },
    firmaImg: {
        height: 70,
        width: 233,
        position: 'absolute',
        top: 580,
        left: 16,
        objectFit: 'cover',
    },
    firmaName: {
        color: '#000000',
        fontSize: 11,
        fontWeight: 'normal',
        position: 'absolute',
        top: 650,
        left: 16,
        width: '100%',
        lineHeight: 1.2,
    },
});
export default CotizacionSignSectionPDF;
