import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import './styles/EquipoDisponibilidad.css';
import { useSessionStorage } from '../../hooks/useSessionStorage'; // Asegúrate de que la ruta sea correcta
import EquipoDisponibleRegistrar from './EquipoDisponibilidadRegistrar';
import EquipoDisponibleEsperaRegistrar from './EquipoDisponibilidadEsperaRegistrar';
import EquipoDisponibilidadVer from './EquipoDisponibilidadVer';
import EquipoDisponibilidadEsperaVer from './EquipoDisponibilidadEsperaVer';
var EquipoDisponibilidad = function (_a) {
    var idVisita = _a.idVisita, onActividadesUpdated = _a.onActividadesUpdated, visita = _a.visita;
    var _b = useState(''), idProtocolo = _b[0], setIdProtocolo = _b[1];
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1]; // Inicializado en true para mostrar carga inicialmente
    var _d = useState([]), actividades = _d[0], setActividades = _d[1];
    var token = useSessionStorage('sessionJWTToken');
    var handleSelectChange = function (e) {
        setIdProtocolo(e.target.value);
        setIsLoading(true); // Simula la carga al cambiar de opción
        // Simula un retraso para mostrar el indicador de carga
        setTimeout(function () {
            setIsLoading(false); // Finaliza la carga
        }, 1000);
    };
    // Preparar componentes de actividades fuera del return
    var equipoDisponibilidadVerComponent = null;
    var equipoDisponibilidadEsperaVerComponent = null;
    if (visita && visita.actividades.length > 0) {
        var equipoDisponibleEsperaActividad = visita.actividades.find(function (act) { return act.id_protocolo.title === "En espera de disponibilidad"; });
        if (equipoDisponibleEsperaActividad) {
            equipoDisponibilidadEsperaVerComponent = _jsx(EquipoDisponibilidadEsperaVer, { actividad: equipoDisponibleEsperaActividad });
        }
        var equipoDisponibleActividad = visita.actividades.find(function (act) { return act.id_protocolo.title === "Equipo disponible"; });
        if (equipoDisponibleActividad) {
            equipoDisponibilidadVerComponent = _jsx(EquipoDisponibilidadVer, { actividad: equipoDisponibleActividad });
        }
    }
    // Revisa si ya existe la actividad "Equipo Disponible"
    var equipoDisponibleExistente = visita && visita.actividades.find(function (act) { return act.id_protocolo.title === "Equipo disponible"; });
    // Revisa si ya existe la actividad "En Espera de Disponibilidad"
    var esperaDisponibilidadExistente = visita && visita.actividades.find(function (act) { return act.id_protocolo.title === "En espera de disponibilidad"; });
    return (_jsxs("div", { className: "EquipoDisponibilidad-visita-actividad", children: [equipoDisponibilidadEsperaVerComponent, equipoDisponibilidadVerComponent, ((!equipoDisponibleExistente && !esperaDisponibilidadExistente) || (!equipoDisponibleExistente && esperaDisponibilidadExistente)) && (_jsx("div", { className: "EquipoDisponibilidad-actividad-group", children: _jsxs("div", { className: "EquipoDisponibilidad-overlap-group", children: [_jsx("div", { className: "EquipoDisponibilidad-actividad-title", children: "ACTIVIDAD A EJECUTAR" }), _jsxs("select", { className: "EquipoDisponibilidad-actividad-select", value: idProtocolo, onChange: handleSelectChange, children: [_jsx("option", { value: "", disabled: true, selected: true, children: "Seleccione una opci\u00F3n" }), !equipoDisponibleExistente && _jsx("option", { value: "65a93df489a02ef211e75ed3", children: "Equipo Disponible" }), !esperaDisponibilidadExistente && _jsx("option", { value: "65a93dec89a02ef211e75ed1", children: "En Espera de Disponibilidad" })] })] }) })), _jsxs(_Fragment, { children: [idProtocolo === '65a93df489a02ef211e75ed3' && _jsx(EquipoDisponibleRegistrar, { idVisita: idVisita, onActividadesUpdated: onActividadesUpdated, visita: visita }), idProtocolo === '65a93dec89a02ef211e75ed1' && _jsx(EquipoDisponibleEsperaRegistrar, { idVisita: idVisita, onActividadesUpdated: onActividadesUpdated, visita: visita })] })] }));
};
export default EquipoDisponibilidad;
