import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import CreateSedeForm from '../components/sedes/forms/CreateSedeForm';
import DashboardMenuLateral from '../components/dashboard/DashboardMenulateral';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { logoutService } from '../services/authService';
var CreateSedePage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        if (!loggedIn) {
            logoutService(); // Utiliza la función de logoutService para manejar el cierre de sesión
        }
    }, [loggedIn]);
    return (_jsxs("div", { children: [_jsx(DashboardMenuLateral, {}), _jsx(CreateSedeForm, {})] }));
};
export default CreateSedePage;
