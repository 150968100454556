// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrdenesPages-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .OrdenesPages-loading-overlay {
    color: #4F7A76;
  }
  
  .OrdenesPages-Container-Card {
    position: fixed;
    left: 3%;
    top: 13%;
    width: 95%;
    height: 100%;
  }
  
  .OrdenesPages-ul-cards {
    padding: 1%;
    width: 100%;
    height: 86%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(417px, 1fr));
    gap: 2%;
    row-gap: 5%;
    list-style: none;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #636b6f61 transparent;
  
    /* Evitar que los ítems se estiren */
    align-items: start;        /* o flex-start */
    /* align-content: start;  (opcional) */
  }
  
  
  /* Para Chrome, Safari y Edge */
  .OrdenesPages-ul-cards::-webkit-scrollbar {
    width: 3px; /* Ajusta el ancho del scrollbar */
  }
  
  .OrdenesPages-ul-cards::-webkit-scrollbar-track {
    background-color: transparent;
  
  }
  
  .OrdenesPages-ul-cards::-webkit-scrollbar-thumb {
    background-color: transparent;
      border-radius: 3px;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/modules/indicadores/pages/styles/RepuestosInstaladosPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,UAAU;IACV,YAAY;EACd;;EAEA;IACE,WAAW;IACX,WAAW;IACX,WAAW;IACX,aAAa;IACb,2DAA2D;IAC3D,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,sCAAsC;;IAEtC,oCAAoC;IACpC,kBAAkB,SAAS,iBAAiB;IAC5C,sCAAsC;EACxC;;;EAGA,+BAA+B;EAC/B;IACE,UAAU,EAAE,kCAAkC;EAChD;;EAEA;IACE,6BAA6B;;EAE/B;;EAEA;IACE,6BAA6B;MAC3B,kBAAkB;EACtB","sourcesContent":[".OrdenesPages-container {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n  }\n  \n  .OrdenesPages-loading-overlay {\n    color: #4F7A76;\n  }\n  \n  .OrdenesPages-Container-Card {\n    position: fixed;\n    left: 3%;\n    top: 13%;\n    width: 95%;\n    height: 100%;\n  }\n  \n  .OrdenesPages-ul-cards {\n    padding: 1%;\n    width: 100%;\n    height: 86%;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(417px, 1fr));\n    gap: 2%;\n    row-gap: 5%;\n    list-style: none;\n    position: relative;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    scrollbar-width: thin;\n    scrollbar-color: #636b6f61 transparent;\n  \n    /* Evitar que los ítems se estiren */\n    align-items: start;        /* o flex-start */\n    /* align-content: start;  (opcional) */\n  }\n  \n  \n  /* Para Chrome, Safari y Edge */\n  .OrdenesPages-ul-cards::-webkit-scrollbar {\n    width: 3px; /* Ajusta el ancho del scrollbar */\n  }\n  \n  .OrdenesPages-ul-cards::-webkit-scrollbar-track {\n    background-color: transparent;\n  \n  }\n  \n  .OrdenesPages-ul-cards::-webkit-scrollbar-thumb {\n    background-color: transparent;\n      border-radius: 3px;\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
