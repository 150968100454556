import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
var styles = StyleSheet.create({
    firmasContainer: {
        height: 167,
        width: 554,
        pageBreakInside: 'avoid',
    },
    firmasSection: {
        height: 167,
        width: 554,
        flexDirection: 'row',
        justifyContent: 'space-between',
        breakInside: 'avoid', // Añadido para evitar el salto de página dentro de este contenedor
    },
    firmaEntregaGroup: {
        width: 274,
        breakInside: 'avoid', // Añadido para evitar el salto de página dentro de este contenedor
    },
    overlap5: {
        backgroundColor: '#00ddfa',
        height: 29,
        justifyContent: 'center',
        alignItems: 'center',
        breakInside: 'avoid', // Añadido para evitar el salto de página dentro de este contenedor
    },
    entregaT: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
    },
    overlapGroup2: {
        height: 75,
        marginTop: 15,
        breakInside: 'avoid', // Añadido para evitar el salto de página dentro de este contenedor
    },
    firmaT: {
        color: '#000000',
        fontSize: 12,
        fontWeight: 'normal',
    },
    firmaSpace: {
        backgroundColor: '#d9d9d9',
        borderRadius: 10,
        height: 58,
        marginTop: 5,
    },
    firmaImg: {
        backgroundColor: '#d9d9d9',
        height: 58,
        width: '100%',
        borderRadius: 10,
    },
    nameT: {
        color: '#000000',
        fontSize: 12,
        fontWeight: 'normal',
        marginTop: 5,
    },
    cedulaT: {
        color: '#000000',
        fontSize: 12,
        fontWeight: 'normal',
        marginTop: 5,
    },
    firmaRecibeGroup: {
        width: 274,
        breakInside: 'avoid', // Añadido para evitar el salto de página dentro de este contenedor
    },
    recibeT: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
    },
});
var OrdenesPDFSignSection = function (_a) {
    var _b, _c, _d, _e;
    var orden = _a.orden, firmaUrls = _a.firmaUrls;
    return (_jsx(View, { style: styles.firmasContainer, children: _jsxs(View, { style: styles.firmasSection, children: [_jsxs(View, { style: styles.firmaEntregaGroup, children: [_jsx(View, { style: styles.overlap5, children: _jsx(Text, { style: styles.entregaT, children: "ENTREGA" }) }), _jsxs(View, { style: styles.overlapGroup2, children: [_jsx(Text, { style: styles.firmaT, children: "Firma" }), firmaUrls.entrega ? (_jsx(Image, { src: firmaUrls.entrega, style: styles.firmaImg })) : (_jsx(View, { style: styles.firmaSpace }))] }), _jsxs(Text, { style: styles.nameT, children: ["Nombre: ", (_b = orden.entrega) === null || _b === void 0 ? void 0 : _b.nombre_entrega] }), _jsxs(Text, { style: styles.cedulaT, children: ["Cedula: ", (_c = orden.entrega) === null || _c === void 0 ? void 0 : _c.cedula_entrega] })] }), _jsxs(View, { style: styles.firmaRecibeGroup, children: [_jsx(View, { style: styles.overlap5, children: _jsx(Text, { style: styles.recibeT, children: "RECIBE" }) }), _jsxs(View, { style: styles.overlapGroup2, children: [_jsx(Text, { style: styles.firmaT, children: "Firma" }), firmaUrls.recibe ? (_jsx(Image, { src: firmaUrls.recibe, style: styles.firmaImg })) : (_jsx(View, { style: styles.firmaSpace }))] }), _jsxs(Text, { style: styles.nameT, children: ["Nombre: ", (_d = orden.recibe) === null || _d === void 0 ? void 0 : _d.nombre_recibe] }), _jsxs(Text, { style: styles.cedulaT, children: ["Cedula: ", (_e = orden.recibe) === null || _e === void 0 ? void 0 : _e.cedula_recibe] })] })] }) }));
};
export default OrdenesPDFSignSection;
