import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import iconAccess from "../images/caracteristicas_ll/icon_access.png";
import iconCustomizable from "../images/caracteristicas_ll/icon_customizable.png";
import iconDashboard from "../images/caracteristicas_ll/icon_dashboard.png";
import iconFilters2 from "../images/caracteristicas_ll/icon_filters-1.png";
import iconFilters from "../images/caracteristicas_ll/icon_filters.png";
import iconIntegration from "../images/caracteristicas_ll/icon_integration.png";
import iconIntelligent from "../images/caracteristicas_ll/icon_intelligent.png";
import iconIntuitive from "../images/caracteristicas_ll/icon_intuitive.png";
import iconNotifications from "../images/caracteristicas_ll/icon_notifications.png";
import iconPassword from "../images/caracteristicas_ll/icon_password.png";
import iconReports from "../images/caracteristicas_ll/icon_reports.png";
import iconSupport from "../images/caracteristicas_ll/icon_support.png";
import "../styles/caracteristicas_ll.css";
var features = [
    { title: ["Acceso"], icon: iconAccess },
    { title: ["Personalizable"], icon: iconCustomizable },
    { title: ["Dashboard", "Interactivo"], icon: iconDashboard },
    { title: ["Filtros", "Avanzados"], icon: iconFilters2 },
    { title: ["Filtros"], icon: iconFilters },
    { title: ["Integración", "con APIs"], icon: iconIntegration },
    { title: ["Informes", "Inteligentes"], icon: iconIntelligent },
    { title: ["Diseño", "Intuitivo"], icon: iconIntuitive },
    { title: ["Notificaciones"], icon: iconNotifications },
    { title: ["Contraseñas", "Robustas"], icon: iconPassword },
    { title: ["Reportes"], icon: iconReports },
    { title: ["Soporte", "Técnico"], icon: iconSupport },
];
export var CaracteristicasLl = function () {
    var _a = useState(null), selectedFeature = _a[0], setSelectedFeature = _a[1];
    var _b = useState(0), rotationAngle = _b[0], setRotationAngle = _b[1];
    var _c = useState(true), autoRotation = _c[0], setAutoRotation = _c[1];
    useEffect(function () {
        var intervalId = null;
        if (autoRotation) {
            intervalId = setInterval(function () {
                setRotationAngle(function (prevAngle) { return (prevAngle + 0.2) % 360; });
            }, 16); // Aproximadamente 60FPS (1000ms / 60 ≈ 16ms)
        }
        return function () {
            if (intervalId)
                clearInterval(intervalId);
        };
    }, [autoRotation]);
    var handleFeatureClick = function (index) {
        if (selectedFeature === index) {
            setSelectedFeature(null);
            setAutoRotation(true); // Reactiva la rotación automática
        }
        else {
            setSelectedFeature(index);
            setAutoRotation(false); // Detiene la rotación automática
            var totalItems = features.length;
            var currentAngle = rotationAngle % 360;
            var targetAngle = (-360 / totalItems) * index;
            // 🔥 Asegurar que siempre tome el camino más corto
            var newRotationAngle = currentAngle;
            var angleDifference = ((targetAngle - currentAngle + 540) % 360) - 180;
            newRotationAngle += angleDifference;
            setRotationAngle(newRotationAngle);
        }
    };
    return (_jsxs("div", { className: "caracteristicas-ll", children: [_jsxs("div", { className: "bgs", children: [_jsx("div", { className: "bg-new" }), _jsx("div", { className: "bg-second" })] }), _jsx("div", { className: "overlap-title", children: _jsxs("div", { className: "overlap-ll", children: [_jsx("div", { className: "text-s", children: "DESTACADOS" }), _jsx("div", { className: "title-text", children: "DESTACADOS" })] }) }), _jsx("div", { className: "slider", style: {
                    "--quantity": features.length,
                    transform: "translateZ(3000px) rotateX(-8deg) rotateY(".concat(rotationAngle, "deg)"),
                    transition: "transform 1s ease",
                }, children: features.map(function (feature, index) { return (_jsx("div", { className: "item ".concat(selectedFeature === index ? "selected" : ""), onClick: function () { return handleFeatureClick(index); }, style: {
                        "--position": index + 1,
                    }, children: _jsxs("div", { className: "feature-content", children: [_jsx("div", { className: "feature-title", children: feature.title.map(function (word, i) { return (_jsx("div", { className: i === 1 ? "gradient-text" : "", children: word }, i)); }) }), _jsx("img", { className: "feature-icon", alt: "Icono ".concat(feature.title.join(" ")), src: feature.icon })] }) }, index)); }) })] }));
};
export default CaracteristicasLl;
