import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import './styles/EquipoModeloCard.css';
import Checkbox from '@mui/material/Checkbox';
var EquipoModeloCard = function (_a) {
    var modeloEquipo = _a.modeloEquipo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "EquipoModeloCard-box ".concat(isSelected ? 'EquipoModeloCard-selected' : '', "\n}");
    return (_jsxs("li", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '102%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#00ddfa',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#00ddfa',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx(DevicesOtherOutlinedIcon, { className: "EquipoModeloCard-div" }), _jsx("div", { className: "EquipoModeloCard-text-wrapper", onClick: onClick, children: modeloEquipo.modelo }), _jsxs("div", { className: "EquipoModeloCard-equipospages-card-2", children: ["$: ", modeloEquipo.precio] }), _jsxs("div", { className: "EquipoModeloCard-overlap-2", children: [_jsx("div", { className: "EquipoModeloCard-equipospages-card-3", children: "Marca:" }), _jsx("div", { className: "EquipoModeloCard-equipospages-card-4", children: modeloEquipo.id_marca.marca })] }), _jsxs("div", { className: "EquipoModeloCard-overlap-3", children: [_jsx("div", { className: "EquipoModeloCard-equipospages-card-5", children: "Clase:" }), _jsx("div", { className: "EquipoModeloCard-equipospages-card-6", children: modeloEquipo.id_clase.clase })] }), _jsx(CheckOutlinedIcon, { className: "EquipoModeloCard-check" })] }, modeloEquipo._id));
};
export default EquipoModeloCard;
