// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TiposEquiposPage-container {
	width: 100%;
	height: 100%;
    position: absolute;
}

.TiposEquiposPage-Container-Card{
    position: fixed;
    left: 3%;
    top: 13%;
    width: 95%;
    height: 100%;
}

.TiposEquiposPage-ul-cards{
    padding: 1%;
    width: 100%;
    height: 86%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));
    gap: 2%;
    row-gap: 5%;
    list-style: none;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #636b6f61 transparent;

}
`, "",{"version":3,"sources":["webpack://./src/modules/equipos/pages/styles/TiposEquiposPage.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;IACT,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,WAAW;IACX,aAAa;IACb,2DAA2D;IAC3D,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,sCAAsC;;AAE1C","sourcesContent":[".TiposEquiposPage-container {\n\twidth: 100%;\n\theight: 100%;\n    position: absolute;\n}\n\n.TiposEquiposPage-Container-Card{\n    position: fixed;\n    left: 3%;\n    top: 13%;\n    width: 95%;\n    height: 100%;\n}\n\n.TiposEquiposPage-ul-cards{\n    padding: 1%;\n    width: 100%;\n    height: 86%;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));\n    gap: 2%;\n    row-gap: 5%;\n    list-style: none;\n    position: relative;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    scrollbar-width: thin;\n    scrollbar-color: #636b6f61 transparent;\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
