import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { usePermission } from '../hooks/usePermission';
var ProtectedRoute = function (_a) {
    var moduleName = _a.moduleName, actionName = _a.actionName, children = _a.children;
    var _b = usePermission(moduleName, actionName), canAccess = _b.canAccess, isLoading = _b.isLoading;
    if (isLoading) {
        return _jsx("div", { children: "Cargando permisos..." });
    }
    if (!canAccess) {
        return _jsx(Navigate, { to: "/unauthorized", replace: true });
    }
    return children;
};
export default ProtectedRoute;
