// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SolicitudBodegaByIdOrden-container {
    height: 37vh; /* 373px */
    left: 30.63vw; /* 588px */
    top: 13.7vh; /* 163px */
    width: 20.36vw; /* 391px */
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/solicitudes_bodega/styles/SolicitudBodegaByIdOrden.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,UAAU;IACxB,aAAa,EAAE,UAAU;IACzB,WAAW,EAAE,UAAU;IACvB,cAAc,EAAE,UAAU;IAC1B,kBAAkB;AACtB","sourcesContent":[".SolicitudBodegaByIdOrden-container {\n    height: 37vh; /* 373px */\n    left: 30.63vw; /* 588px */\n    top: 13.7vh; /* 163px */\n    width: 20.36vw; /* 391px */\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
