import axios from '../../../utils/config/axios.config';
export var getAllPreventivos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/preventivos', options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var getPreventivoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/preventivos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var createPreventivo = function (token, preventivoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/preventivos', preventivoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var updatePreventivo = function (token, id, preventivoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/preventivos?id=".concat(id), preventivoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var deletePreventivoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/preventivos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
var handleRequestError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            window.location.href = '/login';
        }
    }
    throw error;
};
