import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import "../styles/head.css";
import { useNavigate } from "react-router-dom";
export var Head = function (_a) {
    var onSectionChange = _a.onSectionChange, selectedSection = _a.selectedSection;
    var _b = useState(selectedSection), activeSection = _b[0], setActiveSection = _b[1];
    var navigate = useNavigate();
    useEffect(function () {
        setActiveSection(selectedSection);
    }, [selectedSection]);
    var handleClick = function (section) {
        setActiveSection(section);
        onSectionChange(section);
    };
    useEffect(function () {
        if (selectedSection) {
            var sectionElement = document.getElementById(selectedSection);
            if (sectionElement) {
                sectionElement.scrollBy({ behavior: "smooth" });
            }
        }
    }, [selectedSection]);
    var handleLoginClick = function () {
        navigate("/login");
    };
    var handleMemcoClick = function () {
        window.open("https://memcosas.com", "_blank");
    };
    var handleLocationClick = function () {
        window.open("https://maps.app.goo.gl/BLpaTPttGJDvG6e6A", "_blank");
    };
    return (_jsx("div", { className: "head", children: _jsx("header", { className: "header", children: _jsxs("div", { className: "overlap", children: [_jsx("div", { className: "side-effects", children: _jsxs("div", { className: "overlap-group", children: [_jsx("div", { className: "rectangle" }), _jsx("div", { className: "div" })] }) }), _jsxs("div", { className: "nav", children: [_jsx("div", { className: "login", onClick: handleLoginClick, children: _jsx("div", { className: "overlap-group-2", children: _jsx("div", { className: "rectangle-2", children: "LOGIN" }) }) }), _jsx("div", { className: "memco", onClick: handleMemcoClick, children: _jsx("div", { className: "overlap-group-2", children: _jsx("div", { className: "rectangle-3", children: "MEMCO" }) }) }), _jsx("div", { className: "location", onClick: handleLocationClick, children: _jsx("div", { className: "overlap-group-2", children: _jsx("div", { className: "rectangle-3", children: "LOCATION" }) }) }), _jsx("div", { className: "about", onClick: function () { return handleClick("Footer"); }, children: _jsx("div", { className: "overlap-group-2", children: _jsx("div", { className: "rectangle-3", children: "ABOUT US" }) }) })] }), _jsx("div", { className: "bioinfo", children: _jsx("div", { className: "bioswift", children: "BIOSWIFT" }) })] }) }) }));
};
export default Head;
