var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import '../../../../../components/filters/styles/ActionsComponent.css';
import { CircularProgress } from '@mui/material';
import { exportEquiposToExcel } from '../../../services/exportEquiposExcelService';
import MuiAlertComponent from '../../../../../components/MuiAlertsComponent';
import CreateSolicitudesServicioMassiveForEquipos from './CreateSolicitudesServicioMassiveForEquipos'; // Importar el nuevo componente
import { useNavigate } from 'react-router-dom';
import { usePermission } from '../../../../../hooks/usePermission';
var EquiposActionsComponent = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, equipos = _a.equipos, selectMode = _a.selectMode, equiposSelected = _a.equiposSelected;
    var _b = useState(''), selectedAction = _b[0], setSelectedAction = _b[1]; // Acción seleccionada
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1]; // Estado de carga
    var _e = useState(false), showMassiveForm = _e[0], setShowMassiveForm = _e[1]; // Mostrar/ocultar formulario de creación masiva
    var canCreateMasivo = usePermission('equipos', 'crear_masivo').canAccess;
    var canExportExcel = usePermission('equipos', 'exportarExcel').canAccess;
    var canCreateSolicitudesMasivo = usePermission('solicitudes_servicio', 'crear_masivo_en_equipos').canAccess;
    var navigate = useNavigate();
    // Función para manejar la acción de continuar
    var handleContinue = function () {
        if (selectedAction === '') {
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Seleccione una opción antes de continuar',
                    severity: 'warning'
                }
            ], false); });
            return;
        }
        // Si la acción es "descargarExcel"
        if (selectedAction === 'descargarExcel') {
            exportEquiposToExcel(equipos);
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Equipos exportados a Excel exitosamente',
                    severity: 'success'
                }
            ], false); });
            onClose();
        }
        // Si la acción es "crearSolicitudesServicio"
        if (selectedAction === 'crearSolicitudesServicio') {
            if (!equiposSelected) {
                setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                    {
                        id: new Date().getTime(),
                        message: 'No hay Equipos Seleccionados',
                        severity: 'warning'
                    }
                ], false); });
                return;
            }
            // Si hay equipos seleccionados, mostrar el formulario de creación masiva
            setShowMassiveForm(true);
        }
        // Redirección para "crearEquiposMasivo"
        if (selectedAction === 'crearEquiposMasivo') {
            navigate('/equipos/create-masivo');
            onClose(); // Opcional: cierra el modal después de redirigir
        }
    };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    // Si se selecciona la opción de crear solicitudes masivas, renderizar el componente
    if (showMassiveForm) {
        return (_jsx(CreateSolicitudesServicioMassiveForEquipos, { equipos: equipos, onCancel: function () { return setShowMassiveForm(false); } }));
    }
    return (isOpen ? (_jsx("div", { className: "ActionsComponent-modal-overlay", children: _jsxs("div", { className: "ActionsComponent-modal-content", children: [_jsx("h2", { className: "ActionsComponent-title", children: "ACCIONES - EQUIPOS" }), _jsxs("select", { className: "ActionsComponent-select", value: selectedAction, onChange: function (e) { return setSelectedAction(e.target.value); }, disabled: isLoading, children: [_jsx("option", { value: "", disabled: true, children: "Seleccionar..." }), canExportExcel && (_jsx("option", { value: "descargarExcel", children: "Descargar Excel" })), canCreateMasivo && (_jsx("option", { value: "crearEquiposMasivo", children: "Crear Equipos - Masivo" })), canCreateSolicitudesMasivo && (_jsx("option", { value: "crearSolicitudesServicio", children: "Crear Solicitudes Servicio - Equipos Seleccionados" }))] }), _jsxs("div", { className: 'ActionsComponent-buttons-div', children: [_jsx("button", { className: "ActionsComponent-continue-button", onClick: handleContinue, disabled: isLoading, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : 'Continuar' }), _jsx("button", { className: "ActionsComponent-cancel-button", onClick: onClose, disabled: isLoading, children: "Cancelar" })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }) })) : null);
};
export default EquiposActionsComponent;
