import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './styles/SearchOrdenes.css';
var SearchOrdenes = function (_a) {
    var handleSearch = _a.handleSearch;
    var _b = useState(''), keyword = _b[0], setKeyword = _b[1];
    var _c = useState(false), showInput = _c[0], setShowInput = _c[1];
    var handleSubmit = function (e) {
        e.preventDefault();
        handleSearch(keyword);
    };
    var toggleInput = function () {
        setShowInput(!showInput);
        setKeyword(''); // Clear the input when toggling
    };
    return (_jsx("div", { className: "SearchOrdenes-search-container", children: _jsxs("form", { onSubmit: handleSubmit, className: "search-form ".concat(showInput ? 'expanded' : ''), children: [_jsx(IconButton, { className: "SearchOrdenes-search-icon-button", type: "button", "aria-label": "search", onClick: toggleInput, children: showInput ? _jsx(CloseIcon, { className: "SearchOrdenes-search-icon" }) : _jsx(SearchIcon, { className: "SearchOrdenes-search-icon" }) }), _jsx("input", { type: "text", placeholder: "SEARCH...", className: "SearchOrdenes-search-input ".concat(showInput ? 'visible' : ''), value: keyword, onChange: function (e) { return setKeyword(e.target.value); } })] }) }));
};
export default SearchOrdenes;
