import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
var FiltersInputsCotizacionesComponent = function (_a) {
    var filters = _a.filters, selectedFields = _a.selectedFields, handleFilterChange = _a.handleFilterChange, handleRemoveFilter = _a.handleRemoveFilter, loading = _a.loading, results = _a.results, isEditing = _a.isEditing, token = _a.token, CotizacionPageType = _a.CotizacionPageType;
    var handleEstadoChange = function (index, e) {
        var selectedValue = e.target.value;
        handleFilterChange(index, 'id_estado.estado', selectedValue);
    };
    return (_jsx("ul", { className: "AdvancedFilters-filter-ul", children: filters.map(function (filter, index) { return (_jsxs("li", { className: "AdvancedFilters-filter-li", children: [selectedFields.includes('ID') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsx("input", { type: "text", className: "AdvancedFilters-id-input", value: filter._id || '', pattern: "[0-9a-fA-F]{0,24}", maxLength: 24, onChange: function (e) { return handleFilterChange(index, '_id', e.target.value); }, disabled: !isEditing }), _jsx("div", { className: "AdvancedFilters-id-title", children: "ID" })] })), selectedFields.includes('ESTADO') && (_jsxs("div", { className: "AdvancedFilters-id-parameter", children: [_jsxs("select", { className: "AdvancedFilters-id-input", value: CotizacionPageType === 'Cotizaciones-Pendientes'
                                ? 'Pendiente'
                                : CotizacionPageType === 'Cotizaciones-Aprobadas'
                                    ? 'Aprobada'
                                    : CotizacionPageType === 'Cotizaciones-Rechazadas'
                                        ? 'Rechazada'
                                        : filter['id_estado.estado'] || '', onChange: function (e) { return handleEstadoChange(index, e); }, disabled: !isEditing ||
                                CotizacionPageType === 'Cotizaciones-Pendientes' ||
                                CotizacionPageType === 'Cotizaciones-Aprobadas' ||
                                CotizacionPageType === 'Cotizaciones-Rechazadas', children: [_jsx("option", { value: "", disabled: true, children: "Seleccione un estado" }), _jsx("option", { value: "Pendiente", children: "Pendiente" }), _jsx("option", { value: "Aprobada", children: "Aprobada" }), _jsx("option", { value: "Rechazada", children: "Rechazada" })] }), _jsx("div", { className: "AdvancedFilters-id-title", children: "ESTADO" })] })), loading[index] ? (_jsx(CircularProgress, { className: "AdvancedFilters-loading-icon" })) : results[index] === true ? (_jsx(CheckCircleIcon, { className: "AdvancedFilters-check-icon" })) : results[index] === false ? (_jsx(CancelIcon, { className: "AdvancedFilters-error-icon" })) : (isEditing && _jsx(CancelIcon, { className: "AdvancedFilters-delete-icon", onClick: function () { return handleRemoveFilter(index); } })), _jsx("div", { className: "AdvancedFilters-separator" })] }, index)); }) }));
};
export default FiltersInputsCotizacionesComponent;
