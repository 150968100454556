var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { createVisita } from '../../../services/visitasService';
import './styles/RegisterVisitaOrden.css';
import { searchUsersByKeyword } from '../../../../users/services/usersService';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAllProtocolos } from '../../../services/protocolosService';
import { getOrdenById, updateOrden } from '../../../../ordenes/services/ordenesService';
import { CircularProgress, Box } from '@mui/material';
import MuiAlertComponent from '../../../../../components/MuiAlertsComponent';
var RegisterVisitaOrden = function (_a) {
    var onCancel = _a.onCancel, idOrden = _a.idOrden, onReloadNeeded = _a.onReloadNeeded, ordenType = _a.ordenType;
    var loggedIn = useSessionStorage('sessionJWTToken');
    var userId = useSessionStorage('userId');
    var userName = useSessionStorage('userName');
    var estadoAprobadaId = "67a3e3a700d5e1b2f9cc587c";
    var now = new Date();
    var fechaCreacion = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    useEffect(function () {
        setVisitaData(function (prevData) { return (__assign(__assign({}, prevData), { id_orden: idOrden, id_creador: userId, id_visita_estado: estadoAprobadaId })); });
    }, [idOrden, userId, estadoAprobadaId]);
    var _b = useState({
        id_visita_estado: estadoAprobadaId,
        id_responsable: '',
        id_creador: userId,
        ids_protocolos: [],
        id_orden: idOrden,
        fecha_inicio: '',
        ejecutar_sede: false,
        duracion: '',
        fecha_creacion: fechaCreacion,
        fecha_aprobacion: fechaCreacion,
        id_aprobador: userId,
        observacion_aprobacion: "Visita Aprobada y Agendada por ".concat(userName)
    }), visitaData = _b[0], setVisitaData = _b[1];
    // USER STATES
    var _c = useState(''), keyword = _c[0], setKeyword = _c[1];
    var _d = useState([]), searchResults = _d[0], setSearchResults = _d[1];
    var _e = useState(null), selectedUser = _e[0], setSelectedUser = _e[1];
    // PROTOCOLOS STATES
    var _f = useState([]), protocolos = _f[0], setProtocolos = _f[1];
    var _g = useState([]), selectedProtocolos = _g[0], setSelectedProtocolos = _g[1];
    var _h = useState(''), duracion = _h[0], setDuracion = _h[1];
    var _j = useState(''), visitaId = _j[0], setVisitaId = _j[1];
    var _k = useState(true), loading = _k[0], setLoading = _k[1];
    var _l = useState([]), alerts = _l[0], setAlerts = _l[1];
    var _m = useState(false), isSubmitting = _m[0], setIsSubmitting = _m[1]; // Estado para deshabilitar el botón de registrar
    useEffect(function () {
        var timer = setTimeout(function () {
            setLoading(false);
        }, 1000);
        return function () { return clearTimeout(timer); };
    }, []);
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        if (name === 'id_responsable') {
            setKeyword(value); // Actualizamos la palabra clave de búsqueda solo para el campo de búsqueda de usuarios
        }
        setVisitaData(__assign(__assign({}, visitaData), (_a = {}, _a[name] = value, _a))); // Actualizamos el estado visitaData para otros campos
    };
    var handleUserClick = function (user) {
        setSelectedUser(user);
        setVisitaData(__assign(__assign({}, visitaData), { id_responsable: user._id }));
        setKeyword(''); // Limpiamos la palabra clave después de seleccionar un usuario
        setSearchResults([]); // Limpiamos los resultados de búsqueda después de seleccionar un usuario
    };
    var handleCancelUser = function () {
        setSelectedUser(null);
        setVisitaData(__assign(__assign({}, visitaData), { id_responsable: '' }));
    };
    var handleProtocoloChange = function (e) {
        var selectedProtocoloId = e.target.value;
        setSelectedProtocolos(__spreadArray(__spreadArray([], selectedProtocolos, true), [selectedProtocoloId], false));
        setVisitaData(__assign(__assign({}, visitaData), { ids_protocolos: __spreadArray(__spreadArray([], selectedProtocolos, true), [selectedProtocoloId], false) }));
    };
    var removeProtocolo = function (id) {
        var updatedProtocolos = selectedProtocolos.filter(function (protocoloId) { return protocoloId !== id; });
        setSelectedProtocolos(updatedProtocolos);
        setVisitaData(__assign(__assign({}, visitaData), { ids_protocolos: updatedProtocolos }));
    };
    var handleChangeDuracion = function (e) {
        var value = e.target.value;
        setDuracion(value);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var errores, token, nuevaVisita, ordenActual, oidsVisitasExistentes, nuevaOrdenData, error_1, errorMessage_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    errores = [];
                    // Realizamos las validaciones y acumulamos los mensajes de error
                    if (!selectedUser) {
                        errores.push('Seleccione la persona encargada de ejecutar la visita.');
                    }
                    if (selectedProtocolos.length === 0) {
                        errores.push('Seleccione al menos un protocolo.');
                    }
                    if (!visitaData.fecha_inicio) {
                        errores.push('Seleccione la fecha de inicio.');
                    }
                    if (!visitaData.duracion) {
                        errores.push('Ingrese la duración estimada de la visita.');
                    }
                    // Verificamos si hay errores
                    if (errores.length > 0) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            { id: new Date().getTime(), message: "Todos los campos son obligatorios", severity: "warning" }
                        ], false); });
                        return [2 /*return*/];
                    }
                    setIsSubmitting(true); // Deshabilitamos el botón de registrar
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        { id: new Date().getTime(), message: "Registrando...", severity: "info" }
                    ], false); });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, 8, 9]);
                    token = loggedIn;
                    return [4 /*yield*/, createVisita(token, visitaData)];
                case 2:
                    nuevaVisita = _a.sent();
                    if (!(nuevaVisita && nuevaVisita.visitaId)) return [3 /*break*/, 5];
                    return [4 /*yield*/, getOrdenById(token, idOrden)];
                case 3:
                    ordenActual = _a.sent();
                    oidsVisitasExistentes = ordenActual.ids_visitas.map(function (visita) { return visita._id; });
                    nuevaOrdenData = {
                        ids_visitas: __spreadArray(__spreadArray([], oidsVisitasExistentes, true), [nuevaVisita.visitaId], false)
                    };
                    return [4 /*yield*/, updateOrden(token, idOrden, nuevaOrdenData)];
                case 4:
                    _a.sent();
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        { id: new Date().getTime(), message: "Visita Registrada Correctamente", severity: "success" }
                    ], false); });
                    // Después de mostrar la alerta de éxito, recargamos la página
                    setTimeout(function () {
                        onReloadNeeded(); // Llamar a la función para refrescar los datos
                    }, 1000); // Esperamos 2 segundos antes de recargar la página
                    return [3 /*break*/, 6];
                case 5: throw new Error('Error al obtener el ID de la nueva visita.');
                case 6: return [3 /*break*/, 9];
                case 7:
                    error_1 = _a.sent();
                    errorMessage_1 = error_1 instanceof Error ? error_1.message : 'Error desconocido';
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        { id: new Date().getTime(), message: "Hubo un error al crear la visita: ".concat(errorMessage_1), severity: "error" }
                    ], false); });
                    return [3 /*break*/, 9];
                case 8:
                    setIsSubmitting(false); // Habilitamos el botón de registrar después del éxito o error
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var handleCancel = function () {
        onCancel(); // Llama a la función de control pasada desde VisitasOrden.tsx
    };
    useEffect(function () {
        var fetchUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, results, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, searchUsersByKeyword(token, keyword)];
                    case 1:
                        results = _a.sent();
                        setSearchResults(results);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error al buscar usuarios:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (keyword.trim() !== '') {
            fetchUsers();
        }
        else {
            setSearchResults([]);
        }
    }, [keyword, loggedIn]);
    useEffect(function () {
        var fetchProtocolos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, protocolosData, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, getAllProtocolos(token)];
                    case 1:
                        protocolosData = _a.sent();
                        setProtocolos(protocolosData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.error('Error al obtener los protocolos:', error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchProtocolos();
    }, [loggedIn]);
    if (loading) {
        return (_jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", children: _jsx(CircularProgress, { style: { color: '#4F7A76' } }) }));
    }
    return (_jsxs("form", { className: 'RegisterVisitaOrden-div', onSubmit: handleSubmit, children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: function (id) { return setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); }); } }), _jsx("header", { className: "RegisterVisitaOrden-header", children: _jsx("div", { className: "RegisterVisitaOrden-overlap-group", children: _jsx("div", { className: "RegisterVisitaOrden-register-title", children: "REGISTRAR NUEVA VISITA" }) }) }), _jsxs("div", { className: "RegisterVisitaOrden-overlap", children: [_jsxs("div", { className: "RegisterVisitaOrden-user-div", children: [_jsx("p", { className: "RegisterVisitaOrden-text-wrapper", children: "1. Seleccione la persona encargada de ejecutar la visita: *" }), _jsx("input", { className: "RegisterVisitaOrden-rectangle", type: "text", name: "id_responsable", value: selectedUser ? selectedUser._id : visitaData.id_responsable, onChange: handleChange, placeholder: "Buscar..." }), selectedUser && (_jsxs("div", { className: "RegisterVisitaOrden-user-pick", children: [selectedUser.username, _jsx(CancelIcon, { className: 'RegisterVisitaOrden-user-selected-cancel', onClick: handleCancelUser })] })), searchResults.length > 0 && (_jsx("ul", { className: 'RegisterVisitaOrden-users-ul', children: searchResults.map(function (user) { return (_jsx("li", { className: "RegisterVisitaOrden-users-listed", onClick: function () { return handleUserClick(user); }, children: user.username }, user._id)); }) }))] }), _jsxs("div", { className: "RegisterVisitaOrden-protocolos-div", children: [_jsx("p", { className: "RegisterVisitaOrden-text-wrapper", children: "2. Seleccione las actividades a programar: *" }), _jsxs("select", { className: "RegisterVisitaOrden-rectangle", name: "ids_protocolos", onChange: handleProtocoloChange, children: [_jsx("option", { value: "", disabled: true, selected: true, children: "Seleccionar" }), protocolos
                                        .filter(function (protocolo) {
                                        if (ordenType === 'Preventivo') {
                                            return ['Técnico en sede', 'Equipo disponible', 'Imagen'].includes(protocolo.title);
                                        }
                                        if (ordenType === 'Correctivo') {
                                            return ['Técnico en sede', 'Equipo disponible', 'Concepto técnico', 'Imagen'].includes(protocolo.title);
                                        }
                                        return true; // Si ordenType no es "Preventivo" ni "Correctivo", incluir todos los protocolos
                                    })
                                        .map(function (protocolo) { return (_jsx("option", { value: protocolo._id, children: protocolo.title }, protocolo._id)); })] }), _jsx("ul", { className: "RegisterVisitaOrden-protocolos-listed", children: selectedProtocolos.map(function (protocoloId) {
                                    var _a;
                                    return (_jsxs("li", { className: "RegisterVisitaOrden-img", children: [(_a = protocolos.find(function (protocolo) { return protocolo._id === protocoloId; })) === null || _a === void 0 ? void 0 : _a.title, _jsx(CancelIcon, { className: "RegisterVisitaOrden-protocolo-selected-cancel", onClick: function () { return removeProtocolo(protocoloId); } })] }, protocoloId));
                                }) })] }), _jsx("div", { className: "RegisterVisitaOrden-separator" }), _jsxs("div", { className: "RegisterVisitaOrden-fecha-div", children: [_jsx("div", { className: "RegisterVisitaOrden-text-wrapper-2", children: "3. Seleccione fecha de inicio: *" }), _jsx("input", { className: "RegisterVisitaOrden-div-2", type: "datetime-local", name: "fecha_inicio", value: visitaData.fecha_inicio, onChange: handleChange })] }), _jsxs("div", { className: "RegisterVisitaOrden-insede-div", children: [_jsx("div", { className: "RegisterVisitaOrden-text-wrapper-2", children: "4. Ejecutar en sede: *" }), _jsxs("label", { className: "RegisterVisitaOrden-switch", children: [_jsx("input", { className: 'RegisterVisitaOrden-ejecutar-sede-input', type: "checkbox", name: "ejecutar_sede", checked: visitaData.ejecutar_sede, onChange: function (e) { return setVisitaData(__assign(__assign({}, visitaData), { ejecutar_sede: e.target.checked })); } }), _jsx("span", { className: "RegisterVisitaOrden-slider round" })] })] }), _jsxs("div", { className: "RegisterVisitaOrden-time-div", children: [_jsx("div", { className: "RegisterVisitaOrden-text-wrapper-2", children: "5. Duraci\u00F3n estimada (minutos): *" }), _jsx("input", { className: "RegisterVisitaOrden-div-2", type: "text", name: "duracion", value: visitaData.duracion, onChange: handleChange })] })] }), _jsxs("div", { className: "RegisterVisitaOrden-button-container", children: [_jsx("button", { type: "submit", className: "RegisterVisitaOrden-btn-register", disabled: isSubmitting, children: isSubmitting ? 'Registrando...' : 'Registrar' }), _jsx("button", { type: "button", className: "RegisterVisitaOrden-btn-cancel", onClick: handleCancel, children: "Cancelar" })] })] }));
};
export default RegisterVisitaOrden;
