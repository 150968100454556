var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { updateOrden, generatePresignedUrlForFirmaEntrega, generatePresignedUrlForFirmaRecibe, generateGetPresignedUrlForFirmaOrden } from '../../services/ordenesService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import './styles/FirmasOrdenModalSection.css';
import { generateGetPresignedUrlForUserFirma, getUserById } from '../../../users/services/usersService';
var FirmasOrdenModalSection = function (_a) {
    var idOrden = _a.idOrden, orden = _a.orden, onClose = _a.onClose, onUpdate = _a.onUpdate;
    var _b = useState(''), firmaEntrega = _b[0], setFirmaEntrega = _b[1];
    var _c = useState(''), firmaRecibe = _c[0], setFirmaRecibe = _c[1];
    var _d = useState(false), firmaEntregaGuardada = _d[0], setFirmaEntregaGuardada = _d[1];
    var _e = useState(false), firmaRecibeGuardada = _e[0], setFirmaRecibeGuardada = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var _g = useState(''), error = _g[0], setError = _g[1];
    var _h = useState([]), alerts = _h[0], setAlerts = _h[1];
    var _j = useState(false), isEditing = _j[0], setIsEditing = _j[1];
    var _k = useState(false), isNew = _k[0], setIsNew = _k[1];
    var _l = useState(false), isEditingFirmaEntrega = _l[0], setIsEditingFirmaEntrega = _l[1];
    var _m = useState(false), isEditingFirmaRecibe = _m[0], setIsEditingFirmaRecibe = _m[1];
    var userId = useSessionStorage('userId');
    var token = useSessionStorage('sessionJWTToken');
    var userName = useSessionStorage('userName');
    var _o = useState(''), nombreRecibe = _o[0], setNombreRecibe = _o[1];
    var _p = useState(''), cedulaRecibe = _p[0], setCedulaRecibe = _p[1];
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: message, severity: severity }], false); });
    };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    useEffect(function () {
        var _a, _b, _c;
        if (orden) {
            if ((_a = orden.entrega) === null || _a === void 0 ? void 0 : _a.firma_entrega) {
                // Si la orden ya tiene asociada una firma_entrega
                generateGetPresignedUrlForFirmaOrden(token, orden.entrega.firma_entrega)
                    .then(function (url) {
                    setFirmaEntrega(url);
                    setFirmaEntregaGuardada(true);
                })
                    .catch(function (err) { return console.error("Error al obtener la URL de la firma de entrega:", err); });
            }
            else {
                // Obtener `firma_image` del usuario
                getUserById(token, userId)
                    .then(function (response) {
                    var userFirmaImage = response.data.firma_image;
                    if (userFirmaImage) {
                        // Si existe `firma_image`, obtener la firma del usuario desde S3
                        generateGetPresignedUrlForUserFirma(token, userFirmaImage)
                            .then(function (url) {
                            setFirmaEntrega(url);
                            setFirmaEntregaGuardada(true);
                        })
                            .catch(function (err) { return console.error("Error al obtener la URL de la firma del usuario:", err); });
                    }
                    else {
                        // No hay firma disponible
                        setFirmaEntrega('');
                        setFirmaEntregaGuardada(false);
                    }
                })
                    .catch(function (err) { return console.error("Error al obtener los datos del usuario:", err); });
            }
            // Lógica para firma_recibe (sin cambios)
            if (orden.recibe) {
                if (orden.recibe.firma_recibe) {
                    generateGetPresignedUrlForFirmaOrden(token, orden.recibe.firma_recibe)
                        .then(function (url) { return setFirmaRecibe(url); })
                        .catch(function (err) { return console.error("Error al obtener la URL de la firma de recibe:", err); });
                }
                setFirmaRecibeGuardada(!!orden.recibe.firma_recibe);
                setNombreRecibe(orden.recibe.nombre_recibe || '');
                setCedulaRecibe(orden.recibe.cedula_recibe || '');
            }
            // Determinar si es una orden nueva
            setIsNew(!((_b = orden.entrega) === null || _b === void 0 ? void 0 : _b.firma_entrega) && !((_c = orden.recibe) === null || _c === void 0 ? void 0 : _c.firma_recibe));
        }
    }, [orden, token, userId]);
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var firmaUrls, blob, fileName, presignedUrl, blob, fileName, presignedUrl, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if ((isEditingFirmaEntrega && !firmaEntregaGuardada) || (isEditingFirmaRecibe && !firmaRecibeGuardada)) {
                        addAlert('Debe guardar las firmas antes de guardar cambios', 'error');
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    setError('');
                    if (!token) {
                        setError('No estás autenticado');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 11, , 12]);
                    firmaUrls = {
                        entrega: {
                            nombre_entrega: userName,
                            cedula_entrega: '10101010101',
                        },
                        recibe: {
                            nombre_recibe: nombreRecibe,
                            cedula_recibe: cedulaRecibe,
                        },
                    };
                    if (!firmaEntrega) return [3 /*break*/, 5];
                    return [4 /*yield*/, fetch(firmaEntrega).then(function (res) { return res.blob(); })];
                case 2:
                    blob = _a.sent();
                    fileName = "firma-entrega-".concat(userId, "-").concat(Date.now(), ".png");
                    return [4 /*yield*/, generatePresignedUrlForFirmaEntrega(token, fileName)];
                case 3:
                    presignedUrl = _a.sent();
                    return [4 /*yield*/, fetch(presignedUrl, {
                            method: 'PUT',
                            headers: { 'Content-Type': 'image/png' },
                            body: blob,
                        })];
                case 4:
                    _a.sent();
                    firmaUrls.entrega.firma_entrega = presignedUrl.split('?')[0];
                    _a.label = 5;
                case 5:
                    if (!firmaRecibe) return [3 /*break*/, 9];
                    return [4 /*yield*/, fetch(firmaRecibe).then(function (res) { return res.blob(); })];
                case 6:
                    blob = _a.sent();
                    fileName = "firma-recibe-".concat(userId, "-").concat(Date.now(), ".png");
                    return [4 /*yield*/, generatePresignedUrlForFirmaRecibe(token, fileName)];
                case 7:
                    presignedUrl = _a.sent();
                    return [4 /*yield*/, fetch(presignedUrl, {
                            method: 'PUT',
                            headers: { 'Content-Type': 'image/png' },
                            body: blob,
                        })];
                case 8:
                    _a.sent();
                    firmaUrls.recibe.firma_recibe = presignedUrl.split('?')[0];
                    _a.label = 9;
                case 9: return [4 /*yield*/, updateOrden(token, idOrden, firmaUrls)];
                case 10:
                    _a.sent();
                    onUpdate();
                    addAlert('Firmas subidas y orden actualizada correctamente', 'success');
                    setIsEditing(false);
                    setIsEditingFirmaEntrega(false);
                    setIsEditingFirmaRecibe(false);
                    return [3 /*break*/, 12];
                case 11:
                    error_1 = _a.sent();
                    console.error('Error al subir las firmas y actualizar la orden:', error_1);
                    setError('Ocurrió un error al subir las firmas y actualizar la orden');
                    setLoading(false);
                    return [3 /*break*/, 12];
                case 12: return [2 /*return*/];
            }
        });
    }); };
    var _q = useState(null), signaturePadEntrega = _q[0], setSignaturePadEntrega = _q[1];
    var _r = useState(null), signaturePadRecibe = _r[0], setSignaturePadRecibe = _r[1];
    var guardarFirmaEntrega = function () {
        if (signaturePadEntrega) {
            var firmaImagen = signaturePadEntrega.toDataURL('image/png');
            setFirmaEntrega(firmaImagen);
            setFirmaEntregaGuardada(true);
            setIsEditingFirmaEntrega(false);
        }
    };
    var limpiarFirmaEntrega = function () {
        setFirmaEntrega('');
        setFirmaEntregaGuardada(false);
        setIsEditingFirmaEntrega(true);
    };
    var guardarFirmaRecibe = function () {
        if (signaturePadRecibe) {
            var firmaImagen = signaturePadRecibe.toDataURL('image/png');
            setFirmaRecibe(firmaImagen);
            setFirmaRecibeGuardada(true);
            setIsEditingFirmaRecibe(false);
        }
    };
    var limpiarFirmaRecibe = function () {
        setFirmaRecibe('');
        setFirmaRecibeGuardada(false);
        setIsEditingFirmaRecibe(true);
    };
    return (_jsxs("div", { className: "FirmasOrdenModalSection-modal-overlay", children: [_jsxs("div", { className: "FirmasOrdenModalSection-modal-content", children: [_jsx("h3", { className: 'FirmasOrdenModalSection-modal-content-title', children: "Firmas de la Orden" }), _jsx("div", { className: 'FirmasOrdenModalSection-nombre-entrega-title', children: "Nombre Entrega" }), _jsx("div", { className: 'FirmasOrdenModalSection-nombre-entrega-value', children: userName }), _jsx("div", { className: 'FirmasOrdenModalSection-cedula-entrega-title', children: "Cedula Entrega" }), _jsx("div", { className: 'FirmasOrdenModalSection-cedula-entrega-value', children: "10101010101" }), _jsxs("div", { className: "FirmasOrdenModalSection-firma", children: [_jsx("div", { className: "FirmasOrdenModalSection-firma-title", children: "Firma Entrega" }), firmaEntrega ? (
                            // Renderizar la firma como imagen si está disponible
                            _jsx("img", { src: firmaEntrega, alt: "Firma Entrega", className: "FirmasOrdenModalSection-firma-img" })) : (
                            // Renderizar mensaje si no hay firma disponible
                            _jsx("p", { className: "FirmasOrdenModalSection-no-firma-message", children: "Este usuario a\u00FAn no cuenta con una firma. Por favor, dir\u00EDjase al apartado \"Mi Perfil\" y cree su firma." }))] }), _jsx("div", { className: 'FirmasOrdenModalSection-nombre-recibe-title', children: "Nombre Recibe" }), _jsx("input", { className: 'FirmasOrdenModalSection-nombre-recibe-value', value: nombreRecibe, onChange: function (e) { return setNombreRecibe(e.target.value); }, disabled: !isNew && !isEditing }), _jsx("div", { className: 'FirmasOrdenModalSection-cedula-recibe-title', children: "Cedula Recibe" }), _jsx("input", { className: 'FirmasOrdenModalSection-cedula-recibe-value', value: cedulaRecibe, onChange: function (e) { return setCedulaRecibe(e.target.value); }, disabled: !isNew && !isEditing }), _jsxs("div", { className: "FirmasOrdenModalSection-firma", children: [_jsx("div", { className: "FirmasOrdenModalSection-firma-title", children: "Firma Recibe" }), firmaRecibe && !isEditingFirmaRecibe ? (_jsx("img", { src: firmaRecibe, alt: "Firma Recibe", className: "FirmasOrdenModalSection-firma-img" })) : (_jsx(SignaturePad, { penColor: "black", canvasProps: {
                                    className: "FirmasOrdenModalSection-firma-signatureCanvas",
                                }, ref: function (ref) { return setSignaturePadRecibe(ref); } })), _jsx(SaveAltIcon, { className: "FirmasOrdenModalSection-firma-save-icon ".concat(firmaRecibeGuardada && !isEditingFirmaRecibe ? 'FirmasOrdenModalSection-firma-save-icon-active' : ''), onClick: guardarFirmaRecibe }), (isNew || isEditing) && (_jsx(CleaningServicesIcon, { className: "FirmasOrdenModalSection-firma-clear-icon", onClick: limpiarFirmaRecibe }))] }), _jsx("button", { className: "FirmasOrdenModalSection-submit-button", onClick: isNew || isEditing ? handleSubmit : function () { return setIsEditing(true); }, disabled: loading, children: loading ? 'Cargando...' : isNew || isEditing ? 'Guardar Cambios' : 'Editar' }), _jsx("button", { className: "FirmasOrdenModalSection-cancel-button", onClick: onClose, disabled: loading, children: "Cancelar" }), error && _jsx("p", { className: "FirmasOrdenModalSection-error-message", children: error })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }));
};
export default FirmasOrdenModalSection;
