import axios from '../../../utils/config/axios.config';
export var getAllFallosSistemas = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/fallo-sistemas', options)
        .then(function (response) { return response.data.falloSistemas; })
        .catch(handleError);
};
export var getFalloSistemaById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/fallo-sistemas/".concat(id), options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createFalloSistema = function (token, falloSistemaData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/fallo-sistemas', falloSistemaData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateFalloSistema = function (token, id, falloSistemaData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/fallo-sistemas?id=".concat(id), falloSistemaData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteFalloSistemaById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/fallo-sistemas/".concat(id), options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            console.log(error);
        }
    }
    throw error;
};
