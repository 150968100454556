var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from '../../../utils/config/axios.config';
/***
 *
 * @param {string} username to login
 * @param {string} password to log in
 */
export var login = function (username, password) {
    // Declare Body to POST
    var body = {
        username: username,
        password: password
    };
    // Send POST request to login endpoint
    // https://localhost:8080/api/auth/login
    return axios.post('/auth/login', body);
};
export var logoutService = function () {
    sessionStorage.removeItem('sessionJWTToken');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('userRoles');
    window.location.href = '/login';
};
/***
 *
 * @param {number} number to register
 * @param {string} username to register
 * @param {string} password to register
 * @param {string} name to register
 * @param {number} cedula to register
 * @param {string} telefono to register
 * @param {string} email to register
 * @param {string} more_info to register
 */
export var register = function (username, password, name, cedula, telefono, email, more_info, roles, type, // Campo opcional: type
titulo, // Campo opcional: titulo
reg_invima // Campo opcional: reg_invima
) {
    // Declarar un tipo explícito para additionalFields
    var additionalFields = {};
    if (type) {
        additionalFields.type = type;
    }
    if (titulo) {
        additionalFields.titulo = titulo;
    }
    if (reg_invima) {
        additionalFields.reg_invima = reg_invima;
    }
    // Combinar los campos adicionales con el resto de la solicitud
    var body = __assign({ username: username, password: password, name: name, cedula: cedula, telefono: telefono, email: email, more_info: more_info, roles: roles }, additionalFields);
    // Enviar POST request al endpoint de registro
    return axios.post('/auth/register', body).catch(function (error) {
        if (error.response) {
            var status_1 = error.response.status;
            if (status_1 === 500) {
                // Token inválido o expirado
                // Redirigir al usuario a la página de inicio de sesión (/login)
                window.location.href = '/login';
                window.alert("An error ocurred while registering new user");
            }
        }
        throw error;
    });
};
export var forgotPassword = function (email) {
    var body = {
        email: email
    };
    return axios.post('/auth/forgot-password', body);
};
export var validateOTP = function (email, otp) {
    var body = {
        email: email,
        otp: otp,
    };
    return axios.post('/auth/otp-validator', body);
};
export var updatePassword = function (email, newPassword) {
    var body = {
        email: email,
        newPassword: newPassword
    };
    return axios.put('/auth/update-password', body, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};
export var emailContactClient = function (name, email, message) {
    var body = { name: name, email: email, message: message };
    return axios.post('/auth/email-contact-client', body)
        .catch(function (error) {
        // Manejo de errores similar a las otras funciones
        if (error.response) {
            var status_2 = error.response.status;
            if (status_2 === 500) {
                window.alert("Ocurrió un error al enviar la solicitud de contacto");
            }
        }
        throw error;
    });
};
