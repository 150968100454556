var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import '../../../../components/filters/styles/ActionsComponent.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { exportCostosEquiposToExcel } from '../../services/exportCostosEquiposExcelService';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePermission } from "../../../../hooks/usePermission";
var CostosEquiposActionsComponent = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, costosEquipos = _a.costosEquipos, selectMode = _a.selectMode, costosEquiposSelected = _a.costosEquiposSelected;
    var _b = useState(''), selectedAction = _b[0], setSelectedAction = _b[1];
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var navigate = useNavigate();
    var handleContinue = function () {
        if (selectedAction === '') {
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Seleccione una opción antes de continuar',
                    severity: 'warning',
                },
            ], false); });
            return;
        }
        if (selectedAction === 'descargarExcel') {
            exportCostosEquiposToExcel(costosEquipos);
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Costos de equipos exportados a Excel exitosamente',
                    severity: 'success',
                },
            ], false); });
            onClose();
        }
        if (selectedAction === 'crearCostosEquiposMasivo') {
            navigate('/costos-equipos/create-masivo');
            onClose();
        }
    };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    var canExportCostosEquipos = usePermission('costos_equipos', 'exportarExcel').canAccess;
    var canCreateMasivoCostosEquipos = usePermission('costos_equipos', 'crear_masivo').canAccess;
    return isOpen ? (_jsx("div", { className: "ActionsComponent-modal-overlay", children: _jsxs("div", { className: "ActionsComponent-modal-content", children: [_jsx("h2", { className: "ActionsComponent-title", children: "ACCIONES - COSTOS DE EQUIPOS" }), _jsxs("select", { className: "ActionsComponent-select", value: selectedAction, onChange: function (e) { return setSelectedAction(e.target.value); }, disabled: isLoading, children: [_jsx("option", { value: "", disabled: true, children: "Seleccionar..." }), canExportCostosEquipos && (_jsx("option", { value: "exportarExcel", children: "Exportar Costos Equipos a Excel" })), canCreateMasivoCostosEquipos && (_jsx("option", { value: "exportarExcel", children: "Crear Masivo Costos Equipos" }))] }), _jsxs("div", { className: "ActionsComponent-buttons-div", children: [_jsx("button", { className: "ActionsComponent-continue-button", onClick: handleContinue, disabled: isLoading, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : 'Continuar' }), _jsx("button", { className: "ActionsComponent-cancel-button", onClick: onClose, disabled: isLoading, children: "Cancelar" })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }) })) : null;
};
export default CostosEquiposActionsComponent;
