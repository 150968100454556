import axios from '../../../utils/config/axios.config';
// Get all contract types
export var getAllContratosTipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/contratos-tipos', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Get a contract type by ID
export var getContratoTipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/contratos-tipos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Create a new contract type
export var createContratoTipo = function (token, contratoTipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/contratos-tipos', contratoTipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Update a contract type by ID
export var updateContratoTipo = function (token, id, contratoTipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/contratos-tipos?id=".concat(id), contratoTipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Delete a contract type by ID
export var deleteContratoTipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/contratos-tipos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Handle errors in axios responses
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Token inválido o expirado
            // window.location.href = '/login';
            console.error(error);
        }
    }
    // throw error;
};
