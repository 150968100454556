var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../hooks/useSessionStorage';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import EditIcon from '@mui/icons-material/Edit';
import './styles/CotizacionesDetailPage.css';
import CotizacionByIdPendiente from '../../ordenes/components/ordenes_servicios/CotizacionByIdPendiente';
import CotizacionByIdAprobada from '../../ordenes/components/ordenes_servicios/CotizacionByIdAprobada';
import CotizacionByIdRechazada from '../../ordenes/components/ordenes_servicios/CotizacionByIdRechazada';
import { getCotizacionById, getPresignedUrlForGetFirma } from '../services/cotizacionesService'; // Importamos también getPresignedUrlForGetFirma
import EditCotizacionOrden from '../../ordenes/components/ordenes_servicios/EditCotizacionOrden';
import { usePermission } from '../../../hooks/usePermission';
var CotizacionesDetailPage = function () {
    var _a, _b, _c, _d;
    var loggedIn = useSessionStorage('sessionJWTToken');
    var _e = useState(null), cotizacion = _e[0], setCotizacion = _e[1];
    var id = useParams().id;
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var _g = useState(false), isEditing = _g[0], setIsEditing = _g[1]; // Estado para controlar si se está editando la cotización
    var _h = useState([]), imageUrls = _h[0], setImageUrls = _h[1];
    var navigate = useNavigate();
    var canEditCotizacion = usePermission('cotizaciones', 'editar').canAccess;
    useEffect(function () {
        if (!loggedIn) {
            navigate('/login');
            return;
        }
        if (!id) {
            console.error('ID de la cotización no encontrado en la URL');
            return;
        }
        var fetchCotizacionData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, cotizacionData, urlPath, s3ObjectKey, presignedUrlResponse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        token = loggedIn;
                        return [4 /*yield*/, getCotizacionById(token, id)];
                    case 1:
                        cotizacionData = _a.sent();
                        if (!cotizacionData.firma) return [3 /*break*/, 3];
                        urlPath = new URL(cotizacionData.firma).pathname;
                        s3ObjectKey = urlPath.substring(1);
                        return [4 /*yield*/, getPresignedUrlForGetFirma(token, s3ObjectKey)];
                    case 2:
                        presignedUrlResponse = _a.sent();
                        if (presignedUrlResponse.presignedUrl) {
                            // Actualizamos la cotización con la URL firmada
                            cotizacionData.firma = presignedUrlResponse.presignedUrl;
                        }
                        _a.label = 3;
                    case 3:
                        // Actualizamos el estado con los datos de la cotización
                        setCotizacion(cotizacionData);
                        setLoading(false);
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error('Error al obtener detalles de la cotización:', error_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchCotizacionData();
    }, [loggedIn, id, navigate]);
    if (loading) {
        return (_jsx(Box, { sx: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }, children: _jsx(CircularProgress, { sx: { color: '#4F7A76' } }) }));
    }
    var handleEditClick = function () {
        setIsEditing(true);
    };
    var handleCancelEdit = function () {
        setIsEditing(false);
    };
    var onBack = function () {
        navigate(-1); // Volver a la página anterior
    };
    var onCambioSuccess = function () {
        setIsEditing(false); // Resetear el estado de edición
        // Re-fetch data or display success message if necessary
    };
    return (_jsxs("div", { className: 'CotizacionesDetailPage-container', children: [_jsx(DashboardMenuLateral, {}), _jsx("div", { className: 'CotizacionesDetailPage-visitas-container', children: _jsx("div", { className: 'CotizacionesDetailPage-details-container', children: isEditing ? (_jsx(EditCotizacionOrden // Usamos el nuevo componente aquí
                    , { idCotizacion: (cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion._id) || '', idOrden: (_a = cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_orden) === null || _a === void 0 ? void 0 : _a._id, onCotizacionSuccess: onCambioSuccess, onCancel: handleCancelEdit, idCliente: (_b = cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_cliente) === null || _b === void 0 ? void 0 : _b._id, nombreCliente: (_c = cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_cliente) === null || _c === void 0 ? void 0 : _c.client_name })) : (_jsxs(_Fragment, { children: [((_d = cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_estado) === null || _d === void 0 ? void 0 : _d.estado) === 'Pendiente' && canEditCotizacion && (_jsx(EditIcon, { className: "CotizacionesDetailPage-edit-icon", onClick: handleEditClick })), (cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_estado.estado) === 'Pendiente' && (_jsx(CotizacionByIdPendiente, { cotizacion: cotizacion, onBack: onBack, onCambioSuccess: onCambioSuccess, imageUrls: imageUrls })), (cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_estado.estado) === 'Aprobada' && (_jsx(CotizacionByIdAprobada, { cotizacion: cotizacion, onBack: onBack, imageUrls: imageUrls })), (cotizacion === null || cotizacion === void 0 ? void 0 : cotizacion.id_estado.estado) === 'Rechazada' && (_jsx(CotizacionByIdRechazada, { cotizacion: cotizacion, onBack: onBack, imageUrls: imageUrls }))] })) }) })] }));
};
export default CotizacionesDetailPage;
