var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { getPreventivoById } from '../services/preventivosService';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import { useNavigate, useParams } from 'react-router-dom';
import EditPreventivoButton from '../components/preventivos/EditPreventivoButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import './styles/PreventivoDetailPage.css';
import { usePermission } from '../../../hooks/usePermission';
var PreventivoDetailPage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var id = useParams().id;
    var _a = useState(null), preventivo = _a[0], setPreventivo = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), isEditing = _c[0], setIsEditing = _c[1];
    var navigate = useNavigate();
    useEffect(function () {
        if (!loggedIn) {
            return;
        }
        if (!id) {
            console.error('ID del preventivo no encontrado en la URL');
            return;
        }
        var fetchPreventivo = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, getPreventivoById(token, id)];
                    case 1:
                        result = _a.sent();
                        setPreventivo(result);
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error al obtener detalles del preventivo:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchPreventivo();
    }, [loggedIn, id]);
    var canEditPreventivos = usePermission('preventivos', 'editar').canAccess;
    return (_jsxs("div", { children: [_jsx(DashboardMenuLateral, {}), isEditing ? (_jsx(EditPreventivoButton, { preventivoId: id || '', onCancel: function () { return setIsEditing(false); }, initialData: preventivo })) : (_jsx("div", { className: "PreventivoDetailPage-box", children: _jsx("div", { className: "PreventivoDetailPage-preventivo-detail", children: _jsxs("div", { className: "PreventivoDetailPage-overlap-group", children: [_jsxs("div", { className: "PreventivoDetailPage-overlap", children: [_jsx("div", { className: "PreventivoDetailPage-title", children: preventivo ? preventivo.title : 'N/A' }), _jsx(CheckOutlinedIcon, { className: "PreventivoDetailPage-check-funcionamiento" }), _jsx(FlakyOutlinedIcon, { className: "PreventivoDetailPage-icon" }), _jsxs("div", { className: "PreventivoDetailPage-code", children: ["Code: ", preventivo ? preventivo.codigo : ''] }), _jsxs("div", { className: "PreventivoDetailPage-version", children: ["Versi\u00F3n: ", preventivo ? preventivo.version : ''] }), _jsxs("div", { className: "PreventivoDetailPage-date", children: ["Date: ", preventivo ? preventivo.fecha : ''] }), canEditPreventivos && (_jsx(EditOutlinedIcon, { className: "PreventivoDetailPage-edit-icon", onClick: function () { return setIsEditing(true); } })), _jsxs("div", { className: "PreventivoDetailPage-oid", children: ["ID: ", preventivo ? preventivo._id : ''] })] }), _jsxs("div", { className: "PreventivoDetailPage-div", children: [_jsx("div", { className: "PreventivoDetailPage-text-wrapper", children: "PROTOCOLOS CUALITATIVOS" }), _jsx("ul", { className: "PreventivoDetailPage-ul", children: preventivo && preventivo.cualitativo
                                            ? preventivo.cualitativo.map(function (item) { return (_jsx("li", { className: "PreventivoDetailPage-div-wrapper", children: item.title }, item._id)); })
                                            : null })] }), _jsxs("div", { className: "PreventivoDetailPage-overlap-2", children: [_jsx("div", { className: "PreventivoDetailPage-text-wrapper", children: "PROTOCOLOS DE MANTENIMIENTO" }), _jsx("ul", { className: "PreventivoDetailPage-ul", children: preventivo && preventivo.mantenimiento
                                            ? preventivo.mantenimiento.map(function (item) { return (_jsx("li", { className: "PreventivoDetailPage-div-wrapper", children: item.title }, item._id)); })
                                            : null })] }), _jsxs("div", { className: "PreventivoDetailPage-overlap-3", children: [_jsx("div", { className: "PreventivoDetailPage-otros-name", children: "OTROS PROTOCOLOS" }), _jsxs("ul", { children: [preventivo && preventivo.otros
                                                ? preventivo.otros.map(function (item) { return (_jsx("div", { className: "PreventivoDetailPage-otros-text-wrapper", children: item ? item.title : 'N/A' }, item._id)); })
                                                : null, _jsx("li", { className: "PreventivoDetailPage-ul" })] })] }), _jsxs("div", { className: "PreventivoDetailPage-overlap-4", children: [_jsx("div", { className: "PreventivoDetailPage-cuantitativo-title", children: "PROTOCOLOS CUANTITATIVOS" }), _jsx("ul", { className: "PreventivoDetailPage-list-cuantitativo", children: preventivo && preventivo.cuantitativo
                                            ? preventivo.cuantitativo.map(function (item) { return (_jsxs("li", { className: "PreventivoDetailPage-li-cuantitativo", children: [_jsx("div", { className: 'PreventivoDetailPage-cuantitativo-text-wrapper', children: item.campo && item.campo.title ? item.campo.title : 'N/A' }), _jsx("div", { className: "PreventivoDetailPage-info", children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { className: 'PreventivoDetailPage-header', children: [_jsx("th", { className: "PreventivoDetailPage-minimo", children: "M\u00CDNIMO" }), _jsx("th", { className: "PreventivoDetailPage-maximo", children: "M\u00C1XIMO" }), _jsx("th", { className: "PreventivoDetailPage-unidad", children: "UNIDAD" })] }) }), _jsx("tbody", { children: _jsxs("tr", { className: 'PreventivoDetailPage-body', children: [_jsx("td", { className: "PreventivoDetailPage-minimo-value", children: item.minimo }), _jsx("td", { className: "PreventivoDetailPage-maximo-value", children: item.maximo }), _jsx("td", { className: "PreventivoDetailPage-unidad-value", children: item.unidad })] }) })] }) })] }, item._id)); })
                                            : null }), _jsx("br", {})] })] }) }) }))] }));
};
export default PreventivoDetailPage;
