import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import memcoLogo from '../../../../utils/img/memco-logotype.png';
import ziriuzQR from '../../../../utils/img/ziriuzqrcode.png';
var styles = StyleSheet.create({
    headerContainer: {
        height: '238px',
        width: '561px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    headerGroup: {
        position: 'relative',
        width: '561px',
        height: '238px',
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '225px',
        height: '85px',
    },
    overlapGroup: {
        backgroundColor: '#00ddfa',
        position: 'absolute',
        top: '8px',
        left: '303px',
        width: '256px',
        height: '61px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ordenTitle: {
        color: '#ffffff',
        fontFamily: 'Helvetica',
        fontSize: 24,
        fontWeight: 'normal',
    },
    memcoInfo: {
        position: 'absolute',
        top: '99px',
        left: 0,
        width: '256px',
        fontSize: 11,
        fontFamily: 'Helvetica',
        lineHeight: 1.2,
        textAlign: 'left',
    },
    ordenId: {
        position: 'absolute',
        top: '97px',
        left: '303px',
        width: '176px',
        fontSize: 11,
        fontFamily: 'Helvetica',
        textAlign: 'right',
    },
    fechaTitle: {
        position: 'absolute',
        top: '116px',
        left: '341px',
        width: '138px',
        fontSize: 11,
        fontFamily: 'Helvetica',
        textAlign: 'right',
    },
    dateCreated: {
        position: 'absolute',
        top: '135px',
        left: '341px',
        width: '138px',
        fontSize: 11,
        fontFamily: 'Helvetica',
        textAlign: 'right',
    },
    ordenEstado: {
        position: 'absolute',
        top: '152px',
        left: '341px',
        width: '138px',
        fontSize: 11,
        fontFamily: 'Helvetica',
        textAlign: 'right',
    },
    qrCode: {
        position: 'absolute',
        top: '96px',
        left: '490px',
        width: '65px',
        height: '64px',
    },
});
var OrdenPDFHeader = function (_a) {
    var orden = _a.orden;
    var cierre = (orden === null || orden === void 0 ? void 0 : orden.cierre) ? orden.cierre : 'DD/MM/AAAA 00:00:00';
    return (_jsx(View, { style: styles.headerContainer, children: _jsxs(View, { style: styles.headerGroup, children: [_jsx(Image, { style: styles.logo, src: memcoLogo }), _jsx(View, { style: styles.overlapGroup, children: _jsx(Text, { style: styles.ordenTitle, children: "ORDEN DE SERVICIO" }) }), _jsxs(Text, { style: styles.memcoInfo, children: ["MEMCO S.A.S. NIT: 900454322-1", '\n', "Bogot\u00E1: Cra. 70 # 21A - 16 / Medell\u00EDn: Cra. 50FF # 6 Sur - 75", '\n', "Cali: Av. 4N # 17N - 51 / Barranquilla: Cl. 75B # 42F - 83, Local 7", '\n', "Celular: 321 343 9040 / E-mail: info@memcosas.com"] }), _jsxs(Text, { style: styles.ordenId, children: ["ID: ", orden._id] }), _jsx(Text, { style: styles.fechaTitle, children: "Fecha de Cierre" }), _jsx(Text, { style: styles.dateCreated, children: cierre }), _jsxs(Text, { style: styles.ordenEstado, children: ["Estado: ", orden.id_orden_estado.estado] }), _jsx(Image, { style: styles.qrCode, src: ziriuzQR })] }) }));
};
export default OrdenPDFHeader;
