// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --surface-color: #ffffff;
  --curve: 20;
  --text-color:#a0a0a0;
}

.ClientsPages-container{
	width: 100%;
	height: 100%;
    position: absolute;
	}


.ClientsPages-Container-Card{
    position: fixed;
    left: 3%;
    top: 13%;
    width: 95%;
    height: 100%;
}

	
.ClientsPages-cards-list {
    padding: 1%;
    width: 100%;
    height: 86%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(34vh, 1fr));
    gap: 2%;
    row-gap: 5%;
    list-style: none;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #636b6f61 transparent;
  }
  
`, "",{"version":3,"sources":["webpack://./src/modules/users/pages/styles/ClientsPages.css"],"names":[],"mappings":"AACA;EACE,wBAAwB;EACxB,WAAW;EACX,oBAAoB;AACtB;;AAEA;CACC,WAAW;CACX,YAAY;IACT,kBAAkB;CACrB;;;AAGD;IACI,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,WAAW;IACX,WAAW;IACX,aAAa;IACb,2DAA2D;IAC3D,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,sCAAsC;EACxC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');\n:root {\n  --surface-color: #ffffff;\n  --curve: 20;\n  --text-color:#a0a0a0;\n}\n\n.ClientsPages-container{\n\twidth: 100%;\n\theight: 100%;\n    position: absolute;\n\t}\n\n\n.ClientsPages-Container-Card{\n    position: fixed;\n    left: 3%;\n    top: 13%;\n    width: 95%;\n    height: 100%;\n}\n\n\t\n.ClientsPages-cards-list {\n    padding: 1%;\n    width: 100%;\n    height: 86%;\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(34vh, 1fr));\n    gap: 2%;\n    row-gap: 5%;\n    list-style: none;\n    position: relative;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    scrollbar-width: thin;\n    scrollbar-color: #636b6f61 transparent;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
