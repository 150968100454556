import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ParticlesBackground from "./ParticlesBackground"; // Importa el fondo de partículas
import logo from "../images/hero/logoBioswfitHero.png";
import polygon32 from "../images/hero/polygon_32.png";
import polygon33 from "../images/hero/polygon_33.png";
import "../styles/hero_test.css";
export var HeroTest = function (_a) {
    var onDemoClick = _a.onDemoClick;
    return (_jsx("div", { className: "hero", children: _jsxs("div", { className: "overlap", children: [_jsx(ParticlesBackground, {}), _jsx("div", { className: "background-info", children: _jsx("div", { className: "background-info-container", children: _jsx("div", { className: "biodata", children: _jsxs("div", { className: "overlap-group", children: [_jsx("div", { className: "rectangle", children: _jsx("img", { className: "logo", alt: "Logo", src: logo }) }), _jsxs("div", { className: "info", children: [_jsx("p", { className: "phrase", children: "INNOVACI\u00D3N QUE OPTIMIZA, TECNOLOG\u00CDA QUE INSPIRA" }), _jsxs("div", { className: "demo-info", children: [_jsx("div", { className: "element-demo", onClick: onDemoClick, children: "2025 DEMO" }), _jsx("div", { className: "line" }), _jsx("div", { className: "conocer-mas", onClick: onDemoClick, children: "CONOCER MAS..." })] })] })] }) }) }) }), _jsxs("div", { className: "bg", children: [_jsx("img", { className: "polygon", alt: "Polygon", src: polygon32 }), _jsx("img", { className: "img", alt: "Polygon", src: polygon33 })] })] }) }));
};
export default HeroTest;
