var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
import { differenceInDays, parseISO } from 'date-fns';
// Function to calculate "Days of Validity" and "Days Remaining" based on the contract's dates
var calculateDiasVigencia = function (contrato) {
    if (!contrato.fecha_inicio)
        return { activeDays: 'N/A', remainingDays: 'N/A', totalDays: 'N/A' }; // If no start date, return 'N/A'
    // Get the current date and format it to match Bogotá, Colombia timezone
    var now = new Date();
    var formattedDate = new Date("".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0')));
    var startDate = parseISO(contrato.fecha_inicio);
    var endDate = contrato.fecha_finalizacion ? parseISO(contrato.fecha_finalizacion) : formattedDate;
    // Calculate the active days
    var activeDays = differenceInDays(formattedDate, startDate);
    // Calculate the remaining days
    var remainingDays = contrato.fecha_finalizacion ? differenceInDays(endDate, formattedDate) : 'N/A';
    // Calculate the total days of the contract
    var totalDays = contrato.fecha_finalizacion ? differenceInDays(endDate, startDate) : 'N/A';
    return { activeDays: activeDays, remainingDays: remainingDays, totalDays: totalDays };
};
export var exportContratosToExcel = function (contratos) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob, url, a;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('Contratos');
                // Define columns
                worksheet.columns = [
                    { header: 'ID_Contrato', key: 'id_contrato', width: 30 },
                    { header: 'Tipo', key: 'tipo', width: 30 },
                    { header: 'Creador', key: 'creador', width: 30 },
                    { header: 'Responsable', key: 'responsable', width: 30 },
                    { header: 'Vigencia', key: 'vigencia', width: 20 },
                    { header: 'Fecha Inicio', key: 'fecha_inicio', width: 20 },
                    { header: 'Fecha Finalización', key: 'fecha_finalizacion', width: 20 },
                    { header: 'Número', key: 'numero', width: 20 },
                    { header: 'Fecha Creación', key: 'date_created', width: 20 },
                    { header: 'Días Activos', key: 'dias_activos', width: 15 },
                    { header: 'Días Restantes', key: 'dias_restantes', width: 15 },
                ];
                // Header style
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' }, // Dark blue
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });
                // Add rows and apply conditional styles
                contratos.forEach(function (contrato) {
                    var _a = calculateDiasVigencia(contrato), activeDays = _a.activeDays, remainingDays = _a.remainingDays, totalDays = _a.totalDays;
                    var row = worksheet.addRow({
                        id_contrato: contrato._id || 'N/A',
                        tipo: contrato.id_contrato_tipo ? contrato.id_contrato_tipo.tipo : 'N/A',
                        creador: contrato.id_creador ? contrato.id_creador.username : 'N/A',
                        responsable: contrato.id_responsable ? contrato.id_responsable.username : 'N/A',
                        vigencia: contrato.vigencia || 'N/A',
                        fecha_inicio: contrato.fecha_inicio || 'N/A',
                        fecha_finalizacion: contrato.fecha_finalizacion || 'N/A',
                        numero: contrato.numero || 'N/A',
                        date_created: contrato.date_created || 'N/A',
                        dias_activos: activeDays,
                        dias_restantes: remainingDays,
                    });
                    // Apply borders to all cells in the row
                    row.eachCell(function (cell) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                    // Apply color to the entire row based on the contract's remaining days
                    var fillColor = '';
                    if (typeof remainingDays === 'number' && typeof totalDays === 'number') {
                        var remainingPercentage = (remainingDays / totalDays) * 100;
                        if (remainingPercentage <= 20) {
                            fillColor = 'FFFFC7CE'; // Light red (Critical)
                        }
                        else if (remainingPercentage > 20 && remainingPercentage <= 50) {
                            fillColor = 'FFFFFF99'; // Yellow (Warning)
                        }
                        else {
                            fillColor = 'FFCCFFCC'; // Soft green (Good)
                        }
                    }
                    // If a color is defined, apply it to all cells in the row
                    if (fillColor) {
                        row.eachCell(function (cell) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: fillColor },
                            };
                        });
                    }
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = window.URL.createObjectURL(blob);
                a = document.createElement('a');
                a.href = url;
                a.download = 'ContratosDeBioswift.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
                return [2 /*return*/];
        }
    });
}); };
