var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { getVisitaById } from '../services/visitasService';
import VisitaByIdPendiente from '../../ordenes/components/ordenes_servicios/VisitaByIdPendiente';
import VisitaByIdAbierta from '../../ordenes/components/ordenes_servicios/VisitaByIdAbierta';
import VisitaByIdCerrada from '../../ordenes/components/ordenes_servicios/VisitaByIdCerrada';
import VisitaByIdRechazada from '../../ordenes/components/ordenes_servicios/VisitaByIdRechazada';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import EditIcon from '@mui/icons-material/Edit';
import './styles/VisitaDetailPage.css';
import EditVisitaOrden from '../components/visitas/forms/EditVisitaOrden';
var VisitaDetailPage = function () {
    var _a;
    var loggedIn = useSessionStorage('sessionJWTToken');
    var id = useParams().id;
    var _b = useState(null), visita = _b[0], setVisita = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), isEditing = _d[0], setIsEditing = _d[1]; // Estado para controlar si se está editando la visita
    var navigate = useNavigate();
    useEffect(function () {
        if (!loggedIn) {
            navigate('/login');
            return;
        }
        if (!id) {
            console.error('ID de la visita no encontrado en la URL');
            return;
        }
        var fetchVisita = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, getVisitaById(token, id)];
                    case 1:
                        result = _a.sent();
                        setVisita(result);
                        setLoading(false);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error al obtener detalles de la visita:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchVisita();
    }, [loggedIn, id, navigate]);
    if (loading) {
        return (_jsx(Box, { sx: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }, children: _jsx(CircularProgress, { sx: { color: '#4F7A76' } }) }));
    }
    var handleEditClick = function () {
        setIsEditing(true);
    };
    var handleCancelEdit = function () {
        setIsEditing(false);
    };
    // Función para obtener la clase basada en el estado de la visita
    var getEditIconClass = function (estado) {
        switch (estado) {
            case 'Pendiente':
                return 'edit-icon-pendiente';
            case 'Abierta':
                return 'edit-icon-abierta';
            case 'Cerrada':
                return 'edit-icon-cerrada';
            case 'Rechazada':
                return 'edit-icon-rechazada';
            default:
                return 'edit-icon-default';
        }
    };
    var handleReload = function () {
        window.location.reload();
    };
    return (_jsxs("div", { className: 'VisitaDetailPage-container', children: [_jsx(DashboardMenuLateral, {}), _jsx("div", { className: 'VisitaDetailPage-visitas-container', children: isEditing ? (_jsx("div", { className: 'VisitaDetailPage-edit-section', children: _jsx(EditVisitaOrden, { onCancel: handleCancelEdit, idOrden: visita.id_orden._id, visita: visita }) })) : (_jsxs(_Fragment, { children: [!isEditing && (_jsx(EditIcon, { className: getEditIconClass(((_a = visita.id_visita_estado) === null || _a === void 0 ? void 0 : _a.estado) || 'default'), onClick: handleEditClick })), visita && visita.id_visita_estado.estado === 'Pendiente' && (_jsx(VisitaByIdPendiente, { idVisita: visita._id, onClose: function () { return navigate('/visitas'); } })), visita && visita.id_visita_estado.estado === 'Abierta' && (_jsx(VisitaByIdAbierta, { idVisita: visita._id, onClose: function () { return navigate('/visitas'); }, onReloadNeeded: handleReload })), visita && visita.id_visita_estado.estado === 'Cerrada' && (_jsx(VisitaByIdCerrada, { idVisita: visita._id, onClose: function () { return navigate('/visitas'); } })), visita && visita.id_visita_estado.estado === 'Rechazada' && (_jsx(VisitaByIdRechazada, { idVisita: visita._id, onClose: function () { return navigate('/visitas'); } }))] })) })] }));
};
export default VisitaDetailPage;
