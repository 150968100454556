import axios from '../../../utils/config/axios.config';
export var getAllCampos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/campos', options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var getCamposById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/campos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var createCampos = function (token, camposData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/campos', camposData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var updateCampos = function (token, id, camposData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/campos?id=".concat(id), camposData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
export var deleteCamposById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/campos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleRequestError(error); });
};
var handleRequestError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // window.location.href = '/login';
            console.log(error.message);
        }
    }
    throw error;
};
