import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './styles/SolicitudesServicioPagination.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
var SolicitudesServicioPagination = function (_a) {
    var currentPage = _a.currentPage, setCurrentPage = _a.setCurrentPage, totalPages = _a.totalPages;
    var handlePrevPage = function () {
        setCurrentPage(function (prev) { return (prev > 1 ? prev - 1 : prev); });
    };
    var handleNextPage = function () {
        setCurrentPage(function (prev) { return (prev < totalPages ? prev + 1 : prev); });
    };
    return (_jsxs("div", { className: 'SolicitudesServicioPagination-pagination-controls', children: [_jsx(IconButton, { className: 'SolicitudesServicioPagination-IconButton', onClick: handlePrevPage, disabled: currentPage === 1, children: _jsx(ArrowBackIosNewIcon, { className: "SolicitudesServicioPagination-ArrowBackIosNewIcon ".concat(currentPage > 1 ? 'active' : 'inactive') }) }), _jsx(IconButton, { className: 'SolicitudesServicioPagination-IconButton', onClick: handleNextPage, disabled: currentPage === totalPages, children: _jsx(ArrowForwardIosIcon, { className: "SolicitudesServicioPagination-ArrowForwardIosIcon ".concat(currentPage < totalPages ? 'active' : 'inactive') }) }), _jsxs("span", { className: 'SolicitudesServicioPagination-page-number', children: ["P\u00E1gina ", _jsx("span", { className: 'SolicitudesServicioPagination-page-number-value', children: currentPage }), " de ", _jsx("span", { className: 'SolicitudesServicioPagination-page-number-value', children: totalPages })] })] }));
};
export default SolicitudesServicioPagination;
