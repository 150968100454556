import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
var ActividadesVisitasCambiosOrden = function (_a) {
    var actividades = _a.actividades, responsableName = _a.responsableName;
    if (!actividades || actividades.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "CambiosOrden-section-title", children: "Actividades de la Visita" }), actividades.map(function (actividad, index) {
                var _a;
                return (_jsx("li", { className: "CambiosOrden-cambio-card", children: _jsxs("div", { className: "CambiosOrden-overlap-group-3", children: [_jsx("p", { className: "CambiosOrden-subestado-t", children: ((_a = actividad.id_protocolo) === null || _a === void 0 ? void 0 : _a.title) || 'N/A' }), _jsx("div", { className: "CambiosOrden-subestado-comments", children: actividad.observacion || 'N/A' }), _jsx("div", { className: "CambiosOrden-separator" }), _jsx("div", { className: "CambiosOrden-registered-by", children: "REGISTRADO POR:" }), _jsx("div", { className: "CambiosOrden-username", children: responsableName || 'N/A' }), _jsx("div", { className: "CambiosOrden-date-t", children: "EL D\u00CDA:" }), _jsx("div", { className: "CambiosOrden-date-value", children: actividad.date_created || 'N/A' })] }) }, index));
            })] }));
};
export default ActividadesVisitasCambiosOrden;
