var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { generateGetPresignedUrlsForOrdenImages, updateOrden } from '../../services/ordenesService';
import CircularProgress from '@mui/material/CircularProgress';
import './styles/ImagenesImprimirOrden.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { usePermission } from '../../../../hooks/usePermission';
var ImagenesImprimirOrden = function (_a) {
    var orden = _a.orden, onClose = _a.onClose, onUpdate = _a.onUpdate;
    var _b = useState([]), imagesWithObservations = _b[0], setImagesWithObservations = _b[1];
    var _c = useState([]), selectedImages = _c[0], setSelectedImages = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState([]), alerts = _e[0], setAlerts = _e[1];
    useEffect(function () {
        var fetchImageUrls = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, keys_1, hasImages, _i, _a, visita, _b, _c, actividad, urlPath, s3ObjectKey, response, urls, imagesWithObs, selected, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        // Limpiar las alertas cuando se monta el componente o cambia el orden
                        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.severity !== 'warning' && alert.severity !== 'error'; }); });
                        setLoading(true);
                        token = sessionStorage.getItem('sessionJWTToken') || '';
                        if (!(token && orden.ids_visitas)) return [3 /*break*/, 6];
                        keys_1 = [];
                        hasImages = false;
                        for (_i = 0, _a = orden.ids_visitas; _i < _a.length; _i++) {
                            visita = _a[_i];
                            if (visita.actividades) {
                                for (_b = 0, _c = visita.actividades; _b < _c.length; _b++) {
                                    actividad = _c[_b];
                                    if (actividad.id_image) {
                                        urlPath = new URL(actividad.id_image).pathname;
                                        s3ObjectKey = urlPath.startsWith('/') ? urlPath.substring(1) : urlPath;
                                        keys_1.push({ key: s3ObjectKey, observacion: actividad.observacion, idImage: actividad.id_image });
                                        hasImages = true; // Si encontramos al menos una imagen, actualizamos la bandera
                                    }
                                }
                            }
                        }
                        if (!hasImages) {
                            // Si no encontramos ninguna imagen en las actividades
                            addAlert('No hay imágenes en las visitas existentes', 'warning');
                            setLoading(false); // Dejar de cargar si no hay imágenes
                            return [2 /*return*/];
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, generateGetPresignedUrlsForOrdenImages(token, keys_1.map(function (item) { return item.key; }))];
                    case 2:
                        response = _d.sent();
                        if (response && response.urls) {
                            urls = response.urls;
                            imagesWithObs = urls.map(function (url, index) { return ({
                                url: url,
                                observacion: keys_1[index].observacion,
                                idImage: keys_1[index].idImage,
                            }); });
                            setImagesWithObservations(imagesWithObs);
                            // Validación: Si el campo orden.imagenes_imprimir no existe o está vacío, mostrar la alerta
                            if (!orden.imagenes_imprimir || orden.imagenes_imprimir.length === 0) {
                                addAlert('No hay imágenes asociadas a imprimir', 'warning');
                            }
                            else {
                                selected = orden.imagenes_imprimir.map(function (img) { return img.image; });
                                setSelectedImages(selected);
                            }
                        }
                        else {
                            addAlert('No se encontraron URLs prefirmadas', 'error');
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _d.sent();
                        console.error('Error fetching presigned URLs:', error_1);
                        addAlert('Error al obtener imágenes', 'error');
                        return [3 /*break*/, 5];
                    case 4:
                        setTimeout(function () {
                            setLoading(false);
                        }, 1000); // Delay of 1 second
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        addAlert('No hay visitas o imágenes asociadas', 'warning');
                        setLoading(false); // Dejar de cargar si no hay visitas
                        _d.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        fetchImageUrls();
    }, [orden]);
    var handleImageClick = function (idImage) {
        setSelectedImages(function (prevSelected) {
            return prevSelected.includes(idImage)
                ? prevSelected.filter(function (image) { return image !== idImage; })
                : __spreadArray(__spreadArray([], prevSelected, true), [idImage], false);
        });
    };
    var handleUpdateClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = sessionStorage.getItem('sessionJWTToken') || '';
                    if (!(token && orden._id)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    addAlert('Actualizando imágenes...', 'info');
                    return [4 /*yield*/, updateOrden(token, orden._id, { imagenes_imprimir: selectedImages.map(function (image) { return ({ image: image }); }) })];
                case 2:
                    _a.sent();
                    setTimeout(function () {
                        addAlert('Imágenes actualizadas correctamente', 'success');
                        setTimeout(function () {
                            onUpdate(); // Call onUpdate to re-render the parent component
                        }, 1000); // Delay the onUpdate call to ensure the alert is shown first
                    }, 1500); // Delay to show the success alert
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error updating orden:', error_2);
                    addAlert('Error updating orden', 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Función para agregar una nueva alerta evitando duplicados
    var addAlert = function (message, severity) {
        // Verificar si ya existe una alerta con el mismo mensaje y severidad
        setAlerts(function (prevAlerts) {
            var alertExists = prevAlerts.some(function (alert) { return alert.message === message && alert.severity === severity; });
            if (!alertExists) {
                return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now() + Math.random(), message: message, severity: severity }], false);
            }
            return prevAlerts; // Si ya existe, devolver las alertas previas sin cambios
        });
    };
    // Función para cerrar una alerta específica
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    var canAgregarImagenesImprimir = usePermission("ordenes_servicio", "agregar_imagenes_imprimir").canAccess;
    return (_jsxs("div", { className: "ImagenesImprimirOrden-modal-overlay", children: [loading ? (_jsx(CircularProgress, { style: { color: '#4F7A76' } })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ImagenesImprimirOrden-modal-content", children: [_jsx("div", { className: "ImagenesImprimirOrden-images-title", children: "Seleccione las Im\u00E1genes a Imprimir:" }), _jsx("div", { className: "ImagenesImprimirOrden-images", children: imagesWithObservations.map(function (image, index) { return (_jsxs("div", { className: "ImagenesImprimirOrden-image-container ".concat(selectedImages.includes(image.idImage) ? 'selected' : ''), onClick: function () { return handleImageClick(image.idImage); }, children: [_jsx("img", { src: image.url, alt: "Imagen ".concat(index + 1), className: "ImagenesImprimirOrden-image" }), _jsx("p", { className: "ImagenesImprimirOrden-observacion", children: image.observacion }), selectedImages.includes(image.idImage) && _jsx("div", { className: "check-overlay", children: "\u2713" })] }, index)); }) }), _jsxs("div", { className: "ImagenesImprimirOrden-buttons", children: [(canAgregarImagenesImprimir &&
                                        _jsx("button", { className: 'ImagenesImprimirOrden-actualizar-button', onClick: handleUpdateClick, children: "Actualizar" })), _jsx("button", { className: 'ImagenesImprimirOrden-cancel-button', onClick: onClose, children: "Cancelar" })] })] }), _jsxs("div", { className: "ImagenesImprimirOrden-modal-content2", children: [_jsx("div", { className: "ImagenesImprimirOrden-images-selected-title", children: "Im\u00E1genes Seleccionadas:" }), _jsx("div", { className: "ImagenesImprimirOrden-images2", children: selectedImages.map(function (idImage, index) {
                                    var image = imagesWithObservations.find(function (img) { return img.idImage === idImage; });
                                    return (_jsx("div", { className: "ImagenesImprimirOrden-image-container2", children: _jsx("img", { src: image === null || image === void 0 ? void 0 : image.url, alt: "Imagen Seleccionada ".concat(index + 1), className: "ImagenesImprimirOrden-image2" }) }, index));
                                }) })] })] })), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }));
};
export default ImagenesImprimirOrden;
