var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { updateSolicitudDadoBaja } from '../../services/solicitudesDadoBajaService'; // Ensure correct import
import './styles/SolicitudDadoBajaByIdAbierta.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSessionStorage } from '../../hooks/useSessionStorage';
var SolicitudDadoBajaByIdAbierta = function (_a) {
    var solicitudDadoBaja = _a.solicitudDadoBaja, onBack = _a.onBack, onCambioSuccess = _a.onCambioSuccess;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState('aprobar'), accionModal = _c[0], setAccionModal = _c[1];
    var _d = useState(''), observacionEstado = _d[0], setObservacionEstado = _d[1];
    var userId = useSessionStorage('userId');
    var token = useSessionStorage('sessionJWTToken');
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    var handleOpenModal = function (accion) {
        setAccionModal(accion);
        setShowModal(true);
    };
    var handleCloseModal = function () {
        setShowModal(false);
        setObservacionEstado('');
    };
    var handleConfirmarAccion = function () { return __awaiter(void 0, void 0, void 0, function () {
        var idEstado, solicitudDadoBajaData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    idEstado = accionModal === 'aprobar' ? '6642656ac858b4a7a115053e' : '6642656ec858b4a7a1150540';
                    solicitudDadoBajaData = {
                        id_solicitud_baja_estado: idEstado,
                        id_cambiador: userId,
                        date_cambio_estado: formattedDateCreated,
                        observacion_estado: observacionEstado,
                    };
                    if (!(token && solicitudDadoBaja._id)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateSolicitudDadoBaja(token, solicitudDadoBaja._id, solicitudDadoBajaData)];
                case 1:
                    _a.sent();
                    handleCloseModal();
                    onBack && onBack();
                    onCambioSuccess && onCambioSuccess();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "SolicitudDadoBajaByIdAbierta-box", children: _jsxs("div", { className: 'SolicitudDadoBajaByIdAbierta-register-container', children: [_jsxs("div", { className: "SolicitudDadoBajaByIdAbierta-overlap", children: [_jsx("div", { className: "SolicitudDadoBajaByIdAbierta-title-t", children: "VER SOLICITUD DADO DE BAJA" }), _jsx(ArrowBackIcon, { className: "SolicitudDadoBajaByIdAbierta-back-button", onClick: onBack })] }), _jsxs("div", { className: "SolicitudDadoBajaByIdAbierta-register-cotizacion", children: [_jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-id-t', children: "ID" }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-id-value', children: solicitudDadoBaja._id || 'N/A' }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-estado-t', children: "Estado" }), _jsxs("div", { className: 'SolicitudDadoBajaByIdAbierta-estado-value', children: [solicitudDadoBaja.id_solicitud_baja_estado.estado || 'N/A', " por ", solicitudDadoBaja.id_cambiador.name || 'N/A', " el d\u00EDa ", solicitudDadoBaja.date_cambio_estado || 'N/A'] }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-client-t', children: "Cliente" }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-client-name', children: solicitudDadoBaja.id_cliente.client_name || 'N/A' }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-orden-id-t', children: "Orden Relacionada" }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-orden-id', children: solicitudDadoBaja.id_orden._id || 'N/A' }), _jsx("div", { className: "SolicitudDadoBajaByIdAbierta-novedades-section", children: _jsxs("div", { className: "SolicitudDadoBajaByIdAbierta-novedad-detail", children: [_jsx("h3", { className: 'SolicitudDadoBajaByIdAbierta-novedades-title', children: "Observaciones Aprobaci\u00F3n" }), _jsx("div", { className: 'SolicitudDadoBajaByIdAbierta-novedad-text', children: solicitudDadoBaja.observacion_estado || 'N/A' })] }) })] })] }) }));
};
export default SolicitudDadoBajaByIdAbierta;
