var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getAllFallosSistemas } from '../../services/falloSistemasService';
import { getAllModosFallos } from '../../services/modosFallosService';
import { getAllCausasFallas } from '../../services/causasFallasService';
import './styles/ResultadoOrden.css';
import { CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateOrden } from '../../services/ordenesService';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { createSolicitudDadoBaja } from '../../services/solicitudesDadoBajaService';
var ResultadoOrden = function (_a) {
    var idOrden = _a.idOrden, resultadoOrden = _a.resultadoOrden, onUpdate = _a.onUpdate, idClient = _a.idClient;
    var token = useSessionStorage('sessionJWTToken');
    var userId = useSessionStorage('userId');
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState([]), fallosSistemas = _c[0], setFallosSistemas = _c[1];
    var _d = useState([]), modosFallos = _d[0], setModosFallos = _d[1];
    var _e = useState([]), causasFallas = _e[0], setCausasFallas = _e[1];
    var _f = useState([]), selectedModos = _f[0], setSelectedModos = _f[1];
    var _g = useState([]), selectedCausas = _g[0], setSelectedCausas = _g[1];
    var _h = useState(''), selectedFalloSistema = _h[0], setSelectedFalloSistema = _h[1];
    var _j = useState(''), modoValue = _j[0], setModoValue = _j[1];
    var _k = useState(''), causaValue = _k[0], setCausaValue = _k[1];
    var _l = useState(''), comentariosFinales = _l[0], setComentariosFinales = _l[1];
    var _m = useState(''), accionEjecutada = _m[0], setAccionEjecutada = _m[1];
    var _o = useState(false), solicitudDarBaja = _o[0], setSolicitudDarBaja = _o[1];
    var _p = useState([]), alerts = _p[0], setAlerts = _p[1];
    var readOnly = resultadoOrden != null;
    useEffect(function () {
        if (resultadoOrden) {
            setSelectedFalloSistema(resultadoOrden.id_fallo_sistema._id);
            setSelectedModos(resultadoOrden.ids_modos_fallos.map(function (modo) { return modo._id; }));
            setSelectedCausas(resultadoOrden.ids_causas_fallos.map(function (causa) { return causa._id; }));
            setComentariosFinales(resultadoOrden.comentarios_finales);
            setAccionEjecutada(resultadoOrden.accion_ejecutada);
            setSolicitudDarBaja(resultadoOrden.solicitud_dar_baja);
        }
        // Load additional data if necessary
        if (token) {
            setIsLoading(true);
            Promise.all([
                getAllFallosSistemas(token),
                getAllModosFallos(token),
                getAllCausasFallas(token)
            ]).then(function (_a) {
                var fallosData = _a[0], modosData = _a[1], causasData = _a[2];
                setFallosSistemas(fallosData);
                setModosFallos(modosData);
                setCausasFallas(causasData);
            }).catch(function (error) {
                console.error('Error fetching data:', error);
            }).finally(function () {
                setIsLoading(false);
            });
        }
    }, [token, resultadoOrden]);
    var handleModoFallosChange = function (event) {
        var value = event.target.value;
        setSelectedModos(function (prev) { return __spreadArray(__spreadArray([], prev, true), [value], false); });
        setModoValue('');
    };
    var handleCausaFallaChange = function (event) {
        var value = event.target.value;
        setSelectedCausas(function (prev) { return __spreadArray(__spreadArray([], prev, true), [value], false); });
        setCausaValue('');
    };
    var handleFalloSistemaChange = function (event) {
        var value = event.target.value;
        setSelectedFalloSistema(value);
    };
    var removeSelectedModo = function (id) {
        setSelectedModos(function (prev) { return prev.filter(function (modo) { return modo !== id; }); });
    };
    var removeSelectedCausa = function (id) {
        setSelectedCausas(function (prev) { return prev.filter(function (causa) { return causa !== id; }); });
    };
    var handleAccionEjecutadaChange = function (event) {
        setAccionEjecutada(event.target.value);
    };
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: message, severity: severity }], false); });
    };
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    var createSolicitudDadoBajaIfNeeded = function () { return __awaiter(void 0, void 0, void 0, function () {
        var solicitudData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!solicitudDarBaja) return [3 /*break*/, 4];
                    solicitudData = {
                        id_solicitud_baja_estado: '6642654cc858b4a7a115053c',
                        id_cliente: idClient,
                        id_orden: idOrden,
                        id_creador: userId,
                        date_created: formattedDateCreated
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createSolicitudDadoBaja(token, solicitudData)];
                case 2:
                    _a.sent();
                    addAlert('Solicitud de dado de baja creada exitosamente.', 'success');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    addAlert('Error al crear la solicitud de dado de baja.', 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resultadoOrden, response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!idOrden || !selectedFalloSistema || selectedModos.length === 0 || selectedCausas.length === 0 || comentariosFinales.trim() === '' || accionEjecutada === '') {
                        addAlert('Todos los campos son requeridos.', 'error');
                        return [2 /*return*/];
                    }
                    resultadoOrden = {
                        id_fallo_sistema: selectedFalloSistema,
                        ids_modos_fallos: selectedModos,
                        ids_causas_fallos: selectedCausas,
                        comentarios_finales: comentariosFinales.trim(),
                        accion_ejecutada: accionEjecutada,
                        solicitud_dar_baja: solicitudDarBaja
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, updateOrden(token, idOrden, { resultado_orden: resultadoOrden })];
                case 2:
                    response = _a.sent();
                    console.log(response);
                    return [4 /*yield*/, createSolicitudDadoBajaIfNeeded()];
                case 3:
                    _a.sent(); // Crear solicitud si es necesario
                    addAlert('Orden actualizada correctamente.', 'success');
                    onUpdate && onUpdate();
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    console.error('Error updating orden:', error_2);
                    addAlert('Error al actualizar la orden.', 'error');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    if (isLoading) {
        return (_jsx("div", { style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: '#00ddfa', position: 'relative', top: '400px' }, children: _jsx(CircularProgress, { color: "inherit" }) }));
    }
    return (_jsxs("div", { className: "ResultadoOrden-orden", children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), _jsxs("div", { className: "ResultadoOrden-div", children: [_jsx("header", { className: "ResultadoOrden-header", children: _jsx("div", { className: "ResultadoOrden-overlap-group", children: _jsx("div", { className: "ResultadoOrden-cambios-t", children: "RESULTADO DE LA ORDEN" }) }) }), _jsx("div", { className: "resultOrden-box", children: _jsxs("div", { className: "resultOrden-box-overlap-group", children: [_jsxs("div", { className: "resultOrden-box-sistema-falla", children: [_jsx("p", { className: "resultOrden-box-text-wrapper", children: "Seleccione el sistema que fall\u00F3: *" }), _jsxs("select", { className: "resultOrden-box-div", value: selectedFalloSistema, onChange: handleFalloSistemaChange, disabled: readOnly, children: [_jsx("option", { value: "", disabled: true, children: "Seleccione un sistema de falla" }), fallosSistemas.map(function (fallo) { return (_jsx("option", { value: fallo._id, children: fallo.name }, fallo._id)); })] })] }), _jsxs("div", { className: "resultOrden-box-modos-falla-section", children: [_jsx("p", { className: "resultOrden-box-text-wrapper", children: "Seleccione los modos de falla: *" }), _jsxs("select", { className: "resultOrden-box-div", value: modoValue, onChange: handleModoFallosChange, disabled: readOnly, children: [_jsx("option", { value: "", disabled: true, children: "Seleccione un modo de falla" }), modosFallos.filter(function (modo) { return modo.id_fallo_sistema._id === selectedFalloSistema; }).map(function (modo) { return (_jsx("option", { value: modo._id, children: modo.name }, modo._id)); })] }), _jsx("ul", { className: "resultOrden-ul", children: selectedModos.map(function (modo) {
                                                var modoEncontrado = modosFallos.find(function (m) { return m._id === modo; });
                                                return modoEncontrado ? (_jsx(_Fragment, { children: _jsxs("li", { className: "resultOrden-box-div-2", children: [modoEncontrado.name, !readOnly && (_jsx(CancelIcon, { className: "resultOrden-remove-icon", onClick: function () { return removeSelectedModo(modo); } }))] }, modo) })) : null;
                                            }) })] }), _jsxs("div", { className: "resultOrden-box-causas-falla-section", children: [_jsx("p", { className: "resultOrden-box-text-wrapper", children: "Seleccione las causas de falla: *" }), _jsxs("select", { className: "resultOrden-box-div", value: causaValue, onChange: handleCausaFallaChange, disabled: readOnly, children: [_jsx("option", { value: "", disabled: true, children: "Seleccione una causa de falla" }), causasFallas.map(function (causa) { return (_jsx("option", { value: causa._id, children: causa.title }, causa._id)); })] }), _jsx("ul", { className: "resultOrden-ul", children: selectedCausas.map(function (causaId) {
                                                var causa = causasFallas.find(function (c) { return c._id === causaId; });
                                                return causa ?
                                                    _jsx(_Fragment, { children: _jsxs("li", { className: "resultOrden-box-div-2", children: [causa.title, !readOnly && (_jsx(CancelIcon, { className: "resultOrden-remove-icon", onClick: function () { return removeSelectedCausa(causaId); } }))] }, causaId) })
                                                    : null;
                                            }) })] }), _jsx("div", { className: "resultOrden-box-separator" }), _jsxs("div", { className: "resultOrden-box-acciones-ejecutadas", children: [_jsx("div", { className: "resultOrden-box-text-wrapper", children: "Acciones ejecutadas: *" }), _jsxs("select", { className: "resultOrden-box-div", value: accionEjecutada, onChange: handleAccionEjecutadaChange, disabled: readOnly, children: [_jsx("option", { value: "", disabled: true, children: "Seleccione una acci\u00F3n ejecutada" }), _jsx("option", { value: "Inmediata", children: "Inmediata" }), _jsx("option", { value: "Largo Plazo", children: "Largo Plazo" }), _jsx("option", { value: "Mediano Plazo", children: "Mediano Plazo" })] })] }), _jsxs("div", { className: "resultOrden-box-final-comments", children: [_jsx("div", { className: "resultOrden-box-text-wrapper", children: "Ingrese los comentarios finales: *" }), _jsx("textarea", { className: "resultOrden-box-final-comments-input", placeholder: 'Comentarios...', value: comentariosFinales, onChange: function (e) { return setComentariosFinales(e.target.value); }, disabled: readOnly })] }), _jsxs("div", { className: "resultOrden-box-dado-baja-section", children: [_jsx("div", { className: "resultOrden-box-dado-baja-t", children: "Solicitar Dado de Baja: *" }), _jsxs("label", { className: "resultOrden-switch", children: [_jsx("input", { className: 'resultOrden-darbaja-input', type: "checkbox", name: "dar_baja", checked: solicitudDarBaja, onChange: function (e) { return setSolicitudDarBaja(e.target.checked); }, disabled: readOnly }), _jsx("span", { className: "resultOrden-slider round" })] })] }), !readOnly && (_jsx("button", { className: "resultOrden-update-button", onClick: handleSubmit, children: "Generar Resultado" }))] }) })] })] }));
};
export default ResultadoOrden;
