// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SolicitudesBodegaDetailPage-container{
    width: 100%;
    height: 100%;
    position: relative;
}



.SolicitudesBodegaDetailPage-visitas-container {
    width: 39.4vw;
    height: 98vh;
    top: 0vw;
    left: 31%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
} 

.SolicitudesBodegaDetailPage-edit-section {
    width: 100%;
    height: 100%;
    top: 0vw;
    position: relative;
    padding: 20vh 5vw 34vh 5vw;
}


.SolicitudesBodegaDetailPage-edit-icon {
    height: 30px;
    width: 1vh;
    top: 5%;
    left: 87%;
    position: absolute;
    color: #4F7A76;
    cursor: pointer;
    z-index: 9999;
}

.SolicitudesBodegaDetailPage-details-container {
    height: 373px;
    left: 0;
    top: 0;
    width: 391px;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/modules/solicitudes_bodega/pages/styles/SolicitudesBodegaDetailPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;;;AAIA;IACI,aAAa;IACb,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,kBAAkB;IAClB,0BAA0B;AAC9B;;;AAGA;IACI,YAAY;IACZ,UAAU;IACV,OAAO;IACP,SAAS;IACT,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,OAAO;IACP,MAAM;IACN,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".SolicitudesBodegaDetailPage-container{\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n\n\n.SolicitudesBodegaDetailPage-visitas-container {\n    width: 39.4vw;\n    height: 98vh;\n    top: 0vw;\n    left: 31%;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    align-content: center;\n    justify-content: center;\n} \n\n.SolicitudesBodegaDetailPage-edit-section {\n    width: 100%;\n    height: 100%;\n    top: 0vw;\n    position: relative;\n    padding: 20vh 5vw 34vh 5vw;\n}\n\n\n.SolicitudesBodegaDetailPage-edit-icon {\n    height: 30px;\n    width: 1vh;\n    top: 5%;\n    left: 87%;\n    position: absolute;\n    color: #4F7A76;\n    cursor: pointer;\n    z-index: 9999;\n}\n\n.SolicitudesBodegaDetailPage-details-container {\n    height: 373px;\n    left: 0;\n    top: 0;\n    width: 391px;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
