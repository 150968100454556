import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import memcoLogo from '../../../../utils/img/memco-logotype.png';
import ziriuzQR from '../../../../utils/img/ziriuzqrcode.png';
var CotizacionHeaderPDF = function (_a) {
    var cotizacion = _a.cotizacion;
    return (_jsxs(View, { style: styles.container, children: [_jsx(Image, { style: styles.memcoLogo, src: memcoLogo }), _jsx(View, { style: styles.overlapGroup, children: _jsx(Text, { style: styles.cotizacionTitle, children: "COTIZACI\u00D3N" }) }), _jsxs(Text, { style: styles.memcoInfo, children: ["MEMCO S.A.S. NIT: 900454322-1 ", '\n', "Bogot\u00E1: Cra. 70 # 21A - 16 / Medell\u00EDn: Cra. 50FF # 6 Sur - 75 ", '\n', "Cali: Av. 4N # 17N - 51 / Barranquilla: Cl. 75B # 42F - 83, Local 7 ", '\n', "Celular: 321 343 9040 / E-mail: info@memcosas.com"] }), _jsxs(Text, { style: styles.cotizacionId, children: ["ID: ", cotizacion._id] }), _jsx(Text, { style: styles.fechaTitle, children: "Creada en la fecha" }), _jsx(Text, { style: styles.dateCreated, children: cotizacion.fecha_creation }), _jsx(Image, { style: styles.bioswiftQR, src: ziriuzQR })] }));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        width: '100%',
    },
    memcoLogo: {
        height: 103,
        width: 272,
        position: 'absolute',
        top: 32,
        left: 21,
    },
    overlapGroup: {
        backgroundColor: '#00ddfa',
        height: 61,
        width: 205,
        position: 'absolute',
        top: 45,
        left: 375,
    },
    cotizacionTitle: {
        color: '#ffffff',
        fontSize: 24,
        textAlign: 'center',
        position: 'absolute',
        top: 18,
        width: '100%',
    },
    memcoInfo: {
        color: '#000000',
        fontSize: 11,
        position: 'absolute',
        top: 135,
        left: 21,
        width: 272,
        lineHeight: 1.5,
    },
    cotizacionId: {
        color: '#000000',
        fontSize: 11,
        textAlign: 'right',
        position: 'absolute',
        top: 133,
        left: 324,
        width: 176,
    },
    fechaTitle: {
        color: '#000000',
        fontSize: 11,
        textAlign: 'right',
        position: 'absolute',
        top: 152,
        left: 362,
        width: 138,
    },
    dateCreated: {
        color: '#000000',
        fontSize: 11,
        textAlign: 'right',
        position: 'absolute',
        top: 171,
        left: 362,
        width: 138,
    },
    bioswiftQR: {
        height: 64,
        width: 65,
        position: 'absolute',
        top: 131,
        left: 511,
    },
});
export default CotizacionHeaderPDF;
