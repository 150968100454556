import axios from '../../../utils/config/axios.config';
// Obtener todos los costos_repuestos
export var getAllCostosRepuestos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/costos-repuestos', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Obtener un costo_repuesto por ID
export var getCostoRepuestoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/costos-repuestos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Crear un nuevo costo_repuesto
export var createCostoRepuesto = function (token, costoRepuestoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/costos-repuestos', costoRepuestoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createMultipleCostosRepuestos = function (token, costosRepuestosData) {
    var options = {
        headers: { 'x-access-token': token },
    };
    return axios
        .post('/costos-repuestos/crear-masivo', costosRepuestosData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Actualizar un costo_repuesto por ID
export var updateCostoRepuesto = function (token, id, costoRepuestoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/costos-repuestos?id=".concat(id), costoRepuestoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Eliminar un costo_repuesto por ID
export var deleteCostoRepuestoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/costos-repuestos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Manejo de errores en las respuestas de axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Manejo de errores específicos para el código 500
            console.error(error);
        }
    }
};
