var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { getAllClients } from '../../../../users/services/clientsService';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
var ClientSectionContratos = function (_a) {
    var _b;
    var contratoId = _a.contratoId;
    var _c = useState(null), clientDetails = _c[0], setClientDetails = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var loggedIn = useSessionStorage('sessionJWTToken');
    var fetchClients = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, result, matchingClient, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!loggedIn)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    token = loggedIn;
                    return [4 /*yield*/, getAllClients(token)];
                case 2:
                    result = _a.sent();
                    matchingClient = result.clients.find(function (client) {
                        return client.ids_contratos.some(function (contrato) { return contrato._id === contratoId; });
                    });
                    setClientDetails(matchingClient);
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al obtener los detalles del cliente:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchClients();
    }, [contratoId]);
    if (loading) {
        return _jsx("div", { children: "Cargando detalles del cliente..." });
    }
    if (!clientDetails) {
        return _jsx("div", { children: "No se encontraron detalles del cliente para este contrato." });
    }
    return (_jsxs("div", { className: "ContratoDetailPage-complete-details", children: [_jsx("div", { className: "ContratoDetailPage-header", children: _jsxs("div", { className: "ContratoDetailPage-section-title", children: ["Cliente - ", ((_b = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.id_client_tipo) === null || _b === void 0 ? void 0 : _b.tipo) || 'N/A'] }) }), _jsxs("div", { className: "ContratoDetailPage-main-details", children: [_jsxs("div", { className: "ContratoDetailPage-section", children: [_jsx("div", { className: "ContratoDetailPage-section-title", children: clientDetails.client_name || 'N/A' }), _jsx(AccountBalanceOutlinedIcon, { className: "ContratoDetailPage-section-icon" }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["NIT: ", clientDetails.client_nit || 'N/A'] }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["Raz\u00F3n Social: ", clientDetails.razon_social || 'N/A'] }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["Fecha de Registro: ", clientDetails.date_created || 'N/A'] })] }), _jsxs("div", { className: "ContratoDetailPage-section", children: [_jsx("div", { className: "ContratoDetailPage-section-title", children: "Contacto" }), _jsx(ContactMailOutlinedIcon, { className: "ContratoDetailPage-section-icon" }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["Direcci\u00F3n: ", clientDetails.client_address || 'N/A'] }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["Tel\u00E9fono: ", clientDetails.client_telefono || 'N/A'] }), _jsxs("div", { className: "ContratoDetailPage-section-text", children: ["Email: ", clientDetails.client_email || 'N/A'] })] })] })] }));
};
export default ClientSectionContratos;
