var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import './styles/LoginForm.css';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../services/authService';
import BiosWiftLoginLogo from './img/bioswift-login-logo.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
var loginSchema = Yup.object().shape({
    username: Yup.string()
        .matches(/^[a-zA-Z]+\.[a-zA-Z]+$/, 'Formato inválido: Intenta [nombre.apellido]')
        .required('El username es obligatorio'),
    password: Yup.string().required('La Clave es obligatoria'),
});
var LogInForm = function () {
    var _a = useState(null), error = _a[0], setError = _a[1];
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1]; // Estado para manejar el estado del botón
    var _d = useState([]), alerts = _d[0], setAlerts = _d[1]; // Estado para manejar alertas
    var navigate = useNavigate();
    var initialCredentials = {
        username: '',
        password: '',
    };
    var toggleShowPassword = function () {
        setShowPassword(!showPassword);
    };
    var handleForgotPasswordClick = function () {
        navigate('/forgot-password');
    };
    // Función para agregar una alerta
    var addAlert = function (message, severity) {
        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now() + Math.random(), message: message, severity: severity }], false); });
    };
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var validationErrors_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loginSchema.validate(values, { abortEarly: false })];
                case 1:
                    _a.sent();
                    // Lanzar alerta de "Autenticando..." y deshabilitar el botón
                    addAlert('Autenticando...', 'info');
                    setIsSubmitting(true); // Deshabilitar el botón
                    setTimeout(function () {
                        login(values.username, values.password)
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 200 && response.data.token) {
                                    // Autenticación exitosa
                                    addAlert('Usuario Autenticado', 'success');
                                    // Guardar la sesión
                                    sessionStorage.setItem('sessionJWTToken', response.data.token);
                                    sessionStorage.setItem('userRoles', JSON.stringify([response.data.roleName]));
                                    sessionStorage.setItem('userId', response.data.userId);
                                    sessionStorage.setItem('userName', response.data.username);
                                    // Establecer flag de bienvenida en sessionStorage
                                    sessionStorage.setItem('showWelcomeAlert', 'true');
                                    // Luego de 1 segundo, mostrar "Ingresando..." y redirigir a /home
                                    setTimeout(function () {
                                        addAlert('Ingresando...', 'success');
                                        setTimeout(function () {
                                            navigate('/home');
                                        }, 1000);
                                    }, 1000);
                                }
                                else {
                                    // Manejar el caso de credenciales inválidas
                                    throw new Error('Credenciales inválidas');
                                }
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (error) {
                            console.error("[LOGIN ERROR]: Algo sali\u00F3 mal: ".concat(error));
                            setIsSubmitting(false); // Habilitar el botón de nuevo
                            addAlert('Hubo un error al iniciar sesión, revise sus credenciales', 'error');
                        });
                    }, 1000); // Esperar 1 segundo antes de enviar la solicitud
                    return [3 /*break*/, 3];
                case 2:
                    validationErrors_1 = _a.sent();
                    if (validationErrors_1 instanceof Yup.ValidationError) {
                        errorMessage = validationErrors_1.errors.join('\n');
                        addAlert(errorMessage, 'error');
                        setIsSubmitting(false); // Habilitar el botón de nuevo si hay errores de validación
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var handleAutoComplete = function () {
            var usernameField = document.getElementById('username');
            var passwordField = document.getElementById('password');
            setTimeout(function () {
                if (usernameField.value) {
                    usernameField.classList.add('filled');
                }
                if (passwordField.value) {
                    passwordField.classList.add('filled');
                }
            }, 500); // Delay to ensure autofill is complete
            var handleInput = function (event) {
                var input = event.target;
                if (input.value) {
                    input.classList.add('filled');
                }
                else {
                    input.classList.remove('filled');
                }
            };
            usernameField.addEventListener('input', handleInput);
            passwordField.addEventListener('input', handleInput);
            return function () {
                usernameField.removeEventListener('input', handleInput);
                passwordField.removeEventListener('input', handleInput);
            };
        };
        handleAutoComplete();
    }, []);
    return (_jsxs("div", { className: "LoginForm-container", children: [_jsx("img", { src: BiosWiftLoginLogo, alt: "ZiriuzLogoWithLeters", className: "LoginForm-ZiriuzLogo" }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: function (id) { return setAlerts(alerts.filter(function (alert) { return alert.id !== id; })); } }), _jsx(Formik, { initialValues: initialCredentials, validationSchema: loginSchema, onSubmit: handleSubmit, children: function (_a) {
                    var errors = _a.errors, touched = _a.touched;
                    return (_jsx("div", { className: "LoginForm-box", children: _jsxs(Form, { className: "LoginForm-form", children: [_jsx("div", { className: 'LoginForm-inicio-title', children: _jsx("h2", { children: "Iniciar Sesi\u00F3n" }) }), _jsxs("div", { className: "LoginForm-inputBox", children: [_jsxs("div", { className: "UsernameFieldContainer", children: [_jsx(Field, { className: "LoginForm-Field", id: "username", type: "text", name: "username", autoComplete: "off", required: true }), _jsx("span", { children: "Nombre de Usuario" }), " ", _jsx("i", {}), " "] }), _jsx(ErrorMessage, { name: "username", component: "div", className: "LoginForm-error-username" })] }), _jsxs("div", { className: "LoginForm-inputBox", children: [_jsxs("div", { className: "PasswordFieldContainer", children: [_jsx(Field, { className: "LoginForm-Field", id: "password", type: showPassword ? 'text' : 'password', name: "password", autoComplete: "off", required: true }), _jsx("span", { children: "Clave" }), " ", _jsx("i", {}), " ", _jsx("div", { className: "PasswordFieldIcon", onClick: toggleShowPassword, children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })] }), _jsx("div", { children: _jsx(ErrorMessage, { name: "password", component: "div", className: "LoginForm-error-password" }) })] }), _jsx("div", { className: "LoginForm-links", children: _jsx("a", { href: "#", className: "LoginForm-link", onClick: handleForgotPasswordClick, children: "Restaurar Clave" }) }), _jsx("button", { className: "LoginForm-button", type: "submit", disabled: isSubmitting, children: isSubmitting ? 'Loading...' : 'LogIn' }), error && _jsx("div", { className: "LoginForm-error", children: error })] }) }));
                } })] }));
};
export default LogInForm;
