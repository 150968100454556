// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndicadoresMainPage-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    font-family: "Mulish", sans-serif;
    background: #00000073;

  }
  
  /* Sección principal con degradado oscuro y blur */
  .IndicadoresMainPage-section {
    flex: 1;
    margin-left: 180px; /* Ajusta según el ancho de tu menú lateral */
    padding: 2rem;
  
    /* Fondo degradado combinando negro con un matiz de #4F7A76 */
    background: #0018156e;
  
    /* Difuminado de fondo */
    backdrop-filter: blur(6px);
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/indicadores/pages/styles/IndicadoresMainPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,iCAAiC;IACjC,qBAAqB;;EAEvB;;EAEA,kDAAkD;EAClD;IACE,OAAO;IACP,kBAAkB,EAAE,6CAA6C;IACjE,aAAa;;IAEb,6DAA6D;IAC7D,qBAAqB;;IAErB,wBAAwB;IACxB,0BAA0B;EAC5B","sourcesContent":[".IndicadoresMainPage-container {\n    display: flex;\n    width: 100%;\n    min-height: 100vh;\n    font-family: \"Mulish\", sans-serif;\n    background: #00000073;\n\n  }\n  \n  /* Sección principal con degradado oscuro y blur */\n  .IndicadoresMainPage-section {\n    flex: 1;\n    margin-left: 180px; /* Ajusta según el ancho de tu menú lateral */\n    padding: 2rem;\n  \n    /* Fondo degradado combinando negro con un matiz de #4F7A76 */\n    background: #0018156e;\n  \n    /* Difuminado de fondo */\n    backdrop-filter: blur(6px);\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
