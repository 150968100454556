import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Checkbox from '@mui/material/Checkbox';
import './styles/SolicitudServicioCard.css';
var SolicitudServicioCard = function (_a) {
    var solicitud = _a.solicitud, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    // Función para seleccionar el icono según el estado
    var renderEstadoIcon = function (estado) {
        switch (estado) {
            case 'Pendiente':
                return _jsx(HelpOutlinedIcon, { className: "SolicitudServicioCard-estado-pendiente" });
            case 'Aprobada':
                return _jsx(CheckCircleIcon, { className: "SolicitudServicioCard-estado-aprobada" });
            case 'Rechazada':
                return _jsx(CancelIcon, { className: "SolicitudServicioCard-estado-rechazada" });
            default:
                return null; // o puedes poner un icono por defecto
        }
    };
    var liClassName = "SolicitudServicioCard-box ".concat(isSelected ? 'SolicitudServicioCard-selected' : '', "\n  }");
    return (_jsxs("li", { className: liClassName, children: [_jsxs("header", { className: "SolicitudServicioCard-header", children: [_jsx("div", { className: "SolicitudServicioCard-servicio-title", onClick: onClick, children: solicitud.id_servicio.servicio }), _jsx("div", { className: "SolicitudServicioCard-creation-date", children: solicitud.creacion }), _jsx("div", { className: "SolicitudServicioCard-oid-solicitud", children: solicitud._id }), renderEstadoIcon(solicitud.id_solicitud_estado.estado), _jsx("div", { className: "SolicitudServicioCard-username", children: solicitud.id_cambiador.name }), _jsx(PublishedWithChangesOutlinedIcon, { className: "SolicitudServicioCard-update-icon" }), selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                            e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                            onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                        }, className: "SolicitudServicioCard-select-checkbox", sx: {
                            position: 'absolute',
                            top: '10px',
                            left: '90%',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                            zIndex: 5, // Asegura que esté sobre los demás elementos
                            transform: 'scale(1.5)',
                            '&.Mui-checked': {
                                color: '#00ddfa',
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(0, 221, 250, 0.1)',
                                borderRadius: '50%',
                            },
                            '& .MuiSvgIcon-root': {
                                fill: '#00ddfa',
                            },
                            '& input': {
                                zIndex: 3, // Ajuste del z-index del input en el DOM
                            },
                        } }))] }), _jsxs("div", { className: "SolicitudServicioCard-equipo-info", children: [_jsx("div", { className: "SolicitudServicioCard-info-title", children: "EQUIPO" }), _jsx("div", { className: 'SolicitudServicioCard-equipo-id', children: solicitud ? solicitud.id_equipo._id : 'N/A' }), _jsx("div", { className: "SolicitudServicioCard-text-wrapper", children: solicitud ? solicitud.id_equipo.modelo_equipos.id_clase.clase : 'N/A' }), _jsx("div", { className: "SolicitudServicioCard-oid-equipo", children: "SERIE" }), _jsxs("div", { className: "SolicitudServicioCard-serialnumber", children: ["SN: ", solicitud ? solicitud.id_equipo.serie : 'N/A'] }), _jsx("div", { className: "SolicitudServicioCard-ubicacion-title", children: "UBICACI\u00D3N" }), _jsx("div", { className: "SolicitudServicioCard-ubicacion-value", children: solicitud ? solicitud.id_equipo.ubicacion : 'N/A' })] }), _jsx("div", { className: "SolicitudServicioCard-separator" }), _jsxs("div", { className: "SolicitudServicioCard-sede-info", children: [_jsx("div", { className: "SolicitudServicioCard-info-sede-title", children: "SEDE" }), _jsx("div", { className: 'SolicitudServicioCard-sede-id', children: solicitud ? solicitud.id_equipo.id_sede._id : 'N/A' }), _jsx("div", { className: "SolicitudServicioCard-text-wrapper", children: solicitud ? solicitud.id_equipo.id_sede.sede_nombre : 'N/A' }), _jsx("div", { className: "SolicitudServicioCard-oid-sede", children: "CLIENTE" }), _jsx("div", { className: 'SolicitudServicioCard-cliente-id', children: solicitud ? solicitud.id_equipo.id_sede.id_client._id : 'N/A' }), _jsx("p", { className: "SolicitudServicioCard-cliente-name", children: solicitud ? solicitud.id_equipo.id_sede.id_client.client_name : 'N/A' })] })] }, solicitud._id));
};
export default SolicitudServicioCard;
