var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
export var exportEquiposToExcel = function (equipos) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('Equipos');
                // Define las columnas del Excel
                worksheet.columns = [
                    { header: 'ID Equipo', key: '_id', width: 30 },
                    { header: 'ID Sede', key: 'id_sede_id', width: 30 },
                    { header: 'Nombre de Sede', key: 'sede_nombre', width: 25 },
                    { header: 'Dirección de Sede', key: 'sede_address', width: 30 },
                    { header: 'Teléfono de Sede', key: 'sede_telefono', width: 20 },
                    { header: 'Email de Sede', key: 'sede_email', width: 30 },
                    { header: 'ID Cliente', key: 'id_client_id', width: 30 },
                    { header: 'Nombre Cliente', key: 'client_name', width: 25 },
                    { header: 'NIT Cliente', key: 'client_nit', width: 20 },
                    { header: 'Dirección Cliente', key: 'client_address', width: 30 },
                    { header: 'Teléfono Cliente', key: 'client_telefono', width: 20 },
                    { header: 'Email Cliente', key: 'client_email', width: 30 },
                    { header: 'ID Modelo', key: 'modelo_equipos_id', width: 30 },
                    { header: 'Modelo de Equipo', key: 'modelo', width: 25 },
                    { header: 'Precio del Equipo', key: 'precio', width: 20 },
                    { header: 'ID Marca', key: 'id_marca_id', width: 30 },
                    { header: 'Marca', key: 'marca', width: 25 },
                    { header: 'ID Clase', key: 'id_clase_id', width: 30 },
                    { header: 'Clase', key: 'clase', width: 25 },
                    { header: 'ID Área', key: 'id_area_id', width: 30 },
                    { header: 'Área', key: 'area', width: 25 },
                    { header: 'ID Tipo', key: 'id_tipo_id', width: 30 },
                    { header: 'Tipo', key: 'tipo', width: 25 },
                    { header: 'Serie', key: 'serie', width: 20 },
                    { header: 'Ubicación', key: 'ubicacion', width: 30 },
                    { header: 'Frecuencia', key: 'frecuencia', width: 15 },
                    { header: 'Activo Fijo', key: 'activo_fijo', width: 25 },
                    { header: 'Mtto', key: 'mtto', width: 25 },
                    { header: 'SAP', key: 'sap', width: 25 }
                ];
                // Estilo para el encabezado
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // Blanco
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' }, // Azul oscuro
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });
                // Añadir las filas de datos al Excel y aplicar estilos condicionales
                equipos.forEach(function (equipo) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
                    var row = worksheet.addRow({
                        _id: equipo._id || 'N/A',
                        id_sede_id: ((_a = equipo.id_sede) === null || _a === void 0 ? void 0 : _a._id) || 'N/A',
                        sede_nombre: ((_b = equipo.id_sede) === null || _b === void 0 ? void 0 : _b.sede_nombre) || 'N/A',
                        sede_address: ((_c = equipo.id_sede) === null || _c === void 0 ? void 0 : _c.sede_address) || 'N/A',
                        sede_telefono: ((_d = equipo.id_sede) === null || _d === void 0 ? void 0 : _d.sede_telefono) || 'N/A',
                        sede_email: ((_e = equipo.id_sede) === null || _e === void 0 ? void 0 : _e.sede_email) || 'N/A',
                        id_client_id: ((_g = (_f = equipo.id_sede) === null || _f === void 0 ? void 0 : _f.id_client) === null || _g === void 0 ? void 0 : _g._id) || 'N/A',
                        client_name: ((_j = (_h = equipo.id_sede) === null || _h === void 0 ? void 0 : _h.id_client) === null || _j === void 0 ? void 0 : _j.client_name) || 'N/A',
                        client_nit: ((_l = (_k = equipo.id_sede) === null || _k === void 0 ? void 0 : _k.id_client) === null || _l === void 0 ? void 0 : _l.client_nit) || 'N/A',
                        client_address: ((_o = (_m = equipo.id_sede) === null || _m === void 0 ? void 0 : _m.id_client) === null || _o === void 0 ? void 0 : _o.client_address) || 'N/A',
                        client_telefono: ((_q = (_p = equipo.id_sede) === null || _p === void 0 ? void 0 : _p.id_client) === null || _q === void 0 ? void 0 : _q.client_telefono) || 'N/A',
                        client_email: ((_s = (_r = equipo.id_sede) === null || _r === void 0 ? void 0 : _r.id_client) === null || _s === void 0 ? void 0 : _s.client_email) || 'N/A',
                        modelo_equipos_id: ((_t = equipo.modelo_equipos) === null || _t === void 0 ? void 0 : _t._id) || 'N/A',
                        modelo: ((_u = equipo.modelo_equipos) === null || _u === void 0 ? void 0 : _u.modelo) || 'N/A',
                        precio: ((_v = equipo.modelo_equipos) === null || _v === void 0 ? void 0 : _v.precio) || 'N/A',
                        id_marca_id: ((_x = (_w = equipo.modelo_equipos) === null || _w === void 0 ? void 0 : _w.id_marca) === null || _x === void 0 ? void 0 : _x._id) || 'N/A',
                        marca: ((_z = (_y = equipo.modelo_equipos) === null || _y === void 0 ? void 0 : _y.id_marca) === null || _z === void 0 ? void 0 : _z.marca) || 'N/A',
                        id_clase_id: ((_1 = (_0 = equipo.modelo_equipos) === null || _0 === void 0 ? void 0 : _0.id_clase) === null || _1 === void 0 ? void 0 : _1._id) || 'N/A',
                        clase: ((_3 = (_2 = equipo.modelo_equipos) === null || _2 === void 0 ? void 0 : _2.id_clase) === null || _3 === void 0 ? void 0 : _3.clase) || 'N/A',
                        id_area_id: ((_4 = equipo.id_area) === null || _4 === void 0 ? void 0 : _4._id) || 'N/A',
                        area: ((_5 = equipo.id_area) === null || _5 === void 0 ? void 0 : _5.area) || 'N/A',
                        id_tipo_id: ((_6 = equipo.id_tipo) === null || _6 === void 0 ? void 0 : _6._id) || 'N/A',
                        tipo: ((_7 = equipo.id_tipo) === null || _7 === void 0 ? void 0 : _7.tipo) || 'N/A',
                        serie: equipo.serie || 'N/A',
                        ubicacion: equipo.ubicacion || 'N/A',
                        frecuencia: equipo.frecuencia || 'N/A',
                        activo_fijo: equipo.activo_fijo || 'N/A',
                        mtto: equipo.mtto || 'N/A',
                        sap: equipo.sap || 'N/A',
                    });
                    // Añadir borde a todas las celdas de la fila
                    row.eachCell({ includeEmpty: true }, function (cell) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'Equipos.xlsx');
                return [2 /*return*/];
        }
    });
}); };
