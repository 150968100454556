var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { editUserById } from '../../../users/services/usersService';
import { useSessionStorage } from '../../../users/hooks/useSessionStorage';
import './styles/UsersVsClientesVsSedes.css';
import { getAllSedes } from '../../services/sedesService';
var UsersVsSedes = function (_a) {
    var ids_sedes = _a.ids_sedes, userId = _a.userId, onUpdate = _a.onUpdate;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(false), editing = _b[0], setEditing = _b[1];
    var _c = useState(ids_sedes), sedesList = _c[0], setSedesList = _c[1];
    var _d = useState(''), searchQuery = _d[0], setSearchQuery = _d[1];
    var _e = useState([]), searchResults = _e[0], setSearchResults = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var _g = useState([]), alerts = _g[0], setAlerts = _g[1];
    var addAlert = function (message, severity) {
        setAlerts(function (prev) { return __spreadArray(__spreadArray([], prev, true), [{ id: Date.now(), message: message, severity: severity }], false); });
    };
    var handleCloseAlert = function (id) {
        setAlerts(function (prev) { return prev.filter(function (alert) { return alert.id !== id; }); });
    };
    var handleEditClick = function () {
        setEditing(true);
    };
    var handleSaveClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addAlert("Guardando cambios...", "info");
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, editUserById(token, userId, { ids_sedes: sedesList })];
                case 2:
                    _a.sent();
                    addAlert("Actualización exitosa", "success");
                    setEditing(false);
                    onUpdate();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    addAlert("Error al actualizar", "error");
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleSearchChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var query, response, allSedes, filtered, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = e.target.value;
                    setSearchQuery(query);
                    if (query.trim().length < 2) {
                        setSearchResults([]);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getAllSedes(token)];
                case 2:
                    response = _a.sent();
                    allSedes = response.sedes;
                    filtered = allSedes.filter(function (sede) {
                        return Object.values(sede).some(function (val) {
                            return String(val).toLowerCase().includes(query.toLowerCase());
                        });
                    });
                    setSearchResults(filtered);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error(error_2);
                    addAlert("Error al buscar sedes", "error");
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSelectSede = function (sede) {
        if (sedesList.some(function (s) { return s._id === sede._id; })) {
            addAlert("Esta Sede ya se encuentra seleccionada", "warning");
            return;
        }
        setSedesList(__spreadArray(__spreadArray([], sedesList, true), [sede], false));
        setSearchQuery('');
        setSearchResults([]);
    };
    var handleRemoveSede = function (sedeId) {
        setSedesList(sedesList.filter(function (sede) { return sede._id !== sedeId; }));
    };
    return (_jsxs("div", { className: "users-vs-sedes", children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), _jsxs("div", { className: "users-vs-sedes-title-section", children: [_jsx("h3", { children: "Sedes Asociadas" }), editing ? (loading ? (_jsx(CircularProgress, { size: 20 })) : (_jsx(SaveIcon, { className: "users-vs-clients-sedes-edit-icon", onClick: handleSaveClick }))) : (_jsx(EditIcon, { className: "users-vs-clients-sedes-edit-icon", onClick: handleEditClick }))] }), editing && (_jsxs("div", { className: "search-container", children: [_jsx("input", { type: "text", className: "RegisterUserForm-input", placeholder: "Buscar sede...", value: searchQuery, onChange: handleSearchChange }), searchResults.length > 0 && (_jsx("ul", { className: "search-results-ul", children: searchResults.map(function (sede, index) { return (_jsxs("li", { className: "search-results-li", onClick: function () { return handleSelectSede(sede); }, children: [sede._id, " - ", sede.sede_nombre] }, index)); }) }))] })), _jsx("ul", { className: "users-vs-clients-sedes-ul", children: sedesList.map(function (sede, index) { return (_jsxs("div", { className: "users-vs-clients-sedes-divforli", children: [_jsxs("li", { className: "users-vs-clients-sedes-li", children: [_jsxs("p", { children: [_jsx("span", { children: "ID: " }), sede._id] }), _jsxs("p", { children: [_jsx("span", { children: "Sede: " }), sede.sede_nombre] }), _jsxs("p", { children: [_jsx("span", { children: "Direcci\u00F3n: " }), sede.sede_address] })] }), editing && (_jsx(CancelIcon, { className: "users-vs-clients-sedes-remove-icon", onClick: function () { return handleRemoveSede(sede._id); } }))] }, index)); }) })] }));
};
export default UsersVsSedes;
