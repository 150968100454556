import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import './styles/PassUpdateSuccessfull.css';
var PassUpdateSuccessfullPage = function () {
    var navigate = useNavigate();
    var handleLoginClick = function () {
        navigate("/login");
    };
    return (_jsx("div", { className: "PassUpdateSuccessfullPage-box", children: _jsx("div", { children: _jsxs("form", { className: "PassUpdateSuccessfullPage-form", children: [_jsx("h2", { children: "\u00A1Genial! Tu Clave fue actualizada correctamente." }), _jsx("br", {}), _jsx("br", {}), _jsx("p", { className: "PassUpdateSuccessfullPage-p", children: "Por favor, vuelve a iniciar sesi\u00F3n:" }), _jsx("br", {}), _jsx("br", {}), _jsx("button", { type: 'submit', className: "PassUpdateSuccessfullPage-button", onClick: handleLoginClick, children: "Login" })] }) }) }));
};
export default PassUpdateSuccessfullPage;
