import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './styles/EquipoDisponibilidad.css'; // Asegúrate de crear y referenciar los estilos específicos para este componente
import DateRangeIcon from '@mui/icons-material/DateRange';
var ConceptoTecnicoVer = function (_a) {
    var actividad = _a.actividad;
    return (_jsxs("div", { className: "EquipoDisponibilidadVer-box", children: [_jsx("div", { className: "EquipoDisponibilidadVer-en-sede-view", children: _jsxs("div", { className: "EquipoDisponibilidadVer-tecnico-en-sede-view-2", children: [_jsx("div", { className: "EquipoDisponibilidadVer-tecsede-title-2", children: actividad.id_protocolo.title || 'N/A' }), _jsx("div", { className: "EquipoDisponibilidadVer-observacion-t", children: "Observaci\u00F3n:" }), _jsx("textarea", { className: "EquipoDisponibilidadVer-observacion-text", value: actividad.observacion || 'N/A', readOnly: true, style: {
                                backgroundColor: '#d9d9d9',
                                borderRadius: '0.52vw', // 10px
                                height: '5.93vh', // 64px
                                left: '0.73vw', // 14px
                                border: 'none',
                                textAlign: 'center',
                                position: 'absolute',
                                top: '5.37vh', // 58px
                                width: '21.88vw', // 420px
                                fontSize: '1vw'
                            } }), _jsx(DateRangeIcon, { className: 'EquipoDisponibilidadVer-date-icon' }), _jsx("p", { className: 'EquipoDisponibilidadVer-date-value', children: actividad.date_created || 'N/A' })] }) }), _jsx("div", { className: 'EquipoDisponibilidadVer-div-separator' })] }));
};
export default ConceptoTecnicoVer;
