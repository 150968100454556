import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { Dashboard } from '../components/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
export var HomePage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var navigate = useNavigate();
    useEffect(function () {
        if (!loggedIn) {
            navigate('/login'); // Utiliza la función de logoutService para manejar el cierre de sesión
        }
    }, [loggedIn]);
    return (_jsx("div", { children: _jsx(Dashboard, {}) }));
};
