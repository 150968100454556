import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/TipoEquipoCard.css';
var TipoEquipoCard = function (_a) {
    var tipoEquipo = _a.tipoEquipo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "TipoEquipoCard-box ".concat(isSelected ? 'TipoEquipoCard-selected' : '', "\n    }");
    return (_jsxs("div", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '102%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#00ddfa',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#00ddfa',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx("div", { className: "TipoEquipoCard-overlap", children: _jsx(TypeSpecimenOutlinedIcon, { className: "TipoEquipoCard-marcaequipo-icon" }) }), _jsx("div", { className: "TipoEquipoCard-marcaequipo-title", onClick: onClick, children: tipoEquipo.tipo }), _jsxs("div", { className: "TipoEquipoCard-marcaequipo-id", children: ["TIPO ID: ", tipoEquipo._id] })] }, tipoEquipo._id));
};
export default TipoEquipoCard;
