import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import useUserRoleVerifier from "../../hooks/useUserRoleVerifier";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { usePermission } from '../../../../hooks/usePermission';
var DashboardCentralCotizaciones = function () {
    var canViewCotizaciones = usePermission('cotizaciones', 'verTodos').canAccess;
    var navigate = useNavigate();
    var isAdmin = useUserRoleVerifier(['administrador']);
    return (_jsxs("ul", { className: "DashboardMenuCentral-nav-ul", children: [(canViewCotizaciones &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/cotizaciones'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(ReceiptLongIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Cotizaciones" })] }) }) })), (canViewCotizaciones &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/cotizaciones-pendientes'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsxs("i", { className: 'DashboardMenuCentral-nav-icon-i', children: [_jsx(ReceiptLongIcon, { className: 'DashboardMenuCentral-icon' }), _jsx(HelpOutlinedIcon, { className: 'DashboardMenuCentral-add-icon' })] }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Cotizaciones Pendientes" })] }) }) })), (canViewCotizaciones &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/cotizaciones-aprobadas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(ReceiptLongIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Cotizaciones Aprobadas" })] }) }) })), (canViewCotizaciones &&
                _jsx("button", { className: "DashboardMenuCentral-button", onClick: function () { return navigate('/cotizaciones-rechazadas'); }, children: _jsx("li", { children: _jsxs("div", { className: "DashboardMenuCentral-nav-icon", children: [_jsx("i", { className: 'DashboardMenuCentral-nav-icon-i', children: _jsx(ReceiptLongIcon, { className: 'DashboardMenuCentral-icon' }) }), _jsx("p", { className: "DashboardMenuCentral-p", children: "Cotizaciones Rechazadas" })] }) }) }))] }));
};
export default DashboardCentralCotizaciones;
