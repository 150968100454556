import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Typography, Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useNavigate } from 'react-router-dom';
var RepuestosInstaladosTotal = function (_a) {
    var valor = _a.valor;
    var series = [
        { data: [100, 120, 130, 150, 160, 170, 180, 190, 200, 210, 220, 230], label: '2025' },
        { data: [110, 125, 135, 155, 165, 175, 185, 195, 205, 215, 225, 235], label: '2026' },
        { data: [120, 130, 140, 160, 170, 180, 190, 200, 210, 220, 230, 240], label: '2027' },
        { data: [130, 135, 145, 165, 175, 185, 195, 205, 215, 225, 235, 245], label: '2028' },
        { data: [140, 145, 155, 175, 185, 195, 205, 215, 225, 235, 245, 255], label: '2029' },
        { data: [150, 155, 165, 185, 195, 205, 215, 225, 235, 245, 255, 265], label: '2030' },
    ];
    var xAxis = [
        {
            data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            scaleType: 'band',
        },
    ];
    var navigate = useNavigate();
    return (_jsx(Card, { elevation: 4, sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(8px)',
            border: '1px solid rgba(79, 122, 118, 0.3)',
            borderRadius: '12px',
            color: '#7EC6BF',
            minHeight: '300px',
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            transition: 'transform 0.2s ease-in-out',
            '&:hover': { transform: 'scale(1.02)' },
        }, children: _jsxs(CardContent, { sx: { textAlign: 'center', zIndex: 1 }, children: [_jsx(Typography, { variant: "h6", sx: {
                        mb: 2,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        transition: 'color 0.2s ease-in-out',
                        color: '#7EC6BF',
                        '&:hover': {
                            color: '#ffffff', // Cambia el color al pasar el mouse
                        },
                    }, onClick: function () { return navigate('/indicadores/repuestos-instalados'); }, children: "Repuestos Instalados" }), _jsx(Typography, { variant: "h4", sx: { fontWeight: 'bold', color: '#fff', mb: 2 }, children: valor.toLocaleString('es-ES') }), _jsx(Box, { sx: { height: 250 }, children: _jsx(BarChart, { series: series, xAxis: xAxis, height: 250, margin: { top: 10, bottom: 30, left: 40, right: 10 }, sx: {
                            '--ChartsTooltip-bg': 'rgba(0, 0, 0, 0.7)',
                            '--ChartsTooltip-color': '#fff',
                            '--ChartsTooltip-border': '1px solid #fff',
                            // Ejes y ticks en blanco
                            '& .MuiChartsAxis-root text': { fill: '#fff' },
                            '& .MuiChartsAxis-root .MuiChartsAxis-line, .MuiChartsAxis-root .MuiChartsAxis-tick': {
                                stroke: '#fff',
                            },
                            // Ocultar la leyenda (donde salen los años)
                            '& .MuiChartsLegend-root': {
                                display: 'none',
                            },
                        } }) })] }) }));
};
export default RepuestosInstaladosTotal;
