// cotizacionesService.ts
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from '../../../utils/config/axios.config';
export var getAllCotizaciones = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/cotizaciones', options)
        .then(function (response) { return response.data; }) // Ajusta para obtener la propiedad correcta de la respuesta
        .catch(handleError);
};
export var getCotizacionById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/cotizaciones/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createCotizacion = function (token, cotizacionData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/cotizaciones', cotizacionData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateCotizacion = function (token, id, cotizacionData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/cotizaciones?id=".concat(id), cotizacionData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteCotizacionById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/cotizaciones/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var getPresignedUrlForFirma = function (token, fileName) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            fileName: fileName,
        },
    };
    return axios
        .get('/cotizaciones/generate-presigned-url-for-firma', options)
        .then(function (response) { return response.data; }) // Asume que la respuesta contiene un campo 'url' con la URL firmada
        .catch(handleError);
};
export var getPresignedUrlForGetFirma = function (token, objectKey) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            key: objectKey,
        },
    };
    return axios
        .get('/cotizaciones/generate-presigned-url-get', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var sendCotizacionEmail = function (token, emailDetails) { return __awaiter(void 0, void 0, void 0, function () {
    var formData, options;
    return __generator(this, function (_a) {
        formData = new FormData();
        formData.append("emails", emailDetails.emails);
        formData.append("subject", emailDetails.subject);
        formData.append("file", emailDetails.file);
        options = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data'
            },
            timeout: 30000,
        };
        return [2 /*return*/, axios
                .post('/cotizaciones/email-pdf', formData, options)
                .then(function (response) { return response.data; })
                .catch(handleError)];
    });
}); };
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // window.location.href = '/login';
            console.log(error);
        }
    }
    throw error;
};
