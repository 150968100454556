var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import '../../../../components/filters/styles/ActionsComponent.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { exportCostosRepuestosToExcel } from '../../services/exportCostosRepuestosExcelService';
import { CircularProgress } from '@mui/material';
import { usePermission } from "../../../../hooks/usePermission";
import { useNavigate } from 'react-router-dom';
var CostosRepuestosActionsComponent = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, costosRepuestos = _a.costosRepuestos, selectMode = _a.selectMode, costosRepuestosSelected = _a.costosRepuestosSelected;
    var _b = useState(''), selectedAction = _b[0], setSelectedAction = _b[1];
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var navigate = useNavigate();
    var handleContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (selectedAction === '') {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            {
                                id: new Date().getTime(),
                                message: 'Seleccione una opción antes de continuar',
                                severity: 'warning',
                            },
                        ], false); });
                        return [2 /*return*/];
                    }
                    if (!(selectedAction === 'descargarExcel')) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, exportCostosRepuestosToExcel(costosRepuestos)];
                case 2:
                    _a.sent();
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        {
                            id: new Date().getTime(),
                            message: 'Costos de repuestos exportados a Excel exitosamente',
                            severity: 'success',
                        },
                    ], false); });
                    onClose();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                        {
                            id: new Date().getTime(),
                            message: 'Error al exportar los costos de repuestos a Excel',
                            severity: 'error',
                        },
                    ], false); });
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5:
                    if (selectedAction === 'crearCostosRepuestosMasivo') {
                        navigate('/costos-repuestos/create-masivo');
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    var canExportCostosRepuestos = usePermission('costos_repuestos', 'exportarExcel').canAccess;
    var canCreateMasivoCostosRepuestos = usePermission('costos_repuestos', 'crear_masivo').canAccess;
    return isOpen ? (_jsx("div", { className: "ActionsComponent-modal-overlay", children: _jsxs("div", { className: "ActionsComponent-modal-content", children: [_jsx("h2", { className: "ActionsComponent-title", children: "ACCIONES - COSTOS DE REPUESTOS" }), _jsxs("select", { className: "ActionsComponent-select", value: selectedAction, onChange: function (e) { return setSelectedAction(e.target.value); }, disabled: isLoading, children: [_jsx("option", { value: "", disabled: true, children: "Seleccionar..." }), canExportCostosRepuestos && (_jsx("option", { value: "descargarExcel", children: "Descargar Excel" })), canCreateMasivoCostosRepuestos && (_jsx("option", { value: "crearCostosRepuestosMasivo", children: "Crear Masivo Costos Repuestos" }))] }), _jsxs("div", { className: "ActionsComponent-buttons-div", children: [_jsx("button", { className: "ActionsComponent-continue-button", onClick: handleContinue, disabled: isLoading, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : 'Continuar' }), _jsx("button", { className: "ActionsComponent-cancel-button", onClick: onClose, disabled: isLoading, children: "Cancelar" })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }) })) : null;
};
export default CostosRepuestosActionsComponent;
