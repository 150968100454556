import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/OrdenCard.css';
import { differenceInDays, parseISO } from 'date-fns';
var OrdenCard = function (_a) {
    var orden = _a.orden, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    // Función para calcular "Días Fuera" basado en el estado de la orden
    var calculateDiasFuera = function () {
        if (!orden.creacion)
            return 'N/A'; // Si no hay fecha de creación, retornar 'N/A'
        var creationDate = parseISO(orden.creacion);
        if (orden.id_orden_estado.estado === 'Abierta') {
            // Si la orden está "Abierta", calcular diferencia desde la fecha de creación hasta hoy
            var currentDate = new Date();
            return differenceInDays(currentDate, creationDate);
        }
        else if (orden.id_orden_estado.estado === 'Cerrada' && orden.cierre) {
            // Si la orden está "Cerrada", calcular diferencia entre fecha de creación y fecha de cierre
            var cierreDate = parseISO(orden.cierre);
            return differenceInDays(cierreDate, creationDate);
        }
        else if (orden.id_orden_estado.estado === 'Anulada' && orden.anulacion_date) {
            // Si la orden está "Anulada", calcular diferencia entre fecha de creación y fecha de anulación
            var anulacionDate = parseISO(orden.anulacion_date);
            return differenceInDays(anulacionDate, creationDate);
        }
        return 'N/A'; // Si no se puede calcular, retornar 'N/A'
    };
    // Calcula "Días Fuera" utilizando la función correspondiente
    var diasFuera = calculateDiasFuera();
    var renderEstadoIcon = function (estado) {
        switch (estado) {
            case 'Abierta':
                return _jsx(LockOpenOutlinedIcon, { className: "OrdenCard-estado-abierta" });
            case 'Cerrada':
                return _jsx(LockOutlinedIcon, { className: "OrdenCard-estado-cerrada" });
            case 'Anulada':
                return _jsx(BlockOutlinedIcon, { className: "OrdenCard-estado-anulada" });
            default:
                return null;
        }
    };
    // Condicional para el className del div basado en "Días Fuera"
    var servicioTitleClassName = "OrdenCard-servicio-title ".concat(orden.id_orden_estado.estado === 'Abierta' && typeof diasFuera === 'number' && diasFuera >= 5
        ? 'OrdenCard-dias-fuera-alto'
        : '');
    // Simplificar el liClassName para manejar solo la selección
    var liClassName = "OrdenCard-box ".concat(isSelected ? 'OrdenCard-selected' : '');
    return (_jsxs("li", { className: liClassName, children: [_jsx("div", { className: "OrdenCard-overlap", children: _jsxs("header", { className: "OrdenCard-header", children: [_jsx("div", { className: servicioTitleClassName, onClick: onClick, children: orden ? orden.id_solicitud_servicio.id_servicio.servicio : 'N/A' }), _jsxs("div", { className: "OrdenCard-creation-date", children: [orden ? orden.creacion : 'N/A', " - D\u00EDas Fuera: ", diasFuera] }), _jsxs("div", { className: "OrdenCard-oid-orden", children: ["ID: ", orden ? orden._id : 'N/A'] }), _jsxs("div", { className: "OrdenCard-id-incrementable", children: ["# ", orden ? orden.id_incrementable : 'N/A'] }), renderEstadoIcon(orden.id_orden_estado.estado), _jsx("div", { className: "OrdenCard-username", children: orden.id_creador ? orden.id_creador.username : 'N/A' }), _jsx(CalendarTodayOutlinedIcon, { className: "OrdenCard-update-icon" }), _jsx(AddOutlinedIcon, { className: "OrdenCard-add-icon" }), selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                                e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                                onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                            }, className: "OrdenCard-select-checkbox", sx: {
                                position: 'absolute',
                                top: '10px',
                                left: '90%',
                                width: '20px',
                                height: '20px',
                                cursor: 'pointer',
                                zIndex: 5, // Asegura que esté sobre los demás elementos
                                transform: 'scale(1.5)',
                                '&.Mui-checked': {
                                    color: '#4F7A76',
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 221, 250, 0.1)',
                                    borderRadius: '50%',
                                },
                                '& .MuiSvgIcon-root': {
                                    fill: '#4F7A76',
                                },
                                '& input': {
                                    zIndex: 3, // Ajuste del z-index del input en el DOM
                                },
                            } }))] }) }), _jsxs("div", { className: "OrdenCard-equipo-info", children: [_jsx("div", { className: "OrdenCard-info-title", children: "EQUIPO" }), _jsx("div", { className: 'OrdenCard-equipo-id', children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo._id : 'N/A' }), _jsx("div", { className: "OrdenCard-text-wrapper", children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_clase.clase : 'N/A' }), _jsx("div", { className: "OrdenCard-oid-equipo", children: "SERIE" }), _jsxs("div", { className: "OrdenCard-serialnumber", children: ["SN: ", orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.serie : 'N/A'] }), _jsx("div", { className: "OrdenCard-ubicacion-title", children: "UBICACI\u00D3N" }), _jsx("div", { className: "OrdenCard-ubicacion-value", children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.ubicacion : 'N/A' })] }), _jsx("div", { className: "OrdenCard-separator" }), _jsxs("div", { className: "OrdenCard-sede-info", children: [_jsx("div", { className: "OrdenCard-info-sede-title", children: "SEDE" }), _jsx("div", { className: 'OrdenCard-sede-id', children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.id_sede._id : 'N/A' }), _jsx("div", { className: "OrdenCard-text-wrapper", children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.id_sede.sede_nombre : 'N/A' }), _jsx("div", { className: "OrdenCard-oid-sede", children: "CLIENTE" }), _jsx("div", { className: 'OrdenCard-cliente-id', children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.id_sede.id_client._id : 'N/A' }), _jsx("p", { className: "OrdenCard-cliente-name", children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.id_equipo.id_sede.id_client.client_name : 'N/A' })] }), _jsxs("div", { className: 'OrdenCard-solicitud-info', children: [(function () {
                        var _a;
                        var raw = ((_a = orden.prioridad) !== null && _a !== void 0 ? _a : '').toLowerCase();
                        var valid = ['alta', 'media', 'baja'];
                        var isValid = valid.includes(raw);
                        var display = isValid
                            ? "Prioridad ".concat(raw.charAt(0).toUpperCase() + raw.slice(1))
                            : 'Prioridad NA';
                        var cssKey = isValid ? raw : 'none';
                        return (_jsx("div", { className: "OrdenCard-prioridad OrdenCard-prioridad-".concat(cssKey), children: display }));
                    })(), _jsx("div", { className: "OrdenCard-solicitud-title", children: "SOLICITUD" }), _jsx("div", { className: "OrdenCard-solicitud-date", children: orden.id_solicitud_servicio ? orden.id_solicitud_servicio.creacion : 'N/A' })] })] }, orden._id));
};
export default OrdenCard;
