import axios from '../../../utils/config/axios.config';
// Obtener todos los tipos de equipos
export var getAllTiposEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos/tipos', options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Obtener un tipo de equipo por ID
export var getTipoEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/tipos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Crear un nuevo tipo de equipo
export var createTipoEquipo = function (token, tipoEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/tipos', tipoEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Nueva función para crear múltiples tipos de equipos en masivo
export var createMultipleTiposEquipos = function (token, tiposEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/tipos/crear-masivo', tiposEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Actualizar un tipo de equipo por ID
export var updateTipoEquipo = function (token, id, tipoEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos/tipos?id=".concat(id), tipoEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Eliminar un tipo de equipo por ID
export var deleteTipoEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/tipos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) { return handleError(error); });
};
// Manejar errores
var handleError = function (error) {
    if (error.response) {
        var _a = error.response, status_1 = _a.status, data = _a.data;
        if (status_1 === 500) {
            console.error("Error 500: ".concat(data));
            window.alert('ERROR: No puedes hacer esto');
        }
    }
    else {
        console.error('Error desconocido:', error);
        window.alert('ERROR: No puedes hacer esto');
    }
    throw error;
};
