var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
export var exportSedesToExcel = function (sedes) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('Sedes');
                // Define las columnas del Excel
                worksheet.columns = [
                    { header: 'ID Sede', key: '_id', width: 30 },
                    { header: 'Nombre de Sede', key: 'sede_nombre', width: 25 },
                    { header: 'Dirección de Sede', key: 'sede_address', width: 30 },
                    { header: 'Teléfono de Sede', key: 'sede_telefono', width: 20 },
                    { header: 'Correo Electrónico de Sede', key: 'sede_email', width: 30 },
                    { header: 'ID Cliente', key: 'id_client._id', width: 30 },
                    { header: 'Cliente', key: 'id_client.client_name', width: 25 },
                    { header: 'NIT', key: 'id_client.nit', width: 20 },
                    { header: 'ID Municipio', key: 'id_municipio._id', width: 30 },
                    { header: 'Municipio', key: 'id_municipio.nombre', width: 25 },
                    { header: 'ID Departamento', key: 'id_municipio.id_departamento._id', width: 30 },
                    { header: 'Departamento', key: 'id_municipio.id_departamento.nombre', width: 25 },
                    { header: 'ID Responsable Órdenes', key: 'id_responsable_ordenes._id', width: 30 },
                    { header: 'Responsable Órdenes', key: 'id_responsable_ordenes.name', width: 25 },
                    { header: 'ID Responsable Preventivos', key: 'id_responsable_preventivos._id', width: 30 },
                    { header: 'Responsable Preventivos', key: 'id_responsable_preventivos.name', width: 25 },
                    { header: 'ID Responsable Correctivos', key: 'id_responsable_correctivos._id', width: 30 },
                    { header: 'Responsable Correctivos', key: 'id_responsable_correctivos.name', width: 25 },
                ];
                // Estilo para el encabezado
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // Blanco
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' }, // Azul oscuro
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });
                // Añadir las filas de datos al Excel y aplicar estilos condicionales
                sedes.forEach(function (sede) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                    var row = worksheet.addRow({
                        _id: sede._id || 'N/A',
                        sede_nombre: sede.sede_nombre || 'N/A',
                        sede_address: sede.sede_address || 'N/A',
                        sede_telefono: sede.sede_telefono || 'N/A',
                        sede_email: sede.sede_email || 'N/A',
                        'id_client._id': ((_a = sede.id_client) === null || _a === void 0 ? void 0 : _a._id) || 'N/A',
                        'id_client.client_name': ((_b = sede.id_client) === null || _b === void 0 ? void 0 : _b.client_name) || 'N/A',
                        'id_client.nit': ((_c = sede.id_client) === null || _c === void 0 ? void 0 : _c.nit) || 'N/A',
                        'id_municipio._id': ((_d = sede.id_municipio) === null || _d === void 0 ? void 0 : _d._id) || 'N/A',
                        'id_municipio.nombre': ((_e = sede.id_municipio) === null || _e === void 0 ? void 0 : _e.nombre) || 'N/A',
                        'id_municipio.id_departamento._id': ((_g = (_f = sede.id_municipio) === null || _f === void 0 ? void 0 : _f.id_departamento) === null || _g === void 0 ? void 0 : _g._id) || 'N/A',
                        'id_municipio.id_departamento.nombre': ((_j = (_h = sede.id_municipio) === null || _h === void 0 ? void 0 : _h.id_departamento) === null || _j === void 0 ? void 0 : _j.nombre) || 'N/A',
                        'id_responsable_ordenes._id': ((_k = sede.id_responsable_ordenes) === null || _k === void 0 ? void 0 : _k._id) || 'N/A',
                        'id_responsable_ordenes.name': ((_l = sede.id_responsable_ordenes) === null || _l === void 0 ? void 0 : _l.name) || 'N/A',
                        'id_responsable_preventivos._id': ((_m = sede.id_responsable_preventivos) === null || _m === void 0 ? void 0 : _m._id) || 'N/A',
                        'id_responsable_preventivos.name': ((_o = sede.id_responsable_preventivos) === null || _o === void 0 ? void 0 : _o.name) || 'N/A',
                        'id_responsable_correctivos._id': ((_p = sede.id_responsable_correctivos) === null || _p === void 0 ? void 0 : _p._id) || 'N/A',
                        'id_responsable_correctivos.name': ((_q = sede.id_responsable_correctivos) === null || _q === void 0 ? void 0 : _q.name) || 'N/A',
                    });
                    // Añadir borde a todas las celdas de la fila
                    row.eachCell({ includeEmpty: true }, function (cell) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'Sedes.xlsx');
                return [2 /*return*/];
        }
    });
}); };
