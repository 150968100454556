import axios from '../../../utils/config/axios.config';
export var getAllMunicipios = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/municipios', options)
        .then(function (response) { return response.data.municipios; }) // Ajusta para obtener la propiedad "municipios" de la respuesta
        .catch(handleError);
};
export var getMunicipioById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/municipios/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createMunicipio = function (token, municipioData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/municipios', municipioData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createMultipleMunicipios = function (token, municipiosData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/municipios/multiple', municipiosData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateMunicipio = function (token, id, municipioData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/municipios?id=".concat(id), municipioData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var deleteMunicipioById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/municipios/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // window.location.href = '/login';
            console.log(error);
        }
    }
    throw error;
};
