import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Page, Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import OrdenPDFHeader from '../OrdenPDFHeader';
import OrdenPDFDetails from '../OrdenPDFDetails';
import OrdenesPDFCotizacionesSection from '../OrdenesPDFCotizacionesSection';
import OrdenesPDFSignSection from '../OrdenesPDFSignSection';
import OrdenesPDFImagesSection from '../OrdenesPDFImagesSection';
import OrdenesPDFResultadoPreventivoSection from '../OrdenesPDFResultadoPreventivoSection';
import OrdenesPDFPreventivoSection from '../OrdenesPDFPreventivoSection'; // Importamos el nuevo componente
var styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12,
        position: 'relative',
        paddingBottom: 40,
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 10,
    },
    watermark: {
        position: 'absolute',
        fontSize: 60,
        color: 'rgba(150, 150, 150, 0.3)', // Un color gris claro con transparencia
        transform: 'rotate(-45deg)',
        top: '35%',
        left: '10%',
        textAlign: 'center',
    },
});
var OrdenAbiertaPreventivoPDF = function (_a) {
    var _b;
    var orden = _a.orden, cotizaciones = _a.cotizaciones, includeHeader = _a.includeHeader, includeDetails = _a.includeDetails, imageUrls = _a.imageUrls, firmaUrls = _a.firmaUrls, onBack = _a.onBack;
    var _c = useState(0), totalCotizacion = _c[0], setTotalCotizacion = _c[1];
    useEffect(function () {
        var calcularTotalCotizacion = function () {
            var total = 0;
            cotizaciones.forEach(function (cotizacion) {
                var totalRepuestos = (cotizacion.ids_repuestos || []).reduce(function (acc, repuesto) { return acc + (repuesto.cantidad || 0) * (repuesto.valor_unitario || 0); }, 0);
                var totalItemsAdicionales = (cotizacion.items_adicionales || []).reduce(function (acc, item) { return acc + (item.cantidad || 0) * (item.valor_unitario || 0); }, 0);
                total += totalRepuestos + totalItemsAdicionales;
            });
            return total;
        };
        setTotalCotizacion(calcularTotalCotizacion());
    }, [cotizaciones]);
    var formatCurrency = function (cantidad, valorUnitario) {
        return (cantidad * valorUnitario).toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    };
    var formatCurrencyValorU = function (value) {
        return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
    };
    // Verificamos si alguna actividad tiene id_protocolo.title igual a "Preventivo"
    var tieneActividadesPreventivo = (_b = orden.ids_visitas) === null || _b === void 0 ? void 0 : _b.some(function (visita) {
        return visita.actividades.some(function (actividad) { return actividad.id_protocolo.title === "Preventivo"; });
    });
    return (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: styles.page, children: [_jsx(Text, { style: styles.watermark, children: "ORDEN ABIERTA" }), includeHeader && _jsx(OrdenPDFHeader, { orden: orden }), includeDetails && _jsx(OrdenPDFDetails, { orden: orden }), _jsx(OrdenesPDFCotizacionesSection, { cotizaciones: cotizaciones, formatCurrency: formatCurrency, formatCurrencyValorU: formatCurrencyValorU, totalCotizacion: totalCotizacion }), tieneActividadesPreventivo && (_jsx(OrdenesPDFPreventivoSection, { orden: orden })), _jsx(OrdenesPDFResultadoPreventivoSection, { orden: orden }), _jsx(OrdenesPDFImagesSection, { orden: orden, imageUrls: imageUrls }), "  ", _jsx(OrdenesPDFSignSection, { orden: orden, firmaUrls: firmaUrls }), " ", _jsx(View, { style: styles.footer, fixed: true, children: _jsx(Text, { render: function (_a) {
                            var pageNumber = _a.pageNumber, totalPages = _a.totalPages;
                            return "P\u00E1gina ".concat(pageNumber, " de ").concat(totalPages);
                        } }) })] }) }));
};
export default OrdenAbiertaPreventivoPDF;
