var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getPresignedUrlForGet, getVisitaById } from '../../../visitas/services/visitasService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import TecnicoEnSede from './TecnicoEnSede'; // Asegúrate de que la ruta de importación sea correcta
import RegisterTecnicoEnSede from './RegisterTecnicoEnSede';
import './styles/ActividadesEjecutar.css';
import EquipoDisponibilidad from './EquipoDisponibilidad';
import CompleteActivitiesVisita from './CompleteActivitiesVisita';
var ActividadesEjecutar = function (_a) {
    var idVisita = _a.idVisita;
    var token = useSessionStorage('sessionJWTToken');
    var _b = useState(null), visita = _b[0], setVisita = _b[1];
    var _c = useState(0), refreshKey = _c[0], setRefreshKey = _c[1];
    // Función para actualizar el estado y forzar re-renderización
    var refreshActividades = function () {
        setRefreshKey(function (prevKey) { return prevKey + 1; });
    };
    useEffect(function () {
        if (token && idVisita) {
            getVisitaById(token, idVisita)
                .then(function (data) { return __awaiter(void 0, void 0, void 0, function () {
                var actividadesConUrls;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(data && data.actividades)) return [3 /*break*/, 2];
                            return [4 /*yield*/, Promise.all(data.actividades.map(function (actividad) { return __awaiter(void 0, void 0, void 0, function () {
                                    var urlPath, s3ObjectKey, presignedUrlData;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!actividad.id_image) return [3 /*break*/, 2];
                                                urlPath = new URL(actividad.id_image).pathname;
                                                s3ObjectKey = urlPath.startsWith('/') ? urlPath.substring(1) : urlPath;
                                                return [4 /*yield*/, getPresignedUrlForGet(token, s3ObjectKey)];
                                            case 1:
                                                presignedUrlData = _a.sent();
                                                // Agregar la URL firmada al objeto de actividad
                                                return [2 /*return*/, __assign(__assign({}, actividad), { presignedImageUrl: presignedUrlData.presignedUrl })];
                                            case 2: return [2 /*return*/, actividad];
                                        }
                                    });
                                }); }))];
                        case 1:
                            actividadesConUrls = _a.sent();
                            // Actualizar el estado con las actividades que incluyen las URLs firmadas
                            setVisita(__assign(__assign({}, data), { actividades: actividadesConUrls }));
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (error) {
                console.error('Error al obtener la visita por ID:', error);
            });
        }
    }, [token, idVisita, refreshKey]);
    // Lógica para decidir qué componente renderizar
    var renderActividad = function () {
        if (!visita || !visita.actividades || visita.actividades.length === 0) {
            // No hay actividades, mostrar componente para registrar actividad "Técnico en sede"
            return _jsx(RegisterTecnicoEnSede, { idVisita: idVisita, onActividadRegistrada: refreshActividades });
        }
        else if (visita.actividades[0].id_protocolo.title === "Técnico en sede") {
            // La actividad en posición 0 es "Técnico en sede", mostrar componente para ver detalles y la seleccion de disponibilidad del equipo
            return (_jsxs(_Fragment, { children: [_jsx(TecnicoEnSede, { actividad: visita.actividades[0] }), _jsx(EquipoDisponibilidad, { idVisita: idVisita, onActividadesUpdated: refreshActividades, visita: visita }), _jsx(CompleteActivitiesVisita, { idVisita: idVisita, onActividadesUpdated: refreshActividades })] }));
        }
        // Opcionalmente, manejar otros casos o simplemente no renderizar nada
        return null;
    };
    return (_jsxs("div", { className: 'ActividadesEjecutar-div', children: [_jsx("div", { className: 'ActividadesEjecutar-title', children: "EJECUTANDO VISITA" }), _jsx("div", { className: 'ActividadesEjecutar-container', children: renderActividad() }, refreshKey)] }));
};
export default ActividadesEjecutar;
