import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import DefaultSedeImg from './img/DefaultSedeImg.png';
import Checkbox from '@mui/material/Checkbox';
import './styles/SedeCard.css';
var SedeCard = function (_a) {
    var _b;
    var sede = _a.sede, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var navigate = useNavigate();
    var navigateToSedeDetail = function (id) {
        navigate("/sedes/".concat(id));
    };
    var liClassName = "SedesPages-card-section ".concat(isSelected ? 'SedesPages-selected' : '', "\n  }");
    return (_jsx("li", { className: liClassName, children: _jsxs("a", { type: '#', className: 'SedesPages-card', children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                        e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                        onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                    }, className: "SolicitudServicioCard-select-checkbox", sx: {
                        position: 'absolute',
                        top: '10px',
                        left: '87%',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                        zIndex: 5, // Asegura que esté sobre los demás elementos
                        transform: 'scale(1.5)',
                        '&.Mui-checked': {
                            color: '#00ddfa',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0, 221, 250, 0.1)',
                            borderRadius: '50%',
                        },
                        '& .MuiSvgIcon-root': {
                            fill: '#00ddfa',
                        },
                        '& input': {
                            zIndex: 3, // Ajuste del z-index del input en el DOM
                        },
                    } })), _jsx("div", { className: 'SedesPages-card-cover' }), _jsxs("div", { className: 'SedesPages-card-overlay', children: [_jsxs("div", { className: 'SedesPages-card-header', children: [_jsx("img", { src: DefaultSedeImg, className: 'SedesPages-card-SedeImage', alt: '', height: '70px', width: '70px' }), _jsxs("div", { className: 'SedesPages-card-header-text', children: [_jsx("h3", { className: 'SedesPages-card-title', onClick: onClick, children: sede.sede_nombre }), _jsx("span", { className: 'SedesPages-card-status', children: ((_b = sede.id_client) === null || _b === void 0 ? void 0 : _b.client_name) || 'N/A' })] })] }), _jsxs("p", { className: 'SedesPages-card-description', children: ["ID: ", sede._id] }), _jsxs("p", { className: 'SedesPages-card-description', children: ["Direcci\u00F3n: ", sede.sede_address] }), _jsxs("p", { className: 'SedesPages-card-description', children: ["Tel\u00E9fono: ", sede.sede_telefono] }), _jsxs("p", { className: 'SedesPages-card-description', children: ["Email: ", sede.sede_email] })] })] }) }, sede._id));
};
export default SedeCard;
