import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../hooks/useSessionStorage';
import useUserRoleVerifier from '../hooks/useUserRoleVerifier';
import { logoutService } from '../../users/services/authService';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import RegisterContratoForm from '../components/contratos/forms/RegisterContratoForm';
export var RegisterContratoPage = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var isAdmin = useUserRoleVerifier(['administrador']);
    var navigate = useNavigate();
    useEffect(function () {
        if (!loggedIn) {
            logoutService();
        }
    }, [loggedIn]);
    return (_jsxs(_Fragment, { children: [_jsx(DashboardMenuLateral, {}), _jsx(RegisterContratoForm, {})] }));
};
