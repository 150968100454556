var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
export var exportUsersToExcel = function (users) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, worksheet, buffer, blob;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workbook = new ExcelJS.Workbook();
                worksheet = workbook.addWorksheet('Users');
                // Define las columnas del Excel
                worksheet.columns = [
                    { header: 'ID Usuario', key: '_id', width: 30 },
                    { header: 'Número', key: 'number', width: 15 },
                    { header: 'Nombre de Usuario', key: 'username', width: 25 },
                    { header: 'Nombre Completo', key: 'name', width: 30 },
                    { header: 'Cédula', key: 'cedula', width: 20 },
                    { header: 'Teléfono', key: 'telefono', width: 20 },
                    { header: 'Correo Electrónico', key: 'email', width: 30 },
                    { header: 'Más Información', key: 'more_info', width: 30 },
                    { header: 'Roles', key: 'roles', width: 40 },
                    { header: 'Tipo', key: 'type', width: 20 },
                    { header: 'Título', key: 'titulo', width: 20 },
                    { header: 'Registro Invima', key: 'reg_invima', width: 20 },
                    { header: 'Municipio', key: 'id_municipio', width: 30 }
                ];
                // Estilo para el encabezado
                worksheet.getRow(1).eachCell(function (cell) {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // Blanco
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF0073AA' }, // Azul oscuro
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });
                // Añadir las filas de datos al Excel y aplicar estilos condicionales
                users.forEach(function (user) {
                    var _a, _b;
                    var roles = ((_a = user.roles) === null || _a === void 0 ? void 0 : _a.map(function (role) { return role.name; }).join(', ')) || 'N/A'; // Mapear los roles a un string separado por comas
                    var row = worksheet.addRow({
                        _id: user._id || 'N/A',
                        number: user.number || 'N/A',
                        username: user.username || 'N/A',
                        name: user.name || 'N/A',
                        cedula: user.cedula || 'N/A',
                        telefono: user.telefono || 'N/A',
                        email: user.email || 'N/A',
                        more_info: user.more_info || 'N/A',
                        roles: roles,
                        type: user.type || 'N/A',
                        titulo: user.titulo || 'N/A',
                        reg_invima: user.reg_invima || 'N/A',
                        id_municipio: ((_b = user.id_municipio) === null || _b === void 0 ? void 0 : _b._id) || 'N/A'
                    });
                    // Añadir borde a todas las celdas de la fila
                    row.eachCell({ includeEmpty: true }, function (cell) {
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                });
                return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buffer = _a.sent();
                blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'Users.xlsx');
                return [2 /*return*/];
        }
    });
}); };
