// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardStyles{
    font-size: 15px;
    text-decoration:none;
    display:flex;
    height: 100%;
    width: 100%;
}

.MenuCentral{
    
    padding: 90px 10px 50px 100px;
}

.DashboardStylesCuadros {
    
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    
    
}`, "",{"version":3,"sources":["webpack://./src/modules/users/components/dashboard/styles/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;IACpB,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;;IAEI,6BAA6B;AACjC;;AAEA;;IAEI,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;;;AAG1B","sourcesContent":[".DashboardStyles{\n    font-size: 15px;\n    text-decoration:none;\n    display:flex;\n    height: 100%;\n    width: 100%;\n}\n\n.MenuCentral{\n    \n    padding: 90px 10px 50px 100px;\n}\n\n.DashboardStylesCuadros {\n    \n    display: flex;\n    justify-content: space-evenly;\n    flex-direction: column;\n    \n    \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
